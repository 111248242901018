import React from 'react'
import TOCSection from './TOC-Section'
import { PlusIcon } from '@heroicons/react/solid'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import DropTarget from '../../../../../../components/drag-drop/DropTarget'
import { DRAG_N_DROP_TYPES } from '../../../../../../constants'
import ChapterSuggestions from '../StorySuggestions/ChapterSuggestions'
import ChapterTemplates from '../chapter-templates'
import SmallLoadingSpinner from '../../../../../../components/loading/SmallLoadingSpinner'

const TableOfContents = ({ activeChapters, futureChapters, completedChapters, selectedChapterId, setSelectedChapterId, unpublishedChapters, queryKeys, tasksListQueryKey }) => {

    const isMeetingMode = window.location.href.includes("/meeting-mode")

    const sections = isMeetingMode ?
        [
            {
                title: "Active",
                type: "active",
                chapters: activeChapters,
                query_key: queryKeys.active
            },
            {
                title: "Future",
                type: "future",
                chapters: futureChapters,
                query_key: queryKeys.future
            },
            {
                title: "Completed",
                type: "completed",
                chapters: completedChapters,
                query_key: queryKeys.completed
            },
        ] :
        [
            {
                title: "Unpublished",
                type: "unpublished",
                chapters: unpublishedChapters,
                query_key: queryKeys.unpublished
            },
            {
                title: "Active",
                type: "active",
                chapters: activeChapters,
                query_key: queryKeys.active
            },
            {
                title: "Future",
                type: "future",
                chapters: futureChapters,
                query_key: queryKeys.future
            },
            {
                title: "Completed",
                type: "completed",
                chapters: completedChapters,
                query_key: queryKeys.completed
            },
        ]

    const { household_id: householdId } = useParams()
    const chaptersApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_CHAPTERS, API_AUTH_TYPES.ADVISOR);
    const queryClient = useQueryClient();
    const { mutate: createChapter, isLoading: isCreatingChapter } = useMutation(
        () => chaptersApi.post({ endpoint: "create", body: { household_id: householdId ,is_meeting_mode: isMeetingMode} }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isMeetingMode ? queryKeys.active : queryKeys.unpublished);
            },
        }

    );


    const { mutate: handleReorderToEnd } = useMutation(
        (item) =>
            chaptersApi.patch({
                endpoint: "reorder_to_end", body: {
                    item_list_id: item.content.props.chapter?.status, item_id: item.content.props.chapter?.chapter_id, item_type: DRAG_N_DROP_TYPES.CHAPTER,
                    dropped_over_list_id: item.dropped_over_list_id, household_id: householdId
                }
            })
        ,
        {
            onSuccess: (body) => {
                queryClient.invalidateQueries(sections.find(section => section.type === body.item_list_id).query_key);
                queryClient.invalidateQueries(sections.find(section => section.type === body.dropped_over_list_id).query_key);


            },
        }

    );



    return (
        <div className="">
            <h1 className="text-xl mb-1 font-semibold leading-7 text-gray-700 text-left   sm:leading-9 " >
                Table Of Contents </h1>

            <div className='px-5 py-2 bg-white mb-10 rounded-lg shadow-lg flex flex-col '>
                <button className="mr-auto mb-2 mt-5 flex items-center justify-center w-full btn-md btn-gray-outline-rounded"
                    onClick={createChapter}>
                    {isCreatingChapter ? <SmallLoadingSpinner /> : <PlusIcon className=" h-5 w-5 text-blue-500" />}  <p className="text-left text-lg ml-3">
                        {isMeetingMode ? "New Chapter" : 'Blank New Chapter'}
                    </p>

                </button>
                {!isMeetingMode && <ChapterTemplates queryKeys={queryKeys} />}
                <div className="mt-2" />
                {!isMeetingMode && <ChapterSuggestions queryKeys={queryKeys} />}
                <ul className=' '>
                    {sections && sections.map((section) => (
                        <li key={section.title}>

                            <TOCSection section={section} sections={sections} selectedChapterId={selectedChapterId} setSelectedChapterId={setSelectedChapterId} queryKeys={queryKeys} tasksListQueryKey={tasksListQueryKey} />
                            <DropTarget accept={'chapter'} onDrop={(item) => {
                                item.dropped_over_list_id = section.title.toLowerCase()
                                handleReorderToEnd(item)
                            }} isActiveStyling={'drop-target py-10'}>
                                <div className="h-5" />
                            </DropTarget>
                        </li>

                    ))
                    }
                </ul>



            </div>

        </div>)
}

export default TableOfContents