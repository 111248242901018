import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { classNames } from "../../../../../helpers/classNames";
import { BiFilter } from "react-icons/bi";
import ButtonDropdown from "../../../../ButtonDropdown";
import SmartTableFiltersRow from "./SmartTable___Filters___Row";

import useArray from "../../../../../hooks/useArray";
import immutableArrayMethods from "./../../../../../helpers/immutableArrayMethods";
import { ApiRequest, API_AUTH_TYPES, API_ROUTES } from "../../../../../api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import areArraysIdentical from "../../../../../helpers/arrayArraysIdentical";

const SmartTableFilters = ({ columns, dbFilters, viewId }) => {
  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.ADVISOR)
  const { data: advisors, isLoading } = useQuery({ queryFn: usersApi.getFn({ endpoint: 'index_advisors' },), queryKey: 'advisors' });
  const filters = useArray(dbFilters)
  const [changesMade, setChangesMade] = useState(false);
  useEffect(() => {
    setChangesMade(!areArraysIdentical(filters.array, dbFilters));
  }, [filters.array, dbFilters]);

  const [open, setOpen] = useState(false);



    const viewsApi = new ApiRequest(
      API_ROUTES.SMART_TABLE_VIEWS,
      API_AUTH_TYPES.ADVISOR
    );
    const handleAddCondition = async () => {
     const newFilter = {
       col_id: columns[0].col_id,
       operator: "is not empty",
       col_type: columns[0].col_type,
       comparison: null,
       options: columns[0].options || null,
     };
      filters.push(newFilter);
  };

  useEffect(() => {
    filters.set(dbFilters);
  }, [dbFilters]);
  
  const handleSaveChanges = async () => {
    try {
      viewsApi.patch({
        endpoint: 'update', body: {
          column: "filters",
          value: filters.array,
          id: viewId,
        }
      });
    } catch (error) { }
  };

    const queryClient = useQueryClient();
  const { mutate: handleApplyFilterClick } = useMutation(handleSaveChanges, {
    onSuccess: () => {
      queryClient.invalidateQueries("smart-table")
      setChangesMade(false);

    },
  });
  


  return (
    <div className="">
      <ButtonDropdown open={open} setOpen={setOpen}>
        {" "}
        <div className="p-2 min-w-[600px]">
          {" "}
          <p className="text-sm w-max mb-3">In this view, show records</p>
          <ul className="space-y-2">
            {" "}
            {filters.array?.map((filter, index) => (
              <SmartTableFiltersRow
                filters={filters}
                advisors={advisors}
                columns={columns}
                filter={filter}
                key={index}
                thisFilterIndex={index}
              
              />
            ))}
          </ul>
          <div className="flex justify-between">
            <button
            onClick={handleAddCondition}
            className="mt-3 font-semibold p-2 text-gray-600 hover:text-gray-900 text-sm"
          >
            + Add Condition
            </button>

            <button
              onClick={handleApplyFilterClick}
              className={classNames('mt-3 font-semibold px-2 py-1 text-gray-600 hover:text-gray-900 transition duration-200 ease-in hover:bg-gray-100 hover:shadow-md text-sm border rounded-full', changesMade && 'bg-blue-200 hover:bg-blue-300 animate-pulse')}
            >
             Apply Changes
            </button>
          </div>
       
        </div>
      </ButtonDropdown>
      <button
        onClick={() => setOpen(true)}
        className={classNames(
          "rounded hover:ring-2 hover:ring-gray-300 text-gray-600  px-2 py-1 flex space-x-1 items-center",
          filters.array?.length > 0 && "bg-green-100"
        )}
      >
        <BiFilter className="h-6 w-6" />
        <p className="hidden xl:flex">
          {" "}
          {filters.array?.length === 0
            ? "Filter"
            : `${filters.array?.length} filter${filters.array?.length > 1 ? "s" : ""}`}
          {changesMade && ' *'}
        </p>
      </button>
    </div>
  );
};

export default SmartTableFilters;
