import { PlusIcon } from "@heroicons/react/outline";
import ClipboardCopy from "../../../../components/copy-to-clipboard/ClipboardCopy";
import ContactSlideOver from "../Settings/components/Slider/Slider";
import { useState } from "react";
import {  ToolTipHoverContainer, ToolTipLeft } from "../../../../components/ToolTips";
import getStateName from "../../../../helpers/getStateName";


/**
 * Displays contacts associated with a household, including functionality for editing and adding new contacts.
 * It uses a slide-over component for detailed views and editing of contact information. Additionally, it allows for 
 * quick actions like copying email addresses to the clipboard.
 *
 * Props:
 * - householdMeta: Object containing metadata of the household.
 * - contactsMeta: Array containing contact information for each member associated with the household.
 *
 * @returns {JSX.Element} A component that renders a list of contacts with options to view, edit, and add new contacts.
 */
export default function Contacts({
  householdMeta, contactsMeta
}) {
  const [sliderType, setSliderType] = useState("");
  const [contactSlideOverOpen, setContactSlideOverOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [sliderTitle, setSliderTitle] = useState("");
  const [sliderSubtitle, setSliderSubtitle] = useState("");

  // Opens the contact editor slide-over with the selected user's details
  const handleOpenContact = (user) => {
    setSelectedContact(user);
    setSliderType("edit user");
    setSliderTitle("Contact Information");
    setSliderSubtitle("Edit contact details");
    setContactSlideOverOpen(true);
  };

  // Prepares the slide-over for adding a new user
  const handleNewUser = () => {
    setSelectedContact({});
    setSliderType("new user");
    setSliderTitle("Contact Information");
    setSliderSubtitle("Add new contact details");
    setContactSlideOverOpen(true);
  };

  // Calculates the age based on the date of birth
  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className="">
      <ContactSlideOver
        open={contactSlideOverOpen}
        setOpen={setContactSlideOverOpen}
        person={selectedContact}
        title={sliderTitle}
        subtitle={sliderSubtitle}
        type={sliderType}
        householdId={householdMeta.household_id}
      />
      {" "}
      {householdMeta && (
        <>
          <h2 className="sr-only" id="profile-overview-title">
            Contacts
          </h2>
          <div className="relative space-y-5">

              {contactsMeta &&
                contactsMeta.map((user) => (
                  <div key={user.user_id} className="flex flex-col items-start">
                    <button
                      key={user.user_id}
                      onClick={() => {
                        handleOpenContact(user);
                      }}
                      className="text-base mb-1 py-0 text-left font-medium text-gray-700 hover:underline  "
                    >
                      <p>
                        {user.first_name}
                        {user.preferred_first_name && user.preferred_first_name !== user.first_name && ` (${user.preferred_first_name})`}{' '}
                        {user.last_name}
                        {user.preferred_last_name && user.preferred_last_name !== user.last_name && ` (${user.preferred_last_name})`}
                      </p>                    </button>
                    <div className="flex  space-x-1">
                      {" "}
                      <a
                        href={`mailto:${user.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="  flex space-x-1 -mt-2 items-center text-gray-500 max-w-[200px] truncate "
                      >
                        <span>{user.email}</span>
                      </a>{" "}
                      <ClipboardCopy text={user.email} />
                    </div>
                    {user.secondary_email && <div className="flex  space-x-1 ">
                      {" "}
                      <a
                        href={`mailto:${user.secondary_email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="  flex space-x-1 -mt-2 items-center text-gray-500"
                      >
                        <span>{user.secondary_email}</span>
                      </a>{" "}
                      <ClipboardCopy text={user.secondary_email} />
                    </div>}

                    {user.dob && 
                      
                      <p className="text-gray-600 -mt-1">
                        {calculateAge(user.dob)} years old
                      </p>}
                    {
                      user.state &&
                      <p className="text-gray-600 -mt-1">
                        {getStateName(user.state)}
                      </p>
                    }
                    {
                      user.gender &&
                      <p className="text-gray-600 -mt-1">
                        {user.gender}
                      </p>
                    }
                  </div>
                ))}

              <div className="absolute -top-4 right-0 ">
                <ToolTipHoverContainer>
                    <button
                      onClick={handleNewUser}
                      className=" "

                    >
                      <PlusIcon className="h-5 w-5   text-gray-500" />
                  </button>
                  <ToolTipLeft >
                    <p className="text-sm ">Add a new contact</p>
                  </ToolTipLeft>
                </ToolTipHoverContainer>
              </div>
             
            </div>
        </>
      )}
    </div>
  );
}
