import React, { useEffect, useState } from 'react'
import socketIoConnection from '../../../../../../socketIoConnect';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SparklesIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api';
import useArray from "../../../../../../hooks/useArray"
import FadeIn from '../../../../../../components/transitions/FadeIn';
import ParabotLoading from '../../../../../../components/ParabotLoading';
import SuggestionsController from './SuggestionsController';

const OngoingAdviceSuggestions = ({ queryKeys, chapterId }) => {
    const { household_id: householdId } = useParams()
    const [loading, setLoading] = useState(false);
    const [suggestionsHidden, setSuggestionsHidden] = useState(false);
    const [socket, setSocket] = useState(null);
    const suggestions = useArray([])
    useEffect(() => {
        const socket = socketIoConnection("story-generator");
        setSocket(socket);
        socket.on(`completion-done-${chapterId}`, (newSuggestions) => {
            suggestions.set(newSuggestions);
            setLoading(false)
        });


        return () => {
            socket.disconnect();
        };
    }, []);

    const createSuggestions = () => {
        socket.emit("ongoing-advice-suggestions", {
            chapter_id: chapterId,
            household_id: householdId,
        });
        setLoading(true)
        setSuggestionsHidden(false)
    }


    const goalsApi = new ApiRequest(API_ROUTES.HOUSEHOLD_GOALS, API_AUTH_TYPES.ADVISOR);
    const queryClient = useQueryClient();
    const { mutate: createSuggestion } = useMutation(
        ({ description }) => goalsApi.post({ endpoint: "post_goal", body: { household_id: householdId, description,chapter_id:chapterId } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.goals);
            },
        }

    );
    return (
        <div>
            <SuggestionsController loading={loading} hidden={suggestionsHidden} setHidden={setSuggestionsHidden} suggestionsCreated={suggestions.array.length > 0}
          
          createSuggestions={createSuggestions}  />
            <FadeIn isShowing={!loading && !suggestionsHidden}>
                <ul className='space-y-2'>
                    {suggestions.array && suggestions.array.map((suggestion, index) => {
                        return <li key={suggestion.content}>
                            <button className='btn-md btn-gray-outline w-full text-left text-gray-500'
                                onClick={() => {
                                    createSuggestion({ description: suggestion.content });
                                    suggestions.remove(index)
                                }}
                            >
                                <SparklesIcon className='h-5 w-5 flex-shrink-0 text-blue-500 mr-3' />
                                {suggestion.content}
                            </button>
                        </li>
                    })}
                </ul>
            </FadeIn>
        </div>
    )
}

export default OngoingAdviceSuggestions