import React from "react";
import * as DOMPurify from "dompurify";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { addHttp } from "../Quill-Text-Editor/utils";
import { useMemo } from "react";
import "./styles.css";

/**
 * Adds a hook to DOMPurify to set all elements owning target to target=_blank.
 * This enhances security when rendering user-generated content with links.
 * @param {object} node - The DOM element to sanitize.
 */
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // Check if the node is an anchor element with an href attribute
  if (node.tagName === "A" && node.hasAttribute("href")) {
    const href = node.getAttribute("href");
    // Check if the href contains "abundowealth.com"
    if (!href.includes("abundowealth.com")) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    } else {
      node.removeAttribute("target");
      node.removeAttribute("rel");
    }
  }
});



/**
 * RichTextDisplay component for rendering rich text content.
 * @param {object} content - The rich text content to render.
 * @param {string} thisClassName - The CSS class name for the container div.
 */
const RichTextDisplay = ({ content, thisClassName,query }) => {
  const html = useMemo(() => {
    const converter = new QuillDeltaToHtmlConverter(content.ops, {
      linkRel: "noreferrer noopener",
      linkTarget: "_blank",
      urlSanitizer: addHttp,
      inlineStyles: {
        // font: {
        //   serif: "font-family: Georgia, Times New Roman, serif",
        //   monospace: "font-family: Monaco, Courier New, monospace",
        // },
        // size: {
        //   small: "font-size: 0.5em",
        //   large: "font-size: 1.5em",
        //   huge: "font-size: 2.5em",
        // },
        indent: (value, op) => {
          var indentSize = parseInt(value, 10) * 3;
          var side = op.attributes["direction"] === "rtl" ? "right" : "left";
          return "padding-" + side + ":" + indentSize + "em";
        },
        list: (value, op) => {
          var indentSize = parseInt(value, 10) * 3;
          var side = op.attributes["direction"] === "rtl" ? "right" : "left";
          return "padding-" + side + ":" + indentSize + "em";
        },
        direction: (value, op) => {
          if (value === "rtl") {
            return (
              "direction:rtl" +
              (op.attributes["align"] ? "" : "; text-align: inherit")
            );
          } else {
            return "";
          }
        },
      },
    });
    return converter.convert();
  }, [content.ops]);


  const sanitizedHtml = DOMPurify.sanitize(html);

  const renderContent = useMemo(() => {
    if (query && query.trim() !== "") {
      // Escape special characters in the query to safely use it in a regex pattern
      const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedQuery, 'gi');
      const highlightedHtml = sanitizedHtml.replace(regex, match => `<span class="highlighted">${match}</span>`);
      return { __html: highlightedHtml };
    } else {
      return { __html: sanitizedHtml };
    }
  }, [sanitizedHtml, query]);

  
  return (
    <div
      className={thisClassName}
      dangerouslySetInnerHTML={renderContent}
    ></div>
  );
};

export default RichTextDisplay;
