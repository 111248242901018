import React from 'react';
import { dateFormatter, timeToDate } from '../../../../helpers/formatters';
import Highlighter from 'react-highlight-words';

const Reminder = ({ reminder, query }) => {
    // Ensure the query is an array. If it's a string, convert it into an array.
    const searchWords = Array.isArray(query) ? query : [query];

    return (
        <div className="w-full space-y-2">
            <div className="flex items-center justify-end w-full space-x-3 text-sm">
                <div className="flex justify-between w-full">
                    {reminder.date && (
                        <>
                            <p>{dateFormatter.format(new Date(reminder.date))}</p>
                            <p>{timeToDate(new Date(reminder.date))}</p>
                        </>
                    )}
                </div>
            </div>
            <Highlighter
                highlightClassName="highlighted" // Use your own highlight class
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={reminder.title} // Text you want to highlight
            />
            <Highlighter
                highlightClassName="highlighted text-sm text-gray-600" // Use your own highlight class
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={reminder.description} // Text you want to highlight
            />
        </div>
    );
};

export default Reminder;
