import React, { useCallback, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorHandling/error-fallback-page";
import { useSelector } from "react-redux";
import { authenticateAdvisorAdmin } from "../../helpers/jwtAuth";

export default function AdvisorRoute({ component: Component, ...rest }) {
  //state
  const [isAdvisor, setIsAdvisor] = useState(false);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  const authenticate = useCallback(async () => {
    try {
      const auth = await authenticateAdvisorAdmin(accessToken);
      setIsAdvisor(auth.is_advisor);
      setLoading(false);
    } catch (error) {
      setIsAdvisor(false);
      setLoading(false);
    }
  }, [accessToken]);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) authenticate();
    return () => {
      isCancelled = true;
    };
  }, [authenticate]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Route
        {...rest}
        render={(props) => {
          return !loading ? (
            <>
              {currentUser && isAdvisor ? (
                <Component {...props} />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          ) : null;
        }}
      ></Route>
    </ErrorBoundary>
  );
}
