import React from "react";
import { classNames } from "./../../../../helpers/classNames";
import ItemMenu from "./../../../menus/ItemMenu";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import {
  useDeleteUniqueMutation,
  useUpdateMutation,
} from "../../../../api/apiSlice";
import { useState } from "react";
import DoubleClickEdit from "./../../../DoubleClickEdit";

const View = ({
  view,
  thisViewIndex,
  setSelectedViewId,
  selectedViewId,
  isColaborativeViews,
  myViewsLength,
  allViewsLength,
}) => {
  const [update] = useUpdateMutation();
  const [deleteUnique] = useDeleteUniqueMutation();
  const [showEditName, setShowEditName] = useState(false);
  const [name, setName] = useState(view.name);

  const calculateIsSelectedView = () => {
        if (view.smart_table_view_id === selectedViewId) return true;
    if (isColaborativeViews && myViewsLength > 0) return false;
    if (selectedViewId === 'default' && thisViewIndex === 0) return true;
  };
  const isSelectedView = calculateIsSelectedView();

  const handleSetSelectedView = () => {
    setSelectedViewId(view.smart_table_view_id);
  };
  const deleteView = async () => {
    try {
      
      if (allViewsLength === 1) return;
      await deleteUnique({
        params: [view.smart_table_view_id],
        id: view.smart_table_view_id,
      });
    } catch (error) {}
  };
  const renameView = async () => {
    try {
      update({
        id: view.smart_table_view_id,
        body: {
          column: "name",
          id: view.smart_table_view_id,
          value: name,
        },
      });
    } catch (error) {}
  };
  const menuOptions = [
    {
      title: "Rename",
      action: () => setShowEditName(true),
      icon: PencilIcon,
    },
    {
      title: "Delete",
      action: deleteView,
      icon: TrashIcon,
    },
  ];
  return (
    <>
      <div
        onClick={handleSetSelectedView}
        className={classNames(
          "py-2 px-2 rounded-sm z-0 w-full flex justify-between items-center",
          isSelectedView && "bg-blue-100",
          !isSelectedView && "hover:bg-gray-100"
        )}
      >
        {" "}
        <div className="z-10">
          <DoubleClickEdit
            defaultComponent={
              <p className="text-sm text-gray-700 font-semibold text-left truncate w-52">{name}</p>
            }
            editComponent={
              <input
                className="input-primary"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            }
            setShowEdit={setShowEditName}
            showEdit={showEditName}
            handleSubmitEdit={renameView}
          />{" "}
        </div>
        <ItemMenu options={menuOptions} />
      </div>
    </>
  );
};

export default View;
