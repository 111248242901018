import { combineReducers, configureStore } from "@reduxjs/toolkit";
import advisorReducer from "./Slices/advisorSlice";
import investReducer from "./Slices/investSlice";
import userReducer from "./Slices/userSlice";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import adminSlice from "./Slices/adminSlice";
import messagesSlice from "./Slices/messagesSlice";
import videoSlice from "./Slices/videoSlice";
// import logger from "redux-logger"
import utilitiesSlice from "./Slices/utilitiesSlice";
import tutorialSlice from "./Slices/tutorialSlice";
import blogSlice from "./Slices/blogSlice";
import { apiSlice }  from './../api/apiSlice';

const reducers = combineReducers({
  advisor: advisorReducer,
  invest: investReducer,
  user: userReducer,
  admin: adminSlice,
  messages: messagesSlice,
  util: utilitiesSlice,
  tutorial: tutorialSlice,
  video: videoSlice,
  blog: blogSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export default store;
