import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../api';
import { useEffect } from 'react';

const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR);
const smartTableApi = new ApiRequest(API_ROUTES.SMART_TABLE, API_AUTH_TYPES.ADVISOR);

const commonlyUsedTables = [
    { tableId: 'client_meetings', selectedViewId: 'default' },
    { tableId: 'households', selectedViewId: 'default' },
    { tableId: 'users', selectedViewId: 'default' },
    { tableId: 'prospects', selectedViewId: 'default' },
    { tableId: 'onboarding', selectedViewId: 'default' }
];

const useAdvisorPreloadedData = (parentDataLoaded) => {
    const queryClient = useQueryClient();

    // Prefetch email threads
    useQuery({
        queryFn: bunmailApi.getFn({
            endpoint: 'index_threads',
            params: ['inbox', '1',false],
        }),
        queryKey: ['index_threads', 'inbox', '1',false],
        enabled: parentDataLoaded,  // Only run when parent data is loaded
        refetchOnWindowFocus: false,
    });

    // Once parent data is loaded, prefetch common smart tables
    useEffect(() => {
        if (parentDataLoaded) {
            commonlyUsedTables.forEach(({ tableId, selectedViewId }) => {
                queryClient.prefetchInfiniteQuery({
                    queryKey: ['smart-table', { tableId, selectedViewId }],
                    queryFn: ({ pageParam = 0 }) =>
                        smartTableApi.get({
                            endpoint: 'table',
                            params: [tableId, selectedViewId, pageParam, false],
                        })
                });
            });
        }
    }, [parentDataLoaded, queryClient]);
};

export default useAdvisorPreloadedData;
