import React from 'react';
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import { useAppContext } from '../../context/app-context';

const ErrorToast = ({ toast }) => {
    const { removeToast } = useAppContext();

    return (
        <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                <Transition
                    show={true}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-full max-w-sm pointer-events-auto">
                        <div className="bg-red-50 border border-red-200 rounded-lg shadow-lg">
                            <div className="p-4">
                                <div className="flex items-start gap-3">
                                    <div className="flex-shrink-0">
                                        <div className="p-2 bg-red-100 rounded-lg">
                                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                        </div>
                                    </div>

                                    <div className="flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-red-800">
                                            {toast.title}
                                        </p>
                                        <p className="mt-1 text-sm text-red-600">
                                            {toast.description}
                                        </p>
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => removeToast(toast.id)}
                                        className="flex-shrink-0 p-1.5 rounded-lg text-red-400 hover:text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-4 w-4" />
                                    </button>
                                </div>
                                <div className="mt-4 flex justify-end gap-3">
                                    <button
                                        type="button"
                                        onClick={() => removeToast(toast.id)}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md border border-red-300 bg-white text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    >
                                        Close
                                    </button>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default ErrorToast;