import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import { classNames } from "../../../../helpers/classNames";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import useArray from "../../../../hooks/useArray";
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'


function SelectBox({ options, handleChange = () => { } }) {
  const [query, setQuery] = useState('')

  const [selectedOption, setSelectedOption] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.toLowerCase().includes(query.toLowerCase())
      })
  useEffect(() => {
    if (!isInitialized) return setIsInitialized(true)
    if (!selectedOption) return
    handleChange(selectedOption)

  }, [selectedOption])


  return (
    <Combobox as="div" value={selectedOption} onChange={setSelectedOption}>
      <div className="">

        <Combobox.Button className="invisible group-hover:visible text-gray-600 bg-gray-100 hover:bg-gray-200 rounded-sm h-6 w-6 p-1">
          <PlusIcon className="" />
        </Combobox.Button>

        {true && (
          <Combobox.Options className="absolute top-full z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Input
              placeholder="Search for an option..."
              className="w-full border-0 bg-white py-1.5 pl-3 pr-10  text-gray-900 ring-0  text-sm sm:leading-6"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(option) => option}
            />
            {filteredOptions.length > 0 && filteredOptions.map((option) => (
              <Combobox.Option
                key={option}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{option}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

const MultiSelect = ({
  rowId,
  initValue,
  accessToken,
  table,
  colId,
  editable,
  selectOptions,
  editId
}) => {
  const selected = useArray(initValue || []);
  const [showEdit, setShowEdit] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  //handling next thing changes
  const saveChanges = async (newValue) => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: newValue,
        row_id: editId,
        col_id: colId,
      });
      setShowEdit(false);
    } catch (error) { }
  };
  const sortedOptions = useMemo(
    () => selectOptions.sort((a, b) => a.order - b.order),
    [selectOptions]
  );

  useEffect(() => {
    if (!isInitialized) return setIsInitialized(true)
    if (!selected.array) return
    saveChanges(selected.array)
  }
    , [selected.array])

  return (
    <div className=" ">
      {editable ? (
        <div className="relative">
          <div className=" group w-[300px] flex items-center  space-x-1">
        

            <ul className={`flex space-x-1 items-center max-w-[270px] overflow-x-auto py-1 custom-scrollbar-thin custom-scrollbar-gray`}>
              {selected.array && selected.array.map((item, index) => (
                <li
                  key={item}
                  className={"bg-gray-200 space-x-0.5 flex items-center text-gray-700 font-normal rounded-full px-2 py-0.5 text-xs"}
                >
                  <p>{item}</p> <button onClick={() => {selected.remove(index)}}> <XIcon className="h-4 w-4  font-thin" /></button>
                </li>
              ))}
            </ul>
          
            <SelectBox options={sortedOptions.flatMap(item => item.value).filter(item => !selected.array.includes(item))} handleChange={(value) => selected.push(value)} />

          </div>
         </div>
      ) : (
          <>
            <ul className={`flex space-x-1 items-center max-w-[270px] overflow-x-auto py-1 custom-scrollbar`}>
              {selected.array && selected.array.map((item, index) => (
                <li
                  key={item}
                  className={"bg-gray-200 space-x-0.5 flex items-center text-gray-700 font-normal rounded-full px-2 py-0.5 text-xs"}
                >
                  <p>{item}</p> <button onClick={() => { selected.remove(index) }}> <XIcon className="h-4 w-4  font-thin" /></button>
                </li>
              ))}
            </ul>
          </>
      )}
    </div>
  );
};

export default MultiSelect;
