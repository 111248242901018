import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiRequest from "../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../api/apiConstants";
import { useParams } from "react-router-dom";
import Toggle from "../../../../../components/toggle";
import { InformationCircleIcon } from "@heroicons/react/outline";

const PrivacyToggle = () => {
    const { household_id: householdId } = useParams();
    const householdsApi = new ApiRequest(
        API_ROUTES.HOUSEHOLDS,
        API_AUTH_TYPES.ADVISOR
    );

    // Query to get current privacy status
    const { data, isLoading } = useQuery({
        queryFn: householdsApi.getFn({
            endpoint: "privacy_status",
            params: [householdId],
        }),
        queryKey: `household-privacy-${householdId}`,
    });

    const queryClient = useQueryClient();

    // Mutation to toggle privacy
    const mutation = useMutation(
        () =>
            householdsApi.patch({
                endpoint: "toggle_privacy",
                params: [householdId],
            }),
        {
            onSuccess: (data) => {
                // Invalidate and update relevant queries
                queryClient.invalidateQueries(`household-privacy-${householdId}`);
                queryClient.setQueryData(`household-privacy-${householdId}`, {
                    is_private: data.is_private,
                });
            },
        }
    );

    if (isLoading) {
        return (
            <div className="flex items-center space-x-2 justify-between animate-pulse">
                <div className="h-4 w-32 bg-gray-200 rounded"></div>
                <div className="h-6 w-12 bg-gray-200 rounded"></div>
            </div>
        );
    }

    return (
        <div className="space-y-2">
            <div className="flex items-center space-x-2 justify-between">
                <div className="flex items-center space-x-2">
                    <label htmlFor="household-privacy" className="text-gray-600">
                        Private Household
                    </label>
                    <div className="group relative">
                        <InformationCircleIcon className="h-5 w-5 text-gray-400 cursor-help" />
                        <div className="hidden group-hover:block absolute z-10 w-64 p-2 text-sm bg-gray-800 text-white rounded-md -right-2 transform translate-x-full">
                            When enabled, only the assigned advisor can access this household's information
                        </div>
                    </div>
                </div>
                <Toggle
                    enabled={data?.is_private}
                    setEnabled={() => mutation.mutate()}
                    disabled={mutation.isLoading}
                />
            </div>
            {mutation.isLoading && (
                <p className="text-sm text-gray-500">Updating privacy settings...</p>
            )}
            {mutation.isError && (
                <p className="text-sm text-red-600">
                    Failed to update privacy settings. Please try again.
                </p>
            )}
        </div>
    );
};

export default PrivacyToggle;