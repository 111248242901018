
import { useQuery } from "react-query";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../api";
import ActivityRow from "../../Dashboard/components/AllClientActivity/ActivityRow";
import BasicPanel from "../../../../components/panels/BasicPanel";
import { useParams } from "react-router-dom";
import HHActivityRow from "./HHActivityRow";

export default function AllClientActivity() {
  const {household_id:householdId} = useParams()
  const activityApi = new ApiRequest(API_ROUTES.USER_ACTIVITY, API_AUTH_TYPES.ADVISOR);
  const { data: activity, isLoading } = useQuery({ queryFn: activityApi.getFn({ endpoint: 'household', params: [householdId]}), queryKey: "all_user_activity" })

  if (isLoading) return (<div>Loading...</div>)

  const { today, yesterday, this_week, more_than_one_week } = activity

  return (
    <div className="mt-8 flow-root max-h-96 overflow-auto  custom-scrollbar-thick custom-scrollbar-gray">
        <div className=" min-w-full py-2 align-middle   ">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ">
                  User Name
                </th>
               

                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Type
                </th>


                <th scope="col" className=" px-3 py-3.5 text-left text-sm  font-semibold text-gray-900 hidden md:table-cell">
                  Details
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell ">
                  Date
                </th>

              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 ">
              {today?.length > 0 && (
                <tr className="border-t border-gray-200">
                  <th colSpan="5" scope="colgroup" className="bg-gray-100 py-2 pl-4 pr-3 text-left text-sm font-bold text-gray-900 ">
                    Today
                  </th>
                </tr>
              )}
              {today.map((item) => (
                <HHActivityRow item={item} />
              ))}
              {yesterday?.length > 0 && (
                <tr className="border-t border-gray-200">
                  <th colSpan="5" scope="colgroup" className="bg-gray-100 py-2 pl-4 pr-3 text-left text-sm font-bold text-gray-900 ">

                    Yesterday
                  </th>
                </tr>
              )}
              {yesterday.map((item) => (
                <HHActivityRow item={item} />

              ))}
              {this_week?.length > 0 && (
                <tr className="border-t border-gray-200">
                  <th colSpan="5" scope="colgroup" className="bg-gray-100 py-2 pl-4 pr-3 text-left text-sm font-bold text-gray-900 ">
                    This Week
                  </th>
                </tr>
              )}
              {this_week.map((item) => (
                <HHActivityRow item={item} />

              ))}
              {more_than_one_week?.length > 0 && (
                <tr className="border-t border-gray-200">
                  <th colSpan="5" scope="colgroup" className="bg-gray-100 py-2 pl-4 pr-3 text-left text-sm font-bold text-gray-900 ">
                    More Than One Week Ago
                  </th>
                </tr>
              )}
              {more_than_one_week.map((item) => (
                <HHActivityRow item={item} />

              ))}
            </tbody>
          </table>
        </div>
      </div>
  );
}