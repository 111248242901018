import React from "react";

import { useSelector } from "react-redux";

import SingleSelect from "./smartTable___rowComponents/SingleSelect";
import HouseholdLink from "./smartTable___rowComponents/HouseholdLink";
import LongText from "./smartTable___rowComponents/LongText";
import Date from "./smartTable___rowComponents/Date";
import DueDate from "./smartTable___rowComponents/DueDate";
import SmartTableNumber from "./smartTable___rowComponents/SmartTable___Number";
import AdvisorColumn from "./smartTable___rowComponents/AdvisorColumn";
import ShortText from "./smartTable___rowComponents/ShortText";
import Checkbox from './smartTable___rowComponents/Checkbox';
import Button from './smartTable___rowComponents/Button';
import CopyColumn from "./smartTable___rowComponents/CopyColumn";
import MultiSelect from "./smartTable___rowComponents/MultiSelect";
import ClaimHousehold from "./smartTable___rowComponents/ClaimHousehold";
import LinkColumn from "./smartTable___rowComponents/LinkColumn";

const SmartTableRow = React.memo(({
  rowData,
  visibleColumns,
  thisRowIndex, buttonFunctions, totalRows
}) => {
  //hooks
  //state
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  const arrWithComponents = visibleColumns.map((col) => {
    if (col.col_type === "long_text") {
      col.component = () => (
        <LongText
          rowId={rowData.row_id}
          initText={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editable={col.options?.editable}
          colTitle={col.title}
          thisRowIndex={thisRowIndex}
          totalRows={totalRows}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
    if (col.col_type === "short_text") {
      col.component = () => (
        <ShortText
          rowId={rowData.row_id}
          initText={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editable={col.options?.editable}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
    if (col.col_type === "claim_household") {
      col.component = () => (
        <ClaimHousehold
          rowId={rowData.row_id}
          data={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
        if (col.col_type === "checkbox") {
          col.component = () => (
            <Checkbox
              rowId={rowData.row_id}
              initValue={rowData[col.col_id]}
              accessToken={accessToken}
              table={col.table}
              colId={col.col_id}
              editable={col.options?.editable}
              editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

            />
          );
    }
        if (col.col_type === "button") {
          col.component = () => (
            <Button rowData={rowData} colId={col.col_id} buttonFunctions={buttonFunctions}  />
          );
        }
    if (col.col_type === "number") {
      col.component = () => (
        <SmartTableNumber
          rowId={rowData.row_id}
          initValue={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editable={col.options?.editable}
          format={col.options?.format}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
    if (col.col_type === "household_link") {
      col.component = () => (
        <HouseholdLink
          data={{
            household_name: rowData.household_name,
            household_id: rowData.household_id,
          }}
        />
      );
    }
    if (col.col_type === 'link') {
      col.component = () => (
        <LinkColumn
          href={rowData[col.col_id].href}
          text={rowData[col.col_id].text}
        />
      
      );
    }
    if (col.col_type === "single_select") {
      col.component = () => (
        <SingleSelect
          rowId={rowData.row_id}
          initValue={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editable={col.options?.editable}
          selectOptions={col.select_options}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}
        />
      );
    }
    if (col.col_type === "multi_select") {
      col.component = () => (
        <MultiSelect
          rowId={rowData.row_id}
          initValue={rowData[col.col_id]}
          accessToken={accessToken}
          table={col.table}
          colId={col.col_id}
          editable={col.options?.editable}
          selectOptions={col.select_options}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
    if (col.col_type === "copy_to_clipboard") {
      col.component = () => (
        <CopyColumn
          textToCopy={rowData[col.col_id]}
        />
      );
    }
    if (col.col_type === "date") {
      col.component = () => (
        <Date
          initDate={rowData[col.col_id]}
          showTime={col.options?.show_time}
          editable={col.options?.editable}
          table={col.table}
          colId={col.col_id}
          accessToken={accessToken}
          rowId={rowData.row_id}
          editId={col.edit_id ? rowData[col.edit_id] : rowData.row_id}

        />
      );
    }
    if (col.col_type === "due_date") {
      col.component = () => (
        <DueDate
          dueDate={rowData[col.col_id]}
          showTime={col.options.show_time}
        />
      );
    }
    if (col.col_type === "advisor") {
      col.component = () => (
        <AdvisorColumn
          advisor={rowData[col.col_id]}
        />
      );
    }
    return col;
  });
  return (
    <tr className="hover:shadow-md transition duration-100 ease-in-out divide-x divide-gray-200 ">
      {arrWithComponents.map((col, colIndex) => (
        <td
          key={colIndex}
          className="sm:first:left-0 first:z-20  first:sticky bg-white whitespace-nowrap text-sm font-medium text-gray-900 h-10  px-3"
        >
          {colIndex === 0 ? (
            <div className="flex space-x-5 pl-2">
              <p className="text-gray-500 font-thin">{thisRowIndex + 1}</p>
              <col.component />
            </div>
          ) : (
            <col.component />
          )}
        </td>
      ))}
    </tr>
  );
});

export default SmartTableRow;
