import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiRequest from "./../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "./../../../../../api/apiConstants";
import { useParams } from "react-router-dom";
import Toggle from "../../../../../components/toggle";

const FinancialStoryAccess = () => {
  const { household_id: householdId } = useParams();
  const householdsApi = new ApiRequest(
    API_ROUTES.HOUSEHOLDS,
    API_AUTH_TYPES.ADVISOR
  );
  const { data, isLoading } = useQuery({
    queryFn: householdsApi.getFn({
      endpoint: "financial_story_access",
      params: [householdId],
    }),
    queryKey: `financial-story-access-${householdId}`,
  });
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      householdsApi.patch({
        endpoint: "financial_story_access",
        body: {
          household_id: householdId,
          financial_story_access: !data?.financial_story_access,
        },
      }),
    {
      onSuccess: ({ updated_financial_story_access }) => {
        queryClient.invalidateQueries(`financial-story-access-${householdId}`);
        queryClient.setQueryData("financial-story-access", {
          financial_story_access: updated_financial_story_access,
        });
      },
    }
  );
  if (isLoading) return <div>Loading...</div>;
  return (
    <div className="flex items-center space-x-2 justify-between">
      {" "}
      <label htmlFor="financial-story-access" className="text-gray-600">
        Financial Story Access
      </label>
      <Toggle
        enabled={data?.financial_story_access} 
        setEnabled={() => mutation.mutate()}
      />
    
    </div>
  );
};

export default FinancialStoryAccess;
