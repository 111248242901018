import { Popover, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { classNames } from "./misc/styling";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../api";



export default function Notifications() {
    const notificationsApi = new ApiRequest(API_ROUTES.NOTIFICATIONS, API_AUTH_TYPES.USER)
    const notifications = useQuery({
        queryKey: ['notifications'],
        queryFn: notificationsApi.getFn({
            endpoint: 'index_my_active_notifications'
        })
    })
   

    const unreadNotificationsCount = notifications.data?.filter(item => item.status === 'not-read').length || 0
    const notificationContent = {
        highlights: {
            message: `Check your Financial Story for tasks marked with a yellow star. Focus on these to stay on track!`,
            link: '/financial-story',
            tag: { title: 'Highlighted Tasks', styling: 'bg-yellow-100 text-yellow-700' }
        },
        "review-youvest": {
            message: ` You have a new review on your YouVest profile. Click to view!`,
            link: '/youvest-profile',
            tag: { title: 'New Review', styling: 'bg-blue-100 text-blue-700' }
        }
    }
    const queryClient = useQueryClient()
    const { mutate: markAsRead } = useMutation(
        (id) => {
            if (unreadNotificationsCount === 0) return Promise.resolve()
            notificationsApi.patch(
                {
                    endpoint: 'mark_as_read',
                    body: {ids: notifications.data.filter(item => item.status === 'not-read').map(item => item.id) }
                })
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries('notifications')
        }
    })

    const { mutate: dismissNotification } = useMutation(
        (id) => {
            notificationsApi.patch(
                {
                    endpoint: `dismiss`,
                    body: { id }
                })
        }, {
            onSuccess: (data) => {
                setTimeout(() => {
                    queryClient.invalidateQueries('notifications')
                }
                    , 1000)
        }
    })

    const { mutate: actionTakenOnNotification } = useMutation(
        (id) => {
            notificationsApi.patch(
                {
                    endpoint: `action_taken_on_notification`,
                    body: { id }
                })
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries('notifications')
        }
    })

    return (
        <div>
            <Popover as="div" className="ml-3 relative">
                <div className="relative inline-block">
                    <Popover.Button onClick={markAsRead} className=" p-1 rounded-full hover:bg-white transition duration-300 flex items-center ">
                        <span className="sr-only">Notifications</span>
                        <BellIcon
                            className="h-6 w-6 text-white hover:text-gray-400 transition duration-300"
                            aria-hidden="true"
                        />
                   
                        {( notifications?.data?.length > 0) && <span className="absolute top-0 right-0  h-4 w-4 rounded-full flex items-center justify-center bg-red-400 ring-2 text-[10px] ring-white text-white" >
                            {notifications?.data?.length || 0}
                        </span>
                        }


                    </Popover.Button>

                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Popover.Panel>
                        {!notifications.isLoading && <div className="px-1 divide-y divide-gray-100 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white max-h-[500px] overflow-auto  ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {notifications?.data?.length > 0 ? notifications?.data?.map((item) => (
                                <div key={item.id} className="">
                                    <div
                                        className={classNames(

                                            " px-4 py-2 text-sm text-gray-700  text-left flex items-center justify-between gap-x-6 w-72 sm:w-96"
                                        )}

                                    >
                                        <div className="flex gap-x-4">
                                            <div className="min-w-0 flex-auto">
                                                <span className={classNames(notificationContent[item.type].tag.styling, "inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium ")}>
                                                    {notificationContent[item.type]?.tag.title}
                                                </span>
                                                <p className="mt-1  text-sm leading-5 text-gray-700">{notificationContent[item.type].message}</p>
                                                <div className="flex items-baseline space-x-3 mt-2">
                                                    <a href={notificationContent[item.type].link} onClick={() => actionTakenOnNotification(item.id)} className=" text-sm leading-5 text-blue-500 hover:text-blue-700">View</a>
                                                    <button onClick={() => dismissNotification(item.id)} className=" text-sm leading-5 text-blue-500 hover:text-blue-700">Dismiss</button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            )) : <div className="p-3 text-gray-700 w-max">No New Notifications</div>}</div>}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    );
}