import axios from "axios";
const route = "auth";
const version = "v1";
const baseURL =
  process.env.NODE_ENV === "production"
    ? `/api/${version}/${route}`
    : `http://localhost:5000/api/${version}/${route}`;

export default axios.create({
  baseURL,
});
