import { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../api";
import useOnClickOutside from "../../../../hooks/useClickOutside";


export default function OtherContacts() {
    const { household_id: householdId } = useParams();
    const [text, setText] = useState('')
    const householdApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR)
    const { data, isLoading, isError } = useQuery({ queryFn: householdApi.getFn({ endpoint: 'other_contacts', params: [householdId] }), queryKey: `other-contacts-${householdId}` })

    //hooks
    const ref = useRef();
    //state

    const queryClient = useQueryClient()
    const { mutate } = useMutation((data) => householdApi.patch({ endpoint: 'update_other_contacts', body: { household_id: householdId, text: data.text } }), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(`next-thing-${householdId}`)
        }
    })
    //functions
    //handling next thing changes
    const submitChanges = async () => {
        if (text === data?.other_contacts) return;
        mutate({  text,  })
    };

    useEffect(() => {
        setText(data?.other_contacts)
    }, [data?.other_contacts, isLoading])


    useOnClickOutside(ref, () => submitChanges());
    if (isLoading) return <div>loading</div>
    return (
        <div className=" ">

            {" "}
            <textarea
                ref={ref}
                type="text"
                name="nextThing"
                rows={5}
                id="nextThing"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="input-primary  custom-scrollbar-thick custom-scrollbar-gray"
                placeholder="Add information about any other contacts such as children, pets, CPA, Estate Attorney, etc"
            />

        </div>
    );
}