import { ChevronDownIcon, SearchIcon } from "@heroicons/react/solid";
import React from "react";
import View from "./View";
import { classNames } from "./../../../misc/styling";
import { useState } from "react";
import AddNewView from "./AddNewView";
import FadeIn from "../../../transitions/FadeIn";

const ViewsList = ({ selectedViewId, setSelectedViewId, allViews = [], smartTableId }) => {
  const [showAddView, setShowAddView] = useState(false);
  const [showPersonalViews, setShowPersonalViews] = useState(true);
  const [showColaborativeViews, setShowColaborativeViews] = useState(true);

  const [query, setQuery] = useState("");
  const filteredViews =
    query === ""
      ? allViews
      : allViews.filter((view) => {
          return view.name.toLowerCase().includes(query.toLowerCase());
        });
  const myViews = filteredViews.filter((view) => view.advisor_id);
  const colaborativeViews = filteredViews.filter((view) => !view.advisor_id);

  return (
    <>
      <div className=" flex flex-col divide-y-2 divide-gray-300  ">
        <div className={classNames("flex text-gray-500 items-center ")}>
          <SearchIcon className="h-5 w-5 flex-shrink-0 " />{" "}
          <input
            type="text"
            className="ring-0 focus:ring-0 border-0  "
            placeholder="Find a view"
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
        </div>{" "}
        <div className=" ">
          {" "}
          <button
            onClick={() => setShowPersonalViews(!showPersonalViews)}
            className="font-semibold text-gray-800 flex justify-between items-center w-full py-1"
          >
            <p>Personal Views</p>{" "}
            <ChevronDownIcon
              className={classNames(
                showPersonalViews && "rotate-180",
                " transition duration-100 h-5 w-5 text-gray-800"
              )}
            />
          </button>
          <FadeIn isShowing={showPersonalViews}>
            {myViews?.length > 0 ? (
              <ul className="py-2 pl-3">
                {myViews.map((view, index) => (
                  <li key={view.smart_table_view_id}>
                    {" "}
                    <View
                      view={view}
                      setSelectedViewId={setSelectedViewId}
                      selectedViewId={selectedViewId}
                      thisViewIndex={index}
                      isColaborativeViews={false}
                      myViewsLength={myViews?.length || 0}
                      allViewsLength={allViews?.length || 0}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <div className="flex pl-3 w-[250px] h-20">
                <p className="text-gray-500 ">No Matching Views</p>
              </div>
            )}
          </FadeIn>
          <button
            onClick={() => setShowColaborativeViews(!showColaborativeViews)}
            className="font-semibold text-gray-800 flex justify-between items-center w-full py-1"
          >
            <p>Collaborative Views</p>{" "}
            <ChevronDownIcon
              className={classNames(
                showColaborativeViews && "rotate-180",
                " transition duration-100 h-5 w-5 text-gray-800"
              )}
            />
          </button>
          <FadeIn isShowing={showColaborativeViews}>
            {colaborativeViews?.length > 0 ? (
              <ul className="py-2 pl-3">
                {" "}
                {colaborativeViews?.length > 0 &&
                  colaborativeViews.map((view, index) => (
                    <li key={view.smart_table_view_id}>
                      {" "}
                      <View
                        view={view}
                        setSelectedViewId={setSelectedViewId}
                        selectedViewId={selectedViewId}
                        thisViewIndex={index}
                        isColaborativeViews={true}
                        myViewsLength={myViews?.length || 0}
                        allViewsLength={allViews?.length || 0}
                      />
                    </li>
                  ))}{" "}
              </ul>
            ) : (
              <div className="flex pl-3 w-[250px] h-20">
                <p className="text-gray-500 ">No Matching Views</p>
              </div>
            )}
          </FadeIn>
        </div>
        <button
          className="text-left font-semibold text-gray-500 py-2"
          onClick={() => setShowAddView(true)}
        >
          + Create a new view{" "}
        </button>
      </div>
      <AddNewView
        showAddView={showAddView}
        setShowAddView={setShowAddView}
        smartTableId={smartTableId}
      />
    </>
  );
};

export default ViewsList;
