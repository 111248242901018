import React, { useMemo, useState } from 'react'
import WideBlankModal from '../../../../../../components/modals/WideBlankModal';
import { SearchIcon } from '@heroicons/react/solid';
import { useQuery, useQueryClient } from 'react-query';
import ApiRequest from '../../../../../../api/ApiRequest';
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../../api/apiConstants';
import { Template } from './Template';
import ShowTemplatesButton from './ShowTemplatesButton';


const chapterTemplatesApi = new ApiRequest(API_ROUTES.CHAPTER_TEMPLATES, API_AUTH_TYPES.ADVISOR);
const ChapterTemplates = ({queryKeys}) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const [query, setQuery] = useState(null)

  const { data: rawTemplates, isLoading } = useQuery({
    queryKey: "chapter-templates",
    queryFn: chapterTemplatesApi.getFn({ endpoint: 'index' }),
  })
  const filteredTemplates = useMemo(() => {
    let templates = rawTemplates
    if (!templates) return [];
    if (!query) return rawTemplates;
    return rawTemplates.filter(template =>
      JSON.stringify(template.context).toLowerCase().includes(query.toLowerCase())
      || template.title.toLowerCase().includes(query.toLowerCase())
      || JSON.stringify(template.tasks).toLowerCase().includes(query.toLowerCase())
      || JSON.stringify(template.ongoing_advice).toLowerCase().includes(query.toLowerCase())
    )
  }, [showTemplates, query,rawTemplates])
  if (isLoading) return <p>Loading...</p>
  return (
      <>
      <div>
        <WideBlankModal open={showTemplates} setOpen={setShowTemplates}>

          <div className="flex"> <h3 className='text-left ml-4'>Chapter Templates</h3>
          </div>
          <div className="relative mt-2">
            <SearchIcon
              className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <div className="relative w-full text-gray-400 focus-within:text-gray-600 pl-1">
              <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-full w-full border-transparent py-2 pr-3 pl-8 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
          </div>
          <ul role="list" className="divide-y divide-gray-200 text-left h-[600px] overflow-y-auto">
            {filteredTemplates.map((template) => (
              <li key={template.id} className="py-4 flex">
                <Template template={template} queryKeys={queryKeys} searchQuery={query}  />
              </li>
            ))}
          </ul>
        </WideBlankModal>
      </div>
         <ShowTemplatesButton setShowTemplates={setShowTemplates} />
    </>
  )
}

export default ChapterTemplates