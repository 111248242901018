import React from 'react'
import ScratchPad from '../../ScratchPad'
import HHValues from '../HHValues'
import DashboardPanel from '../../../../components/panels/DashboardPanel'
import Contacts from './Contacts'
import AllClientActivity from '../HHActivity'
import FinancialWhy from './FinancialWhy'
import Source from '../HHMetrics/Source'
import Cashflow from './Cashflow'
import PlanningTags from './PlanningTags'
import HouseholdSummary from './HouseholdSummary'
import OtherContacts from './OtherContact'
import AnnualChecklist from './AnnualChecklist'


/**
 * The Dashboard component is the central hub for displaying various widgets and sections
 * related to a household's financial overview. It organizes content into a main area and an aside,
 * showcasing details like the household's financial why, activity, contacts, and more.
 * 
 * @param {Object} props - Component props.
 * @param {Object} props.householdMeta - Meta information about the household.
 * @param {Array} props.contactMeta - Metadata about the contacts related to the household.
 * @returns {JSX.Element} The dashboard layout with various panels containing household-related information and tools.
 */
const Dashboard = ({ householdMeta, contactMeta }) => {
    return (
        <div className="w-full md:pt-7  md:px-7">
            <div className="flex flex-col lg:flex-row lg:space-x-12 ">
                <main className="w-full space-y-6">
                    <DashboardPanel title="My Financial Why">
                        <FinancialWhy householdMeta={householdMeta} />
                    </DashboardPanel>


                    <DashboardPanel title='Household Activity'>
                        <AllClientActivity />
                    </DashboardPanel>
                    <DashboardPanel title='Scratch Pad'>
                        <ScratchPad />
                    </DashboardPanel>
                    
                    <DashboardPanel title='Household AI Summary'>
                        <HouseholdSummary householdMeta={householdMeta} />
                    </DashboardPanel>
                </main>

                <aside className="flex-shrink-0 w-full space-y-6 lg:w-[350px]  xl:w-[400px] mt-6 lg:mt-0">
                    <DashboardPanel title="Clients" >
                        <Contacts contactsMeta={contactMeta} householdMeta={householdMeta} />
                    </DashboardPanel>
                        <AnnualChecklist householdMeta={householdMeta} />
                    <DashboardPanel title='Planning Tags'>
                        <PlanningTags />
                    </DashboardPanel>


                    <DashboardPanel title="Client Values">
                        <HHValues />
                    </DashboardPanel>
                  
                    <DashboardPanel title="Other Contacts" >
                        <OtherContacts />
                    </DashboardPanel>




                </aside>
            </div>
        </div>)
}

export default Dashboard
