import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ApiRequest from '../../../../../api/ApiRequest';
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api/apiConstants';
import { useQuery } from "react-query";
import { ToolTipHoverContainer, ToolTipLeft } from '../../../../../components/ToolTips';
import { PlusIcon } from '@heroicons/react/outline';
import CashflowRow from './cashflow-row';
import NewItemForm from './new-item-form';
import EmptyState from './empty-state';
import { currencyFormatter } from '../../../../../helpers/formatters';

/**
 * The main component for displaying and managing cashflow items.
 * It provides functionality to add new items, edit existing ones, and display a list of all cashflow items.
 *
 * @param {Object} props Component props.
 * @param {boolean} props.isClientPortal Indicates if the current portal is for a client (true) or an advisor (false).
 * @returns {JSX.Element} The Cashflow component rendering the list of cashflow items or an empty state.
 */
const Cashflow = ({ isClientPortal }) => {
  // Retrieve the household ID from URL parameters.
  const { household_id: householdId } = useParams();
  const [showAddNew, setShowAddNew] = useState(false);
  const authType = isClientPortal ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR;
  const cashflowApi = new ApiRequest(API_ROUTES.CASHFLOW, authType);

  // Define query options based on whether the portal is for a client or an advisor.
  const queryOptions = isClientPortal ? {
    endpoint: `index_ids`,
  } : {
    endpoint: `index_ids`,
    params: [householdId],
  };
  // Fetch cashflow IDs using React Query.
  const { data: cashflowIds, isLoading } = useQuery({
    queryFn: cashflowApi.getFn(queryOptions),
    queryKey: isClientPortal ? `cashflow-ids-mine` : `cashflow-ids-${householdId}`,
  });

  const queryOptionsAnnualContribution = isClientPortal ? {
    endpoint: `total_annual_contribution`,
  } : {
      endpoint: `total_annual_contribution`,
    params: [householdId],
  };
  const { data: totalAnnualContribution, isLoading: isLoadingTotalAnnualContribution } = useQuery({
    queryFn: cashflowApi.getFn(queryOptionsAnnualContribution),
    queryKey: isClientPortal ? `total-annual-contribution-mine` : `total-annual-contribution-${householdId}`,
  });

  // Render loading state while cashflow IDs are being fetched.
  if (isLoading) return (<div>Loading...</div>);

  return (
    <div className="relative">
      {/* Modal form for adding a new cashflow item */}
      <NewItemForm open={showAddNew} setOpen={setShowAddNew} isClientPortal={isClientPortal} />
      {cashflowIds.length > 0 ? (
        <>
          {/* Tooltip and button for adding a new cashflow item */}
          <div className="absolute top-0 right-0 z-50">
            <ToolTipHoverContainer>
              <button onClick={() => setShowAddNew(true)} className=" ">
                <PlusIcon className="h-5 w-5 text-gray-500" />
              </button>
              <ToolTipLeft >
                <p className="text-sm ">Add a new cashflow item</p>
              </ToolTipLeft>
            </ToolTipHoverContainer>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  {/* Table headers */}
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Name
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                        Bank/Custodian
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                        Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Frequency
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                       Transfer Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">
                        Annual Amount
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  {/* Cashflow items rows */}
                  {cashflowIds && <tbody className="divide-y divide-gray-200">
                    {cashflowIds.map(({ id }) => (
                      <tr key={id}>
                        <CashflowRow id={id} isClientPortal={isClientPortal} />
                      </tr>
                    ))}
                  </tbody>}
                  <tfoot>
                  
                    <tr>
                      <th
                        scope="row"
                        colSpan={5}
                        className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                      >
                        Total Annual Saving
                      </th>
                      <th scope="row"
                        colSpan={2}
                        className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:hidden">
                        Total Annual Saving
                      </th>
                      <td className="pl-3 pr-4 pt-4  font-semibold text-gray-900 sm:pr-0">{currencyFormatter.format(totalAnnualContribution?.sum || 0)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        // Render the empty state component when there are no cashflow items.
        <EmptyState handleAddNew={() => setShowAddNew(true)} />
      )}
    </div>
  );
};

export default Cashflow;
