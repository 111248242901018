import React from "react";
import { useState } from "react";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import { BsArrowClockwise } from "react-icons/bs";
import { STATUS_OPTIONS } from "../../../../../../constants";
import { XCircleIcon } from "@heroicons/react/solid";
import { MdAvTimer } from "react-icons/md";
import ReactDatePicker from "react-datepicker";

const RepeatUntil = ({ handleSubmitEdit, isClientPortal, task }) => {
  const [value, setValue] = useState(task.repeat_until);
  const [showEdit, setShowEdit] = useState(false);

  const handleChange = (newValue) => {
    setValue(newValue === "Forever" ? null : newValue);
    handleSubmitEdit(newValue);
    setShowEdit(false);
  };

  const handleClearValue = () => {
    setValue(null);
    handleSubmitEdit(null);
    setShowEdit(false);
  }


  return (
    <>


      {isClientPortal ?

        <div className="mt-2 flex items-center  text-gray-500 sm:mt-0 justify-end text-right ">

          {task?.repeat_until ? (
            <div className="flex space-x-2">
              <MdAvTimer 
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <p>Repeats until {new Date(value).toLocaleDateString()}</p>

            </div>
          ) : (
            <div>
            </div>
          )}

        </div> :
        <DoubleClickEdit
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          defaultComponent={
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <MdAvTimer 
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {value ? (
                <div className="group flex items-center space-x-2">
                  <p>Repeats until {new Date(value).toLocaleDateString() }</p>
                  <button onClick={handleClearValue} className="w-5 invisible group-hover:visible">
                    <XCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </button>
                </div>
              ) : (
                <p className="min-w-max">Repeats Forever</p>
              )}
            </div>
          }
          editComponent={

            <ReactDatePicker
              className="input-primary "
              selected={value}
              onChange={(date) => handleChange(new Date(date))}
            />
           
          }
          handleSubmitEdit={() => { }}
          componentName="repeat_until"
        />}
    </>
  );
};

export default RepeatUntil;
