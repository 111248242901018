import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorHandling/error-fallback-page";
import { useSelector } from "react-redux";
import { authenticateAuth } from "../../helpers/jwtAuth";

const AuthorizedRoute = ({
  authorizationType,
  component: Component,
  ...rest
}) => {
  //state
  const [authStatus, setAuthStatus] = useState(false);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  useEffect(() => {
    const asyncEffect = async () => {

      try {
        const auth = await authenticateAuth(accessToken,authorizationType);
        setAuthStatus(auth);
        setLoading(false);
      } catch (error) {
        setLoading(false)
      }
    };

    asyncEffect();
  }, [accessToken]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} >
      <Route
        {...rest}
        render={(props) => {
          return !loading ? (
            <>
              {currentUser && authStatus ? (
                <Component {...props} />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          ) : null;
        }}
      ></Route>
    </ErrorBoundary>
  );
}

export default AuthorizedRoute
