import React from 'react'
import RichTextDisplay from '../../../../components/textEditor/RichTextDisplay'
import MarkdownNotes from '../../../../components/content/MarkdownNotes'
import { dateFormatterGmailTime } from '../../../../helpers/formatters'

const Note = ({ note,query }) => {
    return (

        <div className="flex w-full justify-between">
            <div className="flex flex-col w-full flex-wrap">
                {" "}
                <div className="text-sm text-gray-900 w-full ">
                    {note.content && (
                        <div>
                            <RichTextDisplay
                                content={note.content}
                                thisClassName="notes"
                                query={query}
                            />
                        </div>
                    )}
                    {note.body?.delta && (
                        <div>
                            <RichTextDisplay
                                content={{ ops: note.body?.delta }}
                                thisClassName="notes"
                                query={query}
                            />
                        </div>
                    )}

                    {note.body?.blocks && (
                        <MarkdownNotes noteRaw={note.body} />
                    )}
                </div>

            </div>
            <div className=" flex flex-col space-y-1 items-end ml-au sm:pl-10">
                {" "}

                <p className="text-sm text-gray-600 w-max">
                    {dateFormatterGmailTime(
                        new Date(note.created_at || note.body?.time)
                    )}
                </p>

            </div>
        </div>
    )
}

export default Note