import React, { useEffect, useMemo, useState } from "react";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../api/apiConstants";
import ApiRequest from "../../../../api/ApiRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";

import TableOfContents from "./components/TableOfContents";
import SelectedChapter from "./components/chapter-components/SelectedChapter";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Task from "./components/task-components/Task";
import { classNames } from "../../../../helpers/classNames";
import { STATUS_OPTIONS } from "../../../../constants";
import ChapterGoal from "./components/chapter-components/ChapterGoal";
import Toggle from "../../../../components/toggle";
import isVisibleNowFunction from "./components/task-components/isVisibleNowFunction";

/**
 * This is the FinancialStory component.
 * It's responsible for displaying and handling data related to the user's financial story.
 * This includes querying and displaying active, future and completed chapters of the financial story,
 * as well as handling state related to selected chapters and success alerts.
 */
const FinancialStory = ({ isMeetingMode = false }) => {


  const { household_id: householdId } = useParams();
  // Define query keys for the different parts of the financial story
  const tasksListQueryKey = `financial-story-task-list-${householdId}`;
  const activeChaptersListQueryKey = `active-financial-story-chapters-list-${householdId}`;
  const futureChaptersListQueryKey = `future-financial-story-chapters-list-${householdId}`;
  const completedChaptersListQueryKey = `financial-story-completed-chapters-list-${householdId}`;
  const unpublishedChaptersListQueryKey = `financial-story-unpublished-chapters-list-${householdId}`;
  const goalsQueryKey = useMemo(() => `goals-${householdId}`, [householdId])

  const queryKeys = {
    tasks: tasksListQueryKey,
    active: activeChaptersListQueryKey,
    future: futureChaptersListQueryKey,
    completed: completedChaptersListQueryKey,
    unpublished: unpublishedChaptersListQueryKey,
    goals: goalsQueryKey
  }
  // Initialize APIs for retrieving different parts of the financial story
  const chaptersApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_CHAPTERS, API_AUTH_TYPES.ADVISOR);
  const tasksApi = new ApiRequest(API_ROUTES.CLIENT_TASKS, API_AUTH_TYPES.ADVISOR);

  // State hooks
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [showReallySendNotification, setShowReallySendNotification] = useState(false);


  const goalsApi = new ApiRequest(API_ROUTES.HOUSEHOLD_GOALS, API_AUTH_TYPES.ADVISOR);
  const goals = useQuery({
    queryFn: goalsApi.getFn({
      endpoint: "index_goals", params: [householdId],
    }),
    queryKey: [queryKeys.goals],
  })


  // Query for active chapters
  const {
    data: activeChapters,
    isLoading: isLoadingActiveChapters,
    isError: isErrorActiveChapters,
  } = useQuery({
    queryFn: chaptersApi.getFn({
      endpoint: "index_active_chapters", params: [householdId],
    }),
    queryKey: [activeChaptersListQueryKey],
  });

  // Query for future chapters
  const {
    data: futureChapters,
    isLoading: isLoadingFutureChapters,
    isError: isErrorFutureChapters,
  } = useQuery({
    queryFn: chaptersApi.getFn({
      endpoint: "index_future_chapters", params: [householdId],
    }),
    queryKey: [futureChaptersListQueryKey],
  });

  // Query for completed chapters
  const {
    data: completedChapters,
    isLoading: isLoadingCompletedChapters,
    isError: isErrorCompletedChapters,
  } = useQuery({
    queryFn: chaptersApi.getFn({
      endpoint: "index_completed_household_chapters", params: [householdId],
    }),
    queryKey: [completedChaptersListQueryKey],
  });

  // Query for completed chapters
  const {
    data: unpublishedChapters,
    isLoading: isLoadingUnpublishedChapters,
    isError: isErrorUnpublishedChapters,
  } = useQuery({
    queryFn: chaptersApi.getFn({
      endpoint: "index_unpublished_chapters", params: [householdId],
    }),
    queryKey: [unpublishedChaptersListQueryKey],
  });

  // Query for tasks
  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useQuery({
    queryFn: tasksApi.getFn({
      endpoint: "index_tasks_household_id", params: [householdId],
    }),
    queryKey: [tasksListQueryKey],
  });

  // state variable to check if setup has been completed
  const [setupComplete, setSetupComplete] = useState(false);

  // If any of the data is still loading, the whole component is considered loading
  const loading = isLoadingActiveChapters || isLoadingFutureChapters || isLoadingTasks || isLoadingCompletedChapters || isLoadingUnpublishedChapters || goals.isLoading;

  // useMemo to find and return the currently selected chapter
  const selectedChapter = useMemo(() => {
    if (!setupComplete) return {};
    const allChapters = [...(activeChapters || []), ...(futureChapters || []), ...(completedChapters || []), ...(unpublishedChapters || [])];
    return allChapters.find(chapter => chapter.chapter_id === selectedChapterId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChapterId, activeChapters, futureChapters, completedChapters, unpublishedChapters]);

  // useEffect hook to log and set selectedChapterId when activeChapters changes
  useEffect(() => {
    // Check if the chapters data has loaded
    const chaptersLoaded = activeChapters !== undefined && futureChapters !== undefined && unpublishedChapters !== undefined;
    const allChapters = [...(activeChapters || []), ...(futureChapters || []), ...(completedChapters || []), ...(unpublishedChapters || [])];

    // If the data has loaded and setup is not yet complete, select the first active chapter and set setup as complete
    if (chaptersLoaded && !setupComplete) {
      setSelectedChapterId(allChapters[0]?.chapter_id);
      setSetupComplete(true);
    }
    // If setup is complete but no chapter is selected, select the first active chapter
    else if (setupComplete && !selectedChapterId && allChapters?.length) {
      setSelectedChapterId(allChapters[0]?.chapter_id);
    }
  }, [activeChapters, futureChapters, unpublishedChapters, setupComplete, selectedChapterId]);


  //set selected chapter list query
  const selectedChapterListQuery = useMemo(() => {
    if (!selectedChapter?.chapter_id) return null
    if (selectedChapter.status === 'unpublished') return unpublishedChaptersListQueryKey
    if (selectedChapter.status === 'completed') return completedChaptersListQueryKey
    if (selectedChapter.status === 'active') return activeChaptersListQueryKey
    return futureChaptersListQueryKey
  }, [selectedChapter, activeChaptersListQueryKey, futureChaptersListQueryKey, completedChaptersListQueryKey, unpublishedChaptersListQueryKey]);

  const queryClient = useQueryClient()
  const notificationsApi = new ApiRequest(API_ROUTES.NOTIFICATIONS, API_AUTH_TYPES.ADVISOR)
  const [notificationMessage, setNotificationMessage] = useState(false)
  const { mutate: sendNotification, isLoading: isLoadingSendNotification } = useMutation(
    () => notificationsApi.post({ endpoint: 'create_post_meeting_notification', body: { household_id: householdId } }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('notifications')
        setNotificationMessage(data.sent ? "Notification Sent" : "No Highlighted Tasks")
        setTimeout(() => {
          setNotificationMessage(null)
        }, 3000)
      }
    }
  )

  const handleSendNotificationClick = () => {
    if (showReallySendNotification) {
      sendNotification()
      setTimeout(() => {

        setShowReallySendNotification(false);
      }, 3000)
    }
    else {
      setShowReallySendNotification(true)
    }
  }

  const tabs = [
    'Chapters',
    'Actionable Tasks',
    'Ongoing Strategy',
    "Completed Tasks"
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])
  return (
    <div className="w-full">
      {!loading ? (
        <div className="w-full">
          {" "}
           {/* {!isMeetingMode &&
            <div className="flex justify-center mt-3 lg:mt-0 lg:justify-end w-full mb-4"><button onClick={handleSendNotificationClick} className="w-60 flex justify-center text-center  btn-md btn-secondary">
              {notificationMessage ? <span className="ml-2 ">{notificationMessage}</span> : <p>{showReallySendNotification ? 'Click to Confirm' : 'Send Post Meeting Notification'}</p>}
            </button></div>} */}
          <TabsComponent tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
           {/* {!isMeetingMode && <StoryGeneration chaptersListQueryKey={queryKeys.unpublished} tasksListQueryKey={tasksListQueryKey} />} */}


          {selectedTab === 'Chapters' && <div className="flex flex-col lg:flex-row gap-5 mt-3">

            <div className="w-full">
              <SelectedChapter activeChapters={activeChapters}
                isMeetingMode={isMeetingMode}
                goals={goals}
                selectedChapter={selectedChapter} setSelectedChapterId={setSelectedChapterId} tasks={tasks} showSuccess={showSuccess} selectedChapterListQuery={selectedChapterListQuery} tasksListQueryKey={tasksListQueryKey} queryKeys={queryKeys} />
            </div>
            <div className=" min-w-[340px]">
              <TableOfContents queryKeys={queryKeys} activeChapters={activeChapters || []} futureChapters={futureChapters || []} completedChapters={completedChapters || []} unpublishedChapters={unpublishedChapters || []}
                selectedChapterId={selectedChapterId} setSelectedChapterId={setSelectedChapterId} tasksListQueryKey={tasksListQueryKey}


              />
            </div>

          </div>}
          {
            selectedTab === 'Actionable Tasks' && <ActionableTasksFeed activeChapters={activeChapters || []} tasks={tasks || []} queryKeys={queryKeys} isMeetingMode={isMeetingMode} />

          }
          {
            selectedTab === 'Ongoing Strategy' && <OngoingAdviceFeed queryKeys={queryKeys} activeChapters={activeChapters || []} goals={goals.data} />

          }
          {
            selectedTab === "Completed Tasks" && <CompletedTasksFeed allChapters={[...(activeChapters || []), ...(futureChapters || []), ...(completedChapters || []), ...(unpublishedChapters || [])] || []} tasks={tasks || []} queryKeys={queryKeys} />
          }

        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default FinancialStory;


function ActionableTasksFeed({ activeChapters, tasks, queryKeys,isMeetingMode }) {
  const [showHiddenTasks, setShowHiddenTasks] = useState(!isMeetingMode);

  const activeChaptersWithUncompletedTasks = useMemo(() => {
    return activeChapters.filter(chapter => tasks.filter(task => task.chapter_id === chapter.chapter_id && (task.status === STATUS_OPTIONS.TASKS.ACTIVE
      || task.status === STATUS_OPTIONS.TASKS.HIGHLIGHTED
    )).length > 0)
  }, [activeChapters, tasks])
  return (
    <div>
      <div className="flex mt-10">
        <div className="flex items-center space-x-2 ml-auto">
          <p className="text-gray-800 text-sm">Show hidden tasks</p>
          <Toggle enabled={showHiddenTasks} setEnabled={setShowHiddenTasks} />
        </div>
      </div>
      
   
    <ul className="space-y-10 mt-2">
      {activeChaptersWithUncompletedTasks && activeChaptersWithUncompletedTasks.map(chapter => (
        <li key={chapter.chapter_id}>
          <h6 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider ">{`${chapter.title}`.toUpperCase()}</h6>
          <ul className="space-y-2">
            {tasks && tasks.map(task => (
              (showHiddenTasks || isVisibleNowFunction(task)) &&
              <li key={task.task_id}>
                {(task.chapter_id === chapter.chapter_id && task.status !== STATUS_OPTIONS.TASKS.COMPLETED) &&
                  <Task
                    tasks={tasks}
                    task={task}
                    chapter={chapter}
                    tasksListQueryKey={queryKeys.tasks}
                    chaptersListQueryKey={queryKeys.active_chapters}
                    completedTasksListQueryKey={queryKeys.tasks}
                    isClientPortalTask={false}
                    queryKeys={queryKeys}
                  />
                }
              </li>
            ))}
          </ul>
        </li>
      ))}
      </ul>
    </div>
  )
}

function CompletedTasksFeed({ allChapters, tasks, queryKeys }) {
  const { household_id } = useParams();
  const [organizeByChapter, setOrganizeByChapter] = useState(false);

  const activeChaptersWithCompletedTasks = useMemo(() => {
    return allChapters.filter(
      (chapter) =>
        tasks.filter(
          (task) =>
            task.chapter_id === chapter.chapter_id &&
            task.status === STATUS_OPTIONS.TASKS.COMPLETED
        ).length > 0
    );
  }, [allChapters, tasks]);

  const meetingsApi = new ApiRequest(API_ROUTES.CLIENT_MEETINGS, API_AUTH_TYPES.ADVISOR);
  const { data: latestMeeting, isLoading } = useQuery({
    queryKey: ['latest_meeting'],
    queryFn: meetingsApi.getFn({
      endpoint: 'get_latest_meeting_household_id',
      params: [household_id],
    }),
  });

  const completedTasksBeforeMeeting = tasks.filter(
    (task) =>
      task.status === STATUS_OPTIONS.TASKS.COMPLETED &&
      (!latestMeeting || new Date(task.completed_at) < new Date(latestMeeting.start_time))
  ).sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));

  const completedTasksAfterMeeting = tasks.filter(
    (task) =>
      task.status === STATUS_OPTIONS.TASKS.COMPLETED &&
      latestMeeting &&
      new Date(task.completed_at) >= new Date(latestMeeting.start_time)
  ).sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));

  return (
    <div>
      <div className="flex justify-end mt-5">
        <div className="flex items-center space-x-2 ">
          <p className="text-gray-800 text-sm">Organize By Chapter</p>
          <Toggle enabled={organizeByChapter} setEnabled={setOrganizeByChapter} />
        </div>
      </div>
      {organizeByChapter ? (
        <ul className="space-y-10 mt-10">
          {activeChaptersWithCompletedTasks &&
            activeChaptersWithCompletedTasks.map((chapter) => (
              <li key={chapter.chapter_id}>
                <h6 className="text-sm font-semibold leading-7 sm:leading-9 tracking-wider ">
                  {`${chapter.title}`.toUpperCase()}
                </h6>
                <ul className="space-y-2">
                  {completedTasksAfterMeeting.map(
                    (task) =>
                      task.chapter_id === chapter.chapter_id && (
                        <li key={task.task_id}>
                          <Task
                            tasks={tasks}
                            task={task}
                            chapter={chapter}
                            tasksListQueryKey={queryKeys.tasks}
                            chaptersListQueryKey={queryKeys.active_chapters}
                            completedTasksListQueryKey={queryKeys.tasks}
                            isClientPortalTask={false}
                            queryKeys={queryKeys}
                          />
                        </li>
                      )
                  )}
                  {latestMeeting && (
                    <div className="my-4 flex space-x-2 items-center">
                      <div className="h-1 w-full bg-gray-400"></div>

                      <h6 className="text-sm flex-shrink-0  leading-7 sm:leading-9 tracking-wider text-gray-700">
                        Latest Meeting: {new Date(latestMeeting.start_time).toLocaleDateString()}
                      </h6>
                      <div className="h-1 w-full bg-gray-400"></div>
                    </div>
                  )}

                  {completedTasksBeforeMeeting.map(
                    (task) =>
                      task.chapter_id === chapter.chapter_id && (
                        <li key={task.task_id}>
                          <Task
                            tasks={tasks}
                            task={task}
                            chapter={chapter}
                            tasksListQueryKey={queryKeys.tasks}
                            chaptersListQueryKey={queryKeys.active_chapters}
                            completedTasksListQueryKey={queryKeys.tasks}
                            isClientPortalTask={false}
                            queryKeys={queryKeys}
                          />
                        </li>
                      )
                  )}
                </ul>
              </li>
            ))}
        </ul>
      ) : (
        <>
          <ul className="space-y-2 mt-10">
            {completedTasksAfterMeeting.map((task) => (
              <li key={task.task_id}>
                <Task
                  tasks={tasks}
                  task={task}
                  tasksListQueryKey={queryKeys.tasks}
                  chaptersListQueryKey={queryKeys.active_chapters}
                  completedTasksListQueryKey={queryKeys.tasks}
                  isClientPortalTask={false}
                  queryKeys={queryKeys}
                />
              </li>
            ))}
          </ul>
          {latestMeeting && (
            <div className="my-4 flex space-x-2 items-center">
              <div className="h-1 w-full bg-gray-400"></div>

              <h6 className="text-sm flex-shrink-0  leading-7 sm:leading-9 tracking-wider text-gray-700">
                Latest Meeting: {new Date(latestMeeting.start_time).toLocaleDateString()}
              </h6>
              <div className="h-1 w-full bg-gray-400"></div>
            </div>
          )}
          <ul className="space-y-2 ">

            {completedTasksBeforeMeeting.map((task) => (
              <li key={task.task_id}>
                <Task
                  tasks={tasks}
                  task={task}
                  tasksListQueryKey={queryKeys.tasks}
                  chaptersListQueryKey={queryKeys.active_chapters}
                  completedTasksListQueryKey={queryKeys.tasks}
                  isClientPortalTask={false}
                  queryKeys={queryKeys}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}





function OngoingAdviceFeed({ activeChapters, goals, queryKeys }) {
  return (
    <ul className="space-y-10 mt-10">
      {activeChapters && activeChapters.map(chapter => (
        goals.filter(goal => goal.chapter_id === chapter.chapter_id).length > 0 &&
        <li key={chapter.chapter_id}>
          <h6 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider ">{`${chapter.title}`.toUpperCase()}</h6>

          <ul className=" ">
            {goals && goals.map(
              (goal) =>
                goal.chapter_id === chapter.chapter_id && (
                  <li key={goal.goal_id} className="py-2">
                    {" "}
                    <ChapterGoal goal={goal} queryKeys={queryKeys} />
                  </li>
                )
            )}
          </ul>
        </li>
      ))}
    </ul>
  )
}


export function TabsComponent({ tabs, selectedTab, setSelectedTab }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => setSelectedTab(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
          defaultValue={tabs[0]}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b-2 border-gray-300 ">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setSelectedTab(tab)}
                className={classNames(
                  selectedTab === tab
                    ? 'border-primary-600 text-primary-600 border-b-[3px]'
                    : 'border-transparent text-gray-800 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-xl  font-semibold'
                )}
              >
                {tab}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
