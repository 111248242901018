import SmartTable from "../../../components/smart-table/SmartTable";

const HouseholdsSmartTable = () => {
  const customControlButtons = [
    <a href="pre_approved_users" className="btn-sm btn-primary">
      Pre Approved Users
    </a>,
  ];

  return (
    <SmartTable
      tableId={"households"}
      urlBase={"households"}
      customControlButtons={customControlButtons}
    />
  );
};

export default HouseholdsSmartTable;
