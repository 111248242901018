import React, { useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill"; // Import Quill and the editor component
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./editor-style.css";
import "./blog-editor-style.css";
import { IoMdSend } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import {
  imageUploaderFunction,
  quillEditorFormats,
  quillEditorToolbar,
} from "./utils";
import { useEffect } from "react";
import { useState } from "react";
import { API_AUTH_TYPES } from "../../../api/apiConstants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_ROUTES } from "../../../api/apiConstants";
import useDebounce from "../../../hooks/useDebounce";
import { classNames } from "./../../../helpers/classNames";
import ApiRequest from "../../../api/ApiRequest";
import VoiceToText from "../../ai/Voice-To-Text";
const Link = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', value);
    // Check if the href contains "abundowealth.com"
    if (!value.includes("abundowealth.com")) {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    } else {
      node.removeAttribute("target");
      node.removeAttribute("rel");
    }

    return node;
  }
}

Quill.register(CustomLink, true);

//  #2 register module
Quill.register("modules/imageUploader", ImageUploader);

const SAVE_INTERVAL_MS = 1000 * 0.2;
export default function QuillTextEditor({
  placeholder = "Write somethere here",
  options,
  queryKey,
  listQueryKey,
  handleSubmitOnSuccess = async () => { },
  handleChangeSideEffect = (contents) => { },
  acceptOutsideChange = false,
  setAcceptOutsideChange = () => { },
}) {
  const quillRef = useRef(null);
  const [delta, setDelta] = useState();
  const debouncedDelta = useDebounce(delta, SAVE_INTERVAL_MS);
  const [isSaving, setIsSaving] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [hasChanged, setHasChanged] = useState(false)
  //set up the quill editor modules
  const modules = useMemo(() => {
    return {
      toolbar: quillEditorToolbar,
      imageUploader: imageUploaderFunction,
    };
  }, []);

  //socket io functionality
  const [quill, setQuill] = useState();
  const quillApi = new ApiRequest(
    API_ROUTES.QUILL_TEXT_EDITOR,
    options.auth === 'user' ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR
  );

  const queryClient = useQueryClient();

  const {
    data: document,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryFn: async () =>
      await quillApi.post({ endpoint: "find_or_create", body: options }),
    queryKey: Array.isArray(queryKey) ? queryKey : [queryKey],
    refetchOnWindowFocus: false,
  });
  const { mutate: mutateSave, isLoading: isLoadingSave } = useMutation(
    (content) =>
      quillApi.update({
        route: options.route,
        content,
        id: document.id,
      })
    ,
    {

      onSuccess: () => {
        // Invalidate and refetch
        setIsSaving(false)
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(document.id);
      },
    }
  );
  const { mutate: mutateSubmitDraft, isLoading: isSubmiting } = useMutation(
    () =>
      quillApi.patch({
        endpoint: "submit_draft",
        body: { route: options.route, id: document.id },
      }),
    {
      onSuccess: () => {
        // Invalidate and refetch
        handleSubmitOnSuccess(document.id);

        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(listQueryKey);
        quill.setContents();
      },
    }
  );


  useEffect(() => {
    if (quill == null) return;

    if (
      quill?.getContents().ops[0].insert === "\n" &&
      quill?.getContents().ops.length === 1
    )
      return setIsEmpty(true);
    setIsEmpty(false);
  }, [quill?.getContents().ops[0].insert]);

  useEffect(() => {
    if (quillRef.current == null) return;
    if (hasChanged && !acceptOutsideChange) return
    const q = quillRef.current.getEditor();
    q.history.clear();

    // Check if document and document.content exist before setting the contents
    if (document && document.content) {
      q.setContents(document.content);
    }

    setQuill(q);

    setDelta(q.getContents());
    setAcceptOutsideChange(false)
  }, [isLoading, document]);  // Add document as a dependency


  // Effect for API call
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    if (debouncedDelta) {
      mutateSave(debouncedDelta);
    }
  }, [debouncedDelta]);




  const handleChange = () => {
    if (quill == null) return;
    const contents = quill.getContents();

    setIsSaving(true);
    setDelta(contents);
    handleChangeSideEffect(contents);
    setHasChanged(true)
  };


  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>{error}</p>;
  return (
    <div className="rounded-md">
      <div className="  rounded-md flex flex-col justify-center items-center  ">
        <div className="w-full relative">
          {(isSubmiting || isSaving) && (
            <AiOutlineLoading3Quarters className="animate-spin h-4 w-4 absolute right-2 bottom-2 text-gray-500" />
          )}
          <ReactQuill
            ref={quillRef}
            modules={modules}
            formats={quillEditorFormats}
            onChange={handleChange}
            defaultValue={document.content || null}
            placeholder={placeholder}
          />
        </div>
        <div className="w-full flex justify-end mt-2 space-x-2 items-center">
          {" "}
          <VoiceToText
            text={""}
            setText={(newText) => {
              const contents = quill.getContents();

              quill.setContents([...contents.ops, { insert: `${newText}\n` }]);
              const newContents = quill.getContents();
              setIsSaving(true);
              setDelta(newContents);
              handleChangeSideEffect(newContents);
            }}
          />
          {options.is_draft && (
            <button
              disabled={isSubmiting || isSaving || isEmpty}
              className={classNames(
                "btn-primary btn-md",
                (isSubmiting || isSaving || isEmpty) && "opacity-50"
              )}
              onClick={() => mutateSubmitDraft()}
            >
              <IoMdSend className="h-5 w-7 text-white" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
