import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../errorHandling/error-fallback-page';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} >
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route></ErrorBoundary>
  );
}
