import React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import QuillTextEditor from "../../../../../../components/textEditor/Quill-Text-Editor";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import DisplayTaskRichText from "./DisplayTaskRichText";

const TaskContent = ({ task, listQueryKey }) => {
  const [showEdit, setShowEdit] = useState(false);
  const handleSave = () => {
    queryClient.invalidateQueries(listQueryKey);
    setShowEdit(false);
  };
  const options = {
    is_draft: false,
    route: "client_tasks",
    search_params: { document_id: task.task_id },
  };

  const queryKey = `${task.task_id}`;
  const queryClient = useQueryClient();

  return (
    <DoubleClickEdit
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      defaultComponent={<DisplayTaskRichText task={task} />}
      editComponent={
        <div className="w-full">
          {task.content ? (
            <QuillTextEditor
              placeholder="Create New Note"
              options={options}
              queryKey={queryKey}
              listQueryKey={listQueryKey}
            />
          ) : (
            <>
              {/* <MarkdownNotes noteRaw={task.description} /> */}
              <p>Can't edit old note editor tasks</p>
            </>
          )}
        </div>
      }
      handleSubmitEdit={handleSave}
      componentName="description"
    />
  );
};

export default TaskContent;
