import React from "react";
import { useState } from "react";
import { deleteReq } from "../../../../../apis/internalApi";
import { classNames } from "./../../../../../helpers/classNames";
import { API } from "./../../../../../constants/index";
import { useSelector } from "react-redux";
import useNav from "./../../../../../hooks/useNav";
import AlertSuccess from "./../../../../../components/alerts/AlertSuccess";
import AlertError from "./../../../../../components/alerts/AlertError";

const DeleteHousehold = ({householdId}) => {
  const [text, setText] = useState();
  const nav = useNav();
  const accessToken = useSelector((state) => state.user.accessToken);
  const [showTextBox, setShowTextBox] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
    const textToMatch = "Delete";
  const handleDeleteHousehold = async () => {
    try {
      const response = await deleteReq(
        API.HOUSEHOLDS,
        `delete_household_by_id/${householdId}`,
        accessToken,
        
        );
  
      if (response.status === 200) {
        setSuccess(" Household Deleted! ");
        setTimeout(() => {
          nav("/crm/households/smart-table/default");
        }, 1000 * 2);
      }
    } catch (error) {
        setError(error.response.data);
      
    }
  };
  return (
    <div>
      {success && <AlertSuccess>{success}</AlertSuccess>}
      {error && <AlertError>{error}</AlertError>}
      {!showTextBox ? (
        <div className="flex justify-end">
          <button
            className="btn-danger btn-md  "
            onClick={() => setShowTextBox(true)}
          >
            Delete Household
          </button>
        </div>
        
      ) : (
        <>
          <label htmlFor="confirm_delete">Type Delete to confirm</label>
          <input
            type="text"
            id="confirm_delete"
            placeholder="type Delete"
            className="input-primary"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button
            disabled={text !== textToMatch}
            className={classNames(
              "btn-danger btn-md mt-2 ",
              text !== textToMatch && "opacity-60"
            )}
            onClick={handleDeleteHousehold}
          >
            Delete Household
          </button>
          <button
            className="underline ml-2 "
            onClick={() => {
              setShowTextBox(false);
                setText("");
                setError("")
            }}
          >
            Cancel
          </button>
        </>
      )}
    </div>
  );
};

export default DeleteHousehold;
