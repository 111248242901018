import { useState } from "react";

/**
 * Custom React hook for managing an array state.
 *
 * @param {Array} defaultValue - The initial array value.
 * @returns {Object} An object containing array state and array manipulation functions.
 */
export default function useArray(defaultValue) {
  const [array, setArray] = useState(defaultValue);

  /**
   * Add an element to the end of the array.
   *
   * @param {*} element - The element to be added.
   */
  function push(element) {
    setArray((a) => [...a, element]);
  }

  /**
   * Add an element to the beginning of the array.
   *
   * @param {*} element - The element to be added.
   */
  function unshift(element) {
    setArray((a) => [element, ...a]);
  }

  /**
   * Filter the array based on a callback function.
   *
   * @param {Function} callback - A function used to filter the array.
   */
  function filter(callback) {
    setArray((a) => a.filter(callback));
  }

  /**
   * Update an element at a specific index in the array.
   *
   * @param {number} index - The index of the element to update.
   * @param {*} newElement - The new element to replace the old one.
   */
  function update(index, newElement) {
    setArray((a) => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  }

  /**
   * Remove an element at a specific index from the array.
   *
   * @param {number} index - The index of the element to remove.
   */
  function remove(index) {
    setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  }

  /**
   * Clear the array, making it empty.
   */
  function clear() {
    setArray([]);
  }

  return { array, set: setArray, push, filter, update, remove, clear, unshift };
}
