import React from 'react';
import Highlighter from 'react-highlight-words';
import RichTextDisplay from '../../../../components/textEditor/RichTextDisplay';

const OngoingAdvice = ({ item, query }) => {
  // Ensure the query is an array. If it's a string, convert it into an array.
  // Also, convert the query to lowercase for a case-insensitive match.
  const searchWords = Array.isArray(query)
    ? query
    : [query];

  return (
    <div className="">
   
      <RichTextDisplay
        content={item.content}
        thisClassName="markdown-notes"
        query={query}
      />
    </div>
  );
};

export default OngoingAdvice;
