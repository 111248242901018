import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../../api'
import SmallLoadingSpinner from '../../../../../../../components/loading/SmallLoadingSpinner'
import FadeIn from '../../../../../../../components/transitions/FadeIn'

const parabotApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR)

const CreateTemplate = ({ open, setOpen ,emailId}) => {
    const [title, setTitle] = useState('')
    const queryClient = useQueryClient()
    const { mutate: postPrompt, isLoading } = useMutation(async () => parabotApi.post({
        endpoint: 'template',
        body: {
            title,
            email_id:emailId
        },
    }), {
        onSuccess: () => {
            setOpen(false)
            // reset form inputs
            setTitle('')
            queryClient.invalidateQueries('email-templates')
        },
        onError: (error) => {
            console.error('Error creating prompt:', error)
        },
    })
    return (
        <FadeIn isShowing={open}>
            <div className="border-b pb-2">
              
                <p className="label-primary">
                  Template  Label
                </p>
                <input
                    autoComplete='off'
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="input-primary mt-1"
                />
            
               
                <div className="flex   justify-evenly items-center mt-3">
                    <button className="btn-lg btn-primary "
                        onClick={postPrompt}
                        disabled={title.trim() === ''}
                    >
                        <p className='pr-1'> Create Template </p>  {isLoading ? <SmallLoadingSpinner textColor='text-white' /> : null}
                    </button>
                    <button className=" text-gray-500 btn-lg btn-gray-outline" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>

            </div>



        </FadeIn>)
}

export default CreateTemplate