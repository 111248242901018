import React from 'react';
import { useDrag } from 'react-dnd';
import { classNames } from '../misc/styling';

const DraggableItem = ({ type, item, children, isDraggable = true, onIsDragging }) => {
    const [collected, drag] = useDrag(() => ({
        type,
        item: () => {
            // Called at the beginning of the drag operation
            if (onIsDragging) {
                onIsDragging(true);
            }
            return item;
        },
        options: {
            // Called when the dragging stops
            end: (item, monitor) => {
                if (onIsDragging) {
                    onIsDragging(false);
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: () => isDraggable,
    }));

    return (
        <div
            ref={drag}
            className={classNames(collected.isDragging && "invisible", '', isDraggable && "hover:cursor-grab")}
        >
            {!collected.isDragging && children}
        </div>
    );
};

export default DraggableItem;
