import React from 'react';
import RichTextDisplay from '../../../../components/textEditor/RichTextDisplay';

/**
 * Displays the "Financial Why" of a household, which explains the household's financial goals, motivations,
 * or any significant notes that describe the big financial picture of the household. If no content is set,
 * it displays a fallback message indicating no Financial Why has been set.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.householdMeta - Meta information of the household, including the big picture content.
 * @returns {JSX.Element} A component that renders the Financial Why using a rich text display component if available, otherwise shows a fallback message.
 */
const FinancialWhy = ({ householdMeta }) => {
    return (
        <>
            {householdMeta.big_picture_content ?
                <RichTextDisplay content={householdMeta.big_picture_content} thisClassName={'markdown-notes'} />
                : <p>No Financial Why Set</p>}
        </>
    );
}

export default FinancialWhy;
