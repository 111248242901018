import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 1,
  visible: true,
};
export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    r_stepPlusOne: (state) => {
      state.step = state.step + 1;
    },
    r_stepMinusOne: (state) => {
      state.step = state.step - 1;
    },
    r_resetStep: (state) => {
      state.step = 1;
    },
    r_switchShowTutorial: (state) => {
      state.visible = !state.visible
    }
  },
});

// Action creators are generated for each case reducer function
export const { r_stepPlusOne, r_stepMinusOne, r_resetStep, r_switchShowTutorial } =
  tutorialSlice.actions;

export default tutorialSlice.reducer;
