import React from "react";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../../api";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import LeadSource from "./LeadSource";
import HouseholdReferrer from "./HouseholdReferrer";
import AdvisorReferrer from "./AdvisorReferrer";
import Referrals from "./Referrals";
import ReferralInput from "./ReferralInput";

/**
 * The Source component fetches and displays various referral sources and related information
 * for a specific household. It conditionally renders components based on the lead source and
 * whether any referrals have been made or received.
 * 
 * @returns {JSX.Element} A component that displays the lead source, referrer information, and referrals.
 */
const Source = () => {
  const { household_id: householdId } = useParams();
  const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);

  const { data: household, isLoading } = useQuery({
    queryFn: householdsApi.getFn({ endpoint: "source_data", params: [householdId] }),
    queryKey: `source-${householdId}`,
  });

  if (isLoading || !household) return <p>Loading...</p>;
  return (
    <div className='space-y-5'>
      <LeadSource household={household} householdsApi={householdsApi} />
      {household.lead_source === 'Referral' && <HouseholdReferrer household={household} householdsApi={householdsApi} />}
      {household.lead_source === 'Advisor Referral' && <AdvisorReferrer household={household} householdsApi={householdsApi} />}
      {household.referral_input && <ReferralInput household={household} />}
      {household.referrals && <Referrals household={household} />}
    </div>
  );
};

export default Source;
