import React from "react";
import FadeIn from "../../../../../components/transitions/FadeIn";
import QuillTextEditor from "./../../../../../components/textEditor/Quill-Text-Editor/index";
import { useMutation, useQueryClient } from "react-query";
import ApiRequest from "../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "./../../../../../api/apiConstants";

const EditNote = ({ documentId, open, setOpen, listQueryKey }) => {
  const options = {
    is_draft: false,
    route: "household_notes",
    search_params: { document_id: documentId },
  };
  const queryKey = `${documentId}`;
  const queryClient = useQueryClient();
  const notesApi = new ApiRequest(
    API_ROUTES.HOUSEHOLD_NOTES,
    API_AUTH_TYPES.ADVISOR
  );
  const { mutate: updateEditHistory } = useMutation(
    () => notesApi.patch({
      endpoint: "update_edit_history",
      body: { id: documentId },
    }),
    {  onSuccess: () => queryClient.invalidateQueries(listQueryKey) }
  );

  const handleStopEditing = () => {
    try {
      updateEditHistory();
      queryClient.invalidateQueries(listQueryKey);
      setOpen(false);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <FadeIn isShowing={open}>
      {" "}
      <div className="mt-1"></div>
      <QuillTextEditor
        placeholder="Create New Note"
        options={options}
        queryKey={queryKey}
        listQueryKey={listQueryKey}
      />
      <button onClick={handleStopEditing} className="btn-primary btn-md">
        Stop Editing
      </button>
    </FadeIn>
  );
};

export default EditNote;
