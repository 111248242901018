import React, { useState } from "react";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import useNav from "../../../../hooks/useNav";
import { HouseholdSearch } from "../../../comboboxes";
import { patchReq } from "../../../../apis/internalApi";
import { CheckCircleIcon } from "@heroicons/react/solid";

const ClaimHousehold = ({ rowId, data, accessToken, table, colId,  editId,
}) => {
  const nav = useNav();
  const [showSearch, setShowSearch] = useState(false);
  const [household,setHousehold] = useState(data);
  const [selectedHousehold, setSelectedHousehold] = useState(data);
  const [showSuccess, setShowSuccess] = useState(false);
  const submitChanges = async (e) => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: selectedHousehold.household_id,
        row_id: editId,
        col_id: colId,
      });
      setHousehold({household_name: selectedHousehold.name, household_id: selectedHousehold.household_id})
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
     setShowSearch(false);
    } catch (error) { }
  };

  return (
    <div className='flex items-center space-x-2'>
      {" "}
      {household?.household_id ? (
        <button
          onClick={() => {
            nav(`/crm/households/${household.household_id}`);
          }}
          className="flex items-center text-left px-2"
        >
          <p>{household.household_name}</p>{" "}
          <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0" />
        </button>
      ) : (
          <div className='flex items-center space-x-2'>
            {showSearch ? (
              <div className='flex space-x-2 items-center py-1'>
                <HouseholdSearch label="Households" setHousehold={setSelectedHousehold} household={selectedHousehold} /> 
                <button className="btn-primary btn-md" onClick={submitChanges}>Link</button>

                <button className="underline" onClick={() => setShowSearch(false)}>Cancel</button>
            </div>
            ): (
              <button
                onClick={() => {
                  setShowSearch(true);
                }}
                  className="btn-primary  px-3 py-0.5"
              >
                <p>Select</p>{" "}
              
              </button>
            )}
        </div>
      )}{" "}
      {showSuccess && <CheckCircleIcon className="h-5 w-5 text-green-500" />}

    </div>
  );
};

export default ClaimHousehold;
