import { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import {
  BellIcon,
  MenuAlt2Icon,
  AcademicCapIcon,
  XIcon,
  UserIcon,
  UsersIcon,
  CogIcon,
  SearchIcon,
  EmojiHappyIcon,
  LoginIcon,
  PresentationChartBarIcon,
  DatabaseIcon,
  BookOpenIcon,
  TemplateIcon,
  ClockIcon,
  AnnotationIcon,
  DocumentTextIcon,
  MailIcon,
  ChevronDownIcon
} from "@heroicons/react/outline";
import {
  BellIcon as BellIconSolid,
  UserIcon as UserIconSolid,
  UsersIcon as UsersIconSolid,
  CogIcon as CogIconSolid,
  EmojiHappyIcon as EmojiHappyIconSolid,
  LoginIcon as LoginIconSolid,
  PresentationChartBarIcon as PresentationChartBarIconSolid,
  DatabaseIcon as DatabaseIconSolid,
  BookOpenIcon as BookOpenIconSolid,
  TemplateIcon as TemplateIconSolid,
  AnnotationIcon as AnnotationIconSolid,
  ClockIcon as ClockIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
  MailIcon as MailIconSolid
} from "@heroicons/react/solid";
import useNav from "../../hooks/useNav";
import { useAuth } from "../../context/auth-context";
import { authenticateAdvisorAdmin } from "../../helpers/jwtAuth";
import { CommandPaletteHouseholdSearch } from "../comboboxes/index";
import { TbLayoutDashboard } from "react-icons/tb";
import Notifications from "../Notifications";
import { classNames } from "../misc/styling";
import ParabotInterface from "../ai/Parabot-Interface";
import {  ToolTipHoverContainer, ToolTipLeft } from "../ToolTips";
import EmailMessages from "../../routes/AdvisorPortal/emails/components/EmailMessages";
import useAdvisorPreloadedData from "../../hooks/useAdvisorPreloadedData";
import Toasts from "../Toasts";
import { BiBot } from "react-icons/bi";

// images
const abundoLogo =
  "https://res.cloudinary.com/abundo-wealth-assets/image/upload/v1658176803/Abundo%20Wealth%20Assets/components/logos/Abundo_logo_mtnbxi_zg4tju.webp";
//end images

export default function Layout({
  children,
  bgColor,
  columns = 1,
  asideCol,
  isSmartTable = false,
}) {
  //hooks
  const nav = useNav();

  const [userId, setUserId] = useState(null)
  //data
  const advisor = [
    {
      name: "Dashboard",
      href: "/crm/dashboard",
      icon: {
        outline: TbLayoutDashboard,
        solid: TbLayoutDashboard
      },
      current: window.location.href.includes("crm/dashboard"),
    },
    {
      name: "BunMail",
      href: `/crm/emails/inbox/1`,
      icon: {
        outline: MailIcon,
        solid: MailIconSolid
      },
      current: window.location.href.includes("/crm/emails"),
    },
    {
      name: "Households",
      href: "/crm/households/smart-table/default",
      icon: {
        outline: UsersIcon,
        solid: UsersIconSolid
      },
      current: window.location.href.includes("/households"),
    },
    {
      name: "Users",
      href: "/crm/users/smart-table/default",
      icon: {
        outline: UserIcon,
        solid: UserIconSolid
      },
      current: window.location.href.includes("/users"),
    },
    {
      name: "Prospects",
      href: "/crm/prospects/smart-table/default",
      icon: {
        outline: EmojiHappyIcon,
        solid: EmojiHappyIconSolid
      },
      current: window.location.href.includes("/prospects"),
    },
    {
      name: "Onboarding",
      href: "/crm/onboarding/smart-table/default",
      icon: {
        outline: LoginIcon,
        solid: LoginIconSolid
      },
      current: window.location.href.includes("/onboarding"),
    },

    {
      name: "Meetings",
      href: "/crm/meetings/smart-table/default",
      icon: {
        outline: PresentationChartBarIcon,
        solid: PresentationChartBarIconSolid
      },
      current: window.location.href.includes("/meetings"),
    },

    {
      name: "Notifications",
      href: "/crm/notifications/smart-table/default",
      icon: {
        outline: BellIcon,
        solid: BellIconSolid
      },
      current: window.location.href.includes("/notifications"),
      hidden: true
    },
    {
      name: "Forms",
      href: "/crm/forms/smart-table/default",
      icon: {
        outline: DocumentTextIcon,
        solid: DocumentTextIconSolid
      },
      current: window.location.href.includes("/forms"),
      hidden: true
    },
    {
      name: 'Reminders',
      href: '/crm/reminders',
      icon: {
        outline: ClockIcon,
        solid: ClockIconSolid
      },
      current: window.location.href.includes("/reminders"),
    },
    {
      name: "Resources",
      href: "/crm/resources",
      icon: {
        outline: BookOpenIcon,
        solid: BookOpenIconSolid
      },
      current: window.location.href.includes("/resources"),
    },
    // {
    //   name: 'Agents',
    //   href: '/crm/agents/Active Agents',
    //   icon: {
    //     outline: BiBot,
    //     solid: BiBot
    //   },
    //   current: window.location.href.includes("/agents"),
    // },
    {
      name: "Settings",
      href: `/crm/advisor/settings/${userId}`,
      icon: {
        outline: CogIcon,
        solid: CogIconSolid
      },
      current: window.location.href.includes("/advisor/settings"),
      hidden: true
    },

  ];
  const admin = [
    {
      name: "Analytics",
      href: "/crm/analytics",
      icon: {
        outline: DatabaseIcon,
        solid: DatabaseIconSolid
      },
      current: window.location.href.includes("/analytics"),
    },
    // {
    //   name: "Investment Controls",
    //   href: "/admin/investment_controls",
    //   icon: {
    //     outline: TemplateIcon,
    //     solid: TemplateIconSolid
    //   },
    //   current: window.location.href.includes("/admin"),
    // },
  ];
  const manager = [
    {
      name: "M Dashboard",
      href: "/crm/manager/dashboard",
      icon: {
        outline: TbLayoutDashboard,
        solid: TbLayoutDashboard
      },
      current: window.location.href.includes("/manager/dashboard"),
    },
  ];

  const content = [
    {
      name: "Blog",
      href: "/content/blog/dashboard",
      icon: {
        outline: AnnotationIcon,
        solid: AnnotationIconSolid
      },
      current: window.location.href.includes("/blog"),
    },
    {
      name: "Learning Lab",
      href: "/content/learning_lab/dashboard",
      icon: {
        outline: AcademicCapIcon,
        solid: AcademicCapIcon
      },
      current: window.location.href.includes("/learning_lab"),
    },
  ];


  const { logout } = useAuth(); // Access the logout function from the authentication context.
  const [showHiddenLabels, setShowHiddenLabels] = useState(false); // State to control the visibility of hidden labels.
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control the sidebar open/close state.
  const [isManager, setIsManager] = useState(false); // State to track whether the user is a manager.
  const accessToken = useSelector((state) => state.user.accessToken); // Access the access token from the Redux store.

  // Function to authenticate the user as an admin or advisor.
  const authenticateAdminAdvisor = useCallback(async () => {

    try {
      const auth = await authenticateAdvisorAdmin(accessToken); // Authenticate the user.

      setIsManager(auth.is_manager); // Set the isManager state based on the authentication result.
      setUserId(auth.user_id); // Set the user ID based on the authentication result.
    } catch (error) {
      nav("/"); // Navigate the user to the root page.
      setTimeout(() => {
        logout(); // If there's an error, log the user out.
      }, 1000);

    }
  }, [accessToken]);

  // UseEffect to trigger the authentication process when the component mounts.
  useEffect(() => {
    const authenticate = async () => {
      try {
        authenticateAdminAdvisor(); // Call the authentication function.
      } catch (error) { }
    };
    authenticate(); // Trigger authentication when the component mounts.
  }, [authenticateAdminAdvisor]);


  
  useEffect(() => {
    const hiddenItems = [...advisor, ...admin, ...manager, ...content].filter(item => item.hidden);
    const anyHiddenCurrent = hiddenItems.some(item => item.current);
    if (anyHiddenCurrent) {
      setShowHiddenLabels(true);
    }
  }, []);

  useAdvisorPreloadedData(true)

  return (
    <>
      <ParabotInterface />
      <EmailMessages />
      <Toasts />

      <div>
        <MobileSidebar advisor={advisor} admin={admin} manager={manager} isManager={isManager} content={content} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}
          showHiddenLabels={showHiddenLabels} setShowHiddenLabels={setShowHiddenLabels}
        />
        <DesktopSidebar advisor={advisor} admin={admin} manager={manager} isManager={isManager} content={content}
          showHiddenLabels={showHiddenLabels} setShowHiddenLabels={setShowHiddenLabels}
        />
        {/*Main Secction */}
        <div className=" lg:pl-56 flex flex-col flex-1">
          {/* top Bar */}
          <TopBar setSidebarOpen={setSidebarOpen} />
          <div className="h-[calc(100vh-65px)] ">
            {/* Main Section */}
            {/* 1 Column */}
            {columns === 1 && !isSmartTable ? (
              <main
                className={`flex-1 justify-center min-h-screen relative ${bgColor ? bgColor : " bg-white"
                  }  `}
              >
                <div className="max-w-full overflow-x-hidden pb-8">
                  {" "}
                  {children}
                </div>
              </main>
            ) : null}
            {/* 2 Columns */}
            {columns === 2 && !isSmartTable ? (
              <aside className="flex-shrink-0 w-full lg:w-[350px]  xl:w-[400px] mt-6 lg:mt-0">
                {asideCol}
              </aside>
            ) : null}
            {/* Smart Table */}
            {isSmartTable && <> {children}</>}
          </div>
        </div>
      </div>
    </>
  );
}
/**
 * MobileSidebar component for the application's sidebar on small screens.
 * @param {Object} advisor - Navigation items for advisors.
 * @param {Object} admin - Navigation items for administrators.
 * @param {Object} manager - Navigation items for managers.
 * @param {Object} content - Navigation items for content-related options.
 * @param {boolean} isManager - Indicates whether the user is a manager.
 * @param {boolean} sidebarOpen - Indicates whether the sidebar is open.
 * @param {function} setSidebarOpen - Function to toggle the sidebar.
 */
function MobileSidebar({ advisor, admin, manager, content, isManager, sidebarOpen, setSidebarOpen, showHiddenLabels, setShowHiddenLabels }) {
  const nav = useNav();
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 lg:hidden"
        onClose={setSidebarOpen}
        id="mobile-sidebar-dialog"
        aria-labelledby="mobile-sidebar-dialog-title"
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" id="mobile-sidebar-overlay" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                  id="mobile-sidebar-close-button"
                  aria-label="Close sidebar"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <button
              onClick={() => nav("/crm/households")}
              className="flex-shrink-0 flex items-center px-4"
              id="mobile-sidebar-logo-button"
              aria-label="Navigate to CRM Households"
            >
              <img className="h-12 px-4 w-auto" src={abundoLogo} alt="abundo" />
            </button>
            <div className="mt-5 flex-1 overflow-y-auto text-sm">
              <nav className="px-2 space-y-1 list-none">
                <li> {
                  advisor.map((item) => (
                    <NavLink key={item.name} item={item} />
                  ))}
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400" aria-label="Miscellaneous Options">Misc</div>
                  {admin.map((item) => (
                    <NavLink key={item.name} item={item} />
                  ))}
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400" aria-label="Content Options">Content</div>
                  {content.map((item) => (
                    <NavLink key={item.name} item={item} />
                  ))}
                </li>
                {isManager && (
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400" aria-label="Manager Options">Manager</div>
                    {manager.map((item) => (
                      <NavLink key={item.name} item={item} />
                    ))}
                  </li>
                )}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}



/**
 * DesktopSidebar component for the application's sidebar on large screens.
 * @param {Object} advisor - Navigation items for advisors.
 * @param {Object} admin - Navigation items for administrators.
 * @param {Object} manager - Navigation items for managers.
 * @param {Object} content - Navigation items for content-related options.
 * @param {boolean} isManager - Indicates whether the user is a manager.
 */
function DesktopSidebar({ advisor, admin, manager, content, isManager, showHiddenLabels, setShowHiddenLabels }) {
  const allCurrentStatus = [...advisor.filter(a => !a.hidden).map(a => a.current), ...admin.map(a => a.current), ...manager.map(a => a.current), ...content.map(a => a.current)]
  return (
    /* Static sidebar for desktop */
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col" id="desktop-sidebar">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          <img
            className="h-12 px-4 w-auto"
            src={abundoLogo}
            alt="Abundo Wealth Inc."
          />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-4">
            <li>
              <ul className="-mx-2 space-y-1">
                {advisor.filter(a => !a.hidden).map((item) => (
                  <li key={item.name}>
                    <NavLink item={item} />
                  </li>
                ))}
              </ul>
            </li>
            <button
              className={classNames(
                showHiddenLabels
                  ? "bg-gray-200 text-gray-900"
                  : "text-gray-600 hover:bg-gray-50 ",
                "group flex items-center  -mx-2 py-2 px-2 text-sm font-medium rounded-md"
              )}
              onClick={() => {
                setShowHiddenLabels(!showHiddenLabels);
              }}
            >
              <ChevronDownIcon
                className={classNames(
                  showHiddenLabels
                    ? "rotate-180"
                    : " group-hover:text-gray-500",
                  "mr-3 flex-shrink-0 h-5 w-5  text-gray-500  t"
                )}
                aria-hidden="true"
              />
              <p>{showHiddenLabels ? 'Less' : 'More'}</p>


            </button>
            {showHiddenLabels &&<>
                <li>
                  <ul className="-mx-2 space-y-1">
                    {advisor.filter(a => a.hidden).map((item) => (
                      <li key={item.name}>
                        <NavLink item={item} />
                      </li>
                    ))}
                  </ul>
                </li>
                {<li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Misc</div>
                  <ul className="-mx-2 mt-2 space-y-1">
                    {admin.map((item) => (
                      <li key={item.name}>
                        <NavLink item={item} />
                      </li>
                    ))}
                  </ul>
                </li>}
                {<li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Content</div>
                  <ul className="-mx-2 mt-2 space-y-1">
                    {content.map((item) => (
                      <li key={item.name}>
                        <NavLink item={item} />
                      </li>
                    ))}
                  </ul>
                </li>}
                {isManager && <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Manager</div>
                  <ul className="-mx-2 mt-2 space-y-1">
                    {manager.map((item) => (
                      <li key={item.name}>
                        <NavLink item={item} />
                      </li>
                    ))}
                  </ul>
              </li>}
            </>  }
          </ul>
        </nav>
      </div>
    </div>
  );
}



/**
 * TopBar component for the application's top navigation.
 * @param {function} setSidebarOpen - Function to control the sidebar's open/close state.
 */
function TopBar({ setSidebarOpen }) {
  const nav = useNav();
  const { logout } = useAuth();

  // User navigation options, such as signing out and going to the client view.
  const userNavigation = [
    {
      name: "Sign out",
      func: () => {
        try {
          nav("/");
          setTimeout(() => {
            logout(); // If there's an error, log the user out.
          }, 1000);
        } catch (error) { }
      },
    },
    {
      name: "Client View",
      func: () => {
        try {
          nav("/dashboard");
        } catch (error) { }
      },
    },
  ];

  // State for controlling the search functionality.
  const [open, setOpen] = useState(false);

  return (
    <div className="top-0 z-40 flex h-12 bg-c-orange-abundo shadow" id="top-bar">
      {/* Button to open the sidebar */}
      <button
        type="button"
        className="px-4 border-r border-gray-200 bg-white text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-c-orange-abundo lg:hidden"
        onClick={() => setSidebarOpen(true)}
        id="sidebar-toggle-button"
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="my-auto ml-4 lg:ml-12 text-white flex items-center  space-x-0.5">
        {" "}

        {/* Example icon */}
        <img className="h-11 w-11" src="https://res.cloudinary.com/abundo-wealth-assets/image/upload/v1711124069/Abundo%20Wealth%20Assets/logos/bun_final_log-01_1_mqa5f7.svg" alt="" />
      </div>
      {/* Household Search */}
      <div className="flex-1 px-4 flex justify-end items-center" id="search-section">
        <ToolTipHoverContainer>
          <button
            onClick={() => {
              setOpen(true);
            }}
            id="search-button"
          >
            {" "}
            <SearchIcon className="h-6 w-6 mt-1 text-white" />
            <ToolTipLeft>CTRL + Space</ToolTipLeft>

          </button>
        </ToolTipHoverContainer>

        {/* Search component */}
        <CommandPaletteHouseholdSearch open={open} setOpen={setOpen} />
        <div className=" flex items-center lg:ml-6 space-x-1">
          {/* Notifications */}
          <Notifications id="notifications-button" />
          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative" id="profile-dropdown">
            <div className="">
              <Menu.Button className=" p-1 rounded-full hover:bg-white transition duration-300 ">
                <span className="sr-only">Open user menu</span>
                <UserIcon
                  className="h-6 w-6 text-white hover:text-gray-400 transition duration-300"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            {/* Dropdown menu */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" id="dropdown-menu">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                        )}
                        onClick={() => {
                          item.func();
                        }}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}



function NavLink({ item }) {
  const nav = useNav()
  return (
    <button
      key={item.name}
      onClick={() => { nav(item.href) }}
      className={classNames(
        item.current
          ? "bg-blue-50 text-blue-500"
          : "text-gray-600 hover:bg-gray-50 hover:text-blue-500",
        "group flex items-center w-full px-2 py-2 text-sm font-medium rounded-md"
      )}
    >
      <item.icon.outline
        className={classNames(
          item.current
            ? "text-blue-500"
            : "text-gray-400 group-hover:text-blue-500",
          "mr-4 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {item.name}
    </button>
  )
}