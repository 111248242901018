import { CashIcon, ChartBarIcon, ChartPieIcon } from "@heroicons/react/outline";
import { TbPlaneDeparture, TbCreditCard, TbReceiptTax } from "react-icons/tb";
import { FaUmbrellaBeach } from "react-icons/fa";
import { MdOutlineSchool, MdOutlineRealEstateAgent } from "react-icons/md";
import { HiOutlineCash } from "react-icons/hi";
import {
  BsPiggyBank,
  BsShieldCheck,
  BsFileEarmarkMedical,
} from "react-icons/bs";
export const API = {
  ACTION_CENTER: "action_center",
  ASSET_ALLOCATIONS: "asset_allocations",
  ASSET_CLASS_KEYWORDS: "asset_class_keywords",
  ASSET_CLASSES: "asset_classes",
  BLOG: "blog",
  CALENDLY: "external/calendly",
  CALENDLY_MEETING_TYPES: "calendly_meeting_types",
  CLIENT_MEETINGS: "client_meetings",
  CLIENT_TASKS: "client_tasks",
  COMPANY_SETTINGS: "company_settings",
  FEE_SAVINGS_ITEMS: "fee_savings_items",
  FINANCIAL_STORY_CHAPTERS: "financial_story_chapters",
  FINANCIAL_STORY_TEMPLATES: "financial_story_templates",
  GMAIL: "external/gmail",
  HISTORICAL_FEE_SAVINGS: "historical_fee_savings",
  HISTORICAL_INVESTMENT_ACCOUNT_AUA: "historical_user_account_aua",
  HISTORICAL_USER_AUA: "historical_user_aua",
  HOUSEHOLDS: "households",
  HOUSEHOLD_GOALS: 'household_goals',
  HOUSEHOLD_NOTES: "household_notes",
  YOUVEST_SUBMISSIONS: "investment_tool_submission",
  LEARNING_LAB: "learning_lab",
  MIXPANEL: "external/mixpanel",
  ONBOARDING: "onboarding",
  PANDA_DOC: "external/panda_doc",
  PLAID_ACCESS_TOKENS: "plaid_access_tokens",
  PLAID_ACCOUNT_TYPES_KEY: "plaid_account_types_key",
  POINTS_PILOT: "points_pilot",
  PORTFOLIOS: "portfolios",
  PROSPECTS: "prospects",
  PROSPECT_ASSIGNMENT:'prospect_assignment',
  PRE_APPROVED_USERS: "pre_approved_users",
  RECOMMENDED_CREDIT_CARDS: "recommended_credit_cards",
  REFRESH_TOKENS: "refresh_tokens",
  TIMERS: "timers",
  SMART_TABLE_VIEWS: 'smart_table_views',
  STATS: "stats",
  STRIPE: "external/stripe",
  INVESTMENET_ACCOUNTS: "useraccounts",
  USER_ACTIVITY: "user_activity",
  USERS: "users",
  INVESTMENT_CONTROLS_LOGGER: "investment_controls_logger",
};

export const CUSTODIANS = [
  { value: "fidelity", label: "Fidelity Investments" },
  { value: "vanguard", label: "Vanguard" },
  { value: "schwab", label: "Charles Schwab" },
  { value: "etrade", label: "E*TRADE" },
  { value: "tdameritrade", label: "TD Ameritrade" },
  { value: "merrilledge", label: "Merrill Edge" },
  { value: "robinhood", label: "Robinhood" },
  { value: "webull", label: "Webull" },
  { value: "sofi", label: "SoFi Invest" },
  { value: "ally", label: "Ally Invest" },
  { value: "other", label: "Other" }
];

export const ASSET_ALLOCATIONS = {
  "100-0": "Most Aggressive (100% Stocks / 0% Bonds)",
  "90-10": "Very Aggressive (90% Stocks / 10% Bonds)",
  "80-20": "Aggressive (80% Stocks / 20% Bonds)",
  "70-30": "Moderately Aggressive (70% Stocks / 30% Bonds)",
  "60-40": "Moderate (60% Stocks / 40% Bonds)",
  "50-50": "Balanced (50% Stocks / 50% Bonds)",
  "35-65": "Conservative (35% Stocks / 65% Bonds)",
  "20-80": "Preservation (20% Stocks / 80% Bonds)",
};

export const ANALYTICS_DATE_RANGE_TYPES = [
  { value: '7 Days', label: '7 Days' },
  { value: '30 Days', label: '30 Days' },
  { value: '60 Days', label: '60 Days' },
  { value: '90 Days', label: '90 Days' },
  { value: '6 Months', label: '6 Months' },
  { value: '1 Year', label: '1 Year' },
  { value: 'All Time', label: 'All Time' },
  { value: 'Custom', label: 'Custom' }
];


export const MEETING_TYPES = {
  PROSPECT: "Prospect",
  ONBOARDING: "Onboarding",
  CHECKIN: "Check-In",
  TRADING: "Trading",
  PLANNING: "Planning",
}
export const YOUVEST_VARIABLES = {
  FEE_SAVINGS: {
    RATE_OF_RETURN: 0.085,
    INDUSTRY_AUM: 0.023,
  },
  INDUSTRY_AUM: 0.023,
  FILLER_ETF: {
    TICKER: "SFY",
    NAME: "SoFi Select 500 ETF",
    EXPENSE_RATIO: 0,
  },
  MINIMUM_INVESTMENT_AMOUNT: 100,
};
export const FEE_SAVINGS = {
  STARTING_FEES_SAVED: 1430000,
};
export const AUTHORIZATION = {
  ADVISOR: "advisor",
  ADMIN: "admin",
  CONTENT_EDITOR: "content_editor",
  BLOG_EDITOR: "blog_editor",
  TRAVEL_LOUNGE_EDITOR: "travel_lounge_editor",
  LEARNING_LAB_EDITOR: "learning_lab_editor",
};

export const STATUS_OPTIONS = {
  TASKS: {
    ACTIVE: 'active',
    COMPLETED: 'completed',
    ARCHIVED: 'archived',
    HIGHLIGHTED: 'highlighted',
  },
  CHAPTERS: {
    ACTIVE: 'active',
    COMPLETED: 'completed',
    FUTURE: 'future',
    UNPUBLISHED: 'unpublished',
    ARCHIVED: 'archived',
  },
  ONGOING_ADVICE: {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
  }
  
}

export const DRAG_N_DROP_TYPES = {
  TASK: "task",
  CHAPTER: "chapter",
  GOAL: "goal",
};

export const ACTIVITY_TYPES = {
  INVESTMENT: "Investment",
  LOGIN: "Login",
};

export const RISK_TOLERANCE_QUESTIONS = {
  ACCOUNT: {
    Q1: {
      QUESTION: "I plan to begin taking money from my investments in...",
      ANSWERS: {
        0: "1 year or less",
        1: "3-5 years",
        2: "6-10 years",
        3: "11-15 years",
        4: "16+ years",
      },
    },
    Q2: {
      QUESTION:
        "  As I withdraw my money from these investments, I plan to spend it over a period of...",
      ANSWERS: {
        0: "1 year or less",
        1: "3-5 years",
        2: "6-10 years",
        3: "11-15 years",
        4: "16+ years",
      },
    },
  },

  PERSONAL: {
    Q1: {
      QUESTION:
        "Q1. Generally, I prefer an investment with little to no ups and downs in value and I am willing to accept the lower returns these investments may make.",
      ANSWERS: {
        0: "Strongly Agree",
        1: "Agree",
        2: "Somewhat Agree",
        3: "Disagree",
        4: "Strongly Disagree",
      },
    },
    Q2: {
      QUESTION:
        "  Q2. If I owned a stock investment that lost 30% of its value during the timespan of one month, I would:",
      ANSWERS: {
        0: "Sell all of the remaining investment",
        1: "Sell some of the remaining investment",
        2: "Hold onto the investment and sell nothing",
        3: "Buy more of the investment",
      },
    },
    Q3: {
      QUESTION:
        "Q3. The chart below shows the highest one-year loss and the highest one-year gain on three different investments of $10,000. Given the potential gain or loss in any one year, I would invest my money in:",
      ANSWERS: { 0: "Investment A", 1: "Investment B", 2: "Investment C" },
    },
    Q4: {
      QUESTION:
        "Q4. When it comes to investing in mutual funds (or individual stocks or bonds), I would describe myself as...",
      ANSWERS: {
        0: "Very inexperienced",
        1: "Somewhat inexperienced",
        2: "Somewhat experienced",
        3: "Experienced",
        4: "Very experienced",
      },
    },
  },
};

export const ETHOS_ASSET_CLASSES = {
  "Bond - World": "International Bonds",
  "Bond - High Yield": "High Yield",
  "Bond - Short-Term": "Corporate",
  "Bond - Corporate": "Corporate",
  "Bond - Short Government": "Government",
  "Bond - Intermediate Government": "Government",
  "Single Sector - Real Estate": "Real Estate",
  "Equity - Emerging Markets": "Emerging Market",
  "Equity - Ex-US Large Blend": "International Developed",
  "Equity - US Small Blend": "Small Cap",
  "Equity - US Small Growth": "Small Cap",
  "Equity - US Small Value": "Small Cap",
  "Equity - US Mid-Cap Blend": "Mid Cap",
  "Equity - US Mid-Cap Value": "Mid Cap",
  "Equity - US Mid-Cap Growth": "Mid Cap",
  "Equity - US Large Blend": "Large Blend",
  "Equity - US Large Growth": "Large Growth",
  "Equity - US Large Value": "Large Value",

  "Bond - Inflation-Protected": "TIPS",
};

export const ASSET_CLASSES_ORDER = {
  "Large Blend": 1,
  "Large Growth": 2,
  "Large Value": 3,
  "Mid Cap": 4,
  "Small Cap": 5,
  "International Developed": 6,
  "Emerging Market": 7,
  "Real Estate": 8,
  Government: 9,
  Corporate: 10,
  "High Yield": 11,
  "International Bonds": 12,
  TIPS: 13,
};

export const MIXPANEL_EVENTS = {
  DISCOVERY_MEETING_CLICK: "Discovery Mtg Clk",
  BLOG_POST_VIEW: "Blog Post View",
  OUTSIDE_RESOURCE: "Outside Resource Click",
  PAGE_VIEW: "Page View",
};

export const NOTES_TAGS = [
  {
    name: "Insurance",
    color: "bg-blue-500",
  },
  {
    name: "Taxes",
    color: "bg-yellow-500",
  },
  {
    name: "Investing",
    color: "bg-violet-500",
  },
  {
    name: "Travel",
    color: "bg-orange-500",
  },
  {
    name: "Retirement",
    color: "bg-teal-500",
  },
  {
    name: "Education",
    color: "bg-green-500",
  },
  {
    name: "Debt",
    color: "bg-red-500",
  },
  {
    name: "Estate Planning",
    color: "bg-gray-500",
  },
  {
    name: "Cashflow",
    color: "bg-pink-500",
  },
  { name: "Cash Reserves", color: " bg-sky-500" },
  { name: "Work Benefits", color: "bg-fuchsia-500" },
  {
    name: "Meeting Notes",
    color: "bg-cyan-500",
  },
  {
    name: "For Next Meeting",
    color: "bg-purple-500",
  },
];

export const FINANCIAL_HEALTH_CATEGORIES = [
  {
    name: "Insurance",
    bg_color: "bg-blue-100",
    text_color: "text-blue-700",
    icon: BsShieldCheck,
  },
  {
    name: "Taxes",
    bg_color: "bg-yellow-100",
    text_color: "text-yellow-700",
    icon: TbReceiptTax,
  },
  {
    name: "Investing",
    bg_color: "bg-violet-100",
    text_color: "text-violet-700",
    icon: ChartPieIcon,
  },
  {
    name: "Travel",
    bg_color: "bg-orange-100",
    text_color: "text-orange-700",
    icon: TbPlaneDeparture,
  },
  {
    name: "Retirement",
    bg_color: "bg-teal-100",
    text_color: "text-teal-700",
    icon: FaUmbrellaBeach,
  },
  {
    name: "Education",
    bg_color: "bg-green-100",
    text_color: "text-green-700",
    icon: MdOutlineSchool,
  },
  {
    name: "Debt",
    bg_color: "bg-red-100",
    text_color: "text-red-700",
    icon: TbCreditCard,
  },
  {
    name: "Estate Planning",
    bg_color: "bg-gray-100",
    text_color: "text-gray-700",
    icon: MdOutlineRealEstateAgent,
  },
  {
    name: "Cashflow",
    bg_color: "bg-pink-100",
    text_color: "text-pink-700",
    icon: HiOutlineCash,
  },
  {
    name: "Cash Reserves",
    bg_color: "bg-sky-100",
    text_color: "text-sky-700",
    icon: BsPiggyBank,
  },
  {
    name: "Work Benefits",
    bg_color: "bg-fuchsia-100",
    text_color: "text-fuchsia-700",
    icon: BsFileEarmarkMedical,
  },
];



export const BUNMAIL_DEFAULTS = {
  THREADS_EMAIL_ORDER: 'Oldest First',
  UNDO_SEND_TIMER: 5,
  AUTO_ADVANCE: false,
}