import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { classNames } from "../../../helpers/classNames";
import useOnClickOutside from "../../../hooks/useClickOutside";

const SmartTableEditCell = ({
  defaultComponent,
  editComponent,
  handleSubmitEdit = () => {},
  showEdit,
  setShowEdit,
}) => {
  const ref = useRef();
  const [selected,setSelected] = useState(false)
  useOnClickOutside(ref, () => {
    handleSubmitEdit();
    setShowEdit(false);
  });
  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter") {
  //       handleSubmitEdit(componentName);
  //       setShowEdit(false);
  //     }
  //   };
  return (
    <div tabIndex={0} className={""}>
      {" "}
      {!showEdit ? (
        <button className={classNames(selected && "border-2 border-blue-500 rounded"," h-10 w-full px-2 py-2")}
          onClick={(e) => {
            if (e.detail === 1) setShowEdit(true);

          }}
        >
          {defaultComponent}
        </button>
      ) : (
        <div ref={ref} className="min-w-[200px]">{editComponent}</div>
      )}
    </div>
  );
};

export default SmartTableEditCell;
