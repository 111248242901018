import React from 'react'
import { dateFormaterWithTime } from '../../../../helpers/formatters'
import RichTextDisplay from '../../../../components/textEditor/RichTextDisplay'

const Activity = ({item,query}) => {
  return (
      <div className='flex justify-start' >
          <p className=" px-3 py-4 text-sm text-gray-500">{item.content ? <RichTextDisplay content={item.content} thisClassName={'notes'} query={query} /> : item.details}</p>

         
          


         
      </div>  )
}

export default Activity