import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs'


/**
 * Displays information about the referrals associated with a household, including the referral count and a list of referrals.
 * Each referral is presented as a link to the household's details page, alongside an icon indicating an external link.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.household - The household object containing referrals and referral count.
 * @returns {JSX.Element} A component that renders the referral count and a list of referral households.
 */
const Referrals = ({ household }) => {
  return (
    <>
      
        <div className="">
          <p className="text-gray-600 text-sm">Referral Count </p>
          <p className='font-semibold'>{household.referral_count} </p>
        </div>
     
      <div>
        <p className="text-gray-600 text-sm">Referrals</p>
        <ul className='space-y-1'>
          {household.referrals?.map((referral) => (
            <li key={referral.household_id}>
              <a
                href={`/crm/households/${referral.household_id}`}
                className="flex items-center text-left font-semibold hover:underline"
              >
                <p>{referral.household_name}</p>{" "}
                <BsBoxArrowInUpRight className="ml-1 h-3 w-3  flex-shrink-0" />
              </a>
            </li>
          ))}
      </ul>
      </div>

    </>
  )
}

export default Referrals