import React from 'react';
import Highlighter from 'react-highlight-words';
import { classNames } from "../../../../helpers/classNames";
import { dateFormaterWithTime } from "../../../../helpers/formatters";

export default function Meeting({ meeting, query }) {
    // Ensure the query is an array. If it's a string, convert it into an array.
    const searchWords = query && query.trim() !== '' ? (Array.isArray(query) ? query : [query]) : [];

    const meetingTypeColors = {
        'active': 'bg-green-100 text-green-700 ring-green-400',
        'no-show': 'bg-yellow-100 text-yellow-700 ring-yellow-400',
        'canceled': 'bg-red-100 text-red-700 ring-red-400',
    };
    const colorClasses = meetingTypeColors[meeting?.status] || 'bg-gray-100 text-gray-700 ring-gray-600';

    return (
        <div className='flex items-start space-x-5'>
            {/* Meeting name with optional highlighting */}
            {searchWords.length > 0 ? (
                <Highlighter
                    highlightClassName="highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={meeting.name}
                />
            ) : (
                <p>{meeting.name}</p>
            )}

            {/* Meeting questions and answers with optional highlighting */}
            {searchWords.length > 0 && meeting.questions_and_answers ? (
                <Highlighter
                    highlightClassName="highlighted"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={meeting.questions_and_answers[0]?.answer || ''}
                />
            ) : (
                <p>{meeting.questions_and_answers ? meeting.questions_and_answers[0]?.answer : ""}</p>
            )}

            {/* Meeting status */}
            <span className={classNames(`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium`, colorClasses)}>
                {meeting.status === 'active' && 'Active'}
                {meeting.status === 'no-show' && 'No Show'}
                {meeting.status === 'canceled' && 'Canceled'}
            </span>
        </div>
    );
}
