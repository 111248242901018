import { io } from "socket.io-client";

const socketIoConnection = (route) => {
  const accessToken = localStorage.getItem("access_token");
  const path =
    process.env.NODE_ENV === "production" ? "/api/socket.io" : "/socket.io";
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "wss://abundowealth.com"
      : "http://localhost:5000";
  try {
    const socket = io(baseUrl, {
      query: { access_token: accessToken, route },
      transports: ["websocket"],
      path,
    });
    return socket;
  } catch (error) {
  }
};

export default socketIoConnection;
