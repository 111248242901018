import React, { useMemo } from 'react'
import { classNames } from '../../../../helpers/classNames'
import { BsExclamationDiamond } from 'react-icons/bs'
import { CheckCircleIcon, PencilIcon } from '@heroicons/react/outline'
import useNav from '../../../../hooks/useNav'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { dateFormaterWithTime, dateFormatterGmailTime } from '../../../../helpers/formatters'

const RiskToleranceActions = ({ itemsLength, item, itemIdx }) => {
    const {household_id:householdId} = useParams()
    const nav = useNav()
    const itemTypes = {
        manual_override: {
            icon: BsExclamationDiamond ,
            iconBackground: 'bg-rose-500',
            time: item.manual_override_at

        },
       approved: {
           icon: CheckCircleIcon,
           iconBackground: 'bg-green-500',
              time: item.approved_at
        },
        manual_update: {
            icon: PencilIcon,
            iconBackground: 'bg-purple-500',
            time: item.created_at
       }
    }
    item = { ...item, ...itemTypes[item.status] }
    const navUrl = useMemo(() => window.location.href.includes('meeting-mode') ? `/crm/meeting-mode/${householdId}/risk-tolerance/${item.form_id}` : `/crm/forms/${item.form_id}`, [item.form_id])
    return (

        <li key={item.id}>
            <div className="relative pb-8  ">
                {itemIdx !== itemsLength - 1 ? (
                    <span aria-hidden="true" className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-300" />
                ) : null}
                <div className="relative flex space-x-3 items-center">
                    <div>
                        <span
                            className={classNames(
                                item.iconBackground,
                                'flex h-10 w-10 items-center justify-center rounded-full ring-8 ring-white',
                            )}
                        >
                            <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
                        </span>
                    </div>
                    <div className="flex min-w-0 flex-1  items-center space-x-4 pt-1.5">
                        <div>
                            <div className="whitespace-nowrap text-left text-sm text-gray-500">
                                <time dateTime={item.time}>{(new Date(item.time).toLocaleDateString())}</time>
                                
                               {item.form_id && <>
                               -  <button
                                        onClick={() => nav(navUrl)}
                                    className='text-gray-500 underline mt-4'>
                                    View Full Results
                                </button> </>}
                            </div>
                            <p className="text-base text-gray-800">
                                Risk Tolerance of {" "}
                                <strong>{item.status === 'approved' ? item.results.find(i => i.label === 'Risk Tolerance').value : item.manual_risk_tolerance}</strong>
                             
                                 {" "}  and Asset Allocation of {" "}
                                <strong>{item.status === 'approved' ? item.results.find(i => i.label === 'Asset Allocation').value : item.manual_asset_allocation}</strong>
                                {" "}
                                {item.status === 'approved' && 'was approved '}
                                {item.status === 'manual_override' && 'was manual overridden'}
                                {item.status === 'manual_update' && 'was manually updated '}

                                for {" "}
                                <strong>{item.manual_update_type === 'household' ? `${item.household?.name} Household` :`${item.user.first_name} ${item.user.last_name}`}</strong>
                            </p>
                            <p className="text-sm text-gray-500">
                               {item.manual_override_rationale}
                            </p>

                        </div>
                        
                    </div>
                </div>
            </div>
        </li>

    )

}

export default RiskToleranceActions