import React from "react";
import { CSSLoadingSpinner1 } from "./animations/LoadingAnnimations";
import LoadingSpinner from "./animations/LoadingSpinner";

const abundoLogo =
  "https://res.cloudinary.com/abundo-wealth-assets/image/upload/v1658176803/Abundo%20Wealth%20Assets/components/logos/Abundo_logo_mtnbxi_zg4tju.webp";

const LoadingPage = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-white">
     {/* <CSSLoadingSpinner1 /> */}
    </div>
  );
};

export default LoadingPage;
