import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import firebase from "firebase/app";
import User from "../apis/User";
import { useSelector } from "react-redux";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [reAuthNextURL, setreAuthNextURL] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdvisor, setIsAdvisor] = useState(false);
  const accessToken = useSelector((state) => state.user.accessToken);
  
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  const authLogin = async (email, password, keepLoggedIn) => {
    if (keepLoggedIn) {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

      return auth.signInWithEmailAndPassword(email, password);
    } else {
      await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

      return auth.signInWithEmailAndPassword(email, password);
    }
  };

  
  const loginLocal = (email, password) => {
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
      });
  };

  const loginSession = (email, password) => {
    auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      })
      .catch((error) => {
      });
  };

  function logout() {
    localStorage.removeItem("access_token")
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    const getAuth = async () => {
      try {
        const response = await User.get(`get_user`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setIsAdmin(response.data.user_is_admin);
        setIsAdvisor(response.data.user_is_advisor);
      } catch (error) {
      }
    };
    getAuth();

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    isAdmin,
    isAdvisor,
    setIsAdvisor,
    setIsAdmin,
    currentUser,
    loginLocal,
    loginSession,
    authLogin,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    reAuthNextURL,
    setreAuthNextURL,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
