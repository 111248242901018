import React, { useCallback, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../errorHandling/error-fallback-page";
import { useSelector } from "react-redux";
import { authenticateAdvisorAdmin } from "../../helpers/jwtAuth";

export default function ManagerRoute({ component: Component, ...rest }) {
    //state
    const [isManager, setIsManager] = useState(false);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);
    const accessToken = useSelector((state) => state.user.accessToken);
    //functions
    const authenticate = useCallback(async () => {
        try {
            const auth = await authenticateAdvisorAdmin(accessToken);
            setIsManager(auth.is_manager);
            setLoading(false);
        } catch (error) {
            setIsManager(false);
            setLoading(false);
        }
    }, [accessToken]);
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) authenticate();
        return () => {
            isCancelled = true;
        };
    }, [authenticate]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Route
                {...rest}
                render={(props) => {
                    return !loading ? (
                        <>
                            {currentUser && isManager ? (
                                <Component {...props} />
                            ) : (
                                <Redirect to="/login" />
                            )}
                        </>
                    ) : null;
                }}
            ></Route>
        </ErrorBoundary>
    );
}
