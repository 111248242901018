// Importing necessary components and helpers
import BasicPanel from "../../../components/panels/BasicPanel";
import BasicTag from "../../../components/tags/BasicTag";
import { dateFormaterWithTime, dateFormatter } from "../../../helpers/formatters";
import { calculateDiffDays } from "../../../helpers/dates";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DoubleClickEdit from "../../../components/DoubleClickEdit";
import { useEffect, useState } from "react";
import { classNames } from "../../../helpers/classNames";
import ItemMenu from "../../../components/menus/ItemMenu";
import { BanIcon } from "@heroicons/react/outline";

const meetingsApi = new ApiRequest(API_ROUTES.CLIENT_MEETINGS, API_AUTH_TYPES.ADVISOR);


function Row({ meeting }) {
  const { household_id: householdId } = useParams()

  // Hooks, state, and functions
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const mutate = useMutation((body) => {
    meetingsApi.patch({ endpoint: 'update_meeting_status', params: [meeting.meeting_id], body })
  },
    {
      onSuccess: (data) => {
        setTimeout(() => {
          
          queryClient.invalidateQueries(`meetings-${householdId}`)
        }, 1000)
      }
}
  )

const meetingTypeColors = {
  'active': 'bg-green-100 text-green-700 ring-green-400',
  'no-show': 'bg-yellow-100 text-yellow-700 ring-yellow-400',
  'canceled': 'bg-red-100 text-red-700 ring-red-400',
};
const colorClasses = meetingTypeColors[meeting?.status] || 'bg-gray-100 text-gray-700 ring-gray-600';

  
  const options = [
    {
      title: 'No Show',
      icon: BanIcon,
      action: () => mutate.mutate({ status: 'no-show' })
    }
  ]

return (
  <>
    {/* Meeting date */}
    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
      {dateFormaterWithTime(new Date(meeting.start_time))}
    </td>
    {/* Meeting type */}
    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
      {meeting.name}
    </td>
    {/* Meeting reason */}
    <td className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
      {meeting.questions_and_answers ? meeting.questions_and_answers[0]?.answer : ""}
    </td>
    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 space-x-2 flex items-center justify-between">
      {/* Use a function to map status to the display properties */}
      {/* Meeting status */}

      {loading ? <div>sfsd</div> : <span className={classNames(`inline-flex items-center rounded-full px-2 py-1  text-xs font-medium`, colorClasses)}>
        {meeting.status === 'active' && 'Active'} {meeting.status === 'no-show' && 'No Show'} {meeting.status === 'canceled' && 'Canceled'}
      </span>}
 
      <ItemMenu options={options} />
   
    </td>


  </>
)
}


export default function HHMeetings() {
  // Hooks, state, and functions
  const { household_id: householdId } = useParams()
  const { data: meetings, isLoading, isError } = useQuery({ queryKey: `meetings-${householdId}`, queryFn: meetingsApi.getFn({ endpoint: 'index_household', params: [householdId] }) })

  if (isLoading) return (<div>Loading...</div>)
  return (
    // Rendering the basic panel component
    <BasicPanel>
      <h5 className="crm-title mb-7">Meetings</h5>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {/* Table headers */}
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                    Type
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                    Meeting Reason
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {/* Rendering each meeting */}
                {meetings && meetings.map((meeting) => (
                  <tr key={meeting.meeting_id}>
                   {meeting.status &&  <Row meeting={meeting} />}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </BasicPanel>
  );
}
