import React from 'react'
import { TbMessagePlus } from 'react-icons/tb'
import FadeIn from '../../../../transitions/FadeIn';




const NewConversation = ({ expanded,setConversationId, handleClearConversation }) => {
   
  return (
    <button
      onClick={() => {
        setConversationId(null);
        handleClearConversation()
      }}
          className='btn-lg  btn-gray-outline w-full flex justify-between'>
      <FadeIn durationExit='duration-0' isShowing={expanded}>
        <p className='text-base font-medium'>New Conversation</p>
         </FadeIn>
          <TbMessagePlus className='h-5 w-5 text-gray-700 flex-shrink-0' />

      </button>
  )
}

export default NewConversation