import React from "react";
import { useState } from "react";
import {
  dateFormatterGmailTime,
  formattersReadbaleFileSize,
} from "../../../../helpers/formatters";
import FadeIn from "../../../../components/transitions/FadeIn";
import { classNames } from "../../../../helpers/classNames";
import { GrDocumentPdf } from "react-icons/gr";
import { ChevronUpIcon, PhotographIcon } from "@heroicons/react/outline";
import DOMPurify from "dompurify";

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  let dataBase64Rep = b64Data.replace(/-/g, "+").replace(/_/g, "/");

  let byteCharacters = window.atob(dataBase64Rep);
  let byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  let urlBlob = URL.createObjectURL(blob);
  return urlBlob;
}

const EmailRow = ({ thread, label }) => {
  const firstEmail = thread.messages[0];
  const [showBody, setShowBody] = useState(false);
  return (
    <tr>
      <td
        className={classNames(
          firstEmail?.labels?.indexOf("UNREAD") > -1 ? "bg-white" : "bg-gray-100",
          "whitespace py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 grid sm:grid-cols-12 "
        )}
      >
        <div
          className="flex flex-col col-span-4 text-xs"
          onClick={() => setShowBody(!showBody)}
        >
          {label === "SENT" ||
            label === "DRAFT" ||
            label === "TRASH" ||
            "NONE" ? (
            <p>
              to: {firstEmail.to.split("<")[1]?.split(">")[0] || firstEmail.to}
            </p>
          ) : null}
          {label === "INBOX" || label === "TRASH" || "NONE" ? (
            <p>
              from:{" "}
              {firstEmail.from.split("<")[1]?.split(">")[0] || firstEmail.from}
            </p>
          ) : null}
        </div>
        <div className="col-span-6" onClick={() => setShowBody(!showBody)}>
          {" "}
          <p className="font-bold ">
            {firstEmail.subject} -{" "}
            <span className="text-gray-500 text-xs">
              {firstEmail.snippet.substring(0, 75)}...
            </span>
          </p>
        </div>
        {thread.messages.length > 1 ? (
          <p
            className=" col-span-1 text-gray-500 border rounded-full h-7 w-7 flex items-center justify-center"
            onClick={() => setShowBody(!showBody)}
          >
            {" "}
            {thread.messages.length}
          </p>
        ) : (
          <div></div>
        )}
        {!showBody && <p
          className="text-gray-500 place-self-end col-span-1 h-full "
          onClick={() => setShowBody(!showBody)}
        >
          {dateFormatterGmailTime(new Date(firstEmail.date))}
        </p>}
        <ul className="flex space-x-2 overflow-x-auto col-span-12 mt-2 max-w-2xl">
          {" "}
          {firstEmail.attachments && !showBody
            ? firstEmail.attachments.map((attachment) => (
              <li className="w-full max-w-lg" key={attachment.attachmentId}>
                {" "}
                <a
                  download
                  className="rounded-full p-2 border border-gray-300 my-2 w-max flex items-center space-x-2"
                  href={b64toBlob(
                    attachment.data.data.data,
                    attachment.mimeType,
                    attachment.size
                  )}
                >
                  {attachment.mimeType.includes("image") && (
                    <PhotographIcon className="w-5" />
                  )}
                  {attachment.mimeType === "application/pdf" && (
                    <GrDocumentPdf className="text-red-500" />
                  )}
                  <p>
                    {attachment.filename.substring(0, 15)}... (
                    {formattersReadbaleFileSize(attachment.size)})
                  </p>
                </a>
              </li>
            ))
            : null}
        </ul>
        <div className="col-span-12 flex w-full justify-center">
          {" "}
          <FadeIn isShowing={showBody}>
            <ul>
              {thread.messages.map((email) => (
                <li key={email.id} className="flex flex-col w-full">
                  {" "}
                  <p
                    className="text-gray-500 place-self-end h-full "
                    onClick={() => setShowBody(!showBody)}
                  >
                    {dateFormatterGmailTime(new Date(email.date))}
                  </p>{" "}
                  {email.body ? (
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(email.body),
                      }}
                    />
                  ) : (
                    <p>{email.snippet}</p>
                  )}{" "}
                  <div className=" mt-5 border-t pt-2">
                    {email.attachments && <p>Attachments</p>}
                    <ul className="flex space-x-3 w-full  overflow-x-auto ">
                      {email.attachments &&
                        email.attachments.map((attachment) => (
                          <li className="w-full" key={attachment.attachmentId}>
                            {" "}
                            <a
                              download
                              className="rounded-full p-2 border border-gray-300 my-2 flex w-max items-center space-x-2"
                              href={b64toBlob(
                                attachment.data.data.data,
                                attachment.mimeType,
                                attachment.size
                              )}
                            >
                              {attachment.mimeType === "application/pdf" && (
                                <GrDocumentPdf className="text-red-500" />
                              )}
                              {attachment.mimeType.includes("image") && (
                                <PhotographIcon className="w-5" />
                              )}
                              <p>{attachment.filename.substring(0, 20)}...</p>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </li>
              ))}{" "}
              <div className="flex justify-center">
                {" "}
                <button
                  onClick={() => setShowBody(!showBody)}
                  className="underline flex flex-col items-center "
                >
                  <ChevronUpIcon className="w-5" />
                  <p className="my-0">close</p>
                </button>
              </div>
            </ul>
          </FadeIn>
        </div>
      </td>
    </tr>
  );
};

export default EmailRow;
