import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =
  process.env.NODE_ENV === "production" ? `/` : `http://localhost:5000/`;

const generateQueryUrl = (
  route = "",
  endpoint = "",
  auth_type = "user",
  params = [],
  version = "v1"
) => {
  const paramsString = params.map((param) => `/${param}`);

  if (auth_type !== "user" && auth_type !== "advisor" && auth_type !== "admin")
    throw new Error("Auth Type must be user or advisor or admin");
  return `api/${version}/${route}/${endpoint}_auth_${auth_type}${paramsString}`;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().user.accessToken;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Views", "Advisors"],
  endpoints: (builder) => ({
    //GETS
    // findAll: builder.query({
    //   query: ({ route, auth_type }) =>
    //     generateQueryUrl(route, "find_all", auth_type),
    //   providesTags: (tags = []) => tags,
    // }),
    // findAllMy: builder.query({
    //   query: ({ route, auth_type }) =>
    //     generateQueryUrl(route, "find_all_my", auth_type),
    //   providesTags: (tags = []) => tags,
    // }),
    findManyWhere: builder.query({
      query: ({ route, auth_type = "user", search, params }) =>
        generateQueryUrl(route, `find_many_where_${search}`, auth_type,params),
      transformResponse: (response) =>
        response.sort((a, b) => {
          if (a.name === null) return 1;
          if (b.name === null) return -1;
          return a.name < b.name ? -1 : 1;
        }),
      providesTags: (result, error, arg) => {
        return [
          { type: "Views", id: "LIST" },
          ...result.map(({ smart_table_view_id }) => ({
            type: "Views",
            id: smart_table_view_id,
          })),
        ];
      },
    }),
    findAllAdvisors: builder.query({
      query: () => generateQueryUrl("users", "index_advisors", "advisor"),
      providesTags: () => ["Advisors"],
    }),
    // findUnique: builder.query({
    //   query: ({ route, auth_type, params }) =>
    //     generateQueryUrl(route, "find_unique", auth_type, params),
    //   providesTags: (tags = []) => tags,
    // }),
    // //POSTS
    create: builder.mutation({
      query: ({ body }) => ({
        url: generateQueryUrl("smart_table_views", "create", "advisor"),
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Views", id: "LIST" }],
    }),
    update: builder.mutation({
      query: ({ body }) => ({
        url: generateQueryUrl("smart_table_views", "update", "advisor"),
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Views", id: arg.id }],
    }),
    deleteUnique: builder.mutation({
      query: ({ params }) => ({
        url: generateQueryUrl("smart_table_views", "delete", "advisor", params),
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Views", id: arg.id }],
    }),
  }),
});

export const {
  useCreateMutation,
  useUpdateMutation,
  useDeleteUniqueMutation,
  useFindAllAdvisorsQuery,
} = apiSlice;
