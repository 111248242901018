
/**
 * A base tooltip component that handles positioning
 */
import React, { useState, useLayoutEffect, useRef } from 'react';

function BaseToolTip({ children, preferredPlacement = 'bottom' }) {
    const [placement, setPlacement] = useState(preferredPlacement);
    const [isMounted, setIsMounted] = useState(false);
    const tooltipRef = useRef(null);
    const containerRef = useRef(null);

    // Set mounted state
    useLayoutEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useLayoutEffect(() => {
        if (!isMounted || !tooltipRef.current || !containerRef.current) return;

        const updatePosition = () => {
            // Null checks for safety
            if (!tooltipRef.current || !containerRef.current) return;

            try {
                const tooltip = tooltipRef.current;
                const container = containerRef.current;

                // Ensure elements exist before getting their rectangles
                if (!tooltip || !container) return;

                const rect = container.getBoundingClientRect();
                const tooltipRect = tooltip.getBoundingClientRect();

                // Get window dimensions
                const windowHeight = window.innerHeight;
                const windowWidth = window.innerWidth;

                // Check if tooltip would go off screen in its preferred placement
                const offScreen = {
                    top: rect.top - tooltipRect.height < 0,
                    bottom: rect.bottom + tooltipRect.height > windowHeight,
                    left: rect.left - tooltipRect.width < 0,
                    right: rect.right + tooltipRect.width > windowWidth
                };

                // Determine best placement
                let newPlacement = preferredPlacement;

                switch (preferredPlacement) {
                    case 'bottom':
                        if (offScreen.bottom) newPlacement = 'top';
                        break;
                    case 'top':
                        if (offScreen.top) newPlacement = 'bottom';
                        break;
                    case 'left':
                        if (offScreen.left) newPlacement = 'right';
                        break;
                    case 'right':
                        if (offScreen.right) newPlacement = 'left';
                        break;
                    default:
                        break;
                }

                setPlacement(newPlacement);
            } catch (error) {
                console.error('Error updating tooltip position:', error);
            }
        };

        // Initial position update
        updatePosition();

        // Debounce the scroll and resize handlers
        let timeoutId;
        const debouncedUpdatePosition = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(updatePosition, 100);
        };

        window.addEventListener('scroll', debouncedUpdatePosition);
        window.addEventListener('resize', debouncedUpdatePosition);

        return () => {
            window.removeEventListener('scroll', debouncedUpdatePosition);
            window.removeEventListener('resize', debouncedUpdatePosition);
            clearTimeout(timeoutId);
        };
    }, [preferredPlacement, isMounted]);

    const positionClasses = {
        bottom: '-bottom-9 translate-y-full left-0',
        top: '-top-2 -translate-y-full left-0',
        left: '-left-2 -translate-x-full top-0',
        right: '-right-2 translate-x-full top-0'
    };

    return (
        <div ref={containerRef} className="relative group">
            {isMounted && (
                <div
                    ref={tooltipRef}
                    className={`
                        absolute 
                        ${positionClasses[placement]}
                        hidden 
                        group-hover:block 
                        z-40
                        w-max 
                        max-w-[300px]
                        bg-white 
                        rounded-lg 
                        border
                        border-gray-200
                        shadow-lg
                        transition-all 
                        duration-200 
                        ease-in
                    `}
                >
                    <div className="p-3">
                        <div className="text-sm text-gray-600">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Rest of your code remains the same...

/**
 * Tooltip components using the base component with different preferred placements
 */
export function ToolTipBottom({ children }) {
    if (!children) return null;
    return <BaseToolTip preferredPlacement="bottom">{children}</BaseToolTip>;
}

export function ToolTipTop({ children }) {
    if (!children) return null;
    return <BaseToolTip preferredPlacement="top">{children}</BaseToolTip>;
}

export function ToolTipLeft({ children }) {
    if (!children) return null;
    return <BaseToolTip preferredPlacement="left">{children}</BaseToolTip>;
}

export function ToolTipRight({ children }) {
    if (!children) return null;
    return <BaseToolTip preferredPlacement="right">{children}</BaseToolTip>;
}

/**
 * Container component remains the same
 */
export function ToolTipHoverContainer({ children }) {
    return (
        <div className="relative group">
            {children}
        </div>
    );
}