import { Transition } from "@headlessui/react";
import React from "react";
import { classNames } from './../misc/styling';

const FadeIn = ({ children, isShowing = false,durationEnter="duration-150",durationExit="duration-150" },props) => {


  return (
    <Transition
      {...props}
      show={isShowing}
      enter={classNames("transition-opacity",durationEnter)}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={classNames("transition-opacity",durationExit)}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
      </Transition>
  );
};

export default FadeIn;
