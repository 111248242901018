// Required Libraries
import React, { useState } from 'react';
import { CheckCircleIcon, ChevronUpIcon, MinusCircleIcon, PlayIcon, PlusCircleIcon } from '@heroicons/react/outline';
import FadeIn from '../../../../../../components/transitions/FadeIn';
import { classNames } from '../../../../../../helpers/classNames';

import ChapterItem from './ChapterItem';
import DropTarget from '../../../../../../components/drag-drop/DropTarget';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * Component representing a section of the Table of Contents.
 * 
 * @param {Object} props - Properties passed to the component.
 * @param {Object} props.section - An object representing the section.
 * @param {string} props.section.title - The title of the section.
 * @param {Array} props.section.chapters - An array of chapters in the section.
 * @param {string} props.selectedChapterId - The ID of the currently selected chapter.
 * @param {Function} props.setSelectedChapterId - The setter function to update the selected chapter ID.
 * 
 * @returns {ReactElement} The TOCSection component.
 */
const TOCSection = ({ section, selectedChapterId, setSelectedChapterId, sections, queryKeys, tasksListQueryKey }) => {
    // Initialize state for expansion state of section, defaulting to open if section is 'Active Chapters'
    const [isExpanded, setIsExpanded] = useState(section.title === 'Active');
    const chaptersApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_CHAPTERS, API_AUTH_TYPES.ADVISOR);
    const { household_id: householdId } = useParams()
    const queryClient = useQueryClient();
    const { mutate: updateChapterOrder } = useMutation(
        (body) => chaptersApi.patch({ endpoint: "update_chapter_status", body, params: [householdId] }),
        {
            onSuccess: (body) => {
                queryClient.invalidateQueries(sections.find(section => section.type === body.new_status).query_key);
                queryClient.invalidateQueries(sections.find(section => section.type === body.old_status).query_key);
            },
        }

    );



    const handleUpdateChapter = (item) => {

        const droppedChapterId = item.content.props.chapter.chapter_id
        if (section.chapters?.length > 0) return
        updateChapterOrder({
            chapter_id: droppedChapterId,
            new_status: section.type,
            old_status: item.content.props.chapter.status
        });
    }

    const sectionIcons = {
        'Active': <PlayIcon className='h-6 w-6  rounded-full text-blue-500' />,
        'Future': <PlusCircleIcon className='h-6 w-6  text-purple-500' />,
        'Completed': <CheckCircleIcon className='h-6 w-6  text-green-500 ' />,
        'Unpublished': <MinusCircleIcon className='h-6 w-6  text-gray-500 ' />
    }
    return (
        <div className=''>
            <DropTarget accept='chapter' onIsOver={(isOver) => { if (isOver) { setIsExpanded(true) } }} onDrop={(item) => handleUpdateChapter(item)} isActiveStyling={`  ${section.chapters.length === 0 ? ' drop-target pb-7' : ''}`} >
                <button
                    className={classNames(
                        'text-gray-600  hover:bg-gray-100',
                        'group flex gap-x-3 rounded-lg py-2 px-2 text-lg leading-6 font-semibold justify-between items-center cursor-pointer w-full'
                    )}
                    onClick={() => setIsExpanded(!isExpanded)} // Toggle isExpanded on click
                >
                    <div className="flex items-center space-x-5">
                        {sectionIcons[section.title]}
                        <div className="text-left">
                            <p className='text-xl font-semibold '> {section.title}</p>
                            <p className='text-sm text-gray-500'>{section?.chapters?.length || 0} chapters</p>


                        </div>
                    </div>
                    <div className="flex items-center space-x-3">
                        <ChevronUpIcon className={classNames(isExpanded ? 'transform rotate-180' : '', 'w-6 h-6 text-gray-600')} />
                    </div>
                </button>
                <FadeIn isShowing={isExpanded}>
                    <ul className='space-y-2 '>
                        {section.chapters && section.chapters.map((chapter) => (
                            <li key={chapter.chapter_id}>
                                <ChapterItem
                                    chapter={chapter} selectedChapterId={selectedChapterId} setSelectedChapterId={setSelectedChapterId} sections={sections} queryKeys={queryKeys} tasksListQueryKey={tasksListQueryKey} />
                            </li>
                        ))}
                    </ul>
                </FadeIn>
            </DropTarget>
        </div>
    );
}

export default TOCSection;
