import { CalendarIcon, XCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { useState } from "react";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import ReactDatePicker from "../../../../../../components/ReactDatePicker";


const DueDate = ({ defaultValue, handleSubmitEdit }) => {
  const [value, setValue] = useState(defaultValue);
  const [showEdit, setShowEdit] = useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);
    handleSubmitEdit(newValue);
    setShowEdit(false);
  };

  const handleClearValue = () => {
    setValue(null);
    handleSubmitEdit(null);
    setShowEdit(false);
  }
  return (
    <DoubleClickEdit
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      defaultComponent={
        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
          <CalendarIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          {value ? (
            <div className="group flex items-center ">
              <p className="mr-1">Due on</p>
              <time dateTime={value}>
                {new Date(value).toLocaleDateString()}
              </time>
              <button onClick={handleClearValue} className=" ml-2 invisible group-hover:visible">
                <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>

          ) : (
            <p className="min-w-max">No due date</p>
          )}
        </div>
      }
      editComponent={
        <ReactDatePicker
          className="input-primary z-50"
          selected={value}
          onChange={(date) => handleChange(new Date(date))}
        />
      }
      handleSubmitEdit={() => { }}
      componentName="due_date"
    />
  );
};

export default DueDate;
