import React from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "../SmartTable___EditCell";
import { AiOutlineExpandAlt } from "react-icons/ai";
import BlankModal from "../../../modals/BlankModal";
import { classNames } from "../../../misc/styling";


function ExpandedView({ text, setShowExpanded, showExpanded, colTitle }) {
  return (
    <>
      <div className="flex items-center justify-between space-x-1 group  z-0">
        <p className="text-left truncate ">{text}</p>
        <button onClick={() => setShowExpanded(true)}><AiOutlineExpandAlt className="text-blue-700 invisible  group-hover:visible h-6 w-6 rounded-lg p-1 transition duration-100 ease-in hover:bg-blue-300 " />
        </button>
      </div>
<BlankModal open={showExpanded} setOpen={setShowExpanded} title={colTitle}>
   
      <div className="mt-2 overflow-y-auto">
        <p className=" text-gray-900 text-left whitespace-pre-line">
        {text}
        </p>
      </div>  
  </BlankModal></>
)
}


const LongText = ({ rowId, initText, accessToken, table, colId,editable, colTitle,thisRowIndex,totalRows,editId }) => {
  const [text, setText] = useState(initText || "");
  const [showEdit, setShowEdit] = useState(false);
  const [showExpanded, setShowExpanded] = useState(false);
  //handling next thing changes
  const submitChanges = async () => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: text,
        row_id: editId,
        col_id: colId,
      });
    } catch (error) {
    }
  };

  const isNearEnd = thisRowIndex >= totalRows - 5;
  return (
    <div className="smart-table-cell  ">
      {editable ? (
        <SmartTableEditCell
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={<p className="text-left truncate ">{text}</p>}
          editComponent={
            <div className="relative">
              <textarea
                type="text"
                name={`long_text_${colId}`}
                rows={5}
                id={`long_text_${colId}`}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className={classNames(isNearEnd ? '-bottom-5' : '-top-5',"shadow-sm absolute  border-2 focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md")}
                placeholder=""
              />
            </div>
          }
        />
      ) : (
            <ExpandedView text={text} setShowExpanded={setShowExpanded} showExpanded={showExpanded} colTitle={colTitle} />
      )}
    </div>
  );
};

export default LongText;
