import {
  FaHiking,
  FaUserAlt,
  FaTrophy,
  FaExchangeAlt,
  FaUsers,
  FaStar,
  FaFlagCheckered,
  FaHandsHelping,
  FaPaintBrush,
  FaBullseye,
  FaGlobe,
  FaLeaf,
  FaGraduationCap,
  FaBalanceScale,
  FaMedal,
  FaGavel,
  FaStar as FaStarFame,
  FaHome,
  FaRandom,
  FaDove,
  FaUserFriends,
  FaSmile,
  FaHeartbeat,
  FaHandHoldingHeart,
  FaCheck,
  FaUserCog,
  FaShieldAlt,
  FaUserTie,
  FaHeart,
  FaBriefcase,
  FaMoneyBill,
  FaList,
  FaHandHoldingUsd,
  FaGamepad,
  FaSun,
  FaCrown,
  FaUserLock,
  FaAward,
  FaPeopleArrows,
  FaPrayingHands,
  FaShieldVirus,
  FaLock,
  FaPray,
  FaAnchor,
  FaCertificate,
  FaCoins,
  FaIndustry,
} from 'react-icons/fa';
import { RiServiceFill } from "react-icons/ri";
import { GiFlamer } from "react-icons/gi";

export const valuesExerciseValues = [
  {
    title: "Adventure",
    active: false,
    description: "seeks out and/or participates in exciting events that involve uncertainty",
    icon: FaHiking
  },
  {
    title: "Autonomy",
    active: false,
    description: "places importance on freedom, independence and individual discretion",
    icon: FaUserAlt
  },
  {
    title: "Challenges",
    active: false,
    description: "things or situations that test a person's thoughts or abilities",
    icon: FaTrophy
  },
  {
    title: "Change",
    active: false,
    description: "a shift from one state, stage or phase to another",
    icon: FaExchangeAlt
  },
  {
    title: "Community",
    active: false,
    description: "a group of people with a common background or characteristic or with shared interests",
    icon: FaUsers
  },
  {
    title: "Competence",
    active: false,
    description: "ability to solve problems, demonstrates mastery",
    icon: FaStar
  },
  {
    title: "Competition",
    active: false,
    description: "comparison of self, team or organization against oneself or another",
    icon: FaFlagCheckered
  },
  {
    title: "Cooperation",
    active: false,
    description: "placing importance on working together to achieve results; go along with a role",
    icon: FaHandsHelping
  },
  {
    title: "Creativity",
    active: false,
    description: "placing importance on imagination, inspiration and inventiveness",
    icon: FaPaintBrush
  },
  {
    title: "Decisiveness",
    active: false,
    description: "the ability to make decisions firmly, clearly, conclusively and in a timely manner",
    icon: FaBullseye
  },
  {
    title: "Diversity",
    active: false,
    description: "appreciates and respects individual differences",
    icon: FaGlobe
  },
  {
    title: "Ecology / Environment",
    active: false,
    description: "awareness of natural resources",
    icon: FaLeaf
  },
  {
    title: "Education",
    active: false,
    description: "placing importance on learning and education",
    icon: FaGraduationCap
  },
  {
    title: "Ethics",
    description: "valuing moral standards",
    active: false,
    icon: FaBalanceScale
  },
  {
    title: "Excellence",
    description: "pursuit of the highest level of optimal performance",
    active: false,
    icon: FaMedal
  },
  {
    title: "Excitement",
    description: "things that engage attention and/or emotion, in a lively, stimulating and compelling way",
    active: false,
    icon: GiFlamer  
  },
  {
    title: "Fairness",
    description: "placing importance in justice, decency and equality",
    active: false,
    icon: FaGavel
  },
  {
    title: "Fame",
    description: "recognition by others, desire to be well-known",
    active: false,
    icon: FaStarFame
  },
  {
    title: "Family",
    description: "connection to immediate or expended relations",
    active: false,
    icon: FaHome
  },
  {
    title: "Flexibility",
    description: "the ability to adapt to environments, situations or changes",
    active: false,
    icon: FaRandom
  },
  {
    title: "Freedom",
    description: "without obligation",
    active: false,
    icon: FaDove
  },
  {
    title: "Friendship",
    description: "strong ties with family, friends, co-workers or members of a certain community",
    active: false,
    icon: FaUserFriends
  },
  {
    title: "Happiness",
    description: "feelings of contentment, satisfaction and/or fulfillment",
    active: false,
    icon: FaSmile
  },
  {
    title: "Health",
    description: "placing importance on physical and emotional well-being",
    active: false,
    icon: FaHeartbeat
  },
  {
    title: "Helping Others",
    description: "placing importance on assisting other people",
    active: false,
    icon: FaHandHoldingHeart
  },
  {
    title: "Honesty",
    description: "telling the truth, does not engage in deception, is forthright and candid",
    active: false,
    icon: FaCheck
  },
  {
    title: "Independence",
    description: "ability to be self-directed in one's thoughts and actions",
    active: false,
    icon: FaUserCog
  },
  {
    title: "Integrity",
    description: "firm adherence to a moral code and/or set of values, walking the talk",
    active: false,
    icon: FaShieldAlt
  },
  {
    title: "Leadership",
    description: "the ability to create relationships in order to complete a stated goal or mission",
    active: false,
    icon: FaUserTie
  },
  {
    title: "Loyalty",
    description: "allegiances to people, organizations, ideals, causes, etc.",
    active: false,
    icon: FaHeart
  },
  {
    title: "Meaningful Work",
    description: "doing and providing work that has a purpose and/or signifigance",
    active: false,
    icon: FaBriefcase
  },
  {
    title: "Money",
    description: "financial assets, the accumulation of wealth",
    active: false,
    icon: FaMoneyBill
  },
  {
    title: "Order",
    description: "adherence to a system, protocol or code",
    active: false,
    icon: FaList
  },
  {
    title: "Philanthropy",
    description: "giving of monetary gifts in order to benefit others",
    active: false,
    icon: FaHandHoldingUsd
  },
  {
    title: "Play",
    description: "imagination, spontaneity; the ability to be amused",
    active: false,
    icon: FaGamepad
  },
  {
    title: "Pleasure",
    description: "enjoyment, delight, satisfaction or fulfillment",
    active: false,
    icon: FaSun
  },
  {
    title: "Power",
    description: "strength, superiority, dominance and/or authority",
    active: false,
    icon: FaCrown
  },
  {
    title: "Privacy",
    description: "the need for solitude or separateness",
    active: false,
    icon: FaUserLock
  },
  {
    title: "Recognition",
    description: "acknowledgment and/or validation",
    active: false,
    icon: FaAward
  },
  {
    title: "Relationships",
    description: "connections between and among people",
    active: false,
    icon: FaPeopleArrows
  },
  {
    title: "Religion",
    description: "a specific system of belief or worship",
    active: false,
    icon: FaPrayingHands
  },
  {
    title: "Safety",
    description: "a concern for the security and well-being of one's self and/or others",
    active: false,
    icon: FaShieldVirus
  },
  {
    title: "Security",
    description: "the feeling of being protected or safeguarded from danger, a sense of comfort",
    active: false,
    icon: FaLock
  },
  {
    title: "Service",
    description: "placing importance on serving others with quality and value",
    active: false,
    icon: RiServiceFill
  },
  {
    title: "Spirituality",
    description: "appreciating the need to understand one's inner self and its relationship with the world",
    active: false,
    icon: FaPray
  },
  {
    title: "Stability",
    description: "taking on day-to-day activities in a calm and consistent manner",
    active: false,
    icon: FaAnchor
  },
  {
    title: "Status",
    description: "placing importance on the rank or position of something in a relationship or group",
    active: false,
    icon: FaCertificate
  },
  {
    title: "Wealth",
    description: "richness in terms of assets or money",
    active: false,
    icon: FaCoins
  },
  {
    title: "Work",
    description: "employment, occupation, or fulfillment of a task or duty",
    active: false,
    icon: FaIndustry
  }
];