import { PlusIcon } from '@heroicons/react/solid';
import { MdOutlineSavings } from 'react-icons/md';

/**
 * Displays an empty state with a call to action for adding new savings.
 * It is intended to be shown when no savings are currently being tracked.
 *
 * @param {Object} props The component props.
 * @param {Function} [props.handleAddNew] Function to be called when the "Track New Savings" button is clicked. Defaults to a no-op function.
 * @returns {JSX.Element} The empty state component.
 */
export default function EmptyState({ handleAddNew = () => { } }) {
    return (
        <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-10">
            <div className="flex flex-col sm:flex-row items-center sm:space-x-5">
                {/* Icon indicating savings tracking */}
                <MdOutlineSavings className='text-gray-500 h-16 w-16' />
                <div className="text-left">
                    {/* Title for the empty state */}
                    <h3 className="mt-2 text-base font-semibold text-gray-900">No savings tracked</h3>
                    {/* Description encouraging the user to start tracking savings */}
                    <p className="mt-1 text-sm text-gray-500 max-w-prose text-justify">
                        Start tracking your savings with your advisor by adding them here. Whether it's your monthly contribution to an emergency fund or your yearly 401k allocation, this is the place to input all the savings you want to monitor and discuss with your financial advisor.
                    </p>
                </div>
            </div>
            {/* Button to add new savings */}
            <div className="mt-6 sm:mt-0">
                <button
                    type="button"
                    onClick={handleAddNew}
                    className='btn-2xl btn-primary'
                >
                    <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    Track New Savings
                </button>
            </div>
        </div>
    );
}

