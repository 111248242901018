import React from "react";


const AdvisorColumn = ({ advisor }) => {
  return (
    <div
    
      className=" text-left pl-2"
    >
          <p>{advisor?.first_name}{" "}{advisor?.last_name}</p>
          {" "}
    </div>
  );
};

export default AdvisorColumn;
