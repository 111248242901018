import React, { useEffect, useMemo, useState } from 'react'
import { classNames } from '../../misc/styling'
import { currencyFormatter, numberFormatter } from '../../../helpers/formatters'
import DoubleClickEdit from '../../DoubleClickEdit'


const numberOptions = [
    { value: 'sum', label: 'Sum' },
    { value: 'average', label: 'Average' },
    { value: 'median', label: 'Median' },
    { value: 'min', label: 'Min' },
    { value: 'max', label: 'Max' },
    { value: 'standardDeviation', label: 'Standard Deviation' },
    { value: 'totalEmpty', label: 'Empty' },
    { value: 'totalFilled', label: 'Total Filled' },
    { value: 'totalUnique', label: 'Unique' },
    { value: 'percentEmpty', label: 'Percent Empty' },
    { value: 'percentFilled', label: 'Percent Filled' },
    { value: 'percentUnique', label: 'Percent Unique' }
];

const textOptions = [{ value: 'totalEmpty', label: 'Empty' }, { value: 'totalFilled', label: 'Filled' }, { value: 'totalUnique', label: 'Unique' }, { value: 'percentEmpty', label: 'Percent Empty' }, { value: 'percentFilled', label: 'Percent Filled' }, { value: 'percentUnique', label: 'Percent Unique' }]

const checkboxOptions = [{ value: 'totalChecked', label: 'Checked' }, { value: 'totalUnchecked', label: 'Unchecked' }, { value: 'percentChecked', label: 'Percent Checked' }, { value: 'percentUnchecked', label: 'Percent Unchecked' }]

const dateOptions = [{ value: 'totalEmpty', label: 'Empty' }, { value: 'totalFilled', label: 'Filled' }, { value: 'totalUnique', label: 'Unique' }, { value: 'percentEmpty', label: 'Percent Empty' }, { value: 'percentFilled', label: 'Percent Filled' }, { value: 'percentUnique', label: 'Percent Unique' }, { value: 'earliestDate', label: 'Earliest Date' }, { value: 'latestDate', label: 'Latest Date' }, { value: 'dateRangeDays', label: 'Date Range (Days)' }, { value: 'dateRangeMonths', label: 'Date Range (Months)' }, { value: 'dateRangeYears', label: 'Date Range (Years)' }]



    function SummaryColumn({ data, col }) {
        let defaultOption = useMemo(() => {
            switch (col.col_type) {
                case "number": return { value: 'average', label: 'Average' };
                case "short_text": return { value: 'totalFilled', label: 'Filled' };
                case "long_text": return { value: 'totalFilled', label: 'Filled' };
                case "single_select": return { value: 'totalFilled', label: 'Filled' };
                case 'multi_select': return { value: 'totalFilled', label: 'Filled' };
                case 'date': return { value: 'totalFilled', label: 'Filled' };
                case "checkbox": return { value: 'totalChecked', label: 'Checked' };
                case "date": return { value: 'totalFilled', label: 'Filled' };
                case "due_date": return { value: 'totalFilled', label: 'Filled' };
                default: return { value: 'average', label: 'Average' }; // You can handle other cases if needed              
            }
        }, [col.col_type]); // dependency on col.col_type

    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [showEdit, setShowEdit] = useState(false);

    const options = useMemo(() => {
        switch (col.col_type) {
            case "number": return numberOptions;
            case "short_text":
            case "long_text":
            case "single_select": return textOptions;
            case 'multi_select': return textOptions
            case "checkbox": return checkboxOptions;
            case "date":
            case "due_date": return dateOptions;
            default: return []; // You can handle other cases if needed
        }
    }, [col.col_type]); // dependency on col.col_type
        

        const valueMapping = useMemo(() => {
            if (!data) return {};

            const formatValue = (value, options) => {
                if (value === undefined || value === null) return 'N/A';
                const isCurrency = options?.format === 'currency';
                const isPercent = options?.format === 'percent';
                if (isPercent) return `${Number(value).toFixed(1)}%`;
                if (isCurrency) return currencyFormatter.format(value);
                return numberFormatter.format(Number(value).toFixed(1));
            };

            return {
                sum: formatValue(data.sum, col.options),
                average: formatValue(data.average, col.options),
                median: formatValue(data.median, col.options),
                min: formatValue(data.min, col.options),
                max: formatValue(data.max, col.options),
                standardDeviation: formatValue(data.standardDeviation),
                totalEmpty: formatValue(data.totalEmpty),
                totalFilled: formatValue(data.totalFilled),
                totalUnique: formatValue(data.totalUnique),
                percentEmpty: formatValue(data.percentEmpty, { format: 'percent' }),
                percentFilled: formatValue(data.percentFilled, { format: 'percent' }),
                percentUnique: formatValue(data.percentUnique, { format: 'percent' }),
                totalChecked: formatValue(data.totalChecked),
                totalUnchecked: formatValue(data.totalUnchecked),
                percentChecked: formatValue(data.percentChecked, { format: 'percent' }),
                percentUnchecked: formatValue(data.percentUnchecked, { format: 'percent' }),
                earliestDate: data.earliestDate ? new Date(data.earliestDate).toLocaleDateString() : 'N/A',
                latestDate: data.latestDate ? new Date(data.latestDate).toLocaleDateString() : 'N/A',
                dateRangeDays: formatValue(data.dateRangeDays),
                dateRangeMonths: formatValue(data.dateRangeMonths),
                dateRangeYears: formatValue(data.dateRangeYears),
            };
        }, [data, col.options]);



    useEffect(() => {
        setShowEdit(false)
    }, [selectedOption])

    if (!data) return <div></div>;
    return (
        <div className='text-right flex justify-end'>
            <DoubleClickEdit
                defaultComponent={<div className='flex items-center justify-end h-10 '>
                    <p className='text-gray-600 mr-1'>{selectedOption.label}:</p>
                    <div className='text-right text-base'>
                        {valueMapping[selectedOption.value]}
                    </div>
                </div>}
                editComponent={<select
                    defaultValue={selectedOption.value}
                    onChange={(e) => setSelectedOption(options.find(option => option.value === e.target.value))}
                    className='input-primary max-w-[200px] h-10'
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>}
                handleSubmitEdit={() => setShowEdit(false)}
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                toolTipPosition='top'
            />
        </div>
    );
}


const Summaries = ({ data }) => {
    return (
        <tr>
            {data?.visible_columns.map((col, index) => (
                <th
                    key={index}
                    scope="col"
                    className={classNames(
                        index === 0 && "pl-9",
                        " first:rounded-bl-xl last:rounded-br-xl sm:first:left-0  text-left sticky bg-gray-100  bottom-0   py-1 text-sm font-semibold text-gray-900 px-2 "
                    )}
                >
                    <SummaryColumn data={data?.summaries[col.col_id]} col={col} />
                </th>
            ))}
        </tr>)
}

export default Summaries