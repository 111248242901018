import React from 'react'

const loadingSpinner =
  "https://res.cloudinary.com/abundo-wealth-assets/image/upload/v1658162668/Abundo%20Wealth%20Assets/components/animation/loading-spinner-1_rtg3jy.svg";

const LoadingSpinner = ({height = "h-44"}) => {

  
    return (
      <img
        className={`animate-spin ${height}`}
        src={loadingSpinner}
        alt="loading spinner"
      ></img>
    );
}

export default LoadingSpinner
