import React from "react";
import MarkdownNotes from "../../../../../components/content/MarkdownNotes";
import { dateFormaterWithTime } from "../../../../../helpers/formatters";
import WideBlankModal from "../../../../../components/modals/WideBlankModal";
import RichTextDisplay from "../../../../../components/textEditor/RichTextDisplay";

const NoteEdits = ({ edits, open, setOpen }) => {
  return (
    <WideBlankModal open={open} setOpen={setOpen} title="Edit History">
      <ul className=" divide-y-2 px-4 max-h-[300px] sm:max-h-[500px] overflow-y-auto ">
        {edits.map((edit) => (
          <li
            key={edit.edited_at}
            className=" grid grid-cols-2 sm:grid-cols-6 gap-x-2 place-items-start"
          >
            <p>Edited at: </p>
            <p className="sm:col-span-5">
              {dateFormaterWithTime(new Date(edit.edited_at))}
            </p>
            <p> by:</p>
            <p className="sm:col-span-5"> {edit.edited_by.name}</p>
            <p> Note:</p>
            <div className="sm:col-span-5">
              {" "}
              {edit.content && (
                <div>
                  <RichTextDisplay
                    content={edit.content}
                    thisClassName="notes"
                  />
                </div>
              )}
              {edit.body?.delta && (
                <RichTextDisplay
                  delta={edit.body.delta}
                  thisClassName="notes"
                />
              )}
              {edit.body?.blocks && <MarkdownNotes noteRaw={edit.body} />}
            </div>
          </li>
        ))}
      </ul>
    </WideBlankModal>
  );
};

export default NoteEdits;
