import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs'
import { dateFormaterWithTime } from '../../../../../helpers/formatters'
import RichTextDisplay from '../../../../../components/textEditor/RichTextDisplay'
import { classNames } from '../../../../../helpers/classNames'
import useNav from '../../../../../hooks/useNav'

const ActivityRow = ({ item }) => {
    const nav = useNav()
    const activityColors = {
        'Signup Complete': 'text-green-600 bg-green-100 ',
        'Payment Failed': 'text-red-600 bg-red-100 ',
    }
    const thisColor = activityColors[item.type] || 'text-gray-800 bg-gray-100'
    return (
        <tr key={item.activity_id}>
            <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-800  whitespace-nowrap">
                {item.user_name}
            </td>
            <td className=" py-4 px-5 text-sm font-medium text-gray-800 whitespace-nowrap  ">
                <button onClick={() => nav(`/crm/households/${item.household_id}`)}
                    className='flex items-center hover:underline'
                >{item.household_name}
                    <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0" />
                </button></td>

            <td className={classNames(" px-5 py-4 text-sm text-gray-800 flex  ")}>

                <p className={classNames(thisColor,'font-semibold px-4 py-1 rounded-full')}>
                    {item.type}
                </p></td>


            <td className=" px-5 py-4 text-sm text-gray-800 hidden lg:table-cell">{item.content ? <RichTextDisplay content={item.content} thisClassName={'notes'} /> : item.details}</td>
            <td className="hidden lg:table-cell py-4 pl-4 pr-3 text-sm font-medium text-gray-800 whitespace-nowrap ">
                {dateFormaterWithTime(new Date(item.created_at))}
            </td>
        </tr>)
}

export default ActivityRow