import React from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs'
import RichTextDisplay from '../../../../components/textEditor/RichTextDisplay'
import { dateFormaterWithTime } from '../../../../helpers/formatters'

const HHActivityRow = ({ item }) => {
    return (
        <tr key={item.activity_id}>
            <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900  whitespace-nowrap">
                {item.user_name}
            </td>
          

            <td className=" px-3 py-4 text-sm text-gray-500 ">{item.type}</td>


            <td className=" px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">{item.content ? <RichTextDisplay content={item.content} thisClassName={'notes'} /> : item.details}</td>
            <td className="hidden lg:table-cell py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap ">
                {dateFormaterWithTime(new Date(item.created_at))}
            </td>
        </tr>)
}

export default HHActivityRow