import { useState } from "react";
import { ExclamationIcon, XIcon } from '@heroicons/react/solid';

export default function AlertCaution({
  title = "",
  children = "",
  dismissBtn = false,
  
}) {
  const [hidden, setHidden] = useState(false);
  return (
    <>
      {!hidden && (
        <div className="bg-yellow-50 border-l-4 rounded-xl shadow-md rounded-l-sm border-yellow-400 p-4">
          <div className="flex">
            <div className="flex items-center">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {title && (
                <h3 className="text-sm font-medium text-yellow-800">
               {title}
                </h3>
              )}
              <p className="text-sm text-yellow-700">{children} </p>
            </div>
            {dismissBtn && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => {
                      setHidden(true);
                    }}
                    className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
            
          </div>
        </div>
      )}
    </>
  );
}
