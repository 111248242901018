/* This example requires Tailwind CSS v2.0+ */

import BasicPanel from "../../../../components/panels/BasicPanel";
import Note from "./components/Note";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import NewNote from "./components/NewNote";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../api/apiConstants";
import ApiRequest from "../../../../api/ApiRequest";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";

export default function Notes() {
  const [showNewNote, setShowNewNote] = useState(false);
  const { household_id } = useParams();
  //functions
  const notesApi = new ApiRequest(
    API_ROUTES.HOUSEHOLD_NOTES,
    API_AUTH_TYPES.ADVISOR
  );
  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.ADVISOR);
  const listQueryKey = `notes-${household_id}`;
  const {
    data: notes,
    isLoading,
    isError,
  } = useQuery({
    queryFn: notesApi.getFn({
      endpoint: `index_household_id/household_notes`,
      params: [household_id],
    }),
    queryKey: [listQueryKey],
  });
  const { data: advisors, isLoading: isLoadingAdvisors } = useQuery({
    queryFn: usersApi.getFn({
      endpoint: `index_advisors`,
    }),
    queryKey: ["advisors"],
  });

  if (isLoading || isLoadingAdvisors) return <p>Loading...</p>;
  if (isError) return <p>Error</p>;




  return (
    <BasicPanel>
      <div className="flex items-center justify-between space-x-5 mb-7">
        <h1 className="crm-title ">Notes</h1>
        {!showNewNote ?
          <button
            onClick={() => setShowNewNote(true)}
          className="btn-md  btn-primary mt-1">
          <PlusCircleIcon className='h-5 w-5 mr-2' />
          <p>Add Note</p>
          </button> :
          <button
            onClick={() => setShowNewNote(false)}
          className="underline text-gray-800 mt-1">
          <p>Cancel</p>
        </button>}
      </div>
      <div className="space-y-5 ">
        {" "}
       {showNewNote && <NewNote
          listQueryKey={listQueryKey}
          householdId={household_id}
        />}{" "}
        {notes && notes.length > 0 && (
          <ul className="divide-y divide-gray-200  border rounded-lg  py-2 px-3 ">
            {notes.map((note, index) => (
              <Note
                key={note.household_note_id}
                note={note}
                notesArr={notes}
                index={index}
                listQueryKey={listQueryKey}
                advisors={advisors}
              />
            ))}
          </ul>
        )}
      </div>
    </BasicPanel>
  );
}
