
import { useState } from "react";
import {
  SelectorIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getReq } from "../../apis/internalApi";
import { API_ROUTES } from './../../api/apiConstants';


const fetchItems = async (accessToken) => {
  const response = await getReq(
    API_ROUTES.HOUSEHOLDS,
    "index_households_auth_advisor",
    accessToken
  );
  return response.data;
};

export default function HouseholdSearch({ selector = false, label = "",household='',setHousehold = () => {} }) {
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);
  const accessToken = useSelector((state) => state.user.accessToken);
  const filteredItems =
    query === ""
      ? items
      : items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  const createItemsArray = useCallback(async () => {
    const fetchedItems = await fetchItems(accessToken);
    setItems(fetchedItems);
  }, [accessToken]);

  useEffect(() => {
    createItemsArray();
  }, [createItemsArray]);

  return (
    <div className="relative">
      <Combobox as="div" value={household} onChange={setHousehold}>
        {" "}
        {query.length === 0 && !household ? (
          <Combobox.Label className=" absolute top-1/2 left-3 z-10 -translate-y-1/2 text-sm font-medium flex space-x-1 items-center text-gray-400">
            <SearchIcon
              className="flex-shrink-0 h-5 w-5 z-10"
              aria-hidden="true"
            />{" "}
            <p> Search {label} </p>
          </Combobox.Label>
        ) : null}
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-200 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item) => item?.name}
          />
          <Combobox.Button className="absolute z-10 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            {selector && (
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </Combobox.Button>

          {filteredItems.length > 0 && (
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.map((item) => (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "text-gray-900 bg-gray-200" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <button
                        onClick={() => setHousehold(item)}
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {item.name}
                      </button>

                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}
