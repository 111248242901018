import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production" ? `` : `http://localhost:5000`;
export const getReq = async (
  route,
  endpoint,
  accessToken ,
  config = { headers: { Authorization: `Bearer ${accessToken}` } },
  version = "v1"
) => {
  const fullURL = `${baseURL}/api/${version}/${route}/${endpoint}`;

  return await axios.get(fullURL, config);
};

export const patchReq = async (
  route,
  endpoint,
  accessToken = "",
  data = {},
  config = { headers: { Authorization: `Bearer ${accessToken}` } },
  version = "v1"
) => {
  const fullURL = `${baseURL}/api/${version}/${route}/${endpoint}`;

  return await axios.patch(fullURL, data, config);
};

export const postReq = async (
  route,
  endpoint,
  accessToken = "",
  data = {},
  config = { headers: { Authorization: `Bearer ${accessToken}` } },
  version = "v1"
) => {
  const fullURL = `${baseURL}/api/${version}/${route}/${endpoint}`;

  return await axios.post(fullURL, data, config);
};

export const deleteReq = async (
  route,
  endpoint,
  accessToken = "",
  config = { headers: { Authorization: `Bearer ${accessToken}` } },
  version = "v1"
) => {
  const fullURL = `${baseURL}/api/${version}/${route}/${endpoint}`;

  return await axios.delete(fullURL, config);
};
