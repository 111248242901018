import React from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "../SmartTable___EditCell";

const ShortText = ({ rowId, initText, accessToken, table, colId, editable,editId }) => {
  const [text, setText] = useState(initText || "");
  const [showEdit, setShowEdit] = useState(false);
  //handling next thing changes
  const submitChanges = async () => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: text,
        row_id: editId,
        col_id: colId,
      });
    } catch (error) {}
  };

  return (
    <div className="smart-table-cell px-2">
      {editable ? (
        <SmartTableEditCell
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={<p className="text-left truncate ">{text}</p>}
          editComponent={
            <div className="relative">
              <input
                type="text"
                name={`long_text_${colId}`}
                id={`long_text_${colId}`}
                value={text || ''}
                onChange={(e) => setText(e.target.value)}
                className="shadow-sm absolute -top-5 border-2 focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md"
              />
            </div>
          }
        />
      ) : (
        <p className="text-left truncate ">{text}</p>
      )}
    </div>
  );
};

export default ShortText;
