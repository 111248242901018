import React from 'react';

/**
 * Displays the referral input information for a household.
 * This component is used to present a specific piece of data related to how the household was referred.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.household - The household object containing referral input information.
 * @returns {JSX.Element} A component that renders the referral input text.
 */
const ReferralInput = ({ household }) => {
    return (
        <div>
            {/* Label for the referral input */}
            <p className="text-gray-600 text-sm">
                Referral Input
            </p>
            {/* Display the referral input from the household object */}
            <p className="font-semibold">
                {household.referral_input}
            </p>
        </div>
    );
};

export default ReferralInput;
