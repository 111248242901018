import React from 'react'
import Markdown from 'markdown-to-jsx'

const HouseholdSummary = ({ householdMeta }) => {
    const summary = householdMeta.mental_model
  return (
    <div className='max-h-[500px] overflow-y-auto  custom-scrollbar-thick custom-scrollbar-gray'>
        {summary &&  <Markdown options={{ wrapper: "article" }} className="markdown-notes">
              {summary}
          </Markdown>}
      </div>
  )
}

export default HouseholdSummary