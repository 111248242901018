import React, { useRef , useState, useEffect} from "react";

import SmartTableHiddenFields from "./components/smartTable___buttons/hiddenFields/HiddenColumns";
import SmartTableFilters from "./components/smartTable___buttons/filters/SmartTable___Filters";
import SmartTableSorts from "./components/smartTable___buttons/sorts/SmartTable___Sorts";
import ViewsList from "./components/SmartTable___views/ViewsList";
import { ChevronLeftIcon, PencilAltIcon } from "@heroicons/react/outline";
import Layout from "../layout/Layout";

import { classNames } from "./../misc/styling";
import SmartTableRow from "./components/SmartTable___Row";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import FadeIn from "../transitions/FadeIn";
import Summaries from "./components/Summaries";
import useInfiniteScrollTable from "./hooks/useInfinitateScrollTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useNav from "../../hooks/useNav";
import { ToolTipBottom, ToolTipHoverContainer } from "../ToolTips";



const SmartTable = ({ tableId, customControlButtons, buttonFunctions,urlBase }) => {
  const [isShowingViews, setIsShowingViews] = useState(false);
  const { view_id: selectedViewId } = useParams();
  const nav = useNav()
  const setSelectedViewId = (viewId) => {
    nav(`/crm/${urlBase}/smart-table/${viewId}`)
  }

  
  const observerRef = useRef(null);

  const { rows, metadata, isLoading, isError,isFetching, hasNextPage, fetchNextPage } = useInfiniteScrollTable(tableId, selectedViewId);
  // Observer to detect scroll to the bottom
  useEffect(() => {
    if (isLoading || !hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [isLoading, hasNextPage, fetchNextPage]);

  if (isError) return <div>There was an error</div>;
  return (
    <Layout isSmartTable={true}>
      <div className="flex  ">
        {" "}
        <div
          className={classNames(
            isShowingViews ? "w-[300px]" : "w-[5px]",
            " flex flex-col items-end flex-shrink-0 py-3 px-5 border-r-2 border-gray-300"
          )}
          onClick={() => {
            if (!isShowingViews) {
              setIsShowingViews(true);
            }
          }}
        >
          <button
            className="pl-3 py-1"
            onClick={() => setIsShowingViews(!isShowingViews)}
          >
            <ChevronLeftIcon
              className={classNames(
                "h-5 w-5 transition duration-100 ml-auto",
                !isShowingViews && "rotate-180"
              )}
            />
          </button>
          <div
            className={classNames(
              !isShowingViews && "hidden",
              " overflow-y-auto h-full max-h-[80vh]"
            )}
          >
            <ViewsList
              selectedViewId={selectedViewId}
              setSelectedViewId={setSelectedViewId}
              allViews={metadata?.all_views}
              smartTableId={tableId}
            />
          </div>
        </div>
        <div className="">
          {/* Controls */}
          <div className="flex items-center justify-between h-[60px] pl-5 pr-5  ">
            <div className="space-x-2 flex items-center">
              {" "}
              <div
                className={classNames(
                  isFetching || isLoading ? "visible" : "invisible",
                  "mr-3"
                )}
              >
                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin" />
              </div>
              <FadeIn isShowing={!isLoading}>
                <div className="space-x-2 items-center hidden md:flex">
                  <SmartTableHiddenFields
                    dbHiddenColumns={metadata?.hidden_columns || []}
                    columns={metadata?.columns}
                    viewId={metadata?.view_id}
                  />
                  <div className="">
                    {" "}
                    <SmartTableFilters
                      columns={metadata?.columns}
                      dbFilters={metadata?.filters || []}
                      viewId={metadata?.view_id}
                    />
                  </div>
                  <SmartTableSorts
                    columns={metadata?.columns}
                    dbSorts={metadata?.sorts || []}
                    viewId={metadata?.view_id}
                  />
                  <p className="  text-gray-500">
                    <span className="">{metadata?.filtered_rows_length}</span> / {metadata?.total_rows_length} (
                    {Math.floor(
                      (metadata?.filtered_rows_length / metadata?.total_rows_length) * 100
                    )}
                    %)
                  </p>
                </div>
              </FadeIn>
            </div>
            <ul className="hidden space-x-2  xl:flex items-center">
              {/* <CSVDownload tableId={tableId} selectedViewId={selectedViewId} /> */}
              {customControlButtons &&
                customControlButtons.map((button, index) => <li key={index}>{button}</li>)}
            </ul>
          </div>
          <div
            className={classNames(
              isShowingViews
                ? "w-[calc(100vw-302px)] lg:w-[calc(100vw-524px)] "
                : "w-[calc(100vw-42px)] lg:w-[calc(100vw-266px)]",
              `h-[calc(100vh-108px)] bg-gray-300 overflow-auto custom-scrollbar-thick custom-scrollbar-gray `
            )}
          >
            <div className="">
              <table className="min-w-max  divide-y divide-gray-300 m-5  ">
                <thead className="relative">
                  <tr className="divide-x divide-x-gray-200 ">
                    {metadata?.visible_columns?.map((col, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={classNames(
                          index === 0 && "pl-9",
                          " first:rounded-tl-xl last:rounded-tr-xl sm:first:left-0 first:z-30 z-0   text-left sticky bg-gray-100 border-b top-0  py-3 text-sm font-semibold text-gray-900 px-2 "
                        )}
                      >                      <ToolTipHoverContainer>

                        <div className="flex items-center space-x-1">
                          <p> {col.title} </p>

                          {col.options?.editable && (
                            <PencilAltIcon className="h-5 w-5 text-gray-500" />
                          )}
                          </div>
                          {col?.description && (
                            <ToolTipBottom>{col.description}</ToolTipBottom>
                          )}
                        </ToolTipHoverContainer>

                        </th>
                       
                    ))}
                  </tr>
                </thead>
                <tbody className=" divide-y  divide-gray-200 bg-white transition duration-100  ">
                  {rows &&
                    rows.map((rowData, index) => (
                      <SmartTableRow
                        key={index}
                        rowData={rowData}
                        totalRows={rows.length}
                        visibleColumns={metadata?.visible_columns}
                        thisRowIndex={index}
                        buttonFunctions={buttonFunctions}
                      />
                    ))}
                </tbody>
                <tfoot className="bg-gray-50 h-10 sticky z-20">
                  <Summaries data={metadata} />
                  <tr className="relative">
                    <div className="absolute bottom-44  w-full" ref={observerRef}>

                    </div>
                  </tr> {/* Observer ref */}

                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SmartTable;
