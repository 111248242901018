import {
  ArchiveIcon,
  CheckIcon,
  ExclamationIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React from "react";
import { patchReq } from "../../../../../apis/internalApi";
import ItemMenu from "../../../../../components/menus/ItemMenu";
import { classNames } from "../../../../../components/misc/styling";
import {
  dateFormatter,
  timeToDate,
} from "../../../../../helpers/formatters";
import { toDays } from "../../../../../helpers/formatters";
import { API } from "../../../../../constants/index";
import { useSelector } from "react-redux";
import { useState } from "react";
import EditReminder from "../EditReminder";
import ReminderEdits from "../ReminderEdits";

export default function Reminder({ timer, timerArr, index }) {
  const accessToken = useSelector((state) => state.user.accessToken);
  const [showDescription, setShowDescription] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditHistory, setShowEditHistory] = useState(false);
  const dateColor = (date) => {
    const now = new Date();
    const difference = new Date(date) - now;
    const days = toDays(difference);
    if (difference < 0) return "text-red-500";
    if (days > 5) return "hidden";
    return "text-yellow-500";
  };

  const switchShowEdit = () => {
    setShowEdit(!showEdit);
  };
  const archiveReminder = async () => {
    try {
      const response = await patchReq(
        API.TIMERS,
        "update_archived_timers_auth_advisor",
        accessToken,
        { timer_id: timer.timer_id, is_archived: true }
      );
      timerArr.remove(index);
    } catch (error) {}
  };

  const menuOptions = [
    {
      title: "Edit",
      action: switchShowEdit,
      icon: PencilAltIcon,
    },
    {
      title: "Delete",
      action: archiveReminder,
      icon: TrashIcon,
    },
  ];

  const handleComplete = async () => {
    try {
      const response = await patchReq(
        API.TIMERS,
        "complete_reminder_auth_advisor",
        accessToken,
        { timer_id: timer.timer_id }
      );
      timerArr.update(index, response.data);
    } catch (error) {}
  };
  return (
    <>
      {showEditHistory && (
        <ReminderEdits
          edits={timer.edits}
          open={showEditHistory}
          setOpen={setShowEditHistory}
        />
      )}
      <li
        key={timer.timer_id}
        className="border-2 shadow-md rounded-lg px-4 py-2 sm:px-2"
      >
        <div className=" w-full flex justify-between ">
          <div className="w-full space-y-2">
            {" "}
            <div className="flex items-center justify-end w-full space-x-3 text-sm">
              {" "}
              <ExclamationIcon
                className={classNames(dateColor(timer.date), "h-5")}
              />{" "}
              <div className="flex justify-between w-full">
                {" "}
                <p>{dateFormatter.format(new Date(timer.date))}</p>
                <p> {timeToDate(new Date(timer.date))} </p>
              </div>
            </div>
            <p className=" p-2">{timer.title}</p>
            {showDescription && (
              <p className=" p-2 text-sm text-gray-600">{timer.description}</p>
            )}
          </div>

          <div className="inline-flex items-center pl-3 py-0.5   text-xs font-medium rounded text-gray-700   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <button onClick={handleComplete}>
              <CheckIcon className="text-green-500 h-6 w-6" />
            </button>
            <ItemMenu options={menuOptions} />
          </div>
        </div>{" "}
        <div className="w-full grid grid-cols-2 text-xs px-2 ">
          {" "}
          {timer.description ? (
            <button
              onClick={() => setShowDescription(!showDescription)}
              className="place-self-start text-gray-500"
            >
              {showDescription ? "hide description" : "show description"}
            </button>
          ) : (
            <div></div>
          )}
          {timer.edits && (
            <button
              className="place-self-end text-blue-500"
              onClick={() => setShowEditHistory(true)}
            >
              {timer.edits.length > 1
                ? `${timer.edits.length} edits`
                : "1 edit"}
            </button>
          )}
        </div>
        {showEdit && (
          <EditReminder
            switchShowEdit={switchShowEdit}
            current={timer}
            timerArr={timerArr}
            index={index}
          />
        )}
      </li>
    </>
  );
}
