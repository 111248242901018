import { MinusIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'

const MinimizedMessage = ({toggleMinimizedMutation, updateIsComposingMutation,subject,isMaximized,setIsMaximized}) => {
  return (
      <div className='w-[300px]'>
          <div className="bg-secondary-100 rounded-t-2xl" >
              <div className="p-3">
                  <div className="justify-between flex">
                      <div className="w-full" onClick={() => toggleMinimizedMutation.mutate()}>
                          <p className='truncate text-base w-[200px] '>{subject || 'New Message'}</p>

                      </div>
                      <div className="flex items-center  flex-shrink-0">
                          <button
                              className='p-1 '
                              onClick={() => toggleMinimizedMutation.mutate()}>
                              <MinusIcon className='h-5 w-5' />
                          </button>
                          <button
                              className='p-1 '
                              onClick={() => setIsMaximized(!isMaximized)}>
                              {isMaximized ?
                                  <FiMinimize2 className='h-5 w-5' /> :
                                  <FiMaximize2 className='h-5 w-5' />}
                          </button>
                          <button
                              className='p-1 '

                              onClick={() => updateIsComposingMutation.mutate()}>
                              <XIcon className='h-5 w-5 z-10' />
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>  )
}

export default MinimizedMessage