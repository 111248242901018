import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
  account: {},
  loading: false,
  lastUpdated: 0,
  acctSettings: {
    type: null,
    text: "",
  },
  returns: {},
  portfolio: {}
};

export const advisorSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    loadingTrue: (state) => {
      state.loading = true;
    },
    loadingFalse: (state) => {
      state.loading = false;
    },
    lastUpdated: (state, action) => {
      state.lastUpdated = action.payload;
    },
    setSettings: (state, action) => {
      state.acctSettings.type = action.payload.type;
      state.acctSettings.options = action.payload.options;
      state.acctSettings.text = action.payload.text;
    },
    cancelSettings: (state) => {
      state.acctSettings.type = null;
      state.acctSettings.text = "";
    },
    setReturns: (state,action) => {
  state.returns = action.payload
    },
    setPortfolio: (state, action) => {
      state.portfolio = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setClient,
  setAccount,
  loadingTrue,
  loadingFalse,
  lastUpdated,
  setSettings,
  cancelSettings,
  setReturns,
  setPortfolio,
} = advisorSlice.actions;

export default advisorSlice.reducer;
