import React from "react";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "../SmartTable___EditCell";

const Checkbox = ({
  rowId,
  initValue,
  accessToken,
  table,
  colId,
  editable,
  editId
}) => {
  const [checked, setChecked] = useState(initValue || false);
  const [showEdit, setShowEdit] = useState(false);
  //handling next thing changes
  const submitChanges = async (e) => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: !checked,
        row_id: editId,
        col_id: colId,
      });
      setChecked(!checked);
    } catch (error) {}
  };

  return (
    <div className="smart-table-cell flex justify-center">
      <input
        type="checkbox"
        checked={checked}
        name={`checkbox_${colId}`}
        id={`checkbox_${colId}`}
        value={checked}
        onChange={submitChanges}
        className=".checkbox-lg h-6 w-6 rounded border-gray-300 text-green-500 focus:ring-green-500"
      />
    </div>
  );
};

export default Checkbox;
