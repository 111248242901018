import { useEffect } from 'react';

/**
 * useKeyboardShortcut Hook
 * Listens for the given keyboard combination and calls the provided callback function.
 * 
 * @param {Function} callback - Function to be called when the keyboard shortcut is pressed
 * @param {String} key - The key to listen for (e.g., "Space", "Enter")
 * @param {Boolean} [ctrl] - Whether the Ctrl key should be held down
 * @param {Boolean} [shift] - Whether the Shift key should be held down
 * @param {Boolean} [alt] - Whether the Alt key should be held down
 */
const useKeyboardShortcut = (callback, key, ctrl = false, shift = false, alt = false) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                event.code === key &&
                event.ctrlKey === ctrl &&
                event.shiftKey === shift &&
                event.altKey === alt
            ) {
                callback();
            }
        };

        // Attach the event listener to the window object
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup: remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [callback, key, ctrl, shift, alt]);
};

export default useKeyboardShortcut;
