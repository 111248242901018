import authApi from "../apis/authApi";
import { AUTHORIZATION } from "../constants";

export const authenticateAdvisorAdmin = async (accessToken) => {
  try {
    const response = await authApi.get("/admin_advisor", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Authoriztion ----------------------------------------------------------------
export const getAuthStatus = async (accessToken, endpoint) => {
  try {
    const res = await authApi.get(`/${endpoint}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (res.status !== 200) return false;
    return true;
  } catch (error) {
    return false;
  }
};


const authIsAdmin = async (accessToken) => {
  const auth = await authenticateAdvisorAdmin(accessToken);
  return auth.is_admin;
};

const authIsAdvisor = async (accessToken) => {
  const auth = await authenticateAdvisorAdmin(accessToken);
  return auth.is_advisor;
};

export const authenticateAuth = async (accessToken, authorizationType) => {
  if (authorizationType === AUTHORIZATION.ADMIN) {
    return await authIsAdmin(accessToken);
  }
  if (authorizationType === AUTHORIZATION.ADVISOR) {
    return authIsAdvisor(accessToken);
  }
  if (authorizationType === AUTHORIZATION.CONTENT_EDITOR) {
    return getAuthStatus(accessToken, "is_content_editor");
  }
  if (authorizationType === AUTHORIZATION.BLOG_EDITOR) {
    return getAuthStatus(accessToken, "is_blog_editor");
  }
  if (authorizationType === AUTHORIZATION.TRAVEL_LOUNGE_EDITOR) {
    return getAuthStatus(accessToken, "is_travel_lounge_editor");
  }
  if (authorizationType === AUTHORIZATION.LEARNING_LAB_EDITOR) {
    return getAuthStatus(accessToken, "is_learning_lab_editor");
  }
  throw new Error("Unknown authentication");
};

//End Authorization --------------------------------------------------------------------
