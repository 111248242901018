import { useInfiniteQuery } from "react-query";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";

const smartTableApi = new ApiRequest(   API_ROUTES.SMART_TABLE, API_AUTH_TYPES.ADVISOR)

const useInfiniteScrollTable = ( tableId, selectedViewId) => {
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetching,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["smart-table", { tableId, selectedViewId }],
        queryFn: ({ pageParam: cursor }) =>
            smartTableApi.get({
                endpoint: "table",
                params: [tableId, selectedViewId, cursor || 0,false],
            }),

        getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
        );

    // Concatenate the rows from all the pages
    const rows = data?.pages?.flatMap((page) => page.rows) || [];

    // Access metadata from the first page
    const metadata = data?.pages[0]?.metadata;
    return {
        rows,
        metadata, // Include the metadata in the return value
        isFetching,
        isLoading: isLoading || isFetchingNextPage,
        isError,
        hasNextPage,
        fetchNextPage,
    };


};


export default useInfiniteScrollTable;