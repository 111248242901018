import { TemplateIcon } from '@heroicons/react/outline'
import React, { useMemo, useRef, useState } from 'react'
import { ToolTipHoverContainer, ToolTipTop } from '../../../../../../../components/ToolTips'
import FadeIn from '../../../../../../../components/transitions/FadeIn'
import useOnClickOutside from '../../../../../../../hooks/useClickOutside'
import { PlusCircleIcon } from '@heroicons/react/outline'
import CreateTemplate from './CreateTemplate'
import { useQuery } from 'react-query'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../../api'
import TemplateItem from './TemplateItem'
import { classNames } from '../../../../../../../helpers/classNames'


const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL,API_AUTH_TYPES.ADVISOR)
const Templates = ({ emailId, toggleAcceptChange,isMaximized }) => {
    const [open, setOpen] = useState(false);
    const [showCreateNewTemplate, setShowCreateNewTemplate] = useState(false)
    const ref = useRef()

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    const { data: templates, isLoading } = useQuery({
        queryFn: bunmailApi.getFn({
            endpoint:'templates'
        }),
        queryKey:'email-templates'
    })

    const [query, setQuery] = useState("");
    const filteredTemplates = useMemo(() => {
        return query === ""
            ? templates
            : templates.filter((item) => {
                return (
                    item.title?.toLowerCase().includes(query.toLowerCase()) ||
                    JSON.stringify(item?.content)?.includes(query.toLowerCase())
                );
            });
    }, [query, templates?.length]);


    return (
        <div className='relative'>
            <ToolTipHoverContainer>
                <ToolTipTop>
                    Templates
                </ToolTipTop>
                <button onClick={() => setOpen(!open)}
                    className='btn-gray-clear-rounded'
                >
                    <TemplateIcon className='h-6 w-6 text-gray-600' />
                </button>
            </ToolTipHoverContainer>

            <FadeIn isShowing={open}>
                <div
                    ref={ref}
                    className={classNames(isMaximized ? 'left-0' : 'right-0 ',"absolute z-10 mb-10 w-[700px] p-4 bottom-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none")}

                >
                    <div className="">
                        <div className="flex justify-between border-b pb-1 mb-2 ">
                            <h3 className="text-sm font-semibold">Email Templates</h3>
                            <ToolTipHoverContainer>
                                <ToolTipTop>
                                    Create template from this email
                                </ToolTipTop>
                                <button
                                    onClick={() => setShowCreateNewTemplate(true)}
                                    className="">

                                    <PlusCircleIcon className=' h-5 w-5 text-gray-500 hover:bg-gray-100 rounded-full' />
                                </button>
                            </ToolTipHoverContainer>
                        </div>

                    </div>
                    <CreateTemplate open={showCreateNewTemplate} setOpen={setShowCreateNewTemplate} emailId={emailId} />

                    <input
                        type="text"
                        placeholder="Search prompts..."
                        className="border-2 border-gray-300 rounded-md py-2 px-4 w-full focus:outline-none focus:border-blue-500"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)} />



                    <ul className="py-1 min-h-[200px] max-h-[800px] overflow-auto custom-scrollbar-thick custom-scrollbar-gray w-full">
                        {filteredTemplates && filteredTemplates.map((template) => (
                            <li key={template.id}>
                                <TemplateItem template={template} emailId={emailId} toggleAcceptChange={toggleAcceptChange} />
                            </li>
                        )
                        )}


                    </ul>

                </div>

            </FadeIn>

        </div>
    )
}

export default Templates