import React from 'react'
import { useRef } from "react";
import useOnClickOutside from '../hooks/useClickOutside';

const ClickOutSideClose = ({setOpen,children}) => {
    const ref = useRef();
    useOnClickOutside(ref, () => {
      setOpen(false);
    });
    return <div ref={ref}>{children}</div>;
}

export default ClickOutSideClose