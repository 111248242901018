import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  src: "",
  description: "",
  sectionObj: {},
};
export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    r_selectVideo: (state, action) => {
      state.name = action.payload.name;
      state.src = action.payload.src;
      state.description = action.payload.description;
      state.playlistObj = action.payload.playlistObj;
    },
  },
});

// Action creators are generated for each case reducer function
export const { r_selectVideo } = videoSlice.actions;

export default videoSlice.reducer;
