

 function update(arr,index, newElement) {
   return [
     ...arr.slice(0, index),
     newElement,
     ...arr.slice(index + 1, arr.length),
   ];
 }
  
  function remove(arr,index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
}
  
  function push(arr,element) {
    return[...arr, element];
  }

  function unshift(arr,element) {
    return [element, ...arr];
  }


  const immutableArrayMethods =  {update,remove,push,unshift}


  export default immutableArrayMethods;
  