import {  XIcon } from "@heroicons/react/solid";
import { useState } from "react";

export default function AlertNeutral({ title = "", children = "", dismissBtn = false }) {
  const [hidden, setHidden] = useState(false);
  return (
    <>
      {!hidden && (
        <div className="rounded-xl shadow-md bg-blue-200 px-4 py-2 max-w-prose w-max">
          <div className="flex items-center">
            <div className="">
              {title && (
                <h3 className="text-sm font-bold text-blue-800">{title}</h3>
              )}
              <p className="text-sm font-semibold text-blue-700">{children} </p>
            </div>
            {dismissBtn && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => {
                      setHidden(true);
                    }}
                    className="inline-flex bg-blue-50 rounded-md p-1.5 text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
