import React from "react";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import useNav from "../../../../hooks/useNav";
const LinkColumn = ({ href,text }) => {
    const nav = useNav();
    return (
        <>
            {" "}
            {href ? (
                <button
                    
                    onClick={() => {
                        nav(href);
                    }}
                    className="flex items-center text-left px-2"
                >
                    <p>{text}</p>{" "}
                    <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0" />
                </button>
            ) : (
                <div>
                
                </div>
            )}{" "}
        </>
    );
};

export default LinkColumn;
