import React, { useState } from 'react'
import DashboardPanel from '../../../../components/panels/DashboardPanel'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import useNav from '../../../../hooks/useNav'
import { ToolTipBottom, ToolTipHoverContainer } from '../../../../components/ToolTips'
import { BsExclamationDiamond } from 'react-icons/bs'
import { classNames } from '../../../../helpers/classNames'
import ApiRequest from '../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../api/apiConstants'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'


const api = new ApiRequest(API_ROUTES.RISK_TOLERANCE, API_AUTH_TYPES.ADVISOR)

const PendingItem = ({ item }) => {
    const nav = useNav()
    const {household_id:householdId} = useParams()
    const queryClient = useQueryClient()
    const [manualOverride, setManualOverride] = useState(false);
    const [manualRiskTolerance, setManualRiskTolerance] = useState(false);
    const [manualAssetAllocation, setManualAssetAllocation] = useState(false);
    const [rationale, setRationale] = useState('');
    const acceptMutate = useMutation((result) => 
        api.patch({
            endpoint: 'resolve_pending', body: {
                is_manual_override: result.is_manual_override,
                risk_tolerance: result.risk_tolerance,
                asset_allocation: result.asset_allocation,
                rationale: result.rationale,
                user_id: item.user_id,
                household_id: item.household_id
            },
            params: [item.id]
        }), {
        onSuccess: () => {
                queryClient.invalidateQueries([`risk-tolerance-actions-${householdId}`])
                queryClient.invalidateQueries([`risk-tolerance-actions`]);
                queryClient.invalidateQueries(['advisor-dashboard'])
            }
        }
    )

    const handleAcceptResultsClick = async () => {
        const result = await acceptMutate.mutateAsync({
            is_manual_override: false,
            risk_tolerance: item.results.find((result) => result.label === 'Risk Tolerance').value,
            asset_allocation: item.results.find((result) => result.label === 'Asset Allocation').value,
            rationale: null
        });
    }

    const handleManualOverrideClick = async () => {
        const result = await acceptMutate.mutateAsync({
            is_manual_override: true,
            risk_tolerance: manualRiskTolerance,
            asset_allocation: manualAssetAllocation,
            rationale: rationale
        });
    }
    const formComplete = (manualAssetAllocation && manualRiskTolerance && rationale);
    return (
        <li key={item.id} className='py-5'>
            <div className="flex space-x-10">
                <DashboardPanel>

                    <p className='text-lg font-semibold'>
                        Taken by {item.user.first_name} {item.user.last_name} on {new Date(item.created_at).toLocaleDateString()}
                    </p>
                    <div className="flex space-x-5 items-center mt-4">
                        <button
                            onClick={handleAcceptResultsClick}
                            className='flex items-center space-x-2 btn-lg btn-primary'>
                            <CheckIcon className='h-5 w-5' />    <p>Accept Results</p>
                        </button>
                        <button
                            onClick={() => setManualOverride(true)}
                            className='flex items-center space-x-2 btn-lg btn-secondary'>
                            <XIcon className='h-5 w-5' />    <p>Manual Override</p>
                        </button>
                    </div>
                    <button
                        onClick={() => nav(`/crm/forms/${item.form_id}`)}
                        className='text-gray-500 underline mt-4'>
                        View Full Results
                    </button>
                </DashboardPanel>
                {manualOverride &&
                    <DashboardPanel>
                        <div className="flex items-center space-x-5">
                            <div className="">
                                <label htmlFor="manual_override_risk_tolerance">Risk Tolerance</label>
                                <select
                                    id='manual_override_risk_tolerance'
                                    className='input-primary mt-1'
                                    value={manualRiskTolerance}
                                    onChange={(e) => setManualRiskTolerance(e.target.value)}
                                >

                                    <option hidden value="">Select Risk Tolerance</option>
                                    <option value="Very Aggressive">Very Aggressive</option>
                                    <option value='Aggressive'>Aggressive</option>
                                    <option value='Moderate-Aggressive'>Moderate-Aggressive</option>
                                    <option value='Moderate'>Moderate</option>
                                    <option value='Moderate-Conservative'>Moderate-Conservative</option>
                                    <option value='Conservative'>Conservative</option>
                                    <option value='Very Conservative'>Very Conservative</option>

                                 
                                </select>
                            </div>
                            <div className="">
                                <label htmlFor="manual_override_asset_allocation">Asset Allocation</label>
                                <select
                                    id='manual_override_asset_allocation'
                                    className='input-primary mt-1'
                                    value={manualAssetAllocation}
                                    onChange={(e) => setManualAssetAllocation(e.target.value)}
                                >
                                    <option hidden value="">Select Asset Allocation</option>
                                    <option value="100/0">100/0</option>
                                    <option value="95/5">95/5</option>
                                    <option value="90/10">90/10</option>
                                    <option value="85/15">85/15</option>
                                    <option value="80/20">80/20</option>
                                    <option value="75/25">75/25</option>
                                    <option value="70/30">70/30</option>
                                    <option value="65/35">65/35</option>
                                    <option value="60/40">60/40</option>
                                    <option value="55/45">55/45</option>
                                    <option value="50/50">50/50</option>
                                    <option value="45/55">45/55</option>
                                    <option value="40/60">40/60</option>
                                    <option value="35/65">35/65</option>
                                     <option value="30/70">30/70</option>
                                </select>
                            </div>

                        </div>
                        <div className="mt-2">
                            <label htmlFor="rationale">Rationale</label>
                            <textarea
                                id='rationale'
                                className='input-primary mt-1 h-20'
                                rows={5}
                                placeholder='Enter rationale...'
                                value={rationale}
                                onChange={(e) => setRationale(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-end space-x-5 items-center mt-2">
                            <button
                                onClick={() => setManualOverride(false)}
                                className='underline'>
                                Cancel
                            </button>
                            <button
                                onClick={handleManualOverrideClick}
                                disabled={!formComplete}
                                className={classNames(!formComplete && 'opacity-60','btn-primary btn-md')}>
                                {acceptMutate.isLoading ? 'Submitting...' : 'Submit'}
                            </button>
</div>
                    </DashboardPanel>}
            </div>

            {item.results_flags &&
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {item.results_flags.map((flag) => (
                        <dt key={flag.label}>
                            <ToolTipHoverContainer>

                                <ToolTipBottom>
                                    {flag.description}
                                </ToolTipBottom>
                                <div className="overflow-hidden rounded-lg bg-yellow-100 px-5 py-5 text-yellow-600 shadow flex items-center ">
                                    <BsExclamationDiamond className='h-5 w-5' />
                                    <dd className={classNames(" text-2xl font-semibold tracking-tight ml-2 mr-1 ")}>{flag.label}</dd>

                                </div>
                            </ToolTipHoverContainer>
                        </dt>
                    ))}
                </dl>}
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {item.results.map((result) => (
                    <div key={result.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{result.label}</dt>
                        <dd className={classNames("mt-1 text-2xl font-semibold tracking-tight", item.results_flags?.length > 0 ? 'text-gray-500' : result.color)}>{result.value}</dd>
                    </div>
                ))}
            </dl>

        </li>)
}

export default PendingItem