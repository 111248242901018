import React from "react";
import { useState } from "react";
import { patchReq } from "../../../../../../apis/internalApi";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import { API } from "../../../../../../constants/index";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import ApiRequest from "../../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../api/apiConstants";
import { classNames } from "../../../../../../components/misc/styling";

const ChapterTitle = ({ defaultValue, chapter, chaptersListQueryKey,isMeetingMode }) => {
  const [value, setValue] = useState(defaultValue);
  const [showEdit, setShowEdit] = useState(false);
  const accessToken = useSelector((state) => state.user.accessToken);
  const queryClient = useQueryClient();
  const chaptersApi = new ApiRequest(
    API_ROUTES.FINANCIAL_STORY_CHAPTERS,
    API_AUTH_TYPES.ADVISOR
  );
  const { mutate: updateTitle } = useMutation(
    (body) => chaptersApi.patch({ endpoint: "update_title", body }),
    { onSuccess: () => queryClient.invalidateQueries(chaptersListQueryKey) }
  );

  const handleSubmitEdit = async () => {
    try {
      updateTitle({ title: value, chapter_id: chapter.chapter_id });
    } catch (error) {}
  };
  return (
    <DoubleClickEdit
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      defaultComponent={<h3
        className={'text-gray-700 max-w-2xl text-left  text-3xl mb-2 font-medium'
        }
      >
        {value}
      </h3>}
      editComponent={
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className=" shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:min-w-[400px] sm:text-lg   border-gray-300 rounded-md"
        />
      }
      handleSubmitEdit={() => handleSubmitEdit("title", value)}
      componentName="title"
    />
  );
};

export default ChapterTitle;
