import React, { useContext, useState } from 'react';
import useArray from '../hooks/useArray';

const AppContext = React.createContext();

export function useAppContext() {
    return useContext(AppContext);
}

const AppProvider = ({ children }) => {
    const [showChooseInstitution, setShowChooseInstitution] = useState(false);
    const [showManuallyAddAccount, setShowManuallyAddAccount] = useState(false);
    const toasts = useArray([]);

    const addToast = (notification) => {
        const id = Math.random() * 1000;
        toasts.push({ ...notification, id });
        setTimeout(() => {
            const index = toasts.array.findIndex(n => n.id === id);
            if (index !== -1) {
                toasts.remove(index);
            }
        }, 5000);

        return id;
    };

    const removeToast = (id) => {
        const index = toasts.array.findIndex(n => n.id === id);
        if (index !== -1) {
            toasts.remove(index);
        }
    };

    return (
        <AppContext.Provider
            value={{
                showChooseInstitution,
                setShowChooseInstitution,
                showManuallyAddAccount,
                setShowManuallyAddAccount,
                toasts,
                addToast,
                removeToast,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;