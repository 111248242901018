import React, { useMemo, useState } from "react";
import { CopyToClipboard as Copy } from "react-copy-to-clipboard";
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { classNames } from "../../helpers/classNames";

const ClipboardCopy = ({ text, textColor = "text-gray-400",removeMarkdown = false }) => {
  const [copied, setCopied] = useState();
  const activateAnnimation = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000 * 0.5);
  };

  const textToCopy = removeMarkdown? markdownToPlainText(text) : text;


 


  return (
    <div className="text-[10px] font-sans">
      <Copy text={textToCopy}>
        <button onClick={activateAnnimation}>
          <ClipboardCopyIcon
            className={classNames(textColor, "h-6", copied && " animate-ping ")}
          />
        </button>
      </Copy>
    </div>
  );
};

export default ClipboardCopy;

function markdownToPlainText(markdown) {
  return markdown
    .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
    .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Replace links with link text
    .replace(/#{1,6}\s?(.*?)\n/g, '$1\n\n') // Remove headers and leave text with a newline
    .replace(/(\*\*|__)(.*?)\1/g, '$2') // Remove bold
    .replace(/(\*|_)(.*?)\1/g, '$2') // Remove italic
    .replace(/~~(.*?)~~/g, '$1') // Remove strikethrough
    .replace(/`{1,3}(.*?)`{1,3}/g, '$1') // Remove inline code
    .replace(/<!--.*?-->/gs, '') // Remove HTML comments
    // .replace(/(\*|\+|-) (.*)/g, '  - $2\n') // Format list items, preserve with indentation
    // .replace(/\n{3,}/g, '\n\n') // Limit consecutive newlines to two
    .trim();
}