import React from "react";
import { useState } from "react";

const Button = ({ rowData, colId, buttonFunctions }) => {
  //handling next thing changes
  const [loading, setLoading] = useState(false);
  const executeScript = async () => {
    setLoading(true);
    try {
      const body = rowData;
      const thisFunction = buttonFunctions[colId];
      thisFunction(body);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="smart-table-cell flex justify-center">
      <button
        name={`button_${colId}`}
        id={`button_${colId}`}
        onClick={executeScript}
        disabled={loading}
        className="btn-primary px-3 py-0.5"
      >
        Click
      </button>
    </div>
  );
};

export default Button;
