import React from 'react'
import DraggableItem from '../../../../../../components/drag-drop/DraggableItem'
import { MdOutlineDragIndicator, MdOutlinePublishedWithChanges } from 'react-icons/md'
import { classNames } from '../../../../../../helpers/classNames'
import DropTarget from '../../../../../../components/drag-drop/DropTarget'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { StarIcon } from '@heroicons/react/solid'
import { ToolTipBottom, ToolTipHoverContainer } from '../../../../../../components/ToolTips'
import { FaTasks } from 'react-icons/fa'
import { DRAG_N_DROP_TYPES } from '../../../../../../constants'
const ChapterItem = ({ chapter, selectedChapterId, setSelectedChapterId, sections, queryKeys, tasksListQueryKey }) => {
    const { household_id: householdId } = useParams()
    const chaptersApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_CHAPTERS, API_AUTH_TYPES.ADVISOR);
    const tasksApi = new ApiRequest(API_ROUTES.CLIENT_TASKS, API_AUTH_TYPES.ADVISOR);
    const goalsApi = new ApiRequest(API_ROUTES.HOUSEHOLD_GOALS, API_AUTH_TYPES.ADVISOR)

    const queryClient = useQueryClient();
    const { mutate: updateChapterOrder } = useMutation(
        (body) => chaptersApi.patch({ endpoint: "update_chapter_order", body, params: [householdId] }),
        {
            onSuccess: (body) => {
                setTimeout(() => {
                    queryClient.invalidateQueries(sections.find(section => section.type === body.item_list_id).query_key);
                    queryClient.invalidateQueries(sections.find(section => section.type === body.dropped_over_list_id).query_key);
                }, 0.2 * 1000)


            },
        }

    );


    const handleChapterReorder = (item) => {
        const droppedChapterId = item.content.props.chapter.chapter_id
        updateChapterOrder({
            item_id: droppedChapterId,
            dropped_over_id: chapter.chapter_id,
            item_list_id: item.content.props.chapter.status,
            dropped_over_list_id: chapter.status,
            item_type: DRAG_N_DROP_TYPES.CHAPTER,
            household_id: householdId
        });
    }

    const { mutate: updateTaskChapter } = useMutation(
        (body) =>
            tasksApi.patch({
                endpoint: "update_task_chapter",
                body,
            }),
        {
            onSuccess: ({ old_chapter_status, new_chapter_status }) => {
                queryClient.invalidateQueries(queryKeys[old_chapter_status]
                );
                queryClient.invalidateQueries(queryKeys[new_chapter_status])
                queryClient.invalidateQueries(tasksListQueryKey);
            }
        }
    );
    const { mutate: updateGoalChapter } = useMutation(
        (body) => goalsApi.patch({
            endpoint: "add_to_chapter",
            body,
        }),
        {
            onSuccess: ({ old_chapter_status, new_chapter_status }) => {
                queryClient.invalidateQueries(queryKeys[old_chapter_status]
                );
                queryClient.invalidateQueries(queryKeys[new_chapter_status])
                queryClient.invalidateQueries(queryKeys.goals);
            }
        }
    );



    const handleDropItem = (item) => {
        if (item.type === 'goal') {

            updateGoalChapter({
                item_id: item.content.props.goal.goal_id,
                item_list_id: chapter.chapter_id,
                item_type: DRAG_N_DROP_TYPES.GOAL,
                household_id: householdId
            })
        }
        if (item.type === 'task') {
            updateTaskChapter({
                task_id: item.content.props.task.task_id,
                old_chapter_id: item.content.props.task.chapter_id,
                new_chapter_id: chapter.chapter_id,
                household_id: householdId
            })
        }

    }



    return (
        <>
            <DropTarget accept="chapter" onDrop={(item) => handleChapterReorder(item)} className={''} isActiveStyling={'pt-20 drop-target '} >
                <DropTarget
                    accept={['task', 'goal']}
                    onDrop={(item) => handleDropItem(item)} onIsOver={(item) => { setSelectedChapterId(chapter.chapter_id) }}
                    isActiveStyling={'p-1 bg-gray-300 rounded-lg animate-pulse'} >
                    <button
                        className={classNames(chapter.chapter_id === selectedChapterId ? 'bg-gray-100 border-l-4 border-l-secondary-500' : 'bg-white', ' border w-full text-left flex justify-between items-center hover:bg-gray-100 rounded-lg my-1 text-gray-700 py-2 px-2 text-base font-semibold ')}
                        onClick={() => { setSelectedChapterId(chapter.chapter_id) }} // Update selectedChapterId on click
                    >
                        <div className="flex justify-between w-full items-center space-x-1">

                            <div className="">
                                <p>{chapter.title} {" "}

                                </p>


                                <div className="text-sm text-gray-400 mt-1 w-max">
                                    <div className="flex items-center space-x-2">

                                        {chapter.task_count > 0 &&
                                            <ToolTipHoverContainer>
                                                <div className="flex items-center space-x-1 ">
                                                    <p className='text-blue-600'>
                                                        {chapter.task_count}
                                                    </p>
                                                    <FaTasks className='h-3.5  w-3.5 text-blue-500' />
                                                    <ToolTipBottom>Number of Visible Actionable Tasks in Chapter</ToolTipBottom>
                                                </div>
                                            </ToolTipHoverContainer>
                                        }

                                        {chapter.is_highlighted_task_count > 0 &&
                                            <ToolTipHoverContainer>
                                                <div className="flex items-center space-x-1">
                                                    <p className='text-yellow-600'>
                                                        {chapter.is_highlighted_task_count}
                                                    </p>
                                                    <StarIcon className='h-4 w-4 text-yellow-500' />

                                                </div>
                                                <ToolTipBottom>Number of Highlighted Tasks in Chapter</ToolTipBottom>
                                            </ToolTipHoverContainer>
                                        }
                                        {chapter.goal_count > 0 &&
                                            <ToolTipHoverContainer>
                                                <div className="flex items-center space-x-1">
                                                    <p className='text-gray-600'>
                                                        {chapter.goal_count}
                                                    </p>
                                                    <MdOutlinePublishedWithChanges className='h-4  w-4 text-gray-500' />

                                                </div>
                                                <ToolTipBottom>Number of Ongoing Strategies in Chapter</ToolTipBottom>
                                            </ToolTipHoverContainer>
                                        }

                                    </div>

                                </div>



                            </div>
                            <DraggableItem
                                item={{
                                    type: 'chapter',
                                    content: <ChapterItem chapter={chapter} type="chapter"
                                        selectedChapterId={selectedChapterId} setSelectedChapterId={setSelectedChapterId} />
                                }} type='chapter'>
                                <MdOutlineDragIndicator className="h-6 w-6 text-gray-500" />
                            </DraggableItem>
                        </div>

                    </button>
                </DropTarget>

            </DropTarget>
        </>)
}

export default ChapterItem