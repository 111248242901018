/* This example requires Tailwind CSS v2.0+ */
import { classNames } from './../misc/styling';
export default function BasicPanel({ children,bgColor="bg-white" }) {
  
  return (
    <div className={classNames(bgColor, " shadow-lg rounded-lg")}>
      <div className="py-5 px-7 sm:px-9 sm:py-7">{children}</div>
    </div>
  );
}
