import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { patchReq } from '../../../../../apis/internalApi';
import DoubleClickEdit from '../../../../../components/DoubleClickEdit'
import { API } from './../../../../../constants/index';
import { PencilIcon } from '@heroicons/react/outline';

const Subscription = ({ householdMeta }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [monthlySub, setMonthlySub] = useState(householdMeta.monthly_sub);
  const accessToken = useSelector(state => state.user.accessToken)
  const handleSubmitEdit = async () => {
try {
  const response = await patchReq(API.HOUSEHOLDS, "update_monthly_sub",accessToken,{monthly_sub:monthlySub,household_id:householdMeta.household_id});
} catch (error) {
  
}
  }
  return (
    <div className='flex justify-between space-x-2'>
          <p>Subscription</p>
      <DoubleClickEdit
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        handleSubmitEdit={handleSubmitEdit}
            defaultComponent={
              <div className="flex text-gray-800 items-center space-x-1  font-semibold">
                {" "}
                <p>
                  {" "}
                  {monthlySub
                    ? `$${monthlySub}`
                    : "N/A"}
                </p>      

              </div>
            }
            editComponent={
              <select className="input-primary" value={monthlySub} onChange={(e) => setMonthlySub(e.target.value)}>
                <option value="">Not Selected</option>
                <option value={139}>$139</option>
                <option value={89}>$89</option>
              </select>
            }
          />
        </div>  )
}

export default Subscription