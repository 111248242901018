import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../../api";

// Function to handle spreadsheet uploads
const handleSpreadsheetUpload = async (file) => {
    let apiRequest;
    let endpoint;
   
        apiRequest = new ApiRequest(
            API_ROUTES.PARABOT,
            API_AUTH_TYPES.ADVISOR
        );
        endpoint = "upload_file_parabot";

    try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await apiRequest.post({
            endpoint,
            body: formData,
        });
        return {
            name: file.name,
            id: response
        };
    } catch (error) {
        console.error("Error:", error);
        throw new Error("Upload failed");
    }
};

export default handleSpreadsheetUpload;