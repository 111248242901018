import React, { useEffect, useState } from 'react'
import BlankModal from '../../../../../components/modals/BlankModal'
import { BsCurrencyDollar } from 'react-icons/bs';
import { RiPercentLine } from 'react-icons/ri';
import ApiRequest from '../../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api/apiConstants'
import { useMutation, useQueryClient } from "react-query";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SmallLoadingSpinner from '../../../../../components/loading/SmallLoadingSpinner';

/**
 * Form component to create a new cashflow item. It provides inputs for name, amount, type, period, and location.
 * 
 * @param {Object} props - Component props.
 * @param {boolean} props.open - State controlling the visibility of the modal.
 * @param {Function} props.setOpen - Function to update the visibility state of the modal.
 * @param {boolean} props.isClientPortal - Indicates if the operation is performed in a client portal.
 * @returns {JSX.Element} Modal form for creating a new cashflow item.
 */
const NewItemForm = ({ open, setOpen, isClientPortal }) => {
  const { household_id: householdId } = useParams()
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState('');
  const [location, setLocation] = useState('');
  const [annualContribution, setAnnualContribution] = useState(0);
  const [transferType, setTransferType] = useState('');
  const authType = isClientPortal ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR
  const cashflowApi = new ApiRequest(API_ROUTES.CASHFLOW, authType);

  const queryClient = useQueryClient()
  const createNew = useMutation(
    () => cashflowApi.post({
      endpoint: 'create_new',
      body: { household_id: householdId, name, amount, type, period, location , annual_contribution: annualContribution, transfer_type: transferType }
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`cashflow-ids-${householdId}`)
        queryClient.invalidateQueries(`total-annual-contribution-${householdId}`)
        setOpen(false)
      }
    }
  )

  const handleCreateNew = (e) => {
    e.preventDefault()
    createNew.mutateAsync()
    setOpen(false)
    setName('')
    setType('')
    setAmount(0)
    setPeriod('')
    setLocation('')
    setAnnualContribution(0)
    setTransferType('')
  }
  useEffect(() => {
    if (type === 'Maxing Out') {
      setPeriod("Yearly")
    }
  }, [type])


  return (
    <div>
      <BlankModal
        open={open}
        setOpen={setOpen}
      >
        <h3 className="text-lg text-left mb-2">Create New Savings Item</h3>
        <form className="grid sm:grid-cols-2 gap-5 text-left " onSubmit={handleCreateNew}>
          <div className="">
            <label
              htmlFor="first_name"
              className="label-primary text-left">
              Name
            </label>
            <input type="text"
              id='name'
              className='input-primary'
              value={name}
              placeholder='Emergency Fund'
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          {/* location */}
          <div className="">
            <label
              htmlFor="location"
              className="label-primary text-left">
              Bank/Custodian
            </label>
            <input type="text"
              id='location'
              className='input-primary'
              value={location}
              placeholder='Ally Bank'
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          {/* type */}
          <div className="">
            <label
              htmlFor="type"
              className="label-primary text-left">
               Type
            </label>
            <select
              className='input-primary mr-2'
              value={type}
              id={'type'}
              onChange={(e) => setType(e.target.value)}
            >
              <option hidden value=''>Select Type</option>
              <option value='Maxing Out'>Maxing Out</option>
              <option value='Dollar Amount'>Dollar Amount</option>
              <option value='Percent'>Percent</option>

            </select>
          </div>
          <div>
            <label htmlFor="transfer-type" className="label-primary">Transfer Type</label>
            <select
              id='transfer-type'
              className='input-primary'
              value={transferType}
              onChange={(e) => setTransferType(e.target.value)}
            >
              <option hidden value=''>Select Transfer Type</option>
              <option value='Manual'>Manual</option>
              <option value='Automatic'>Automatic</option>
            </select>
          </div>

          {/* period */}
          <div className="">
            <label
              htmlFor="period"
              className="label-primary text-left">
              Frequency
            </label>
            {type === "Maxing Out" ?
              <p className='text-base flex-shrink-0 text-left'> Yearly</p> :
              <select
                value={period}
                id='period'
                onChange={(e) => setPeriod(e.target.value)}
                className='input-primary'>
                <option hidden value=''>Select Frequency</option>
                <option value='Weekly'>Weekly</option>
                <option value='Monthly'>Monthly</option>
                <option value='Quarterly'>Quarterly</option>
                <option value='Yearly'>Yearly</option>
              </select>}
          </div>
          

          {/* amount */}
          <div className="">
             <>
              <label
                htmlFor="amount"
                className="label-primary text-left">
                Amount
              </label>

              <div className="flex items-center">
                {type === 'Dollar Amount' && <BsCurrencyDollar className='h-5 w-5 mr-2 flex-shrink-0' />}
                {type === "Maxing Out" ?
                  <p className='text-base flex-shrink-0'>Maxing Out</p> :

                  <input type="number"
                    id='amount'
                    className='input-primary '
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />}
                {type === 'Percent' && <RiPercentLine className='h-5 w-5 ml-2 flex-shrink-0' />}
              </div></>
          </div>
          <div>
            <label htmlFor="annual_contribution" className="label-primary">Annual Amount</label>
            <div className="flex items-center">
              <BsCurrencyDollar className='h-5 w-5 mr-2' />
              <input
                type="number"
                id='annual_contribution'
                className='input-primary'
                value={annualContribution}
                placeholder='$50,000'
                onChange={(e) => setAnnualContribution(e.target.value)}
              />
            </div>
          </div>
       
          <div className='sm:col-span-2 flex justify-center'>
            <button
              className="btn-lg btn-primary"
              type="submit"
              disabled={createNew.isLoading}
            >
              {createNew.isLoading ? <SmallLoadingSpinner textColor='text-white' /> : 'Create'}
            </button>
          </div>
        </form>
      </BlankModal>
    </div>
  )
}

export default NewItemForm