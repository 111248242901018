import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { AlertError } from "../../../../components/alerts";
import { dateFormaterWithTime, dateFormatter } from "../../../../helpers/formatters";
import { postReq } from "./../../../../apis/internalApi";
import { API } from "./../../../../constants/index";

const AddTimer = ({ open, setOpen, householdId, pushTimer }) => {
  const accessToken = useSelector((state) => state.user.accessToken);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: "",
      title: '',
      //  date: new Date(),
      repeat: "",
    },
  });
  const onSubmit = async (data) => {
    try {
      const response = await postReq(
        API.TIMERS,
        "post_timer_auth_advisor",
        accessToken,
        { form: data, household_id: householdId }
        );
        pushTimer(response.data)
      reset();
      setOpen(!open);
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className="space-y-3  flex flex-col items-center border rounded-md p-2"
    >
      <input
        name="hidden"
        type="text"
        className="hidden "
      />

      {/* register your input into the hook by invoking the "register" function */}
      <div className="w-full">
        {" "}
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          name="reminder-name"
          placeholder="add a title..."
          className="mb-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          id="reminder-name"
          {...register("title", {
            required: "This is required",
          })}
        />{" "}
        {errors.title && <AlertError>{errors.title.message}</AlertError>}
      </div>
      <div className="w-full">
        {" "}
        <label
          htmlFor="timer-description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <textarea
          id="timerDescription"
          rows={5}
          placeholder="add a description..."
          className=" mb-1       shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          {...register("description")}
        />
        {errors.description && (
          <AlertError>{errors.description.message}</AlertError>
        )}
      </div>
      <div className="w-full">
        {" "}
        <label
          htmlFor="timer-date"
          className="block text-sm font-medium text-gray-700"
        >
          Due By
        </label>
        <input
          type="date"
          name="timer-date"
          className="mb-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          id="timer-date"
          {...register("timer", {
            required: "This is required",
            min: {
              message: "Cannot pick date in past",
              value: dateFormaterWithTime(new Date()),
            },
          })}
        />{" "}
        {errors.timer && <AlertError>{errors.timer.message}</AlertError>}
      </div>
      {/* <div className="w-full">
        {" "}
        <label
          htmlFor="timer-repeat"
          className="block text-sm font-medium text-gray-700"
        >
          Repeat?
        </label>
        <select
          id="timer-repeat"
          className="        shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          {...register("repeat")}
        >
          <option hidden value="">
            Please Select
          </option>
          <option>Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
          <option value="Semi-Annually">Semi-Annualy</option>
          <option value="Annually">Annually</option>
        </select>
      </div> */}
      <button
        className="rounded-lg border-2 shadow-md hover:shadow-lg hover:brightness-110 py-2 px-5 "
        type="submit"
      >
        Create Reminder
      </button>
      <button
        type="button"
        className="underline hover:text-gray-900 text-gray-500 "
        onClick={() => {
          reset();
          setOpen(!open);
        }}
      >
        Cancel
      </button>
    </form>
  );
};

export default AddTimer;
