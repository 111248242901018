import { Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { classNames } from '../../../misc/styling';
import ImageGallery from './ImageGallery';
import Markdown from 'markdown-to-jsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ClipboardCopy from '../../../copy-to-clipboard/ClipboardCopy';
import { ToolTipBottom, ToolTipHoverContainer, ToolTipTop } from '../../../ToolTips'
import { DownloadIcon } from '@heroicons/react/outline';
import axios from 'axios';

const Message = ({ item, index, openExpandedImage }) => {
    const textContent = item.content.find((content) => content.type === "text")?.text;
    const contentImages = item.content.map((content) => {
        if (content.type === "image_url") return{url: content.image_url.url, type:'image'};
        return null; 
    }).filter((url) => url !== null);
    const fileDownloads = item.content.map((content) => {
        if (content.type === "file_download") return { file_id: content.file_id, file_name: content.file_name, file_type: content.file_type };
        return null;
    }).filter((val) => val !== null);

   

    const [annotatedText, setAnnotatedText] = useState(textContent);
    const [annotationArray, setAnnotationArray] = useState([]);

    useEffect(() => {
        if (textContent) {
            const annotations = extractAnnotations(textContent);
            const { updatedText, newAnnotations } = processAnnotations(textContent, annotations);
            setAnnotatedText(updatedText);
            setAnnotationArray(newAnnotations);
        }
    }, [textContent]);

    const extractAnnotations = (text) => {
        const annotationRegex = /\[(\d+)\]\s*([^[]+)/g;
        const matches = [];
        let match;

        while ((match = annotationRegex.exec(text)) !== null) {
            matches.push({
                number: parseInt(match[1], 10),
                fileName: match[2].trim()
            });
        }

        return matches;
    };

    const processAnnotations = (text, annotations) => {
        let updatedText = text;
        const newAnnotations = [];

        annotations.forEach((annotation) => {
            const marker = `[${annotation.number}]`;
            const regex = new RegExp(`\\[${annotation.number}\\]\\s*([^[]+)?`, 'g');
            updatedText = updatedText.replace(regex, '');
            newAnnotations.push(annotation);
        });

        return { updatedText: updatedText.trim(), newAnnotations };
    };

  
const [downloading, setDownloading] = useState(false);
    const fileDownload = async (fileId, fileType) => {
        setDownloading(true);
        const accessToken = localStorage.getItem("access_token");
        const baseURL =
            process.env.NODE_ENV === "production" ? "/" : "http://localhost:5000/";
        const version = 'v1'
        const route = 'parabot'
        const endpoint = 'download_file'
        const authType = 'advisor'
        const paramsString = `/${fileId}`
        const callUrl = `${baseURL}api/${version}/${route}/${endpoint}_auth_${authType}${paramsString}`;
        try {
            // Make the API call to get the file content
            const response = await axios.get(callUrl, {
                responseType: 'arraybuffer', // Ensure the response is treated as binary data
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            // Determine the MIME type and extension based on the fileType
            let mimeType;
            let fileExtension;

            switch (fileType) {
                case 'pptx':
                    mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                    fileExtension = 'pptx';
                    break;
                case 'csv':
                    mimeType = 'text/csv';
                    fileExtension = 'csv';
                    break;
                case 'xlsx':
                    mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    fileExtension = 'xlsx';
                    break;
                case 'jpg':
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    fileExtension = 'jpg';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    fileExtension = 'png';
                    break;
                case 'gif':
                    mimeType = 'image/gif';
                    fileExtension = 'gif';
                    break;
                default:
                    throw new Error('Unsupported file type');
            }

            // Create a Blob from the file data
            const blob = new Blob([response.data], { type: mimeType });
            const url = URL.createObjectURL(blob);

            // Create an anchor element and trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileId}.${fileExtension}`; // Suggested default filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
        }
        setDownloading(false);
    };



    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            key={index}
        >
            <li
                key={index}
                className={classNames(
                    "flex flex-col",
                    item.role === "user" && "items-end",
                    item.role === "assistant" && "items-start"
                )}
            >
                <ImageGallery imageSize='h-16 w-16' files={contentImages} removable={false} openExpandedImage={openExpandedImage} />
                <div>
                    <div className="flex space-x-1 markdown-parabot">
                        <div
                            className={classNames(
                                item.role === "user"
                                    ? "bg-blue-600 text-white ml-20"
                                    : "bg-gray-200 text-gray-800",
                                "px-4 py-2 max-w-3xl custom-scrollbar-thick custom-scrollbar-gray overflow-x-auto markdown-chat shadow-md text-lg rounded-bl-3xl rounded-tr-3xl rounded-tl-xl rounded-br-xl font-[400] "
                            )}
                        >
                            {annotatedText ? (
                                <Markdown options={{ wrapper: "" }}>
                                    {annotatedText}
                                </Markdown>
                            ) : (
                               <>{item.role === 'assistant' && <AiOutlineLoading3Quarters className="h-6 w-6 text-gray-700 animate-spin mt-1" />}</> 
                            )}
                            {annotationArray.length > 0 && (
                                <div className="mt-2 text-sm text-gray-600">
                                    <div className="flex space-x-0.5 list-none">
                                        {annotationArray.map((annotation) => (
                                            <di key={annotation.number}>
                                                <ToolTipHoverContainer>
                                                    <p> [{annotation.number}]</p>
                                                    <ToolTipTop>

                                                        <p className="text-xs">{annotation.fileName}</p>
                                                    </ToolTipTop>
                                                </ToolTipHoverContainer>
                                            </di>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {
                            item.role === "assistant" && index !== 0 && (
                                <div className="flex flex-col justify-start items-center space-y-2 h-full mr-20 pl-3 pt-5">
                                    {
                                        fileDownloads?.length > 0 ? fileDownloads.map(({ file_id, file_type, file_name }) => (

                                            <div key={file_id}>
                                                <ToolTipHoverContainer>
                                                    <button onClick={() => fileDownload(file_id, file_type, file_name)}>
                                                        {downloading ? <AiOutlineLoading3Quarters className="h-6 w-6 text-gray-700 animate-spin" />
                                                            : <DownloadIcon className="h-6 w-6 text-gray-700 " />}

                                                    </button>
                                                    <ToolTipBottom>
                                                        <p className="text-xs">Securely Download File</p>
                                                    </ToolTipBottom>
                                                </ToolTipHoverContainer>
                                            </div>
                                        )) : 
                                            <ClipboardCopy
                                                text={item.content[0].text}
                                                textColor="text-gray-600"
                                                removeMarkdown={true}
                                            />
                                    }
                                </div>
                            )
                        }
                    </div>

                </div>
            </li>
        </Transition>
    );
};

export default Message;
