import { API_AUTH_TYPES, API_ROUTES } from "../../../api/apiConstants";
import ApiRequest from "../../../api/ApiRequest";

export function addHttp(url) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }
  return url;
}

const cloudinaryApi = new ApiRequest(
  API_ROUTES.CLOUDINARY,
  API_AUTH_TYPES.USER
);

export const imageUploaderFunction = {
  upload: async (file) => {
    try {
      let formData = new FormData();
      formData.append("image", file);
      const url = cloudinaryApi.post({
        endpoint: "upload_file",
        body: formData,
      });

      return url;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Upload failed");
    }
  },
};

export const quillEditorToolbar = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],

  ["link", "image"],
];

export const quillEditorFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",

  "indent",
  "link",
  "image",
];
