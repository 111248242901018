import React, { useEffect, useState } from 'react'
import socketIoConnection from '../../../../../../socketIoConnect';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SparklesIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api';
import useArray from "../../../../../../hooks/useArray"
import FadeIn from '../../../../../../components/transitions/FadeIn';
import ParabotLoading from '../../../../../../components/ParabotLoading';
import SuggestionsController from './SuggestionsController';

const ChapterSuggestions = ({ queryKeys }) => {
    const { household_id: householdId } = useParams()
    const [loading,setLoading] = useState(false)
    const [socket, setSocket] = useState(null);
    const [suggestionsHidden, setSuggestionsHidden] = useState(false);
    const suggestions = useArray([])
    useEffect(() => {
        const socket = socketIoConnection("story-generator");
        setSocket(socket);
        socket.on("completion-done", (newSuggestions) => {
            suggestions.set(newSuggestions);
            setLoading(false)
        });


        return () => {
            socket.off("completion-done");
            socket.disconnect();
        };
    }, []);

    
    const createSuggestions = () => {
        setLoading(true)
        socket.emit("chapter-suggestions", {

            household_id: householdId,
        });
        setSuggestionsHidden(false)
    }


    const chaptersApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_CHAPTERS, API_AUTH_TYPES.ADVISOR);
    const queryClient = useQueryClient();
    const { mutate: createChapter } = useMutation(
        ({ title }) => chaptersApi.post({ endpoint: "create", body: { household_id: householdId, title } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.unpublished);
            },
        }

    );
    return (
        <div>
            <SuggestionsController loading={loading}
                setLoading={setLoading} hidden={suggestionsHidden} setHidden={setSuggestionsHidden} suggestionsCreated={suggestions.array.length > 0}
                createSuggestions={createSuggestions} />
            <FadeIn isShowing={!loading && !suggestionsHidden}>
            <ul className='space-y-2'>
                {suggestions.array.map((suggestion, index) => {
                    return <li key={suggestion.title}>
                            <button className='btn-sm btn-gray-outline-rounded w-full text-left text-gray-500'
                            onClick={() => {
                                createChapter({ title: suggestion.title });
                                suggestions.remove(index)
                            }}
                        >
                            <SparklesIcon className='h-5 w-5 flex-shrink-0 text-blue-500 mr-3' />
                            {suggestion.title}
                        </button>
                    </li>
                })}
                </ul>
                </FadeIn>
        </div>
    )
}

export default ChapterSuggestions