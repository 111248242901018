import React from "react";
import BasicPanel from "../../../../components/panels/BasicPanel";
import EmailList from "./EmailList";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  DocumentIcon,
  InboxIcon,
  PaperAirplaneIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { classNames } from "../../../../helpers/classNames";

import {
  dateFormaterWithTime,
} from "../../../../helpers/formatters";
import { SearchIcon } from "@heroicons/react/solid";
import { AlertNeutral } from "../../../../components/alerts";
import { useMemo } from "react";
import { ApiRequest, API_AUTH_TYPES, API_ROUTES } from "../../../../api";
import { useQuery } from "react-query";
import { useParams } from 'react-router-dom';


const HHEmails = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [emailLabel, setEmailLabel] = useState("NONE");
  const [query, setQuery] = useState("");
  const [selectedEmail, setSelectedEmail] = useState(false);

  const [noEmailsFound, setNoEmailsFound] = useState(false);
  const navigation = [
    {
      name: "All",
      label_id: "none",
      href: "#",
      icon: InboxIcon,
      current: emailLabel === "NONE",
    },

    //TODO: add these back in when possible
    // {
    //   name: "Inbox",
    //   label_id: "INBOX",
    //   href: "#",
    //   icon: InboxIcon,
    //   current: emailLabel === "INBOX",
    // },
    // {
    //   name: "Sent",
    //   label_id: "SENT",
    //   href: "#",
    //   icon: PaperAirplaneIcon,

    //   current: emailLabel === "SENT",
    // },
    // {
    //   name: "Drafts",  
    //   href: "#",
    //   icon: DocumentIcon,
    //   current: emailLabel === "DRAFT",
    // },
    // {
    //   name: "Trash",
    //   label_id: "TRASH",
    //   href: "#",
    //   icon: TrashIcon,
    //   current: emailLabel === "TRASH",
    // },
  ];
  const { household_id } = useParams();
  const emailThreadsApi = new ApiRequest(API_ROUTES.EMAIL_THREADS, API_AUTH_TYPES.ADVISOR);
  const {
    data: threads,
    isLoading,
    isError
  } = useQuery({
    queryFn: emailThreadsApi.getFn({
      endpoint: "index_household_threads", params: [household_id]

    }),
    queryKey: "household_emails",
  });
  const filterdEmails = useMemo(() => query === ""
    ? threads
    : threads.filter((thread) => {
      return (
        thread.messages[0].subject.toLowerCase().includes(query.toLowerCase()) ||
        thread.messages[0].snippet.toLowerCase().includes(query.toLowerCase()) ||
        thread.messages[0].plain_text.toLowerCase().includes(query.toLowerCase()) ||
        dateFormaterWithTime(new Date(thread.messages[0].date)).includes(
          query.toLowerCase()
        )
      );
    }), [query, threads])


  return (
    <BasicPanel>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                      alt="Workflow"
                    />
                  </div> */}
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group rounded-md py-2 px-2 flex items-center text-base font-medium"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="flex ">
          <div className="h-full md:inset-y-0 md:flex md:w-64 md:flex-col  max-h-screen">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200  pt-5 h-screen ">
              <div className="flex flex-shrink-0 items-center px-4">
              </div>
              <div className="mt-5 flex flex-grow flex-col">
                <nav className="flex-1 space-y-1 px-2 pb-4">
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group rounded-md py-2 px-2 flex items-center text-sm font-medium w-full"
                      )}
                      onClick={() => {
                        setEmailLabel(item.label_id.toUpperCase());
                      }}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="mx-auto flex max-w-4xl flex-col md:px-8 xl:px-0">
              <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
                <button
                  type="button"
                  className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex flex-1 justify-between px-4 md:px-0">
                  <div className="flex flex-1">
                    <form
                      className="flex w-full md:ml-0"
                      action="#"
                      method="GET"
                    >
                      <label htmlFor="search-field" className="sr-only">
                        Search in mail
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600 pl-1">
                        <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                          onChange={(e) => {
                            setQuery(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  {/* Top menu buttons */}
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* <button
                    type="button"
                    className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                    {/* end top menu buttons */}
                  </div>
                </div>
              </div>

              <main className="flex-1">
                <div className="py-6">
                  <div className="px-4 sm:px-6 md:px-0"></div>
                  <div className="px-4 sm:px-6 md:px-0">
                    {/* Replace with your content */}
                    {!isLoading ? (
                      !noEmailsFound ? (
                        <EmailList
                          threads={filterdEmails}
                          loading={isLoading}
                          label={emailLabel.toUpperCase()}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <AlertNeutral>No Emails Found</AlertNeutral>
                        </div>
                      )
                    ) : (
                      <div className="flex justify-center">
                        <AlertNeutral>Loading...</AlertNeutral>
                      </div>
                    )}
                    {/* /End replace */}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </BasicPanel>
  );
};

export default HHEmails;
