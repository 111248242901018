import React, { Fragment, useRef } from 'react'
import WideBlankModal from '../../../../../../components/modals/WideBlankModal'
import { Dialog, Transition } from '@headlessui/react';

const MessageContainer = ({ isMaximized, setIsMaximized, children }) => {
    const cancelButtonRef = useRef(null);

    return (
        <>
            {isMaximized ? (

                <Transition.Root show={isMaximized} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50"
                        initialFocus={cancelButtonRef}
                        onClose={setIsMaximized}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-450"
                                    enterFrom="opacity-0 translate-y-4 sm:-translate-y-5 sm:scale-80"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:-translate-y-5 sm:scale-80"
                                >
                                    <div className="relative">

                                        <Dialog.Panel className=" overflow-visible custom-scrollbar-thick custom-scrollbar-gray bg-white rounded-lg  text-left  shadow-xl transform transition-all sm:my-8 sm:min-w-[600px] sm:w-full ">
                                            <div className=" w-[80vw]">
                                                {children}
                                            </div>
                                        </Dialog.Panel>
                                    </div>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

            ) : (
                <div className='shadow-xl w-[600px]'>
                    {children}
                </div>

            )}
        </>
    )
}

export default MessageContainer