import { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { patchReq } from "../../../apis/internalApi";
import useOnClickOutside from "../../../hooks/useClickOutside";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


export default function ScratchPad() {
  const { household_id: householdId } = useParams();
  const [nextThing, setNextThing] = useState('')
  const householdApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR)
  const { data, isLoading, isError } = useQuery({ queryFn: householdApi.getFn({ endpoint: 'next_thing', params: [householdId] }), queryKey: `next-thing-${householdId}` })

  //hooks
  const ref = useRef();
  //state

  const queryClient = useQueryClient()
  const { mutate } = useMutation((data) => householdApi.patch({ endpoint: 'update_next_thing', body: { household_id: householdId, next_thing: data.next_thing } }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(`next-thing-${householdId}`)
    }
  })
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  //handling next thing changes
  const submitChanges = async () => {
    if (nextThing === data?.next_thing) return;
    mutate({ next_thing: nextThing })
  };

  useEffect(() => {
    setNextThing(data?.next_thing)
  }, [data?.next_thing, isLoading])


  useOnClickOutside(ref, () => submitChanges());
  if (isLoading) return <div>loading</div>
  return (
    <div className=" ">

      {" "}
      <textarea
        ref={ref}
        type="text"
        name="nextThing"
        rows={5}
        id="nextThing"
        value={nextThing}
        onChange={(e) => setNextThing(e.target.value)}
        className="input-primary  custom-scrollbar-thick custom-scrollbar-gray"
        placeholder="Write anything to the household scratch pad here..."
      />

    </div>
  );
}