import React from "react";
import {
  dateFormaterWithTime,
  dateFormatter,
} from "./../../../../helpers/formatters";
import WideBlankModal from "./../../../../components/modals/WideBlankModal";

const ReminderEdits = ({ edits, open, setOpen }) => {
  return (
    <WideBlankModal open={open} setOpen={setOpen} title="Edit History">
      <ul className=" divide-y-2 space-y-10 max-h-[300px] sm:max-h-[500px] overflow-y-auto px-4">
        {edits.map((edit) => (
          <li
            key={edit.edited_at}
            className="py-3 grid grid-cols-2 gap-x-2  gap-y-3 sm:grid-cols-6 place-items-start"
          >
            <p>Edited at:</p>
            <p className="sm:col-span-5">
              {" "}
              {dateFormaterWithTime(new Date(edit.edited_at))}
            </p>
            <p>Edited by: </p>
            <p className="sm:col-span-5">{edit.edited_by.name}</p>
            <p> Title:</p>
            <p className="sm:col-span-5">{edit.title}</p>
            <p> Description:</p>
            <p className="sm:col-span-5">{edit.description}</p>
            <p> Date:</p>
            <p className="sm:col-span-5">
              {dateFormatter.format(new Date(edit.date))}
            </p>
          </li>
        ))}
      </ul>
    </WideBlankModal>
  );
};

export default ReminderEdits;
