import React from 'react'
import HHStats from './HHStats'
import DashboardPanel from '../../../../components/panels/DashboardPanel'
import Source from './Source'

/**
 * The Dashboard component is the central hub for displaying various widgets and sections
 * related to a household's financial overview. It organizes content into a main area and an aside,
 * showcasing details like the household's financial why, activity, contacts, and more.
 * 
 * @param {Object} props - Component props.
 * @param {Object} props.householdMeta - Meta information about the household.
 * @param {Array} props.contactMeta - Metadata about the contacts related to the household.
 * @returns {JSX.Element} The dashboard layout with various panels containing household-related information and tools.
 */
const HHMetrics = ({householdMeta}) => {
    return (
        <div className="w-full md:pt-7  md:px-7">
            <div className="flex flex-col lg:flex-row lg:space-x-12 ">
                <main className="w-full space-y-6">
                    <DashboardPanel title="Household Information">
                        <HHStats />
                    </DashboardPanel>
                </main>

                <aside className="flex-shrink-0 w-full space-y-6 lg:w-[350px]  xl:w-[400px] mt-6 lg:mt-0">

                    <DashboardPanel title='Lead Source & Referrals'>
                        <Source householdMeta={householdMeta} />
                    </DashboardPanel>







                </aside>
            </div>
        </div>)
}

export default HHMetrics
