import React from 'react'
import ApiRequest from '../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../api/apiConstants'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const HHValues = () => {
  const householdApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR)
  const { household_id: householdId } = useParams()
  const { data, isLoading, isError } = useQuery({ queryFn: householdApi.getFn({ endpoint: 'index_values', params: [householdId] }), queryKey: 'values' });
  if (isLoading) return <p>Loading...</p>;
  return (
    <div className='w-full  px-3'>
      <p className='text-lg font-semibold mb-2'>Values</p>
      <ul>
        {data?.users?.map((user) => (
          <li key={user.name} className='flex justify-between items-start space-x-2'>
            <p>{user.name}</p>{user.values.length > 0 ?
            <ul> { user.values.map((value) => (
              <li key={value}>{value}</li>
            ))}</ul> : <p>N/A</p>}

          </li>
        ))}
      </ul>
    </div>
  )
}

export default HHValues