import { TrashIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";

const directionOptions = {
  single_select: ["First -> Last", "Last -> First"],
  multi_select: ["First -> Last", "Last -> First"],
  checkbox: ["Checked -> Empty", "Empty -> Checked"],
  household_link: ["A -> Z", "Z -> A"],
  link: ["A -> Z", "Z -> A"],
  claim_household: ["A -> Z", "Z -> A"],
  long_text: ["A -> Z", "Z -> A"],
  short_text: ["A -> Z", "Z -> A"],
  date: ["1 -> 9", "9 -> 1"],
  due_date: ["1 -> 9", "9 -> 1"],
  number: ["1 -> 9", "9 -> 1"],
  advisor: ["A -> Z", "Z -> A"],
};

const SmartTableSortsRow = ({
  sorts,
  columns,
  sort,
  thisSortIndex,
}) => {
  const colType = columns.find((col) => col?.col_id === sort?.col_id)?.col_type || 'removed';

  const handleUpdateRow = async ({new_value:newValue, key}) => {
    const data = {
      col_id: key === "col_id" ? newValue : sort.col_id,
      direction: key === "direction" ? newValue : sort.direction,
    };
   sorts.update(thisSortIndex, data);
  };
  const handleRemoveRow = async () => {
    try {
    sorts.remove(thisSortIndex)
    } catch (error) {}
  };

  useEffect(() => {
    if (colType === 'removed')
      handleRemoveRow()
  },[])


  const nonSortableColumns = ["button", "copy_to_clipboard"];
if (colType === 'removed') return null
  return (
    <div className=" flex items-center">
      {" "}
      <div className="flex items-center border text-gray-700 border-gray-200 divide-x divide-gray-200 rounded px-2 w-[500px]">
        <select
          name="sort_column_select"
          id="sort_column_select"
          value={sort.col_id}
          onChange={(e) => {
            handleUpdateRow({new_value:e.target.value, key:"col_id"});
          }}
          className=" border-0 focus:ring-0"
        >
          {columns.map(
            (column) =>
              !nonSortableColumns.includes(column.col_type) && (
                <option value={column.col_id} key={column.col_id}>
                  {column.title}
                </option>
              )
          )}
        </select>
        <select
          name="direction_options"
          id="direction_options"
          value={sort.direction}
          onChange={(e) => {
            handleUpdateRow({new_value:e.target.value, key:"direction"});
          }}
          className=" border-0 focus:ring-0 w-42"
        >
          <option value="asc">{directionOptions[colType][0]}</option>
          <option value="desc">{directionOptions[colType][1]}</option>
        </select>

        <button onClick={handleRemoveRow} className="pl-2 h-10 w-10">
          <TrashIcon className="w-5" />
        </button>
      </div>
    </div>
  );
};

export default SmartTableSortsRow;
