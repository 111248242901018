import { XIcon } from '@heroicons/react/outline'
import React from 'react'

const MessageSent = ({ undoFunction = () => { } ,closeFunction=() => {}}) => {
  return (
    <div className=' bg-gray-800 p-3 rounded-lg shadow-lg z-50 text-white flex items-center space-x-6 fixed bottom-10 left-60'>
      <p className=''>Message sent</p>
      <button onClick={undoFunction}
      className='bg-gray-800 text-secondary-300'
      >Undo</button>
      <button className=''
      onClick={closeFunction}
      >
        <XIcon className='h-5 w-5' />
      </button>
    </div>
  )
}

export default MessageSent