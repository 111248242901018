import edjsHTML from "editorjs-html";

// Custom Parsers
const simpleImageParser = (block) => {
  return `  <img className="mx-auto" src={${block.data.url}}/>`;
};
const rawParser = (block) => {
  return block.data.html;
};


//Parser
const edjsParser = edjsHTML({ simpleImage: simpleImageParser, raw: rawParser });

export default edjsParser;
