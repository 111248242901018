import React from "react";
import ClipboardCopy from "../../../copy-to-clipboard/ClipboardCopy";

const CopyColumn = ({ textToCopy }) => {
  //handling next thing changes

  return (
    <div className="smart-table-cell flex justify-center">
      <ClipboardCopy text={textToCopy} />
    </div>
  );
};

export default CopyColumn;
