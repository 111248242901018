import Task from "./../task-components/Task";
import { useState } from "react";
import FadeIn from "../../../../../../components/transitions/FadeIn";
import useOnClickOutside from "../../../../../../hooks/useClickOutside";
import { useRef } from "react";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../api/apiConstants";
import { useMutation, useQueryClient } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ApiRequest from "../../../../../../api/ApiRequest";
import ChapterTitle from "./ChapterTitle";
import { TrashIcon, TemplateIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/solid";
import DropTarget from "../../../../../../components/drag-drop/DropTarget";
import TaskTemplates from "./TaskTemplates";
import ChapterGoal from "./ChapterGoal";
import ChapterContext from "./ChapterContext";
import { DRAG_N_DROP_TYPES, STATUS_OPTIONS } from "../../../../../../constants";
import OngoingAdviceSuggestions from "../StorySuggestions/OngoingAdviceSuggestions";
import ItemMenu from "../../../../../../components/menus/ItemMenu";
import TaskList from './TaskList'
import SmallLoadingSpinner from "../../../../../../components/loading/SmallLoadingSpinner";


export default function Chapter({
  chapter,
  tasks,
  tasksListQueryKey,
  chaptersListQueryKey,
  isMeetingMode = false,
  queryKeys,
  setSelectedChapterId
  , goals
}) {
  const ref = useRef();
  const [showTemplates, setShowTemplates] = useState(false);
  const [templateType, setTemplateType] = useState(null)
  useOnClickOutside(ref, () => {
    setShowDelete(false);
  });
  const [showDelete, setShowDelete] = useState(false);
  const chaptersApi = new ApiRequest(
    API_ROUTES.FINANCIAL_STORY_CHAPTERS,
    API_AUTH_TYPES.ADVISOR
  );
  const chapterTemplatesApi = new ApiRequest(
    API_ROUTES.CHAPTER_TEMPLATES,
    API_AUTH_TYPES.ADVISOR
  );
  const { household_id: householdId } = useParams()

  const queryClient = useQueryClient();

  const { mutate: mutateHandleArchive, isLoading: isArchiving } = useMutation(
    () =>
      chaptersApi.patch({
        endpoint: "archive_chapter",
        body: { id: chapter.chapter_id },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(chaptersListQueryKey);
        setSelectedChapterId(null)
      }

    }
  );

    const [showPostTemplateSuccess, setShowPostTemplateSuccess] = useState(false);
  const { mutate: postTemplate, isLoading: isLoadingPostTemplate } = useMutation(
    () =>
      chapterTemplatesApi.post({
        endpoint: "new",
        body: {
          title: chapter.title,
          context: chapter.content,
          tasks: tasks.filter(task => task.chapter_id === chapter.chapter_id && task.status !== STATUS_OPTIONS.TASKS.COMPLETED),
          ongoing_advice: goals.data.filter(goal => goal.chapter_id === chapter.chapter_id)

        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('chapter-templates');
        setShowPostTemplateSuccess(true)
        setTimeout(() => {
          setShowPostTemplateSuccess(false)
        }, 2000);
      }
    }
  )

  const menuOptions = [

    {
      title: "Create Template",
      action: () => postTemplate(),
      icon: TemplateIcon,
    }, {
      title: "Delete",
      action: () => setShowDelete(true),
      icon: TrashIcon,
    },


  ];

  return (
    <div key={chapter.chapter_id} className="">
      <TaskTemplates open={showTemplates} setOpen={setShowTemplates} chapterId={chapter.chapter_id} queryKeys={queryKeys} type={templateType}
        setType={setTemplateType} />
      <div className="">
        <div className="flex items-center justify-between w-full my-2 ">
          {" "}

          <ChapterTitle
            defaultValue={chapter.title}
            chapter={chapter}
            chaptersListQueryKey={chaptersListQueryKey}
            isMeetingMode={isMeetingMode} />


          <div className="ml-2 flex space-x-2 relative">
            {" "}
          {showPostTemplateSuccess && <p className="text-green-500"><CheckCircleIcon className='h-5 w-5'/></p>}
            <ItemMenu options={menuOptions} />

            <div
              ref={ref}
              className="absolute right-7 bottom-1/2 translate-y-1/2"
            >


              <FadeIn isShowing={showDelete}>
                <button
                  disabled={isArchiving}
                  className="btn-lg btn-danger min-w-max "
                  onClick={mutateHandleArchive}
                >
                  {isArchiving ? (
                    <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin" />
                  ) : (
                    <p>Really Delete?</p>
                  )}
                </button>
              </FadeIn>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ChapterContext chapter={chapter} chaptersListQueryKey={chaptersListQueryKey} />
        </div>
      </div>{" "}
      <div className="mt-5 ">
        <TaskList
          tasks={tasks}
          chapter={chapter}
          tasksListQueryKey={tasksListQueryKey}
          chaptersListQueryKey={chaptersListQueryKey}
          isMeetingMode={isMeetingMode}
          queryKeys={queryKeys}
          householdId={householdId}
          showTemplates={showTemplates}
          setShowTemplates={setShowTemplates}
          setTemplateType={setTemplateType}

        />
        <div className=""></div>
        <OngoingAdviceComponent
          goals={goals?.data}
          chapter={chapter}
          queryKeys={queryKeys}
          householdId={householdId}
          showTemplates={showTemplates}
          setShowTemplates={setShowTemplates}
          isMeetingMode={isMeetingMode}
          setTemplateType={setTemplateType}
        />
       
      </div>

    </div>
  );
}






function OngoingAdviceComponent({ goals, chapter, queryKeys, householdId, showTemplates, setShowTemplates, isMeetingMode, setTemplateType }) {
  const goalsApi = new ApiRequest(
    API_ROUTES.HOUSEHOLD_GOALS,
    API_AUTH_TYPES.ADVISOR
  );
  const queryClient = useQueryClient()
  const { mutate: createGoal, isLoading:isCreatingGoal } = useMutation(
    () => goalsApi.post({ endpoint: "post_goal", body: { chapter_id: chapter.chapter_id, household_id: householdId, content: {ops: [{insert:'New Ongoing Strategy'}]} } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.goals);
        queryClient.invalidateQueries(queryKeys.active);
        queryClient.invalidateQueries(queryKeys.future);
        queryClient.invalidateQueries(queryKeys.completed);
        queryClient.invalidateQueries(queryKeys.unpublished);
      },
    }

  );

  const { mutate: handleReorderToEnd } = useMutation(
    (item) => {
      goalsApi.patch({
        endpoint: "reorder_to_end", body: {
          item_list_id: chapter.chapter_id, item_id: item.content.props.goal?.goal_id, item_type: DRAG_N_DROP_TYPES.GOAL, dropped_over_list_id: chapter.chapter_id,
          household_id: householdId
        }
      })
    },
    {
      onSuccess: () => {
        setTimeout(() => {

          queryClient.invalidateQueries(queryKeys.goals);
          queryClient.invalidateQueries(queryKeys.active);
          queryClient.invalidateQueries(queryKeys.future);
          queryClient.invalidateQueries(queryKeys.completed);
          queryClient.invalidateQueries(queryKeys.unpublished);
        }
          , 250)
      },
    }

  );
  return (
    < >
      <div className="flex justify-between items-center my-5 border-b pb-3">

        <h5 className="text-lg sm:text-2xl  ">Ongoing Strategy</h5>

        <div className="flex space-x-5 items-center  ">

          <button className="flex items-center btn-md btn-gray-outline-rounded"
            onClick={createGoal}>{isCreatingGoal ? <SmallLoadingSpinner  /> :<PlusIcon className=" h-5 w-5 text-blue-500" />  } <p className="ml-3 text-lg">Create New</p>
          </button>

          {!isMeetingMode && <button className="flex items-center btn-md btn-gray-outline-rounded"
            onClick={() => { setTemplateType('goal'); }}><PlusIcon className="h-5 w-5 text-blue-500 mr-3" />   <p className=" text-xl">Create From Template</p>
          </button>}
        </div>
      </div>

      <ul className=" space-y-2">
        {goals && goals.map(
          (goal) =>
            goal.chapter_id === chapter.chapter_id && (
              <li key={goal.goal_id}>
                {" "}
                <ChapterGoal
                  goal={goal}
                  queryKeys={queryKeys}
                  isMeetingMode={isMeetingMode}
                />
              </li>
            )
        )}
      </ul>
      <div className="mt-5"></div>
      <DropTarget accept={'goal'} onDrop={(item) => { handleReorderToEnd(item) }} isActiveStyling={'py-10 drop-target'}>
        <div className="h-10">  </div>
      </DropTarget>

      {!isMeetingMode && <OngoingAdviceSuggestions chapterId={chapter?.chapter_id} queryKeys={queryKeys} />}
    </>
  )
}


