import { Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { classNames } from '../../../../helpers/classNames'
import ApiRequest from '../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../api/apiConstants'
import { useQuery } from 'react-query';
import { useParams } from "react-router-dom";
import { AnnotationIcon, CheckCircleIcon, ClockIcon, FolderAddIcon, MailIcon, PencilAltIcon, PresentationChartBarIcon, SearchCircleIcon, SearchIcon, StarIcon, XCircleIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { FaTasks } from 'react-icons/fa'
import Note from './Note'
import Task from './Task'
import OngoingAdvice from './OngoingAdvice'
import { MdOutlinePublishedWithChanges } from 'react-icons/md'
import Meeting from './Meeting'
import Reminder from './Reminder'
import Activity from './Activity'
import SmallLoadingSpinner from '../../../../components/loading/SmallLoadingSpinner'
import useDebounce from '../../../../hooks/useDebounce'
import Email from './Email'
import useKeyboardShortcut from '../../../../hooks/useKeyShortcut'
import { ToolTipBottom, ToolTipHoverContainer } from '../../../../components/ToolTips'



const searchApi = new ApiRequest(API_ROUTES.HOUSEHOLD_SEARCH, API_AUTH_TYPES.ADVISOR);

const icons = {
    "Task": FaTasks,
    'Ongoing Strategy': MdOutlinePublishedWithChanges,
    "Note": PencilAltIcon,
    "Activity": StarIcon,
    "Reminder": AnnotationIcon,
    "Meeting": PresentationChartBarIcon,
    "Email": MailIcon

}

export default function HHSearch() {
    const { household_id } = useParams()
    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 300);

    const { data, isLoading } = useQuery({ queryFn: searchApi.getFn({ endpoint: `get_search_results`, params: [household_id, debouncedQuery] }), queryKey: [`hh-search`, household_id, debouncedQuery], });
    const results = data?.results || [];
    const numberOfResults = data?.number_of_results
    const [open, setOpen] = useState(false);
    useKeyboardShortcut(() => setOpen(true), "KeyF",true,true,false);


    return (
        <>
            <div className='my-auto'>
            <ToolTipHoverContainer>
 
            <button className='btn-md btn-gray-outline  flex items-center  space-x-1' onClick={() => setOpen(true)}>
                <SearchCircleIcon className='h-[18px] w-[18px] mt-0.5 ' />
                <p>Search Household </p>
                </button>
                <ToolTipBottom>CTRL + Shift + F</ToolTipBottom>

                </ToolTipHoverContainer>
            </div>
            <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="mx-auto max-w-5xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                                <Combobox >
                                    <div className="relative">
                                        <SearchIcon
                                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                            placeholder="Search All Household Documents..."
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                        {isLoading &&
                                            <div className="pointer-events-none absolute right-4 top-3.5 h-5 w-5 text-gray-400"
                                            >

                                                <SmallLoadingSpinner
                                                    aria-hidden="true"
                                                />
                                            </div>}
                                    </div>

                                    {(!isLoading  && (query === '' || numberOfResults > 0)) && (
                                        <Combobox.Options static className="max-h-[600px] scroll-py-2 divide-y divide-gray-100 overflow-y-auto">
                                            <li className="p-2">

                                                <ul className="text-sm text-gray-700 divide-y">
                                                    {results?.map((item) => {
                                                        const ItemIcon = icons[item.data_type]
                                                        return (
                                                            <Combobox.Option
                                                                key={item.id}
                                                                value={item}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                                                        active && 'bg-blue-50 '
                                                                    )
                                                                }
                                                            >
                                                                {({ active }) => (
                                                                    <div className='w-full'>
                                                                        <div className="flex mb-2">
                                                                            <ItemIcon
                                                                                className={classNames('h-4 w-4 mr-2 mb-auto flex-none', active ? 'text-gray-500' : 'text-gray-400')}
                                                                                aria-hidden="true"
                                                                            />
                                                                            <p className="text-xs text-gray-500">{item.data_type}</p>
                                                                            {item.data_type !== "Email Thread" &&
                                                                                <p className="text-xs text-gray-500 ml-auto">
                                                                                  {new Date(item.created_at || item.date).toLocaleDateString()}
                                                                                </p>
                                                                            }
                                                                        </div>

                                                                        {/* Render based on the item type */}
                                                                        {item.data_type === 'Note' && <Note query={debouncedQuery} note={item} />}
                                                                        {item.data_type === 'Task' && <Task query={debouncedQuery} task={item} />}
                                                                        {item.data_type === 'Ongoing Strategy' && <OngoingAdvice query={debouncedQuery} item={item} />}
                                                                        {item.data_type === "Meeting" && <Meeting query={debouncedQuery} meeting={item} />}
                                                                        {item.data_type === 'Reminder' && <Reminder query={debouncedQuery} reminder={item} />}
                                                                        {item.data_type === "Activity" && <Activity query={debouncedQuery} item={item} />}
                                                                        {item.data_type === 'Email' && <Email query={debouncedQuery} email={item} />}
                                                                        {/* ... other types */}
                                                                    </div>
                                                                )}
                                                            </Combobox.Option>
                                                        )
                                                    })}
                                                </ul>
                                            </li>

                                        </Combobox.Options>
                                    )}

                                    {!isLoading && query !== '' && numberOfResults === 0 && (
                                        <div className="px-6 py-14 text-center sm:px-14">
                                            <XCircleIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                            <p className="mt-4 text-sm text-gray-900">
                                                We couldn't find any items with that term. Please try again.
                                            </p>
                                        </div>
                                    )}
                                </Combobox>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root></>
    )
}
