import { useState, useEffect } from "react";
import { useHistory } from "react-router";

const useNav = () => {
  const [nav, setNav] = useState(null); // State to store the navigation path
  const history = useHistory(); // Access the browser's history object

  useEffect(() => {
    if (nav) {
      // Trigger navigation only when 'nav' has a value
      history.push(nav); // Navigate to the specified path using history.push
    }
    setNav(null); // Reset 'nav' to null after navigation
  }, [history, nav]); // Trigger the effect when 'history' or 'nav' changes

  return setNav; // Return a function to set the navigation path
};

export default useNav;
