import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import {
  dateFormaterWithTime,
  dateFormatter,
  timeToDate,
  toDays,
} from "../../../../helpers/formatters";
import { classNames } from "./../../../misc/styling";

const DueDate = ({ dueDate = { date: "", title: "" }, showTime = false }) => {
  const dateColor = (date) => {
    const now = new Date();
    const difference = new Date(date) - now;
    const days = toDays(difference);
    if (difference < 0) return "text-red-500";
    if (days > 5) return "hidden";
    return "text-yellow-500";
  };

  return (
    <div className="smart-table-cell">
      {" "}
      {dueDate ? (
        <>
          <div className="flex">
            {" "}
            <ExclamationIcon
              className={classNames(dateColor(dueDate.date), "h-5 mr-1")}
            />{" "}
            <p className="text-gray-700 text-xs">
              {" "}
              {showTime
                ? dateFormaterWithTime(new Date(dueDate.date))
                : dateFormatter.format(new Date(dueDate.date))}
            </p>
          </div>{" "}
          <p className="text-sm w-[180px] truncate">{dueDate.title}</p>
        </>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default DueDate;
