import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { classNames } from "../../../../../helpers/classNames";
import { BiHide } from "react-icons/bi";
import HiddenColumnsRow from './HiddenColumns___Row';
import ButtonDropdown from "../../../../ButtonDropdown";
import useArray from "../../../../../hooks/useArray";
import areArraysIdentical from "../../../../../helpers/arrayArraysIdentical";
import { useMutation, useQueryClient } from "react-query";
import { ApiRequest, API_AUTH_TYPES, API_ROUTES } from "../../../../../api";
const SmartTableHiddenFields = ({ columns, dbHiddenColumns, viewId }) => {
  const hiddenColumns = useArray(dbHiddenColumns || [])
  const [open, setOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);


  useEffect(() => {
    hiddenColumns.set(dbHiddenColumns);
  }, [dbHiddenColumns]);

  useEffect(() => {
    setChangesMade(!areArraysIdentical(hiddenColumns.array, dbHiddenColumns));
  }, [hiddenColumns.array, dbHiddenColumns]);

  const viewsApi = new ApiRequest(
    API_ROUTES.SMART_TABLE_VIEWS,
    API_AUTH_TYPES.ADVISOR
  );
  const handleSaveChanges = async () => {
    try {
      viewsApi.patch({
        endpoint: 'update', body: {
          column: "hidden_columns",
          value: hiddenColumns.array,
          id: viewId,
        }
      });
    } catch (error) { }
  };

  const queryClient = useQueryClient();
  const { mutate: handleApplyFilterClick } = useMutation(handleSaveChanges, {
    onSuccess: () => {
      queryClient.invalidateQueries("smart-table")
      setChangesMade(false);

    },
  });
  return (
    <div className="">
      <ButtonDropdown open={open} setOpen={setOpen}>
        {" "}

        <button
          onClick={handleApplyFilterClick}
          className={classNames('mt-3 mb-1 font-semibold px-2 py-1 text-gray-600 hover:text-gray-900 transition duration-200 ease-in hover:bg-gray-100 hover:shadow-md text-sm border rounded-full', changesMade && 'bg-blue-200 hover:bg-blue-300 animate-pulse')}
        >
          Apply Changes
        </button>
        <ul className="space-y-3 px-2 py-2 max-h-[600px] overflow-auto">
          {" "}
          {columns?.map((column,index) => (
            <HiddenColumnsRow hiddenColumns={hiddenColumns} column={column} key={index} viewId={viewId} />
          ))}
        </ul>
      </ButtonDropdown>
      <button
        onClick={() => setOpen(true)}
        className={classNames(
          "rounded hover:ring-2 hover:ring-gray-300 text-gray-600  px-2 py-1 flex space-x-1 items-center",
          (hiddenColumns.array.length) > 0 && "bg-blue-100"
        )}
      >
        <BiHide className="h-5 w-5" />
        
        <p className="hidden xl:flex">
          {" "}
          {( hiddenColumns.array.length === 0)
            ? "Hide Fields"
            : `${hiddenColumns.array.length} hidden field${
                hiddenColumns.array.length > 1 ? "s" : ""
            }`}
          {changesMade && ' *'}
        </p>
      </button>
    </div>
  );
};

export default SmartTableHiddenFields;
