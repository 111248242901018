import React, { useEffect } from "react";
import { useState } from "react";

import Toggle from "../../../../toggle";
const HiddenColumnsRow = ({ hiddenColumns, column, viewId }) => {
  const [isHidden, setIsHidden] = useState(
    hiddenColumns.array.includes(column.col_id)
  );

  const handleUpdate = async () => {
    if (isHidden) {
      const index = hiddenColumns.array.indexOf(column.col_id);
      hiddenColumns.remove(index);
      setIsHidden(!isHidden)
      return;
    }
    hiddenColumns.push(column.col_id);
    setIsHidden(!isHidden)
  };



  return (
    <li className="flex items-center justify-between space-x-3">
      {" "}   <p className="w-max">
        {column.title}
      </p>
      <Toggle enabled={!isHidden} setEnabled={() => handleUpdate()} />

    </li>
  );
};

export default HiddenColumnsRow;
