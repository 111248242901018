import React from 'react';
import Chapter from './Chapter'; // Assuming this component is imported here
import { AlertNeutral, AlertSuccess } from '../../../../../../components/alerts';

/**
 * Component that displays the selected chapter details and associated tasks.
 * @param {Object} props - The props object that contains the necessary data for rendering.
 * @param {boolean} props.showSuccess - A boolean indicating whether to show a success message.
 * @param {Array} props.activeChapters - An array of active chapters.
 * @param {Object} props.selectedChapter - The selected chapter object to display.
 * @param {string} props.activeChaptersListQueryKey - Query key for the list of active chapters.
 * @param {string} props.tasksListQueryKey - Query key for the list of tasks.
 * @returns {JSX.Element} - The JSX element to render the selected chapter details and tasks.
 */
const SelectedChapter = ({
    activeChapters,
    selectedChapter,
    selectedChapterListQuery,
    tasksListQueryKey,
    tasks,
    queryKeys,
    setSelectedChapterId, isMeetingMode
    ,goals
}) => {
    return (
        <>
            <h1 className="text-xl mb-1 font-semibold leading-7 text-gray-700 text-left   sm:leading-9">
                Selected Chapter
            </h1>
            <div className="mb-10 flex flex-col p-10 bg-white rounded-lg shadow-lg">
                
                    {/* Render a message if no chapters have been added yet */}

                {activeChapters?.length === 0 && (
                    <div className="w-full mb-10 flex justify-center">
                        <AlertNeutral>
                            You don't currently have any active chapters for your client.
                        </AlertNeutral>
                    </div>
                )}

                {/* Render the selected chapter if available */}
                {selectedChapter?.chapter_id && (
                    <Chapter
                        chapter={selectedChapter}
                        tasks={tasks} // Assuming `tasks` is provided
                        chaptersListQueryKey={selectedChapterListQuery}
                        tasksListQueryKey={tasksListQueryKey}
                        queryKeys={queryKeys}
                        setSelectedChapterId={setSelectedChapterId}
                        isMeetingMode={isMeetingMode}
                        goals={goals}
                    />
                )}
            </div>
        </>
    );
};

export default SelectedChapter;

