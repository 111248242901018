import React from "react";

import QuillTextEditor from "../../../../../components/textEditor/Quill-Text-Editor/index";

import { createQueryString } from "../../../../../components/textEditor/Quill-Text-Editor/utils";

const NewNote = ({ householdId, listQueryKey }) => {

  const options = {
    is_draft: true,
    route: "household_notes",
    search_params: { household_id: householdId },
  };
  const queryKey = `note-draft-${householdId}`;

  return (
    <div>
      <QuillTextEditor
        placeholder="Create New Note"
        options={options}
        queryKey={queryKey}
        listQueryKey={listQueryKey}
      />
    </div>
  );
};

export default NewNote;
