import { useState } from "react";

import { useSelector } from "react-redux";
import { patchReq } from "../../../apis/internalApi";

import { API } from "../../../constants/index";
import DoubleClickEdit from "../../../components/DoubleClickEdit";
import ClipboardCopy from "../../../components/copy-to-clipboard/ClipboardCopy";


export default function HHName({ householdMeta }) {
  //hooks


  //state

  const [showEditName, setShowEditName] = useState(false);
  const [name, setName] = useState(householdMeta.name);
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  //handling next thing changes



  const handleNameEditSubmit = async () => {
    try {
      const response = await patchReq(
        API.HOUSEHOLDS,
        "update_household_name_auth_advisor",
        accessToken,
        { name, household_id: householdMeta.household_id }
      );
    } catch (error) { }
  };

  return (
    <div className="flex space-x-2 items-center py-2">
    <div className=" ">
      <DoubleClickEdit
      showEdit={showEditName}
      setShowEdit={setShowEditName}
      defaultComponent={
       <p className="font-semibold text-2xl text-center  text-gray-800 w-full ">
          {name}
        </p>
       
      }
      handleSubmitEdit={handleNameEditSubmit}
      editComponent={
        <input
          className="input-primary"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      }
    />

      </div>
      <ClipboardCopy text={name} />
  
</div>


  );
}
