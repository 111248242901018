import { PlusIcon } from '@heroicons/react/solid'
import React from 'react'

const ShowTemplatesButton = ({setShowTemplates}) => {
  return (
      <button className="mr-auto mb-2  mt-2 w-full justify-center flex  items-center btn-md btn-gray-outline-rounded"
          onClick={() => setShowTemplates(true)}><PlusIcon className="h-5 w-5 text-blue-500 mr-3" />
          <p className="text-left text-lg">Chapter Template</p></button>  )
}

export default ShowTemplatesButton