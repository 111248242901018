import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineChangeCircle } from "react-icons/md";
import { ToolTipHoverContainer, ToolTipLeft } from '../../../../../components/ToolTips';


/**
 * Component for displaying and updating the lead source for a specific household.
 * It allows for the selection from a predefined list of lead sources and updates the household's lead source.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.householdsApi - API object for performing operations on households.
 * @param {Object} props.household - Current household object.
 * @returns {JSX.Element} A component for viewing and editing the lead source.
 */
const LeadSource = ({ householdsApi, household }) => {
    const { household_id: householdId } = useParams();
    const [showSearch, setShowSearch] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [newValue, setNewValue] = useState({});
    const queryClient = useQueryClient()
    const updateSource = useMutation(
        () => householdsApi.patch({
            endpoint: 'lead_source',
            body: { household_id:householdId, new_value: newValue }
        })

        , {
            onSuccess: () => {
                setShowSuccess(true);
                queryClient.invalidateQueries(`source-${householdId}`)
                setShowSearch(false);

                setTimeout(() => setShowSuccess(false), 3000);
            }
        }
    )
    useEffect(() => {
        if (household.lead_source) {
            setNewValue(household.lead_source);
        }
    }, [household.lead_source]);

    const leadSources = [
        {
            color: "bg-gray-500",
            value: "Referral",
            order: 1,
        },
        {
            color: "bg-fuchsia-500",
            value: "Google Search",
            order: 2,
        },
        {
            color: "bg-cyan-500",
            value: "Advisor Referral",
            order: 3,
        },
        {
            color: "bg-purple-500",
            value: "XYPN",
            order: 4,
        },
        {
            color: "bg-yellow-500",
            value: "Blog",
            order: 5,
        },
        {
            color: "bg-yellow-500",
            value: "Can I Retire Yet",
            order: 16,
        },
        {
            color: "bg-red-500",
            value: "Advice Only Network",
            order: 6,
        },
        {
            color: "bg-orange-500",
            value: "NAPFA",
            order: 7,
        },
        {
            color: "bg-teal-500",
            value: "Social Media",
            order: 8,
        },
        {
            color: "bg-blue-600",
            value: "Wealthtender",
            order: 9,
        },
        {
            color: "bg-green-500",
            value: "Seminar",
            order: 10,
        },
        {
            color: "bg-blue-500",
            value: "CFP.com",
            order: 11,
        },
        {
            color: "bg-pink-500",
            value: "News Article",
            order: 12,
        },
        {
            color: "bg-violet-500",
            value: "Podcasts",
            order: 13,
        },
        {
            color: "bg-blue-500",
            value: "Friends/Family",
            order: 14,
        },
        {
            color: 'bg-red-500',
            value: "Harry Sit",
            order: 17,
        },
        {
            color: "bg-red-500",
            value: "Cody's List",
            order: 18
        },
        {
            color: "bg-blue-500",
            value: "COI",
            order: 19,
        },
        {
            color: "bg-blue-500",
            value: "Voila",
            order: 20
        },
        {
            color: "bg-sky-500",
            value: "Other",
            order: 15,
        },
        {
            color: 'bg-teal-500',
            value: "Twitter",
            order: 21
        },
        {
            color: 'bg-teal-500',
            value: "Facebook",
            order: 22
        },
        {
            color: 'bg-teal-500',
            value: "Instagram",
            order: 23
        },
        {
            color: 'bg-teal-500',
            value: "LinkedIn",
            order: 24
        },
        {
            color: 'bg-teal-500',
            value: "YouTube",
            order: 25
        },
        {
            color: 'bg-teal-500',
            value: "Pinterest",
            order: 26
        },
        {
            color: 'bg-pink-500',
            value: 'Advisor Transfer',
            order: 27
        }

    ]
    return (
        <div>{(household?.lead_source && !showSearch) ?
            (
                <div className='flex  justify-between items-end'>
                    <div className="">
                        <p className="text-gray-600 text-sm">Lead Source</p>

                        <p className='font-semibold'>{household.lead_source}</p>{" "}
                    </div>
                    <ToolTipHoverContainer>
                        <button onClick={() => setShowSearch(true)} className='hover:text-gray-600'>
                            <MdOutlineChangeCircle className='h-5 w-5 text-gray-500' />
                            <ToolTipLeft>
                                Update lead source
                            </ToolTipLeft>
                        </button>
                    </ToolTipHoverContainer>
                </div>

            ) : (
                <div className='space-y-1'>
                    <p className="text-gray-600 text-sm">Lead Source</p>

                    {showSearch ? (

                        <div className='flex space-x-2 items-center py-1'>
                            <select className='input-primary'
                                defaultValue={newValue}
                                onChange={(e) => setNewValue(e.target.value)}
                            >
                                <option hidden value=''>Select</option>
                                {leadSources.map((source) => (
                                    <option key={source.value} value={source.value}>
                                        {source.value}
                                    </option>
                                ))}
                            </select>

                            <button className="btn-primary btn-md" onClick={() => updateSource.mutate()}>Submit</button>

                            <button className="underline" onClick={() => setShowSearch(false)}>Cancel</button>
                        </div>
                    ) : (
                        <button
                            onClick={() => {
                                setShowSearch(true);
                            }}
                            className="btn-primary  px-3 py-0.5"
                        >
                            <p>Select</p>{" "}

                        </button>
                    )}
                </div>
            )}{" "}
            {showSuccess && <CheckCircleIcon className="h-5 w-5 text-green-500" />}</div>
    )
}

export default LeadSource