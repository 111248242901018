import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invest: {
    portfolio: {},
    editPortfolio: {
      display_name: "",
      name: "",
      tickers: [],
      names: [],
      expense_ratios: [],
    },
    edited: [],
    requiredArr: [],
    allocation: {},
    editAllocation: {
      display_name: "",
      name: "",
      allocations: [],
    },
    sum: 0,
    orderLast: 0,
  },
  advisors: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setPortfolioEdited: (state) => {
      state.invest.edited = [];
      for (let i = 0; i < state.invest.portfolio.tickers.length; i++) {
        state.invest.edited.push("");
      }
    },
    setAdminPortfolio: (state, action) => {
      state.invest.portfolio = action.payload;
      state.invest.editPortfolio.tickers = state.invest.portfolio.tickers;
      state.invest.editPortfolio.names = state.invest.portfolio.names;
      state.invest.editPortfolio.expense_ratios =
        state.invest.portfolio.expense_ratios;
      state.invest.editPortfolio.display_name =
        state.invest.portfolio.display_name;
      state.invest.editPortfolio.name = state.invest.portfolio.name;
    },
    setTickers: (state, action) => {
      state.invest.editPortfolio.tickers.splice(action.payload.i, 1, [
        action.payload.assetClass,
        action.payload.new,
      ]);
      if (
        state.invest.portfolio.tickers[action.payload.i][1] !==
          state.invest.editPortfolio.tickers[action.payload.i][1] ||
        state.invest.portfolio.names[action.payload.i][1] !==
          state.invest.editPortfolio.names[action.payload.i][1] ||
        state.invest.portfolio.expense_ratios[action.payload.i][1] !==
          state.invest.editPortfolio.expense_ratios[action.payload.i][1]
      ) {
        state.invest.edited[action.payload.i] = "table-info";
      } else {
        state.invest.edited[action.payload.i] = "";
      }
    },
    setNames: (state, action) => {
      state.invest.editPortfolio.names.splice(action.payload.i, 1, [
        action.payload.assetClass,
        action.payload.new,
      ]);
      if (
        state.invest.portfolio.tickers[action.payload.i][1] !==
          state.invest.editPortfolio.tickers[action.payload.i][1] ||
        state.invest.portfolio.names[action.payload.i][1] !==
          state.invest.editPortfolio.names[action.payload.i][1] ||
        state.invest.portfolio.expense_ratios[action.payload.i][1] !==
          state.invest.editPortfolio.expense_ratios[action.payload.i][1]
      ) {
        state.invest.edited[action.payload.i] = "table-info";
      } else {
        state.invest.edited[action.payload.i] = "";
      }
    },
    setExpenseRatios: (state, action) => {
      state.invest.editPortfolio.expense_ratios.splice(action.payload.i, 1, [
        action.payload.assetClass,
        action.payload.new,
      ]);
      if (
        state.invest.portfolio.tickers[action.payload.i][1] !==
          state.invest.editPortfolio.tickers[action.payload.i][1] ||
        state.invest.portfolio.names[action.payload.i][1] !==
          state.invest.editPortfolio.names[action.payload.i][1] ||
        state.invest.portfolio.expense_ratios[action.payload.i][1] !==
          state.invest.editPortfolio.expense_ratios[action.payload.i][1]
      ) {
        state.invest.edited[action.payload.i] = "table-info";
      } else {
        state.invest.edited[action.payload.i] = "";
      }
    },
    setPortfolioName: (state, action) => {
      state.invest.editPortfolio.display_name = action.payload.displayName;
      state.invest.editPortfolio.name = action.payload.name;
    },
    createPortfolio: (state, action) => {
      state.invest.orderLast = action.payload.orderLast;
      state.invest.portfolio = action.payload.portfolio;
      state.invest.portfolio.display_name = "";
      state.invest.portfolio.name = "";
      state.invest.portfolio.portfolio_id = "";
      state.invest.requiredArr = [];

      for (let i = 0; i < state.invest.portfolio.tickers.length; i++) {
        state.invest.portfolio.names[i][1] = "";
        state.invest.portfolio.expense_ratios[i][1] = "";
        state.invest.portfolio.tickers[i][1] = "";
        if (i === 1 || i === 2) {
          state.invest.requiredArr.push(false);
        } else {
          state.invest.requiredArr.push(true);
        }
      }
      state.invest.editPortfolio.tickers = state.invest.portfolio.tickers;
      state.invest.editPortfolio.names = state.invest.portfolio.names;
      state.invest.editPortfolio.expense_ratios =
        state.invest.portfolio.expense_ratios;
    },
    changeRequired: (state, action) => {
      if (
        action.payload === 0 ||
        action.payload === 1 ||
        action.payload === 2
      ) {
        state.invest.requiredArr[0] = !state.invest.requiredArr[0];
        state.invest.requiredArr[1] = !state.invest.requiredArr[1];
        state.invest.requiredArr[2] = !state.invest.requiredArr[2];
      } else {
        state.invest.requiredArr[action.payload] =
          !state.invest.requiredArr[action.payload];
      }
    },
    submitPortfolio: (state) => {
      for (let i = 0; i < state.invest.editPortfolio.tickers.length; i++) {
        if (!state.invest.requiredArr[i]) {
          state.invest.editPortfolio.tickers[i][1] = "";
          state.invest.editPortfolio.names[i][1] = "";
          state.invest.editPortfolio.expense_ratios[i][1] = "";
        }
      }
    },
    clearAdminInvest: (state) => {
      state.invest = initialState.invest;
    },
    //ALLOCATIONS //////////////////////////////////////////////////////////////////////////
    setAdminAllocation: (state, action) => {
      state.invest.allocation = action.payload;
      state.invest.editAllocation.allocations =
        state.invest.allocation.allocations;
      state.invest.editAllocation.display_name =
        state.invest.allocation.display_name;
      state.invest.editAllocation.name = state.invest.allocation.name;
    },
    setAllocationEdited: (state) => {
      state.invest.edited = [];
      for (let i = 0; i < state.invest.allocation.allocations.length; i++) {
        state.invest.edited.push("");
      }
    },
    setAllocations: (state, action) => {
      state.invest.editAllocation.allocations.splice(action.payload.i, 1, [
        action.payload.assetClass,
        action.payload.new,
      ]);
      state.invest.sum = 0;
      for (let i = 0; i < state.invest.editAllocation.allocations.length; i++) {
        state.invest.sum =
          state.invest.sum +
          Number(state.invest.editAllocation.allocations[i][1]);
      }
      if (
        state.invest.allocation.allocations[action.payload.i][1] !==
        state.invest.editAllocation.allocations[action.payload.i][1]
      ) {
        state.invest.edited[action.payload.i] = "table-info";
      } else {
        state.invest.edited[action.payload.i] = "";
      }
    },
    setAllocationDisplayName: (state, action) => {
      state.invest.editAllocation.display_name = action.payload;
    },
    setAllocationName: (state, action) => {
      state.invest.editAllocation.name = action.payload;
    },
    resetSum: (state) => {
      state.invest.sum = 1;
    },
    createallocation: (state, action) => {
      state.invest.allocation = action.payload;
      state.invest.allocation.display_name = "";
      state.invest.allocation.name = "";
      state.invest.allocation.allocation_id = "";
      state.invest.requiredArr = [];

      for (let i = 0; i < state.invest.allocation.allocations.length; i++) {
        state.invest.allocation.allocations[i][1] = "";
        if (i === 1 || i === 2) {
          state.invest.requiredArr.push(false);
        } else {
          state.invest.requiredArr.push(true);
        }
      }
      state.invest.editAllocation.allocations =
        state.invest.allocation.allocations;
    },
    submitAllocation: (state) => {
      for (let i = 0; i < state.invest.editAllocation.allocations.length; i++) {
        if (!state.invest.requiredArr[i]) {
          state.invest.editAllocation.allocations[i][1] = "";
        }
      }
    },
    setAdvisors: (state, action) => {
      state.advisors = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setPortfolioEdited,
  setAdminPortfolio,
  setTickers,
  setNames,
  setExpenseRatios,
  setPortfolioName,
  createPortfolio,
  changeRequired,
  submitPortfolio,
  clearAdminInvest,
  //Allocations //////////////////////////////////
  setAdminAllocation,
  setAllocationEdited,
  setAllocations,
  setAllocationName,
  setAllocationDisplayName,
  resetSum,
  createallocation,
  submitAllocation,
  setAdvisors,
} = adminSlice.actions;

export default adminSlice.reducer;
