import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "../SmartTable___EditCell";
import { classNames } from "./../../../../helpers/classNames";

const SingleSelect = ({
  rowId,
  initValue,
  accessToken,
  table,
  colId,
  editable,
  selectOptions,
  editId
}) => {
  const [selected, setSelected] = useState(initValue || "");
  const [showEdit, setShowEdit] = useState(false);
  const [bgColor, setBgColor] = useState("");
  //handling next thing changes
  const submitChanges = async (newValue) => {
    try {
      if (!newValue) return

      setSelected(newValue);
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value: newValue,
        row_id: editId,
        col_id: colId,
      });
      setShowEdit(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (!selected || !selectOptions) return;
    setBgColor(
      selectOptions.find((option) => option.value === selected)?.color
    );
  }, [selectOptions, selected]);

  const sortedOptions = useMemo(
    () => selectOptions.sort((a, b) => a.order - b.order),
    [selectOptions]
  );
  return (
    <div className="smart-table-cell px-2">
      {editable ? (
        <SmartTableEditCell
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={
            <div
              className={classNames(
                bgColor,
                "rounded-2xl px-2 text-center w-full h-full flex items-center justify-center"
              )}
            >
              {" "}
              <p className={"text-center text-white truncate "}>{selected}</p>
            </div>
          }
          editComponent={
            <div className="relative">
              <select
                type="text"
                name={`single_select_${colId}`}
                id={`single_select_${colId}`}
                value={selected}
                onChange={(e) => submitChanges(e.target.value)}
                className="shadow-sm absolute -top-5 border-2 focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md"
              >
                <option value="" hidden></option>
                {sortedOptions &&
                  sortedOptions.map((option) => (
                    <option key={option.order} value={option.value}>
                      {option.value}
                    </option>
                  ))}
              </select>
            </div>
          }
        />
      ) : (
        <div
          className={classNames(
            bgColor,
            "rounded-2xl px-2 text-center w-full h-full flex items-center justify-center"
          )}
        >
          {" "}
          <p className={"text-center text-white truncate "}>{selected}</p>
        </div>
      )}
    </div>
  );
};

export default SingleSelect;
