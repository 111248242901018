import { ChevronDownIcon, SparklesIcon } from '@heroicons/react/outline'
import React from 'react'
import SmallLoadingSpinner from '../../../../../../components/loading/SmallLoadingSpinner'
import FadeIn from '../../../../../../components/transitions/FadeIn'
import { classNames } from '../../../../../../helpers/classNames'


const SuggestionsController = ({ loading, hidden, setHidden, suggestionsCreated, createSuggestions }) => {
    return (
        <div className='bg-white items-center rounded-full px-4 py-2 space-x-3 flex border w-min border-gray-300 mb-2'>
            <button onClick={() => createSuggestions()}>
                <SparklesIcon className='h-5 w-5 text-blue-500 flex-shrink-0 ' />
            </button>
            {loading && <SmallLoadingSpinner />}
            <FadeIn isShowing={suggestionsCreated}>
                <button onClick={() => {setHidden(!hidden)}} className='flex items-center'>
                    <ChevronDownIcon className={classNames(!hidden && 'rotate-180', 'flex-shrink-0 w-5 transition duration-200 ease-in text-gray-500')} />
                </button>
            </FadeIn>
        </div>
    )
}

export default SuggestionsController