import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { classNames } from "../../../../../helpers/classNames";
import ButtonDropdown from "../../../../ButtonDropdown";
import SmartTableSortsRow from "./SmartTable___Sorts___Row";
import { TbArrowsDownUp } from "react-icons/tb";
import immutableArrayMethods from "../../../../../helpers/immutableArrayMethods";
import { useMutation, useQueryClient } from "react-query";
import { ApiRequest, API_AUTH_TYPES, API_ROUTES } from "../../../../../api";
import useArray from "../../../../../hooks/useArray";
import areArraysIdentical from "../../../../../helpers/arrayArraysIdentical";

const SmartTableSorts = ({ columns, dbSorts, viewId }) => {
  const [open, setOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);


  const sorts = useArray(dbSorts || [])

  useEffect(() => {
    sorts.set(dbSorts);
  }, [dbSorts]);

  useEffect(() => {
    setChangesMade(!areArraysIdentical(sorts.array, dbSorts));
  }, [sorts.array, dbSorts]);

  const handleAddCondition =  () => {
    const newSort = {
      col_id: columns[0].col_id,
      direction: "desc",
    };
    sorts.push(newSort);
  };

  const handleSaveChanges = async () => {

    try {
      viewsApi.patch({endpoint:'update', body:{
        column: "sorts",
        value: sorts.array,
        id: viewId,
      }});
    } catch (error) { }
  };

  const viewsApi = new ApiRequest(
    API_ROUTES.SMART_TABLE_VIEWS,
    API_AUTH_TYPES.ADVISOR
  );
  const queryClient = useQueryClient();
  const { mutate:handleApplyFilterClick } = useMutation(handleSaveChanges, {
    onSuccess: () => {
      queryClient.invalidateQueries("smart-table")
      setChangesMade(false);

    },
  });


  return (
    <div className="">
      <ButtonDropdown open={open} setOpen={setOpen}>
        {" "}
        <div className="p-2 min-w-[600px]">
          {" "}
          <p className="text-sm w-max mb-3">Sort by</p>
          <ul className="space-y-2">
            {" "}
            {sorts.array.map((sort, index) => (
              <SmartTableSortsRow
                key={index}
                sorts={sorts}
                columns={columns}
                sort={sort}
                thisSortIndex={index}
                viewId={viewId}
              />
            ))}
          </ul>
          <div className="flex justify-between">
            <button
              onClick={handleAddCondition}
              className="mt-3 font-semibold p-2 text-gray-600 hover:text-gray-900 text-sm"
            >
              + Add Condition
            </button>

            <button
              onClick={handleApplyFilterClick}
              className={classNames('mt-3 font-semibold px-2 py-1 text-gray-600 hover:text-gray-900 transition duration-200 ease-in hover:bg-gray-100 hover:shadow-md text-sm border rounded-full', changesMade && 'bg-blue-200 hover:bg-blue-300 animate-pulse')}
            >
              Apply Changes
            </button>
          </div>
        </div>
      </ButtonDropdown>
      <button
        onClick={() => setOpen(true)}
        className={classNames(
          "rounded hover:ring-2 hover:ring-gray-300 text-gray-600  px-2 py-1 flex space-x-1 items-center",
          sorts.array.length > 0 && "bg-orange-100"
        )}
      >
        <TbArrowsDownUp className="w-6 " />
        <p className="hidden xl:flex">
          {" "}
          {sorts.array.length === 0
            ? "Sort"
            : `Sorted by ${sorts.array.length} field${sorts.array.length > 1 ? "s" : ""}`}
          {changesMade && ' *'}

        </p>
      </button>
    </div>
  );
};

export default SmartTableSorts;
