import React, { useState } from 'react';
import ApiRequest from '../../../../../api/ApiRequest';
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api/apiConstants';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import EditCashflow from './edit-cashflow';
import { TrashIcon } from '@heroicons/react/outline';
import ItemMenu from '../../../../../components/menus/ItemMenu';
import FadeIn from '../../../../../components/transitions/FadeIn';
import ReallyDelete from '../../../../../components/ReallyDelete';
import { currencyFormatter } from '../../../../../helpers/formatters';

/**
 * Represents a row within the cash flow table, providing functionality to edit and delete cash flow items.
 *
 * @param {Object} props The component props.
 * @param {string} props.id The id of the cash flow item.
 * @param {boolean} props.isClientPortal Indicates if the current portal is for a client (true) or an advisor (false).
 * @returns {JSX.Element} A row in the cash flow table with options to edit or delete the item.
 */
const CashflowRow = ({ id, isClientPortal }) => {
    // Using useParams to access the household ID from the URL.
    const { household_id: householdId } = useParams();
    // State for controlling the visibility of edit and delete modals.
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    // Determine the authentication type based on the portal type.
    const authType = isClientPortal ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR;
    const cashflowApi = new ApiRequest(API_ROUTES.CASHFLOW, authType);

    // Fetching the details of a specific cash flow item using its ID.
    const { data: item, isLoading } = useQuery({
        queryFn: cashflowApi.getFn({
            endpoint: `item_details`,
            params: [id],
        }),
        queryKey: [id],
    });

    const queryClient = useQueryClient();
    // Mutation to update the status of a cash flow item (e.g., archiving it).
    const updateStatus = useMutation(
        (data) => cashflowApi.patch({
            endpoint: 'update_status',
            body: { status: 'archived' },
            params: [id],
        }),
        {
            onSuccess: () => {
                // Invalidate queries related to cash flow items to reflect changes.
                queryClient.invalidateQueries(`cashflow-ids-${householdId}`);
                // Close the delete confirmation modal upon successful deletion.
                setShowDelete(false);
            }
        }
    );

    // Options for the item menu, allowing actions like delete to be performed on the cash flow item.
    const menuOptions = [
        {
            title: "Delete",
            action: () => setShowDelete(true),
            icon: TrashIcon,
        },
    ];

    // Render a loading state while the cash flow item details are being fetched.
    if (isLoading) return <p>Loading...</p>;

    return (
        <>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-0">
                {/* Edit cash flow modal component */}
                <EditCashflow item={item} open={showEdit} isClientPortal={isClientPortal} setOpen={setShowEdit} id={id} />
                {/* Cash flow item name */}
                {item.name}
                {/* Details shown only on smaller screens */}
                <dl className="font-normal lg:hidden">
                    <dd className="mt-1 truncate text-gray-700">{item.location}</dd>
                    {item.annual_contribution && <dd className="mt-1 truncate text-gray-500">{currencyFormatter.format(item.annual_contribution)} annually</dd>}
                </dl>
            </td>
            {/* Location displayed only on larger screens */}
            <td className="hidden whitespace-nowrap px-3 py-4 text-gray-800 lg:table-cell">{item.location}</td>
            {/* Amount and type displayed only on larger screens */}
            <td className="hidden whitespace-nowrap px-3 py-4 text-gray-800 sm:table-cell">
                <p>
                    {item.type === 'Dollar Amount' && currencyFormatter.format(item.amount)}
                    {item.type === "Maxing Out" && 'Maxing Out'}
                    {item.type === 'Percent' && `${item.amount}%`}
                </p>
            </td>
            {/* Cash flow item period */}
            <td className="whitespace-nowrap px-3 py-4 text-gray-800">
                <dd>  {item.period}</dd>
                <dd className="mt-1 truncate text-gray-500 sm:hidden">
                    {/* Formatting the cash flow amount based on its type */}
                    <p>
                        {item.type === 'Dollar Amount' && currencyFormatter.format(item.amount)}
                        {item.type === "Maxing Out" && 'Maxing Out'}
                        {item.type === 'Percent' && `${item.amount}%`}
                    </p>
                </dd>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-gray-800 hidden lg:table-cell">{item.transfer_type}</td>
            <td className="whitespace-nowrap px-3 py-4 text-gray-800 hidden lg:table-cell">{currencyFormatter.format(item.annual_contribution)}</td>

            {/* Edit button */}
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-0">
                <button
                    onClick={() => setShowEdit(true)}
                    className="text-primary-600 hover:text-primary-900 my-auto">
                    Edit<span className="sr-only">, {item.name}</span>
                </button>
            </td>
            {/* Menu options and delete confirmation modal */}
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-0 ">
                {!showDelete && <div className=' sm:mt-0 flex items-center '><ItemMenu options={menuOptions} /></div>}
                <FadeIn isShowing={showDelete}>
                    <ReallyDelete showDelete={showDelete} handleDelete={() => { updateStatus.mutate() }} setShowDelete={setShowDelete} />
                </FadeIn>
            </td>
        </>
    );
}

export default CashflowRow;
