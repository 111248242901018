import React, { useEffect, useMemo, useState } from 'react'
import WideBlankModal from "../../../../../../components/modals/WideBlankModal";
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../../api/apiConstants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiRequest from '../../../../../../api/ApiRequest';
import RichTextDisplay from '../../../../../../components/textEditor/RichTextDisplay';

import { CheckCircleIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/solid';
import { SearchIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import QuillTextEditor from '../../../../../../components/textEditor/Quill-Text-Editor';
import DoubleClickEdit from '../../../../../../components/DoubleClickEdit';
import DisplayTaskRichText from '../task-components/DisplayTaskRichText';



const templatesApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_TEMPLATES, API_AUTH_TYPES.ADVISOR);
const tasksApi = new ApiRequest(API_ROUTES.CLIENT_TASKS, API_AUTH_TYPES.ADVISOR);
const goalsApi = new ApiRequest(API_ROUTES.HOUSEHOLD_GOALS, API_AUTH_TYPES.ADVISOR);


function TemplateContent({ template, type }) {
  

  const [showEdit, setShowEdit] = useState(false);
  const handleSave = () => {
    if (type === 'task') return queryClient.invalidateQueries('task-templates');
    if (type === 'goal') return queryClient.invalidateQueries('goal-templates');
  };
  const options = {
    is_draft: false,
    route: "templates",
    search_params: { document_id: template.id },
  };

  const queryKey = `${template.id}`;
  const queryClient = useQueryClient();

  return (
    <div className="w-full">
    <DoubleClickEdit
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      defaultComponent={type !== 'goal' ? <DisplayTaskRichText task={template} /> : <RichTextDisplay content={template.content} thisClassName='markdown-notes' />}
      editComponent={
        <div className="w-full">
          {type === 'task' &&
            <QuillTextEditor
            placeholder="Create New Template"
            options={options}
            queryKey={queryKey}
            listQueryKey={'task-templates'}
            />
          }
          {type === 'goal' &&
            <QuillTextEditor
              placeholder="Create New Template"
              options={options}
              queryKey={queryKey}
              listQueryKey={'goal-templates'}
            />
          }

        </div>
      }
      handleSubmitEdit={handleSave}
      componentName="description"
    /></div>
  );
};


export function Template({ template,  chapterId, type,queryKeys }) {
  const [showSuccess, setShowSuccess] = useState(false)
  const toggleSuccessMessage = () => {
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
    }, 2000);
  }
  const { household_id: householdId } = useParams();
  const queryClient = useQueryClient();
  const { mutate: createTask, isLoading: isLoadingCreateTask } = useMutation(
    () => tasksApi.post({ endpoint: "post_task", body: { chapter_id: chapterId, household_id: householdId, content:template.content } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.tasks);
        queryClient.invalidateQueries(queryKeys.chapters);
        toggleSuccessMessage()
      },
    }

  );

  const { mutate: createGoal, isLoading: isLoadingCreateGoal } = useMutation(
    () => goalsApi.post({ endpoint: "post_goal", body: { chapter_id: chapterId, household_id: householdId, content: template.content } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.goals);
        queryClient.invalidateQueries(queryKeys.chapters);
        toggleSuccessMessage()
      },
    }
  )
  

  const createTemplateItem = () => {
    if (type === 'goal') createGoal()
    if (type === 'task') createTask()
  }
  

  const { mutate: archiveTemplate } = useMutation(
    () => templatesApi.patch({ endpoint: "archive_template", params:  [template.id] }),
    {
      onSuccess: () => {
        if (type === 'goal') queryClient.invalidateQueries("goal-templates");
        if (type === 'task') queryClient.invalidateQueries("task-templates");
       
      },
    }
  )
    return (
      <div className='flex px-2  justify-between w-full  items-start'>
        <div className="">
          <button onClick={createTemplateItem}>
          <PlusCircleIcon className='h-8 w-8 text-blue-500 hover:text-blue-600 transition duration-100 ease-in' />
        </button>
        <div className="">
          {showSuccess && <CheckCircleIcon className="h-8 w-8 text-green-500" />}
          {(isLoadingCreateGoal || isLoadingCreateTask)   && <AiOutlineLoading3Quarters className="h-8 w-8 animate-spin text-gray-500" />
}

          </div>
        </div>
    
        <div className="max-w-[800px] mr-auto ml-5">
          <TemplateContent template={template} type={type} />
        </div>
        <button>
          <TrashIcon onClick={archiveTemplate} className='h-8 w-8 text-red-500 hover:text-red-600 transition duration-100 ease-in' />
         </button>
        </div>
    )
}




export default function TaskTemplates({ open, setOpen, chapterId,queryKeys,type,setType }) {

  const [query, setQuery] = useState('');
  const [title, setTitle] = useState('Task Templates')
  const queryClient = useQueryClient();

  useEffect(() => {
    if (type === null) return
    let newTitle
    if (type === 'goal') newTitle = 'Strategy Templates'
    if (type === 'task') newTitle = 'Task Templates'
    setTitle(newTitle)
    setTimeout(() => {
 queryClient.invalidateQueries('goal-templates')
      queryClient.invalidateQueries('task-templates')
    }, 100);
  }, [queryClient, type])
  const { data: taskTemplates, isLoading, isError } = useQuery({
    queryKey: "task-templates",
    queryFn: templatesApi.getFn({ endpoint: 'index_templates',params:['task']})
  })

  const { data: goalTemplates, isLoading: isLoadingGoalTemplates, isError: isErrorGoalTemplates } = useQuery({
    queryKey: "goal-templates",
    queryFn: templatesApi.getFn({ endpoint: 'index_templates', params: ['goal'] })
  })

  
  

  const filteredTemplates = useMemo(() => {
    let templates
    if (type === 'goal') templates = goalTemplates
    if (type === 'task') templates = taskTemplates
    if (!templates)  return [];
    if (!query) return templates;
    return templates.filter(template => JSON.stringify(template.content).toLowerCase().includes(query.toLowerCase()))
  }, [type, goalTemplates, taskTemplates, query])

    if (isLoading || isLoadingGoalTemplates ) return <p>Loading...</p>
  return (
      <div>
      <WideBlankModal open={type !== null} setOpen={() => setType(null)}>
        
        <div className="flex"> <h3 className='text-left ml-4'>{title}</h3>
        </div>
        <div className="relative mt-2">
          <SearchIcon
            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <div className="relative w-full text-gray-400 focus-within:text-gray-600 pl-1">
            <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center">
              <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              id="search-field"
              className="block h-full w-full border-transparent py-2 pr-3 pl-8 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
          </div>
        </div>
        <ul role="list" className="divide-y divide-gray-200 text-left h-[600px] overflow-y-auto">
          {filteredTemplates.map((template) => (
            <li key={template.id} className="py-4 flex">
              <Template template={template}  chapterId={chapterId} queryKeys={queryKeys} type={type}  />
            </li>
          ))}
        </ul>
          </WideBlankModal>
      </div>
  )
}
