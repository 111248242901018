import React, { useState } from 'react'
import FadeIn from '../../../../transitions/FadeIn'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import { useMutation, useQueryClient } from 'react-query'
import SmallLoadingSpinner from '../../../../loading/SmallLoadingSpinner'

const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)

const CreatePrompt = ({ isOpen, setIsOpen }) => {
    const [title, setTitle] = useState('')
    const [prompt, setPrompt] = useState('')
    const queryClient = useQueryClient()
    const { mutate: postPrompt, isLoading } = useMutation(async () => parabotApi.post({
        endpoint: 'create_prompt_template',
        body: {
            title,
            prompt,
        },
    }), {
        onSuccess: () => {
            setIsOpen(false)
            // reset form inputs
            setTitle('')
            setPrompt('');
            queryClient.invalidateQueries('parabot-prompt-templates')
        },
        onError: (error) => {
            console.error('Error creating prompt:', error)
        },
    })
    return (
        <FadeIn isShowing={isOpen}>
            <div className="border-b pb-2">
                <p className="label-primary">
                    Label
                </p>
                <input
                    autoComplete='off'
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="input-primary mt-1"
                />
                <p className=" mt-2 label-primary">
                    Prompt
                </p>

                <textarea
                    className="input-primary mt-1"
                    rows="6"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <div className="flex   justify-evenly items-center mt-3">
                    <button className="btn-lg btn-primary "
                        onClick={postPrompt}
                        disabled={title.trim() === '' || prompt.trim() === ''}
                    >
                        <p className='pr-1'> Create Prompt </p>  {isLoading ? <SmallLoadingSpinner textColor='text-white' /> : null}
                    </button>
                    <button className=" text-gray-500 btn-lg btn-gray-outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </button>
                </div>

            </div>



        </FadeIn>)
}

export default CreatePrompt