import { TrashIcon } from "@heroicons/react/solid";
import React from "react";
import { useCallback } from "react";


const filterOperators = {
  single_select: ["is", "is not", "is not empty", "is empty"],
  multi_select: ["includes", "does not include", "is not empty", "is empty"],
  household_link: ["is not empty", "is empty", "contains", "does not contain", 'is exactly', 'is not exactly'],
  link: ["is not empty", "is empty", "contains", "does not contain", 'is exactly', 'is not exactly'],
  claim_household: ["is not empty", "is empty", "contains", "does not contain", 'is exactly', 'is not exactly'],
  long_text: ["is not empty", "is empty", "contains", "does not contain", 'is exactly', 'is not exactly'],
  short_text: ["is not empty", "is empty", "contains", "does not contain",'is exactly', 'is not exactly'],
  checkbox: ["checked", "not checked"],
  date: [
    "is not empty",
    "is empty",
    "before",
    "after",
    "after number of days from now",
    "before number of days from now",
    "after number of days ago",
    "before number of days ago",
  ],
  due_date: ["is not empty", "is empty", "before", "after"],
  number: ["is not empty", "is empty", "<", ">", "=", '<=', '>=', '!='],
  advisor: ["is not empty", "is empty", "is", "is not"],
};
const comparisonTextFilters = ['contains', 'does not contain', 'is exactly', 'is not exactly'];
const comparisonDateFilters = ["before", "after"];
const comparisonNumberFilters = [
  "<",
  ">",
  "=",
  '<=',
  '>=',
  '!=',
  "after number of days from now",
  "before number of days from now",
  "after number of days ago",
  "before number of days ago",
];
const comparisonSelectFilters = ["is", "is not", "includes", "does not include"];

const SmartTableFiltersRow = ({
  filters,
  columns,
  filter,
  advisors,
  thisFilterIndex,
}) => {
  

  const handleComparisonType = useCallback(() => {
    if (comparisonTextFilters.includes(filter.operator)) return "text";
    if (comparisonDateFilters.includes(filter.operator)) return "date";
    if (comparisonNumberFilters.includes(filter.operator)) return "number";
    if (comparisonSelectFilters.includes(filter.operator)) return "select";

    return null;
  }, [filter.col_type, filter.operator]);
  const comparisonType = handleComparisonType();

  const handleUpdateRow = async ({ new_value: newValue, key }) => {
    if (comparisonType === "date" && key === "comparison") {
      //check if the date is in the correct format

      const dateStr = newValue; // e.g., "2024-11-14"
      const [year, month, day] = dateStr.split('-').map(Number);
      
      // Create a Date object at noon UTC to prevent date shifting
      newValue = new Date(Date.UTC(year, month - 1, day, 12, 0, 0)).toISOString();
    }
    const newFilter = { ...filter };
    const matchingColumn = columns.find(
      (col) => col.col_id === (key === "col_id" ? newValue : newFilter.col_id)
    );
    switch (key) {
      case "col_id":
        newFilter.col_id = newValue;
        newFilter.col_type = matchingColumn.col_type;
        newFilter.select_options = matchingColumn.select_options;
        break;
      case "operator":
        newFilter.operator = newValue;
        break;
      case "comparison":
        newFilter.comparison = newValue;
        break;
      default:
        break;
    }
    filters.update(thisFilterIndex, newFilter)
   
  };

  const handleRemoveRow = async () => {
    try {
      filters.remove(thisFilterIndex)
    
    } catch (error) {}
  };
  const nonFilterableColumns = ["button", "copy_to_clipboard"];
  return (
    <div className=" flex items-center">
      {" "}
      <p className="mr-3 w-10 text-right">
        {thisFilterIndex === 0 ? "Where" : "and"}
      </p>
      <div className="flex items-center border text-gray-700 border-gray-200 divide-x divide-gray-200 rounded px-2 w-[750px]">
        <select
          name="filter_column_select"
          id="filter_column_select"
          value={filter.col_id}
          onChange={(e) =>
            handleUpdateRow({ new_value: e.target.value, key: "col_id" })
          }
          className=" border-0 focus:ring-0"
        >
          {columns && columns.map(
            (column) =>
              !nonFilterableColumns.includes(column.col_type) && (
                <option value={column.col_id} key={column.col_id}>
                  {column.title}
                </option>
              )
          )}
        </select>
        <select
          name="operator_options"
          id="operator_options"
          value={filter.operator}
          onChange={(e) => {
            handleUpdateRow({ new_value: e.target.value, key: "operator" });
          }}
          className=" border-0 focus:ring-0"
        >
          {filterOperators[filter.col_type] &&
            filterOperators[filter.col_type].map((operator) => (
              <option value={operator} key={operator}>
                {operator}
              </option>
            ))}
        </select>
        {comparisonType !== "select" && (
          <input
            type={comparisonType || "text"}
            name=""
            id=""
            value={
              comparisonType === 'date'
                ? filter.comparison
                  ? String(filter.comparison).substring(0, 10)
                  : ''
                : filter.comparison || ''
            }

            onChange={(e) => {
              handleUpdateRow({ new_value: e.target.value, key: "comparison" });
            }}
            placeholder={comparisonType && "Enter a value"}
            disabled={!comparisonType}
            className="focus:ring-0 border-0 w-max"
          />
        )}
        {comparisonType === "select" && (
          <select
            name=""
            id=""
            value={comparisonType ? filter.comparison || "" : ""}
            onChange={(e) => {
              handleUpdateRow({ new_value: e.target.value, key: "comparison" });
            }}
            placeholder={comparisonType && "Enter a value"}
            disabled={!comparisonType}
            className="focus:ring-0 border-0 w-max"
          >
            <option value="">Please Select</option>
            {filter.col_type === "advisor" &&
              advisors.map((advisor) => (
                <option value={advisor.user_id}>
                  {advisor.first_name}
                  {" " + advisor.last_name}
                </option>
              ))}
            {(filter.col_type === "single_select" || filter.col_type === 'multi_select')&&
              filter.select_options.map((option) => (
                <option value={option.value}>{option.value}</option>
              ))}
          </select>
        )}
        <button onClick={handleRemoveRow} className="pl-2 h-10">
          <TrashIcon className="w-5 h-full" />
        </button>
      </div>
    </div>
  );
};

export default React.memo(SmartTableFiltersRow);
