import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiBox = ({ duration = 3 * 1000, particleCountFactor = 50, tickCount = 60, startVelocity = 30, spread = 360, zIndex = 0 }) => {

    useEffect(() => {
        const animationEnd = Date.now() + duration;
        const defaults = { startVelocity, spread, ticks: tickCount, zIndex };

        const randomInRange = (min, max) => Math.random() * (max - min) + min;

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = particleCountFactor * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
            confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        }, 250);

        // Clean up on unmount
        return () => clearInterval(interval);
    }, [duration, particleCountFactor, tickCount, startVelocity, spread, zIndex]);

    return (
        <div className="">
          
        </div>
    );
};

export default ConfettiBox;
