import React from "react";
import { useRef } from "react";
import { useState } from "react";
import useOnClickOutside from "../hooks/useClickOutside";
import { classNames } from "./misc/styling";

const DoubleClickEdit = ({
  defaultComponent,
  editComponent,
  handleSubmitEdit = () => {},
  componentName,
  showEdit,
  setShowEdit = () => {},
  toolTipPosition = "bottom",
}) => {
  const ref = useRef();
  const [showToolTip, setShowToolTip] = useState(false);
  useOnClickOutside(ref, () => {
    handleSubmitEdit(componentName);
    setShowEdit(false);
  });
  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter") {
  //       handleSubmitEdit(componentName);
  //       setShowEdit(false);
  //     }
  //   };
  return (
    <div tabIndex={0} className="">
      {" "}
      {!showEdit ? (
        <button
          onClick={(e) => {
            if (e.detail === 2) setShowEdit(true);
          }}
          className="relative"
          onMouseEnter={() => setShowToolTip(true)}
          onMouseLeave={() => setShowToolTip(false)}
        >
          {defaultComponent}
          {showToolTip && <span className={classNames(toolTipPosition === 'bottom' && 'right-1/2 translate-x-1/2 -bottom-10',
            toolTipPosition === 'top' && 'right-1/2 translate-x-1/2 -top-10',
            "text-xs  absolute w-max transform  z-10 bg-gray-800 text-white opacity-80 p-2 rounded-lg")}>Double click to edit</span>}
        </button>
      ) : (
        <div ref={ref} className="">{editComponent}</div>
      )}
    </div>
  );
};

export default DoubleClickEdit;
