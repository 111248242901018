import React, { useEffect, useState } from 'react'
import { classNames } from '../../../../misc/styling'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid'
import ItemMenu from '../../../../menus/ItemMenu'
import DoubleClickEdit from '../../../../DoubleClickEdit'


const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)
const ConversationItem = ({ id, setConversationId, conversationId }) => {
    const queryClient = useQueryClient()
    const [showRename, setShowRename] = useState(false)
    const [editValue, setEditValue] = useState('')
    const { data: conversation, isLoading } = useQuery({
        queryFn: parabotApi.getFn({
            endpoint: 'conversation_details',
            params: [id],
        }),
        queryKey: [id],
    })
    useEffect(() => {
        setEditValue(conversation?.name)
    }, [conversation])
    const { mutate: archiveConversation } = useMutation(() => parabotApi.patch({
        endpoint: 'archive_conversation',
        params: [id],
    }), {
        onSuccess: () => {
            if (id === conversationId) setConversationId(null)
            queryClient.invalidateQueries('parabot_conversation_ids')
        },
    })
    const { mutate: updateName } = useMutation(() => parabotApi.patch({
        endpoint: 'update_name',
        params: [id],
        body: {
            name: editValue,
        },
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries(id)
        },
    })

    const menuOptions = [
       
        {
            title: 'Rename',
            action: () => setShowRename(true),
            icon: PencilIcon
        },
        {
            title: 'Delete',
            action: () => archiveConversation(),
            icon: TrashIcon
        },
    ]
    if (isLoading) return <div></div>
    return (

      <button
          onClick={() => setConversationId(id)}
          className={classNames(
              'px-2 py-2 flex text-lg font-medium w-full items-center justify-between space-x-2 text-gray-800 rounded-lg hover:bg-gray-200 group',
              conversationId === id && 'bg-gray-200'
          )}
        >
            <DoubleClickEdit 
                showEdit={showRename}
                setShowEdit={setShowRename}
                defaultComponent={<p className='text-base font-medium flex-nowrap truncate max-w-[180px]'>{conversation?.name}</p>}
                editComponent={<input
                    type="text"
                    className="input-primary"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                />
                
                }
                handleSubmitEdit={() => updateName()}
            />
          
            <div className={classNames(conversationId !== id && ' invisible group-hover:visible','my-auto flex items-center')}>
                <ItemMenu options={menuOptions} />
            </div>
      </button>
  )
}

export default ConversationItem