
import { XIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useState } from 'react';
export default function AlertError({ title = "", children = "", dismissBtn = false }) {
  const [hidden, setHidden] = useState(false);
  return (
    <>
      {!hidden && (
        <div className="bg-red-50 my-2 rounded-xl shadow-md p-4 max-w-prose">
          <div className="flex items-center">
            <div className=" flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5  text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {title && (
                <h3 className="text-sm font-medium text-red-800">{title}</h3>
              )}
              <p className="text-sm text-red-700">{children} </p>
            </div>
            {dismissBtn && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => {
                      setHidden(true);
                    }}
                    className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
