import React, { useState } from "react";

import { useReactMediaRecorder } from "react-media-recorder";
import { useEffect } from "react";
import ApiRequest from "./../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "./../../api/apiConstants";
import { MicrophoneIcon } from "@heroicons/react/solid";
import { classNames } from "../misc/styling";

const VoiceToText = ({ text='', setText }) => {
  const { status, startRecording, stopRecording, mediaBlobUrl,custom } =
    useReactMediaRecorder({ audio: true });
  const [audioFile, setAudioFile] = useState(null);
  const handleStart = () => {
    try {
      startRecording();

    } catch (error) {
      console.error(error)
    }
  };

  const handleStopRecording = async () => {
    try {
          stopRecording();

    } catch (error) {
      console.error(error)
    }
  };

  const openaiApi = new ApiRequest(API_ROUTES.OPENAI, API_AUTH_TYPES.ADVISOR);

  useEffect(() => {
    if (!mediaBlobUrl) return;
    const handler = async () => {
      try {
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioFile = new File([audioBlob], "audio.wav", {
          type: "audio/wav",
        });
        setAudioFile(audioFile);
        const formData = new FormData(); // preparing to send to the server

        formData.append("audio", audioFile); // preparing to send to the server

        const response = await openaiApi.post({
          endpoint: "voice_to_text",
          body: formData,
        });
        setText(`${text} ${response}`);
      } catch (error) {
        console.error(error);
      }
    };
    handler();
  }, [mediaBlobUrl]);
  return (
    <div>
      <button
        className={classNames(
          status === "recording" && "animate-pulse",
          "rounded-full bg-blue-600 p-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        )}
        onClick={() => {
          status === "recording" ? handleStopRecording() : handleStart();
        }}
      >
        <MicrophoneIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default VoiceToText;
