import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { AlertError } from "../../../../components/alerts";
import {
  dateFormaterWithTime,
  dateFormatter,
} from "../../../../helpers/formatters";
import { patchReq } from "./../../../../apis/internalApi";
import { API } from "./../../../../constants/index";

const createUpdatedReminder = (current, allEdits, data) => {
  current.edits = allEdits;
  current.description = data.description;
  current.date = data.timer;
  current.title = data.title;
  return current;
};

const EditReminder = ({ switchShowEdit, current, timerArr, index }) => {
  const accessToken = useSelector((state) => state.user.accessToken);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: current.title,
      description: current.description,
      timer: dateFormaterWithTime(new Date(current.date)),
      repeat: "",
    },
  });

  const onSubmit = async (data) => {
    const newEdits = {
      edited_at: new Date(),
      description: current.description,
      date: current.date,
      title: current.title,
    };

    const allEdits = current.edits ? [...current.edits, newEdits] : [newEdits];
    try {
      const response = await patchReq(
        API.TIMERS,
        "edit_timer_auth_advisor",
        accessToken,
        { form: data, edits: allEdits, timer_id: current.timer_id }
      );
      allEdits[allEdits.length - 1].edited_by = response.data.edited_by;
      const updatedReminder = createUpdatedReminder(current, allEdits, data);
      timerArr.update(index, updatedReminder);
      reset();
      switchShowEdit();
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-3 mt-5  flex flex-col items-center"
    >
      {/* register your input into the hook by invoking the "register" function */}
      <div className="w-full">
        {" "}
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          name="reminder-name"
          autoComplete="false"
          placeholder="add a title..."
          className="mb-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          id="reminder-name"
          {...register("title", {
            required: "This is required",
          })}
        />{" "}
        {errors.title && <AlertError>{errors.title.message}</AlertError>}
      </div>
      <div className="w-full">
        {" "}
        <label
          htmlFor="timer-description"
          className="block text-sm font-medium text-gray-700"
        >
          Timer Description
        </label>
        <textarea
          id="timerDescription"
          rows={5}
          placeholder="add a description..."
          className=" mb-1       shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          {...register("description")}
        />
        {errors.description && (
          <AlertError>{errors.description.message}</AlertError>
        )}
      </div>
      <div className="w-full">
        {" "}
        <label
          htmlFor="timer-date"
          className="block text-sm font-medium text-gray-700"
        >
          Set Time
        </label>
        <input
          type="date"
          name="timer-date"
          className="mb-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          id="timer-date"
          {...register("timer", {
            required: "This is required",
            min: {
              message: "Cannot pick date in past or today",
              value: dateFormatter.format(new Date()),
            },
          })}
        />{" "}
        {errors.timer && <AlertError>{errors.timer.message}</AlertError>}
      </div>
      {/* <div className="w-full">
        {" "}
        <label
          htmlFor="timer-repeat"
          className="block text-sm font-medium text-gray-700"
        >
          Repeat?
        </label>
        <select
          id="timer-repeat"
          className="        shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md"
          {...register("repeat")}
        >
          <option hidden value="">
            Please Select
          </option>
          <option>Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
          <option value="Semi-Annually">Semi-Annualy</option>
          <option value="Annually">Annually</option>
        </select>
      </div> */}
      <button
        className="rounded-lg border-2 shadow-md hover:shadow-lg hover:brightness-110 py-2 px-5 "
        type="submit"
      >
        Edit Reminder
      </button>
      <button
        type="button"
        onClick={() => {
          switchShowEdit();
        }}
      >
        Cancel
      </button>
    </form>
  );
};

export default EditReminder;
