import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  accessToken: "",
  refreshToken: "",
  firstName: "",
  lastName: "",
  email: "",
  riskQ1: "",
  riskQ2: "",
  riskQ3: "",
  riskQ4: "",
  calendlyLink: "",
};
export const userSlice = createSlice({
  name: "user",
  mixpanelId: '',
  initialState,
  reducers: {
    r_clearUserSlice: () => initialState,
    r_mixpanelIdCreate: (state, action) => {
      state.mixpanelId = action.payload
    },
    setUser: (state, action) => {
      state.id = action.payload.id;
    },
    login: (state, action) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    setRiskTolerance: (state, action) => {
      state.riskQ1 = action.payload.riskQ1;
      state.riskQ2 = action.payload.riskQ2;
      state.riskQ3 = action.payload.riskQ3;
      state.riskQ4 = action.payload.riskQ4;
    },
    update: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    r_calendlyLink: (state, action) => {
      state.calendlyLink = action.payload
    },
    r_accessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    r_refreshToken: (state, action) => {
      state.refreshToken = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  r_clearUserSlice,
  setUser,
  login,
  setRiskTolerance,
  update,
  r_calendlyLink,
  r_accessToken,
  r_refreshToken,
  r_mixpanelIdCreate,
} = userSlice.actions;

export default userSlice.reducer;
