import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ApiRequest from '../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../api/apiConstants'
import { useParams } from 'react-router-dom'
import DashboardPanel from '../../../../components/panels/DashboardPanel'
import { classNames } from '../../../../helpers/classNames'
import PendingItem from './PendingItem'
import RiskToleranceActions from './RiskToleranceActions'
import { PencilIcon } from '@heroicons/react/outline'
import { ToolTipBottom, ToolTipHoverContainer } from '../../../../components/ToolTips'
const api = new ApiRequest(API_ROUTES.RISK_TOLERANCE, API_AUTH_TYPES.ADVISOR)
const RiskTolerance = () => {
  const { household_id: householdId } = useParams()
  const { data, isLoading } = useQuery({
    queryFn: api.getFn({ endpoint: 'household', params: [householdId] }),
    queryKey: [`risk-tolerance-actions-${householdId}`],
  })
  const [rationale, setRationale] = useState('')
  const [manualRiskTolerance, setManualRiskTolerance] = useState('')
  const [manualAssetAllocation, setManualAssetAllocation] = useState('')
  const [showManualUpdate, setShowManualUpdate] = useState(false)
  const [manualUpdateFor, setManualUpdateFor] = useState({})
  const queryClient = useQueryClient()

  const textColors = {
    'Very Aggressive': 'text-primary-800',
    'Aggressive': 'text-primary-600',
    'Moderate-Aggressive': 'text-primary-500',
    'Moderate': 'text-gray-800',
    'Moderate-Conservative': 'text-orange-500',
    'Conservative': 'text-orange-600',
    'Very Conservative': 'text-orange-800',
    '100/0': 'text-primary-800',
    '95/5': 'text-primary-800',
    '90/10': 'text-primary-600',
    '85/15': 'text-primary-600',
    '80/20': 'text-primary-500',
    '75/25': 'text-primary-500',
    '70/30': 'text-gray-800',
    '65/35': 'text-gray-800',
    '60/40': 'text-orange-500',
    '55/45': 'text-orange-500',
    '50/50': 'text-orange-600',
    '45/55': 'text-orange-600',
    '40/60': 'text-orange-800',
    '35/65': 'text-orange-800',
    '30/70': 'text-orange-800',
  }
  const acceptMutate = useMutation((result) =>
    api.patch({
      endpoint: 'manual_update', body: {
        type: manualUpdateFor.type,
        risk_tolerance: manualRiskTolerance,
        asset_allocation: manualAssetAllocation,
        rationale: rationale,
        user_id: manualUpdateFor.user_id || null,
        household_id: householdId
      },
    }), {
    onSuccess: () => {
      queryClient.invalidateQueries([`risk-tolerance-actions-${householdId}`])
      setShowManualUpdate(false)
      setRationale('')
      setManualRiskTolerance('')
      setManualAssetAllocation('')
      setManualUpdateFor({})
    }
  }
  )



  const formComplete = (manualAssetAllocation && manualRiskTolerance && rationale)
  if (isLoading) return <div>Loading...</div>

  return (
    <div>
      <div className='flex items-center justify-start gap-10 flex-wrap'>
        <div >
          <div className="flex justify-between items-center">
            <h4 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider">
              HOUSEHOLD
            </h4>
            <ToolTipHoverContainer>
              <ToolTipBottom>
                Update Risk Tolerance and Asset Allocation
              </ToolTipBottom>
              <button
                onClick={() => {
                  setShowManualUpdate(true)
                  setManualUpdateFor({
                    ...data.household, type: 'household'
                  })
                }}
              >

                <PencilIcon className="h-5 w-5" />
              </button>
            </ToolTipHoverContainer>

          </div>
          <ul>

            <div className="flex items-center space-x-4">
              {/* Risk Tolerance */}

              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">Risk Tolerance</dt>
                <dd className={classNames("mt-1 text-2xl font-semibold tracking-tight", textColors[data.household.risk_tolerance] || 'text-gray-500')}>{data.household.risk_tolerance || 'Not Set'}</dd>
              </div>
              {/* Asset Allocation */}
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">Asset Allocation</dt>
                <dd className={classNames("mt-1 text-2xl font-semibold tracking-tight", textColors[data.household.asset_allocation] || 'text-gray-500')}>{data.household.asset_allocation || 'Not Set'}
                </dd>
              </div>
            </div>
          </ul>
        </div>
        {data.users.map(user => (
          <div key={user.user_id}>
            <div className="flex justify-between items-center">
              <h4 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider">
                {`${user.first_name} ${user.last_name}`.toUpperCase()}
              </h4>
              <ToolTipHoverContainer>
                <ToolTipBottom>
                  Update Risk Tolerance and Asset Allocation
                </ToolTipBottom>
                <button
                  onClick={() => {
                    setShowManualUpdate(true)
                    setManualUpdateFor({
                      ...user, type: 'user', name: `${user.first_name} ${user.last_name}`
                    })
                  }}
                >

                  <PencilIcon className="h-5 w-5" />
                </button>
              </ToolTipHoverContainer>

            </div>

            <ul>

              <div className="flex items-center space-x-4">
                {/* Risk Tolerance */}

                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Risk Tolerance</dt>
                  <dd className={classNames("mt-1 text-2xl font-semibold tracking-tight", textColors[user.risk_tolerance] || 'text-gray-500')}>{user.risk_tolerance || 'Not Set'}</dd>
                </div>
                {/* Asset Allocation */}
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Asset Allocation</dt>
                  <dd className={classNames("mt-1 text-2xl font-semibold tracking-tight", textColors[user.asset_allocation] || 'text-gray-500')}>{user.asset_allocation || 'Not Set'}
                  </dd>
                </div>
              </div>
            </ul>
          </div>
        ))}
      </div>
      {showManualUpdate &&
        <div className='mt-5  max-w-prose'>
          <DashboardPanel title={`Update ${manualUpdateFor.name} ${manualUpdateFor.type === 'household' && 'HOUSEHOLD'}`}>
            <div className="flex items-center space-x-5">
              <div className="">
                <label htmlFor="manual_override_risk_tolerance">Risk Tolerance</label>
                <select
                  id='manual_override_risk_tolerance'
                  className='input-primary mt-1'
                  value={manualRiskTolerance}
                  onChange={(e) => setManualRiskTolerance(e.target.value)}
                >

                  <option hidden value="">Select Risk Tolerance</option>
                  <option value="Very Aggressive">Very Aggressive</option>
                  <option value='Aggressive'>Aggressive</option>
                  <option value='Moderate-Aggressive'>Moderate-Aggressive</option>
                  <option value='Moderate'>Moderate</option>
                  <option value='Moderate-Conservative'>Moderate-Conservative</option>
                  <option value='Conservative'>Conservative</option>
                  <option value='Very Conservative'>Very Conservative</option>


                </select>
              </div>
              <div className="">
                <label htmlFor="manual_override_asset_allocation">Asset Allocation</label>
                <select
                  id='manual_override_asset_allocation'
                  className='input-primary mt-1'
                  value={manualAssetAllocation}
                  onChange={(e) => setManualAssetAllocation(e.target.value)}
                >
                  <option hidden value="">Select Asset Allocation</option>
                  <option value="100/0">100/0</option>
                  <option value="95/5">95/5</option>
                  <option value="90/10">90/10</option>
                  <option value="85/15">85/15</option>
                  <option value="80/20">80/20</option>
                  <option value="75/25">75/25</option>
                  <option value="70/30">70/30</option>
                  <option value="65/35">65/35</option>
                  <option value="60/40">60/40</option>
                  <option value="55/45">55/45</option>
                  <option value="50/50">50/50</option>
                  <option value="45/55">45/55</option>
                  <option value="40/60">40/60</option>
                  <option value="35/65">35/65</option>
                  <option value="30/70">30/70</option>
                </select>
              </div>

            </div>
            <div className="mt-2">
              <label htmlFor="rationale">Rationale</label>
              <textarea
                id='rationale'
                className='input-primary mt-1 h-20'
                rows={5}
                placeholder='Enter rationale...'
                value={rationale}
                onChange={(e) => setRationale(e.target.value)}
              />
            </div>
            <div className="flex justify-end space-x-5 items-center mt-2">
              <button
                onClick={() => setShowManualUpdate(false)}
                className='underline'>
                Cancel
              </button>
              <button
                onClick={() => {
                  acceptMutate.mutate()
                }}
                disabled={!formComplete}
                className={classNames(!formComplete && 'opacity-60', 'btn-primary btn-md')}>
                {acceptMutate.isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </DashboardPanel>
        </div>
      }
      <div className="mt-10">
        <h4 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider">
          PENDING RISK TOLERANCE SUBMISSIONS
        </h4>
        <ul className='divide-y-4 divide-gray-400'>
          {
            data.pending.length > 0 ? data.pending.map(item => (
              <PendingItem item={item} />
            ))
              : <div className="flex"> <DashboardPanel>No pending risk tolerance submissions</DashboardPanel> </div>}
        </ul>
      </div>
      {data.actions.length > 0 && <div className="mt-10">
        <h4 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider">
          PREVIOUS RISK TOLERANCE ACTIONS
        </h4>
        <ul className='mt-3'>
          {
            data.actions.map((item, index) => (
              <RiskToleranceActions item={item} itemIdx={index} itemsLength={data.actions.length} />
            ))
          }
        </ul>
      </div>}
    </div>
  )
}

export default RiskTolerance