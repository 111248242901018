import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  title: "",
  href: "",
  category: {
    name: "",
    href: "",
  },
  read_time: 0,
  description: "",
  body: "",
  image_url: "",
  author: {
    href: "",
    name: "",
    image_src: "",
  },
  date: "",
  keywords: "",
};
export const blogSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    r_initialBlogEditState: (state, action) => {
      state.title = action.payload.title;
      state.href = action.payload.href;
      state.category.name = action.payload.category.name;
      state.category.href = action.payload.category.href;
      state.read_time = action.payload.read_time;
      state.description = action.payload.description;
      state.body = action.payload.body;
      state.id = action.payload.id;
      state.image_url = action.payload.image_url;
      state.author.href = action.payload.author.href;
      state.author.name = action.payload.author.name;
      state.author.image_src = action.payload.author.image_src;
      state.date = action.payload.date;
      state.keywords = action.payload.keywords;
    },
    r_title: (state, action) => {
      state.title = action.payload.title;
      state.href = action.payload.href;
    },
    r_category: (state, action) => {
      state.category.name = action.payload.name;
      state.category.href = action.payload.href;
    },
    r_read_time: (state, action) => {
      state.read_time = action.payload;
    },
    r_description: (state, action) => {
      state.description = action.payload;
    },
    r_body: (state, action) => {
      state.body = action.payload;
    },
    r_image_url: (state, action) => {
      state.image_url = action.payload;
    },
    r_keywords: (state, action) => {
      state.keywords = action.payload;
    },
    r_date: (state, action) => {
      state.date = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  r_initialBlogEditState,
  r_title,
  r_category,
  r_read_time,
  r_description,
  r_body,
  r_image_url,
  r_keywords,
  r_date,
} = blogSlice.actions;

export default blogSlice.reducer;
