import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiRequest from "../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../api/apiConstants";
import { useParams } from "react-router-dom";
import Toggle from "../../../../../components/toggle";

const YouvestAccess = () => {
  const { household_id: householdId } = useParams();
  const householdsApi = new ApiRequest(
    API_ROUTES.HOUSEHOLDS,
    API_AUTH_TYPES.ADVISOR
  );
  const { data, isLoading } = useQuery({
    queryFn: householdsApi.getFn({
      endpoint: "youvest_access",
      params: [householdId],
    }),
    queryKey: `youvest-access-${householdId}`,
  });
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      householdsApi.patch({
        endpoint: "youvest_access",
        body: {
          household_id: householdId,
          youvest_access: !data?.youvest_access,
        },
      }),
    {
      onSuccess: ({ update_youvest_access }) => {
        queryClient.invalidateQueries(`youvest-access-${householdId}`);
        queryClient.setQueryData("youvest-access", {
          youvest_access: update_youvest_access,
        });
      },
    }
  );
  if (isLoading) return <div>Loading...</div>;
  return (
    <div className="flex items-center space-x-2 justify-between">
      {" "}
      <label htmlFor="youvest-access" className="text-gray-600">
       YouVest Access
      </label>
      <Toggle
        enabled={data?.youvest_access}  
        setEnabled={() => mutation.mutate()}
      />
    
    </div>
  );
};

export default YouvestAccess;
