import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import React from "react";
import { TemplateIcon } from "@heroicons/react/outline";
import { useState } from "react";

import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../api/apiConstants";
import { useMutation, useQueryClient } from "react-query";
import { ApiRequest } from "../../../../../../api";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import ItemMenu from "../../../../../../components/menus/ItemMenu";
import { classNames } from "../../../../../../helpers/classNames";
import { MdOutlineDragIndicator } from "react-icons/md";
import DraggableItem from "../../../../../../components/drag-drop/DraggableItem";
import DropTarget from "../../../../../../components/drag-drop/DropTarget";
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import QuillTextEditor from "../../../../../../components/textEditor/Quill-Text-Editor";

const ChapterGoal = ({ goal, queryKeys }) => {
  const [showEdit, setShowEdit] = useState(false);

  const goalsApi = new ApiRequest(API_ROUTES.HOUSEHOLD_GOALS, API_AUTH_TYPES.ADVISOR);
  const queryClient = useQueryClient();


  const handleUpdateStatus = useMutation(async ({ new_status }) => {
    await goalsApi.patch({ endpoint: `update_status`, params: [goal.goal_id], body: { new_status } })

  }, {
    onSuccess: () => {

      queryClient.invalidateQueries(queryKeys.goals);
      queryClient.invalidateQueries(queryKeys.chapters);

    }
  })


  const templateApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_TEMPLATES, API_AUTH_TYPES.ADVISOR);
  const { mutate: postTemplate } = useMutation(
    (body) => templateApi.post({ endpoint: "post_template", body: { content: goal.content, type: 'goal' } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('goal-templates')
      },
    }
  );

  const menuOptions = [
    {
      title: "Edit",
      action: () => setShowEdit(true),
      icon: PencilIcon,
    },
    {
      title: "Create Template",
      action: postTemplate,
      icon: TemplateIcon,
    },
    {
      title: "Delete",
      action: () => handleUpdateStatus.mutate({ new_status: 'archived' }),
      icon: TrashIcon,
    },


  ];

  const { mutate: updateGoalOrder } = useMutation(
    (body) => goalsApi.patch({ endpoint: "update_order", body }),
    {
      onSuccess: ({ old_chapter_status, new_chapter_status }) => {
        queryClient.invalidateQueries(
          queryKeys.goals
        );
        queryClient.invalidateQueries(queryKeys[old_chapter_status]);
        queryClient.invalidateQueries(queryKeys[new_chapter_status]);
      },
    }
  );

  const handleGoalReorder = (item) => {

    updateGoalOrder({
      item_id: item.content.props.goal?.goal_id,
      dropped_over_id: goal.goal_id,
      dropped_over_list_id: goal.chapter_id,
      item_list_id: item.content.props.goal?.chapter_id,
      item_type: 'goal'
    });
  }

  const handleSave = () => {
    queryClient.invalidateQueries(queryKeys.goals);
    setShowEdit(false);
  };
  const options = {
    is_draft: false,
    route: "household_goals",
    search_params: { document_id: goal.goal_id },
  };

  const queryKey = `${goal.goal_id}`;
  
  return (
    <DropTarget accept="goal" onDrop={(item) => handleGoalReorder(item)} isActiveStyling={'pt-16 drop-target '} >

      <div className={classNames("text-gray-800  flex justify-between  border-2 bg-white  rounded-md")}>
        <div className=" px-3 py-4 w-full text-left">
          <DoubleClickEdit
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            defaultComponent={<RichTextDisplay content={goal.content} thisClassName='markdown-notes' />}
            editComponent={
              <div className="w-full">
                {goal.content ? (
                  <QuillTextEditor
                    placeholder="Create New Goal"
                    options={options}
                    queryKey={queryKey}
                    listQueryKey={queryKeys.goals}
                  />
                ) : (
                  <>
                    {/* <MarkdownNotes noteRaw={task.description} /> */}
                    <p>Can't edit old note editor tasks</p>
                  </>
                )}
              </div>
            }
            handleSubmitEdit={handleSave}
            componentName="description"
          />
        </div>
        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500  flex justify-end item-center space-x-5">



          <div className="ml-2 flex flex-shrink-0 space-x-2 items-center ">
            <DraggableItem
              isDraggable={true}
              type='goal'
              item={{
                type: 'goal',
                content:
                  <ChapterGoal goal={goal} queryKeys={queryKeys} />
              }}
            >
              <MdOutlineDragIndicator className="h-6 w-6 text-gray-500" />
            </DraggableItem>
            <ItemMenu options={menuOptions} />
          </div>


        </div>
      </div>
    </DropTarget>
  );
};

export default ChapterGoal;
