
import { useState } from "react";
import {
    SelectorIcon,
    SearchIcon,
    XIcon,
} from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { useEffect } from "react";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../api";
import { useQuery } from "react-query";
import { classNames } from "../../../misc/styling";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Toggle from "../../../toggle";







export default function ContextSelection({ household = '', setHousehold = () => { } ,enableContext,setEnableContext}) {
    const params = useParams();
    const [query, setQuery] = useState("");

    const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);
    const households = useQuery({
        queryFn: householdsApi.getFn({
            endpoint: 'index_all_households'
        }
        )
        ,queryKey: 'households-search'
    })
    let filteredItems =
        query === ""
            ? households.data
            : households.data.filter((item) => {
                return item.name.toLowerCase().includes(query.toLowerCase());
            })
    filteredItems = filteredItems ? [...filteredItems, { name: 'All Households', household_id: 'mental_models' }] : []
    

    useEffect(() => {
        if (!households.isSuccess) return
        if (params?.household_id) {
            setHousehold(households.data.find(household => household.household_id === params.household_id))
        }
    }, [params?.household_id, households.data]);


    useEffect(() => {
        if (!households.isSuccess) return
        setEnableContext(household?.household_id ? true : false)
    }, [household.isSuccess, household?.household_id])
    return (
        <div className="flex items-center space-x-2">
            <p className="text-white">Context</p>
            <Toggle enabled={enableContext} setEnabled={setEnableContext} color='gray'/>
            <Combobox as="div" className="relative w-full max-w-[250px]" value={household} onChange={setHousehold}>
                {" "}
                {query.length === 0 && !household ? (
                    <Combobox.Label className=" absolute top-1/2 left-3 z-10 -translate-y-1/2 text-sm font-medium flex space-x-1 items-center text-gray-400">
                        <SearchIcon
                            className="flex-shrink-0 h-5 w-5 z-10"
                            aria-hidden="true"
                        />{" "}
                        <p> Search Context</p>
                    </Combobox.Label>
                ) : null}
                <div className="relative">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-200 sm:text-sm"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(item) => item?.name}
                    />
                    <Combobox.Button className="absolute z-10 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                    </Combobox.Button>
                    <button
                    onClick={() => {setQuery(''); setHousehold({})}}
                        className="absolute z-10 inset-y-0 right-5 flex items-center rounded-r-md px-2 focus:outline-none">
                        <XIcon className='h-4 w-4 text-gray-400'/>
                    </button>

                    {filteredItems?.length > 0 && (
                        <Combobox.Options className="absolute z-20 mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredItems.map((item) => (
                                <Combobox.Option
                                    key={item.id}
                                    value={item}
                                    className={({ active }) =>
                                        classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active ? "text-gray-900 bg-gray-200" : "text-gray-900"
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <button
                                                onClick={() => setHousehold(item)}
                                                className={classNames(
                                                    "block truncate",
                                                    selected && "font-semibold"
                                                )}
                                            >
                                                {item.name}
                                            </button>

                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        </div>
    );
}
