import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import { XCircleIcon } from "@heroicons/react/solid";
import { MdAvTimer } from "react-icons/md";
import ReactDatePicker from "react-datepicker";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const VisibleOn = ({ handleSubmitEdit, isClientPortal, task }) => {
    const [value, setValue] = useState(task.visible_on);
    const [showEdit, setShowEdit] = useState(false);

    const handleChange = (newValue) => {
        setValue(newValue === "Always Visible" ? null : newValue);
        handleSubmitEdit(newValue);
        setShowEdit(false);
    };

    const handleClearValue = () => {
        setValue(null);
        handleSubmitEdit(null);
        setShowEdit(false);
    }

    useEffect(() => {
        setValue(task.visible_on);
    }, [task.visible_on]);

    const isVisibleNow = useMemo(() => {
        if (value === "Always Visible")  return true;
        if (new Date(value).getTime() < new Date().getTime()) return true
        return false
    }, [value]);
    return (
        <>


            {isClientPortal ?

                <div className="">


                </div> :
                <DoubleClickEdit
                    showEdit={showEdit}
                    setShowEdit={setShowEdit}
                    defaultComponent={
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          
                            {value ? (
                                <>
                                    {isVisibleNow ? <FaRegEye
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                    /> : <FaRegEyeSlash 
                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                    />}
                                <div className="group flex items-center space-x-2">
                                    <p>Visible on {new Date(value).toLocaleDateString()}</p>
                                    <button onClick={handleClearValue} className="w-5 invisible group-hover:visible">
                                        <XCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                    </div>
                                </>
                            ) : (
                                    <>
                                        <FaRegEye  
                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <p className="min-w-max">Always Visible</p>
                                    </>
                            )}
                        </div>
                    }
                    editComponent={

                        <ReactDatePicker
                            className="input-primary "
                            selected={value}
                            onChange={(date) => handleChange(new Date(date))}
                        />

                    }
                    handleSubmitEdit={() => { }}
                    componentName="visible_on"
                />}
        </>
    );
};

export default VisibleOn;
