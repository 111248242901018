import React from 'react'
import { useState } from 'react';
import { useCreateMutation } from '../../../../api/apiSlice';
import ButtonDropdown from '../../../ButtonDropdown';
import { useMutation, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../api';

const AddNewView = ({ showAddView, setShowAddView,smartTableId }) => {
  const smartTableApi = new ApiRequest(API_ROUTES.SMART_TABLE_VIEWS, API_AUTH_TYPES.ADVISOR)

  const [newViewName, setNewViewName] = useState("");
  const [whoCanEdit,setWhoCanEdit] = useState('personal');
  const queryClient = useQueryClient()
  const handleAddNewView = useMutation(() => {
    smartTableApi.post({
      endpoint: 'create',
      body: {
        name: newViewName || "New View Name",
        who_can_edit: whoCanEdit,
        smart_table_id: smartTableId
      }
    })
  },
    {
      onSuccess: () => {
        setShowAddView(false);
        setNewViewName("");
        setWhoCanEdit("personal");
        queryClient.invalidateQueries('smart-table');

    }
  })



  return (
    <ButtonDropdown open={showAddView} setOpen={setShowAddView}>
      <div className="w-56 p-2">
        {" "}
        <input
          type="text"
          name="New View"
          autoComplete="off"
          className="input-primary"
          placeholder="New View Name"
          value={newViewName}
          onChange={(e) => setNewViewName(e.target.value)}
        />
        <div className="mt-4"> <label htmlFor="who-can-edit" className="">
        Who can edit</label>
        <select name="who-can-edit" className="input-primary" id="who-can-edit" value={whoCanEdit} onChange={(e) => setWhoCanEdit(e.target.value)}>
          <option value="personal">Personal</option>
          <option value="colaborative">Colaborative</option>
        </select></div>
       
        <div className="flex justify-end w-full mt-5 space-x-3">
          <button
            className="border-b border-transparent hover:border-gray-300"
            onClick={() => {
              setShowAddView(false);
              setNewViewName("");
            }}
          >
            Cancel
          </button>
          <button onClick={handleAddNewView.mutate} className="btn-primary btn-md">
            Create new view
          </button>
        </div>
      </div>
    </ButtonDropdown>
  );
}

export default AddNewView