import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import EditUser from './SliderBodys/EditUser';

const ContactSlideOver = ({
  open,
  setOpen,
  person,
  title,
  subtitle,
  type,
  householdId
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-hidden bg-white">
                    {/* Header */}
                    <div className="bg-white border-b border-gray-200 px-4 py-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-900">
                            {title}
                          </Dialog.Title>
                          {subtitle && (
                            <p className="mt-2 text-base sm:text-lg text-gray-600 max-w-prose">
                              {subtitle}
                            </p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="btn-gray-outline btn-md flex-shrink-0"
                          onClick={() => setOpen(false)}
                        >
                          <XIcon className="h-5 w-5" />
                          <span className="sr-only">Close panel</span>
                        </button>
                      </div>
                    </div>

                    {/* Content Area */}
                    <div className="flex-1 overflow-y-auto bg-gray-50">
                      <div className="px-4 py-6 sm:px-6">
                       
                        {(type === "edit user" || type === "new user") && (
                            <EditUser
                              person={person}
                              type={type}
                            householdId={householdId}
                            closeSlider={() => setOpen(false)}
                            />
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactSlideOver;