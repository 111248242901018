import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { MdOutlineChangeCircle } from "react-icons/md";
import { ToolTipHoverContainer, ToolTipLeft } from '../../../../../components/ToolTips';


/**
 * Component for displaying and updating the advisor referrer for a household.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.householdsApi - API object for performing household-related operations.
 * @param {Object} props.household - The current household object.
 * @returns {JSX.Element} A component for viewing and editing the advisor referrer.
 */
const AdvisorReferrer = ({ householdsApi, household }) => {
    const { household_id: householdId } = useParams();
    const [showSearch, setShowSearch] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [newValue, setNewValue] = useState({});
    const queryClient = useQueryClient()
    const updateSource = useMutation(
        () => householdsApi.patch({
            endpoint: 'advisor_referrer',
            body: { household_id: householdId, new_value: newValue }
        })

        , {
            onSuccess: () => {
                setShowSuccess(true);
                queryClient.invalidateQueries(`source-${householdId}`)
                setShowSearch(false);

                setTimeout(() => setShowSuccess(false), 3000);
            }
        }
    )
    useEffect(() => {
        if (household.advisor_referrer) {
            setNewValue(household.advisor_referrer);
        }
    }, [household.advisor_referrer]);

    const advisorReferrers = [
        { color: 'bg-gray-500', value: "Delyanne the Money Coach", order: 1 },
        { color: 'bg-gray-500', value: "Valerie Riveria", order: 2 },
        { color: 'bg-gray-500', value: "Bill Mulvahill - Trailhead Planners", order: 3 },
        { color: 'bg-gray-500', value: "Michelle Klisanich - Birch Cove", order: 4 },
        { color: 'bg-gray-500', value: "Houston Financial Synergies", order: 5 },
        { color: 'bg-gray-500', value: "Amber Miller - Rising Financial", order: 6 },
        { color: 'bg-gray-500', value: "Nick Augustin - Mindful Asset Planning", order: 7 },
        { color: 'bg-gray-500', value: "Kim Rubenstein", order: 8 },
        { color: 'bg-gray-500', value: "Maggie Klokkenga", order: 9 },
        { color: 'bg-gray-500', value: "Ashley Coake", order: 10 },
        { color: 'bg-gray-500', value: "Luis Guardia", order: 11 },
        { color: 'bg-gray-500', value: "Barbara Warner - Warner Financial", order: 12 },
        {
            color: 'bg-gray-500',
            value: "Danny Beck",
            order: 13
        },
        {
            color: 'bg-gray-500',
            value: "Rob Berger",
            order: 14
        },
        {
            color: 'bg-gray-500',
            value: "Steve Drost",
            order: 15
        },
        {
            color: 'bg-gray-500',
            value: "Riki Cooke",
            order: 16
        },
        {
            color: 'bg-gray-500',
            value: "Bjorn Amundson",
            order: 17
        },
        {
            color: 'bg-gray-500',
            value: "Jim Davis",
            order: 18
        },
        {
            color: 'bg-gray-500',
            value: "Cody Warren",
            order: 19
        },
        {
            color: 'bg-gray-500',
            value: "Summit Financial",
            order: 20
        },
        {
            color: 'bg-gray-500',
            value: "Wise Stewardship Financial Planning",
            order: 21
        },
        {
            color: 'bg-gray-500',
            value: "Streamline Financial",
            order: 22
        },
        {
            color: 'bg-gray-500',
            value: "Darrin Swanson",
            order: 23
        },
        {
            color: 'bg-gray-500',
            value: "Robert Darrow",
            order: 24
        },
        {
            color: 'bg-gray-500',
            value: "Mike Piper",
            order: 25
        },
        {
            color: 'bg-gray-500',
            value: "Jason Howell",
            order: 26
        }

    ]
    return (
        <div>{(household?.advisor_referrer && !showSearch) ?
            (
                <div className='flex  justify-between items-end'>
                    <div className="">
                        <p className="text-gray-600 text-sm">Referred By</p>

                        <p className='font-semibold'>{household.advisor_referrer}</p>{" "}  
                    </div>
                    <ToolTipHoverContainer>
                        <button onClick={() => setShowSearch(true)} className='hover:text-gray-600'>
                            <MdOutlineChangeCircle className='h-5 w-5 text-gray-500' />
                            <ToolTipLeft>
                                Update outside referrer
                            </ToolTipLeft>
                        </button>
                    </ToolTipHoverContainer>
                </div>

            ) : (
                <div className='space-y-1'>
                    <p className="text-gray-600 text-sm">Referred By</p>

                    {showSearch ? (

                        <div className='flex space-x-2 items-center py-1'>
                            <select className='input-primary'
                                defaultValue={newValue}
                                onChange={(e) => setNewValue(e.target.value)}
                            >
                                {advisorReferrers.map((source) => (
                                    <option key={source.value} value={source.value}>
                                        {source.value}
                                    </option>
                                ))}
                            </select>

                            <button className="btn-primary btn-md" onClick={() => updateSource.mutate()}>Submit</button>

                            <button className="underline" onClick={() => setShowSearch(false)}>Cancel</button>
                        </div>
                    ) : (
                        <button
                            onClick={() => {
                                setShowSearch(true);
                            }}
                            className="btn-primary  px-3 py-0.5"
                        >
                            <p>Select</p>{" "}

                        </button>
                    )}
                </div>
            )}{" "}
            {showSuccess && <CheckCircleIcon className="h-5 w-5 text-green-500" />}</div>
    )
}

export default AdvisorReferrer