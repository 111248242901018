import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'
import { useAppContext } from '../../context/app-context'

const SuccessToast = ({ toast }) => {
    const {removeToast} = useAppContext()
  return (
      <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <div className="pointer-events-auto w-full max-w-sm  rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
                      <div className="p-4">
                          <div className="flex items-start">
                              <div className="flex-shrink-0">
                                  <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-400" />
                              </div>
                              <div className="ml-3 w-0 flex-1 pt-0.5">
                                  <p className="text-sm font-medium text-gray-900">{toast.title}</p>
                                  <p className="mt-1 text-sm text-gray-500">{toast.description}</p>
                              </div>
                              <div className="ml-4 flex flex-shrink-0">
                                  <button
                                      type="button"
                                      onClick={() => {
                                          removeToast(toast.id)
                                      }}
                                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                  >
                                      <span className="sr-only">Close</span>
                                      <XIcon aria-hidden="true" className="h-5 w-5" />
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
          </div>
      </div>  )
}

export default SuccessToast