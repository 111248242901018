import React from "react";

import { useState } from "react";
import FadeIn from "../../../../components/transitions/FadeIn";
import { classNames } from "../../../../helpers/classNames";
import AddTimer from "./AddTimer";
import Reminder from "./components/Reminder";
import CompletedReminder from './components/CompletedReminder';
import { useParams } from "react-router-dom";
import { BasicPanel } from "../../../../components/panels";

const HHReminders = ({ timers }) => {
  const {household_id: householdId} = useParams()
  const [open, setOpen] = useState(false);
  const [module, setModule] = useState("Open");
  const tabs = [
    // { name: "Focuses", current: module === "Focuses" },
    { name: "Open", current: module === "Open" },
    { name: "Completed", current: module === "Completed" },
  ];
  return (
    <BasicPanel>
      <h1 className="crm-title mb-7 ">Reminders</h1>
      <div className="flex w-full  justify-center mb-4">
        {" "}
        <button
          onClick={() => setOpen(!open)}
          className={classNames(open && "hidden", "btn-primary btn-md")}
        >
          Add Reminder
        </button>{" "}
      </div>{" "}
      <FadeIn isShowing={open}>
        <AddTimer
          householdId={householdId}
          pushTimer={timers.push}
          open={open}
          setOpen={setOpen}
        />
      </FadeIn>
      <div className="flex justify-center">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex " aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setModule(tab.name)}
                className={classNames(
                  tab.current
                    ? "border-gray-500 text-gray-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 w-32 border-b-2 font-medium text-md"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <ul className="space-y-2 my-4 ">
        {module === "Open"
          ? timers.map(
              (timer, index) =>
                !timer.is_completed && (
                  <Reminder
                    key={timer.timer_id}
                    timer={timer}
                    timerArr={timers}
                    index={index}
                  />
                )
            )
          : timers.map(
              (timer, index) =>
                timer.is_completed && (
                  <CompletedReminder
                    key={timer.timer_id}
                    timer={timer}
                    timerArr={timers}
                    index={index}
                  />
                )
            )}
      </ul>{" "}
    </BasicPanel>  
  );
};

export default HHReminders;
