import React from "react";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import useNav from "../../../../hooks/useNav";

const HouseholdLink = ({ data }) => {
  const nav = useNav();
  return (
    <>
      {" "}
      {data?.household_id ? (
        <button
          onClick={() => {
            nav(`/crm/households/${data.household_id}`);
          }}
          className="flex items-center text-left px-2"
        >
          <p>{data.household_name}</p>{" "}
          <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0" />
        </button>
      ) : (
          <div>
            
        </div>
      )}{" "}
    </>
  );
};

export default HouseholdLink;
