import React, { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "./../SmartTable___EditCell";

/**
 * A UTC-based formatter for date + time that avoids local time shifts.
 */
function dateFormatterWithTimeUTC(date) {
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;

  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year} ${strTime}`;
}

function dateFormatterWithTimeLocal(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year} ${strTime}`;
}


/**
 * Display a date (as an ISO string) in UTC without shifting days.
 */
const displayFormattedDate = (isoString, showTime) => {
  if (!isoString) return null;
  const d = new Date(isoString);
  if (isNaN(d)) return "Invalid Date";

  if (showTime) {
    return dateFormatterWithTimeLocal(d);
  } else {
    const month = String(d.getUTCMonth() + 1).padStart(2, "0");
    const day = String(d.getUTCDate()).padStart(2, "0");
    const year = d.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }
};

/**
 * Convert from initDate ISO string to an input-friendly string:
 * - For date fields: YYYY-MM-DD
 * - For datetime fields: YYYY-MM-DDThh:mm
 */
const extractDateString = (initDate, showTime) => {
  if (!initDate) return "";
  const d = new Date(initDate);
  if (isNaN(d)) return "";

  const year = d.getUTCFullYear();
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const day = String(d.getUTCDate()).padStart(2, "0");

  if (!showTime) {
    return `${year}-${month}-${day}`;
  } else {
    const hours = String(d.getUTCHours()).padStart(2, "0");
    const minutes = String(d.getUTCMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
};

const DateComponent = ({
  initDate,
  showTime = false,
  editable,
  table,
  colId,
  accessToken,
  rowId,
  editId
}) => {
  // A state for the displayed date (for optimistic updates)
  const [displayDate, setDisplayDate] = useState(initDate);

  // A state for the editable date input value
  const [dateValue, setDateValue] = useState(extractDateString(initDate, showTime));

  const [showEdit, setShowEdit] = useState(false);

  const submitChanges = async () => {
    let isoStringToSave = null;
    let oldDisplayDate = displayDate;

    try {
      if (dateValue) {
        if (!showTime) {
          // Date-only: YYYY-MM-DD
          const [year, month, day] = dateValue.split("-").map(Number);
          const selectedDate = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
          isoStringToSave = selectedDate.toISOString();
        } else {
          // Datetime: YYYY-MM-DDThh:mm
          const [datePart, timePart] = dateValue.split("T");
          const [year, month, day] = datePart.split("-").map(Number);
          const [hours, minutes] = timePart.split(":").map(Number);
          const selectedDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, 0));
          isoStringToSave = selectedDate.toISOString();
        }
      }

      // Optimistic update: set displayDate right away
      setDisplayDate(isoStringToSave);

      await patchReq(table, "smart_table_update", accessToken, {
        value: isoStringToSave,
        row_id: editId,
        col_id: colId,
      });

      setShowEdit(false);
    } catch (error) {
      console.error("Error updating date:", error);
      // Revert on error
      setDisplayDate(oldDisplayDate);
    }
  };

  return (
    <div className="smart-table-cell">
      {editable ? (
        <SmartTableEditCell
          date={dateValue}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={
            <time className="">
              {displayDate ? displayFormattedDate(displayDate, showTime) : null}
            </time>
          }
          editComponent={
            <div className="relative">
              <input
                type={showTime ? "datetime-local" : "date"}
                name={`date_${colId}`}
                id={`date_${colId}`}
                value={dateValue}
                onChange={(e) => setDateValue(e.target.value)}
                className="shadow-sm absolute -top-5 border-2 focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md"
              />
            </div>
          }
        />
      ) : (
        <time className="">
          {displayDate ? displayFormattedDate(displayDate, showTime) : null}
        </time>
      )}
    </div>
  );
};

export default DateComponent;
