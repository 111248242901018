import React, { useEffect, useState } from 'react'
import socketIoConnection from '../../../../../../socketIoConnect';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SparklesIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../api';
import useArray from "../../../../../../hooks/useArray"
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import FadeIn from '../../../../../../components/transitions/FadeIn';
import ParabotLoading from '../../../../../../components/ParabotLoading';
import SuggestionsController from './SuggestionsController';

const TaskSuggestions = ({ queryKeys,chapterId }) => {
    const { household_id: householdId } = useParams();
    const [loading,setLoading] = useState(false)
    const [socket, setSocket] = useState(null);
    const [hidden, setHidden] = useState(false);
    const suggestions = useArray([])
    useEffect(() => {
        const socket = socketIoConnection("story-generator");
        setSocket(socket);
        socket.on(`completion-done-${chapterId}`, (newSuggestions) => {
            suggestions.set(newSuggestions);
            setLoading(false)
        });


        return () => {
            socket.disconnect();
        };
    }, []);

   const createSuggestions = () => {
        socket.emit("task-suggestions", {
            chapter_id: chapterId,
            household_id: householdId,
        });
       setLoading(true)
       setHidden(false)
    }

    const tasksApi = new ApiRequest(API_ROUTES.CLIENT_TASKS, API_AUTH_TYPES.ADVISOR);
    const queryClient = useQueryClient();
    const { mutate: createSuggestion } = useMutation(
        ({ content }) => tasksApi.post({ endpoint: "post_task", body: { chapter_id: chapterId, household_id:householdId ,content } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.tasks);
            },
        }

    );
    return (
        <div>
            <SuggestionsController loading={loading}
                setLoading={setLoading} hidden={hidden} setHidden={setHidden} suggestionsCreated={suggestions.array.length > 0}
                createSuggestions={createSuggestions} />
            <FadeIn isShowing={!loading && !hidden}>
            <ul className='space-y-2'>
                {suggestions.array.map((suggestion, index) => {
                    return <li key={suggestion.title}>
                            <button className='btn-xl btn-gray-outline w-full items-start text-left flex text-gray-500'
                            onClick={() => {
                                createSuggestion({ content: suggestion.content });
                                suggestions.remove(index)
                            }}
                        >
                            <SparklesIcon className='h-5 w-5 flex-shrink-0 text-blue-500 mr-3' />
                            <RichTextDisplay thisClassName="notes" content={suggestion.content} />
                        </button>
                    </li>
                })}
                </ul>
            </FadeIn>
        </div>
    )
}

export default TaskSuggestions