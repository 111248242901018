import React from 'react'


const DashboardPanel = ({ title, children, SecondaryTitleComponent, description }) => {
    return (
        <div>
            <div className="flex justify-between items-center mb-3 ">
                {title &&
                    <h4 className="text-sm font-semibold leading-7   sm:leading-9  tracking-wider">
                        {`${title} `.toUpperCase()}
                    </h4>
                }
                {SecondaryTitleComponent && SecondaryTitleComponent}
            </div>

            <div className={" shadow-md bg-white rounded-xl "}>
                <div className="p-6">
                    {description && <p className='text-gray-600 text-sm mb-2'>{description}</p>}
                    {children}
                </div>
            </div>
        </div>
    )
}




export default DashboardPanel