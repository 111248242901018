import React, { useState } from 'react'
import ImageGallery from '../ImageGallery'
import { ToolTipHoverContainer, ToolTipTop } from '../../../../ToolTips'
import { HiOutlineStop } from 'react-icons/hi2'
import { IoMdSend } from 'react-icons/io'
import { classNames } from '../../../../misc/styling'
import cloudinaryImageUpload from '../../../../../helpers/cloudinaryImageUpload'
import handleSpreadsheetUpload from './handleSpreadsheetUpload'
import { UploadIcon } from '@heroicons/react/outline'
import FileUploadButton from './FileUploadButton'
import PromptList from '../Prompts-List'

const TextBox = ({ files, setFiles, query, setQuery, loading, handleStopGenerating, handleSubmit, textAreaRef, openExpandedImage }) => {

    const handleInput = () => {
        const textAreaNode = textAreaRef.current;
        textAreaNode.style.height = "auto";
        textAreaNode.style.height = `${textAreaNode.scrollHeight}px`;
    };




    const addImage = (url) => {
        const urls = Array.isArray(url) ? url : [url];

        setFiles((currentFiles) => {
            // Check if 'url' is an array
            return [...currentFiles, ...urls];
        });
    };

    const addSpreadsheet = (data) => {
        setFiles((currentFiles) => {
            // Check if 'url' is an array
            return [...currentFiles, data];
        });

    }

    const [uploading, setUploading] = useState(false);
    const handleFileChange = async (files) => {
        if (files.length > 0) {
            setUploading(true);

            const imageFiles = files.filter(file => file.type.startsWith('image/') || file.type === 'application/pdf');

            const otherFiles = files.filter(file =>
                file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type === 'text/csv' ||
                file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                file.type === 'text/plain'
            ).map(file => {
                if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv') {
                    return {
                        file,
                        type: 'spreadsheet'
                    };
                }
                if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                    return {
                        file,
                        type: 'powerpoint'
                    };
                }
                if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'text/plain') {
                    return {
                        file,
                        type: 'document'
                    };
                }
            });

            try {
                // Handle image uploads
                if (imageFiles.length > 0) {
                    const imageUploadPromises = imageFiles.map(file => cloudinaryImageUpload(file, 'parabot'));
                    const imageUploadUrls = await Promise.all(imageUploadPromises);
                    const flattenedImageUploadUrls = imageUploadUrls.flat(); // Flatten the array of arrays
                    flattenedImageUploadUrls.forEach(url => addImage({ url, type: 'image' })); // Emit each uploaded image URL to the parent component
                }

                // Handle other files uploads
                if (otherFiles.length > 0) {
                    const spreadsheetFiles = otherFiles.filter(file => file.type === 'spreadsheet');
                    const powerpointFiles = otherFiles.filter(file => file.type === 'powerpoint');
                    const documentFiles = otherFiles.filter(file => file.type === 'document');

                    // Implement specific upload functions for each type
                    if (spreadsheetFiles.length > 0) {
                        const spreadsheetUploadPromises = spreadsheetFiles.map(file => handleSpreadsheetUpload(file.file));
                        const spreadsheetUploadResults = await Promise.all(spreadsheetUploadPromises);
                        spreadsheetUploadResults.forEach(result => addSpreadsheet({ ...result, type: 'spreadsheet' })); // Emit each uploaded spreadsheet data to the parent component
                    }

                    if (powerpointFiles.length > 0) {
                        const powerpointUploadPromises = powerpointFiles.map(file => handleSpreadsheetUpload(file.file));
                        const powerpointUploadResults = await Promise.all(powerpointUploadPromises);
                        powerpointUploadResults.forEach(result => addSpreadsheet({ ...result, type: 'powerpoint' })); // Emit each uploaded powerpoint data to the parent component
                    }

                    if (documentFiles.length > 0) {
                        const documentUploadPromises = documentFiles.map(file => handleSpreadsheetUpload(file.file));
                        const documentUploadResults = await Promise.all(documentUploadPromises);
                        documentUploadResults.forEach(result => addSpreadsheet({ ...result, type: 'document' })); // Emit each uploaded document data to the parent component
                    }
                }
            } catch (error) {
                console.error('Upload Error:', error);
                alert('Upload failed, please try again.');
            } finally {
                setUploading(false);
            }
        }
    };



    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        const files = [];
        for (const item of items) {
            if (item.type.indexOf("image") === 0) {
                files.push(item.getAsFile());
            }
        }
        if (files.length > 0) {
            handleFileChange(files);
        }
    };
    const [isDragActive, setIsDragActive] = useState(false);

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);  // Reset drag active when dropping
        const files = Array.from(event.dataTransfer.files).filter(file =>
            file.type.startsWith('image/') ||
            file.type === 'application/pdf' ||
            file.type === 'text/csv' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'text/plain'
        );
        if (files.length > 0) {
            handleFileChange(files);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(true);  // Set drag active when dragging over
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);  // Reset drag active when dragging leaves
    };
    const removeFile = (index) => {
        setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            handleSubmit(query);
        }
    };
    return (
        <div onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handleDragLeave} className="absolute -bottom-8 left-1/2 -translate-x-1/2 w-11/12 sm:w-3/4 bg-white border border-gray-300 focus:border-gray-400 focus:shadow-sm rounded-2xl  mb-5 py-1 mx-auto  px-4">
            <div className="absolute left-0 -top-10"><PromptList handleSendPrompt={handleSubmit} /></div>

            {
                isDragActive && (
                    <div className='flex ml-2 items-center'>
                        <UploadIcon className='text-gray-500 h-4 w-4' />
                        <p className='ml-2 text-gray-500'>
                            Drop files to upload
                        </p>
                    </div>
                )
            }
            <div className={classNames(files?.length > 0 && 'pt-2', "md:flex  space-x-2  hidden  w-full px-2")}>


                <ImageGallery openExpandedImage={openExpandedImage} files={files} removeFile={removeFile} />


            </div>

            <div className="   flex  space-x-2 relative items-end  ">
                <FileUploadButton handleFileChange={handleFileChange} uploading={uploading} />

                {" "}
                <div className="max-h-52 overflow-auto w-full custom-scrollbar-thick  custom-scrollbar-gray">
                    <textarea
                        value={query}
                        onKeyDown={handleKeyPress}
                        ref={textAreaRef}
                        onInput={handleInput}
                        rows={1}
                        onPaste={handlePaste} // Attach the paste handler

                        className="w-full border-none ring-0  focus:ring-0  rounded-lg resize-none overflow-hidden "
                        placeholder="Message Parabot..."
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                {/* <VoiceToText setText={setQuery} text={query} /> */}
                {loading ?
                    <ToolTipHoverContainer>
                        <button
                            onClick={() => handleStopGenerating()}
                            className="p-1 rounded-full border-2 border-gray-400 group-hover:border-gray-500 group mb-2 "
                        >

                            <HiOutlineStop className='h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                        </button>
                        <ToolTipTop>Stop generating</ToolTipTop>
                    </ToolTipHoverContainer>
                    :
                    <button disabled={uploading} onClick={() => handleSubmit(query)} className='mb-2'>
                        <IoMdSend className="h-7  w-10 text-blue-500 hover:brightness-105" />
                    </button>}
            </div>
        </div>)
}

export default TextBox