import React, { useState } from 'react'
import { classNames } from '../../../../misc/styling'
import FadeIn from '../../../../transitions/FadeIn';
import { IoMdSend } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import SmallLoadingSpinner from '../../../../loading/SmallLoadingSpinner';
import ItemMenu from '../../../../menus/ItemMenu';
import { TrashIcon, PencilIcon } from '@heroicons/react/outline';

const PromptItem = ({ index, template, handleSendPrompt }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [title, setTitle] = useState(template.title)
    const [prompt, setPrompt] = useState(template.prompt)

    const queryClient = useQueryClient()
    const handleEdit = () => {
        setIsEditing(true);
        setIsOpen(true);
    }

    const handleOpenClick = () => {
        if (isEditing) return;
        setIsOpen(!isOpen);
    }

    const handleCancelEdit = () => {
        setIsEditing(false);
        setIsOpen(false);
        setPrompt(template.prompt);
        setTitle(template.title);
    }
    const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)

    const { mutate: updatePromptTemplate, isLoading } = useMutation(async () => parabotApi.patch({
        endpoint: 'update_prompt_template',
        body: {
            title,
            prompt
        },
        params: [template.id]
    }),
        {
            onSuccess: () => {
                setIsEditing(false);
                setIsOpen(false);
            },
            onError: (error) => {
                console.error('Error updating prompt:', error)
            },
        })

    const { mutate: archivePromptTemplate, isLoading: deleteLoading } = useMutation(async () => parabotApi.patch({
        endpoint: 'archive_prompt_template',
        params: [template.id]
    }),
        {
            onSuccess: () => {
                setIsEditing(false);
                setIsOpen(false);
                queryClient.invalidateQueries('parabot-prompt-templates')
            },
            onError: (error) => {
                console.error('Error updating prompt:', error)
            },
        })

    const options = [
        {
            title: 'Edit',
            icon: PencilIcon,
            action: handleEdit,
        },
        {
            title: 'Delete',
            action: archivePromptTemplate,
            icon: TrashIcon
        },
    ]
    return (
        <li 
            className={classNames(isOpen ? 'text-gray-900 bg-gray-100' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900')}
        >
            <div
                className={classNames(
                    '',
                    '  text-sm w-full text-left flex px-1',
                )}  >

                <button
                    onClick={handleOpenClick}
                    className=" w-full text-left  py-2 text-base font-semibold items-center space-x-2 ">
                    {isEditing ?
                        <input type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="input-primary mt-1"
                            defaultValue='Title'
                        /> :
                        <p>
                            {title}
                        </p>}

                </button>
                {!isEditing && <div className='mr-4 my-auto'><ItemMenu options={options} /></div>}

                {!isEditing && <button onClick={() => handleSendPrompt(prompt)} className=''>
                    <IoMdSend className="h-6  w-8 text-blue-500 hover:brightness-105" />
                </button>}

            </div>
            <FadeIn isShowing={isOpen}>
                <div className="px-1 py-2">
                    {isEditing ?
                        <textarea
                            className="input-primary mt-1"
                            rows="6"
                            value={prompt}
                            placeholder='Your prompt here...'
                            onChange={(e) => setPrompt(e.target.value)}
                        /> :
                        <p className='max-h-[400px] overflow-auto custom-scrollbar-thick custom-scrollbar-gray'>
                            {prompt}
                        </p>}
                </div>

            </FadeIn>

            <FadeIn isShowing={isEditing}> <div className="flex   justify-evenly items-center py-2">
                <button className="btn-lg btn-primary "
                    onClick={updatePromptTemplate}
                >
                    <p className='pr-1'> Update Prompt </p>  {isLoading ? <SmallLoadingSpinner textColor='text-white' /> : null}
                </button>
                <button className=" text-gray-500 btn-lg btn-gray-outline" onClick={handleCancelEdit}>
                    Cancel
                </button>
            </div>
            </FadeIn>

        </li>)
}

export default PromptItem