import React, { useState } from 'react'
import { classNames } from '../../../../misc/styling'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api'
import { useQuery } from 'react-query'
import NewConversation from './NewConversation'
import ConversationItem from './ConversationItem'
import FadeIn from '../../../../transitions/FadeIn'

const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)
const Conversations = ({ conversationId, setConversationId, handleClearConversation }) => {
    const [expanded, setExpanded] = useState(false)  // Initialize with false for default collapsed state
    const { data: conversationIds } = useQuery({
        queryFn: parabotApi.getFn({
            endpoint: 'index_conversation_ids',
        }),
        queryKey: 'parabot_conversation_ids',
    })

    const handleMouseEnter = () => {
        setExpanded(true)
    }

    const handleMouseLeave = () => {
        setExpanded(false)
    }

    return (
        <div
            className={classNames('max-w-64 transition duration-400 ease-in bg-gray-100 rounded-bl-xl px-2 py-4')}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <NewConversation expanded={expanded} setConversationId={setConversationId} handleClearConversation={handleClearConversation} />
        

                <ul className='mt-2 pr-2 h-[70vh] overflow-y-auto overflow-x-hidden custom-scrollbar-thick custom-scrollbar-gray'>
                <FadeIn durationExit='duration-0' isShowing={expanded}>
  
                {conversationIds && conversationIds.map((id, index) => (
                        <li key={index}>
                            <ConversationItem id={id} conversationId={conversationId} setConversationId={setConversationId} />
                        </li>
                ))}
                </FadeIn>

                </ul>

        </div>
    )
}

export default Conversations
