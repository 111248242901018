import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react'
import { BsBoxArrowInUpRight } from 'react-icons/bs';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useNav from '../../../../../hooks/useNav';
import { HouseholdSearch } from '../../../../../components/comboboxes';

const HouseholdReferrer = ({ householdsApi, household }) => {
    const nav = useNav();
    const { household_id: householdId } = useParams();
    const [showSearch, setShowSearch] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedHousehold, setSelectedHousehold] = useState({});
    const queryClient = useQueryClient()
    const updateSource = useMutation(
        (newValue) => householdsApi.patch({
            endpoint: 'household_referrer',
            body: { household_id:householdId, new_value: newValue }
        })

        , {
            onSuccess: () => {
                setShowSearch(false);
                setShowSuccess(true);
                queryClient.invalidateQueries(`source-${householdId}`)
                setTimeout(() => setShowSuccess(false), 3000);
            }
        }
    )
    return (
        <div> {household?.household_referrer?.household_id ? (
            <>
                <p className="text-gray-600 text-sm"> Referred By</p>
                <div className="flex justify-between">
                    <button
                        onClick={() =>{nav(`/crm/households/${household.household_referrer?.household_id}`)}}
                        className="flex items-center text-left font-semibold hover:underline"
                    >
                    <p>{household.household_referrer?.household_name}</p>{" "}
                    <BsBoxArrowInUpRight className="ml-1 h-3 w-3 flex-shrink-0" />
                    </button>
                <button onClick={() => updateSource.mutate(null)}><XCircleIcon className='text-gray-500 h-5 w-5'/></button>
                </div>
            </>

        ) : (
            <div className='space-y-1'>
                <p className="text-gray-600 text-sm">Referred By</p>

                {showSearch ? (

                    <div className='flex items-center py-1 justify-between'>
                        <HouseholdSearch label="Households" setHousehold={setSelectedHousehold} household={selectedHousehold} />

                        <button className="btn-primary btn-md" onClick={() => updateSource.mutate(selectedHousehold.household_id)}>Link</button>

                        <button className="underline" onClick={() => setShowSearch(false)}>Cancel</button>
                    </div>
                ) : (
                    <button
                        onClick={() => {
                            setShowSearch(true);
                        }}
                        className="btn-primary  px-3 py-0.5"
                    >
                        <p>Select</p>{" "}

                    </button>
                )}
            </div>
        )}{" "}
            {showSuccess && <CheckCircleIcon className="h-5 w-5 text-green-500" />}   </div>
    )
}

export default HouseholdReferrer