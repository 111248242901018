import React from "react";
import { useAuth } from './../../context/auth-context';

const ErrorFallback = () => {
  const auth = useAuth();

  return (
    <>

      <main
        className="min-h-full bg-cover bg-top sm:bg-top h-screen"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
           {" Whoops, looks like something went wrong!"}
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
           Sorry about the issue! Please try again.
          </p>
          <div className="mt-6">
            <a
              href={auth?.currentUser ? "/dashboard" : "/"}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 hover:bg-opacity-90"
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default ErrorFallback;
