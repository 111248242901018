import React from "react";
import FadeIn from "./transitions/FadeIn";
import ClickOutSideClose from "./ClickOutSideClose";

const ReallyDelete = ({showDelete = false,setShowDelete = () => showDelete = !showDelete,handleDelete}) => {
  return (
    <ClickOutSideClose setOpen={setShowDelete}>
      <FadeIn isShowing={showDelete}>
        <button className="btn-lg btn-danger min-w-max " onClick={handleDelete}>
          Really Delete?
        </button>
      </FadeIn>
    </ClickOutSideClose>
  );
};

export default ReallyDelete;
