import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
};
export const messagesSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.error = initialState.error;
    },
    setError: (state, action) => {
        state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearMessages, setError } = messagesSlice.actions;
export const selectSubmission = (state) => state.invest.submission;

export default messagesSlice.reducer;
