import React from 'react'
import DisplayTaskRichText from '../Financial-Story/components/task-components/DisplayTaskRichText'
import { STATUS_OPTIONS } from '../../../../constants'

const Task = ({task,query}) => {
  return (
    <div>
      <div className="flex justify-end items-center space-x-1"><p className='text-xs  text-gray-500'>Completed:</p> <input type="checkbox" className='checkbox-md'  name="" id="" checked={task?.status === STATUS_OPTIONS.TASKS.COMPLETED}
 /></div>
          <DisplayTaskRichText task={task} query={query} />
      </div>
  )
}

export default Task