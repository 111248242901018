import Markdown from "markdown-to-jsx";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import edjsParser from "../textEditor/htmlParser";

const MarkdownNotes = ({ noteRaw }) => {
  const [html, setHtml] = useState("");
  useEffect(() => {
    if (!noteRaw) return;
    setHtml(edjsParser.parse(noteRaw).join(''));
  }, [noteRaw]);
  return (
    <Markdown options={{ wrapper: "article" }} className="markdown-notes">
      {html.toString()}
    </Markdown>
  );
};

export default MarkdownNotes;
