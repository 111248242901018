import React, { useState } from "react";
import { useSelector } from "react-redux";
import SideNavBar from "../../components/nav/side-navbar";
import useArray from "../../hooks/useArray";
import { patchReq } from "../../apis/internalApi";
import { API } from "../../constants/index";
import {
  AlertError,
  AlertCaution,
  AlertSuccess,
} from "../../components/alerts";
import { valuesExerciseValues } from "../../helpers/values";
import useNav from "../../hooks/useNav";
import { InformationCircleIcon, ClockIcon, CheckIcon } from "@heroicons/react/outline";
import { ExclamationIcon, XIcon } from "@heroicons/react/solid";

const ValueCard = ({ value, onClick, isSelected }) => {
  const IconComponent = value.icon;

  return (
    <button
      onClick={onClick}
      className={`
        w-full relative overflow-hidden rounded-xl p-6
        transition-all duration-300 ease-in-out
        ${isSelected
          ? 'bg-gradient-to-br from-blue-50 to-white border-2 border-blue-200 shadow-md transform hover:-translate-y-1'
          : 'bg-white border border-gray-200 hover:border-gray-300 hover:shadow-lg'
        }
      `}
    >
      {/* Selected Indicator */}
      {isSelected && (
        <div className="absolute top-3 right-3">
          <div className="h-2 w-2 rounded-full bg-blue-500"></div>
        </div>
      )}

      {/* Icon Container */}
      <div className={`
        mx-auto mb-4 p-3 flex items-center justify-center space-x-4 rounded-lg transition-colors duration-200
        ${isSelected
          ? 'bg-blue-100 text-blue-600'
          : 'bg-gray-50 text-gray-600 group-hover:bg-gray-100'
        }
      `}>
        <IconComponent className="h-6 w-6" />
        <h3 className={`
          font-semibold transition-colors duration-200
          ${isSelected ? 'text-blue-900' : 'text-gray-900'}
        `}>
          {value.title}
        </h3>
      </div>

      {/* Content */}
      <div className="space-y-2 text-center">


        <p className={`
          text-sm line-clamp-3 transition-colors duration-200
          ${isSelected ? 'text-blue-700' : 'text-gray-600'}
        `}>
          {value.description}
        </p>
      </div>
    </button>
  );
};

// Custom Alert Component
const CustomAlert = ({ type, onDismiss, children }) => {
  const styles = {
    success: {
      container: 'bg-green-50 border-green-200',
      icon: 'text-green-500',
      text: 'text-green-800'
    },
    error: {
      container: 'bg-red-50 border-red-200',
      icon: 'text-red-500',
      text: 'text-red-800'
    },
    caution: {
      container: 'bg-yellow-50 border-yellow-200',
      icon: 'text-yellow-500',
      text: 'text-yellow-800'
    }
  }[type];

  return (
    <div className={`rounded-lg border p-4 ${styles.container}`}>
      <div className="flex items-start gap-3">
        <ExclamationIcon className={`h-5 w-5 ${styles.icon} flex-shrink-0 mt-0.5`} />
        <p className={`flex-1 text-sm font-medium ${styles.text}`}>{children}</p>
        {onDismiss && (
          <button
            onClick={onDismiss}
            className={`flex-shrink-0 ${styles.icon} hover:opacity-75`}
          >
            <XIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};


const HeaderPanel = ({ step, valueCount, hideUnselected, setHideUnselected, handleSubmit, alerts }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 max">
      <div className="grid grid-cols-12 gap-6 p-6">
        {/* Left Column - Title and Description */}
        <div className="col-span-12 lg:col-span-8">
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-900">
                Values Exercise
              </h3>
              {step === 1 && (
                <p className="text-gray-600 text-sm mt-1 max-w-2xl">
                  Discovering the things that we truly value can be a huge help in planning our finances.
                </p>
              )}
            </div>

            {/* Instructions */}
            <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <InformationCircleIcon className="h-5 w-5 text-blue-600" />
                </div>
                <p className="text-base text-blue-900">
                  {step === 1 && "Select all values that meaningfully resonate with your personal beliefs and goals"}
                  {step === 2 && "Review your selections and refine your choices to the 15 most significant values"}
                  {step === 3 && "Continue refining your selection to identify your top 10 core values"}
                  {step === 4 && "Select the 5 essential values that best define your priorities and aspirations"}
                  {step === 5 && "Exercise complete. Your chosen values will serve as key guidelines throughout your financial planning journey"}
                </p>
              </div>
            </div>

            {/* Alerts Stack */}
            {alerts.caution.text && (
              <CustomAlert type="caution" onDismiss={() => alerts.caution.setCaution("")}>
                {alerts.caution.text}
              </CustomAlert>
            )}
            {alerts.error.text && (
              <CustomAlert type="error" onDismiss={() => alerts.error.setError("")}>
                {alerts.error.text}
              </CustomAlert>
            )}
            {alerts.success.text && (
              <CustomAlert type="success" onDismiss={() => alerts.success.setSuccess("")}>
                {alerts.success.text}
              </CustomAlert>
            )}
            {/* Action Button */}
            <div className="hidden lg:block">
              <button
                onClick={handleSubmit}
                className="btn-primary btn-xl flex  items-center space-x-1 "
              >
                <span>{step === 5 ? "Back to Dashboard" : "Save & Continue"}</span>
                <CheckIcon className="h-5 w-5" />
              </button>
            </div>

          </div>

        </div>

        {/* Right Column - Actions and Stats */}
        <div className="col-span-12 lg:col-span-4 ">
          <div className="space-y-6">
            {/* Value Counter */}
            <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-gray-100 rounded-lg">
                  <ClockIcon className="h-5 w-5 text-gray-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">Selected Values</p>
                  <p className="text-2xl font-bold text-gray-900">{valueCount}</p>
                </div>
              </div>

              {/* Hide Unselected Toggle */}
              {step > 1 && (
                <div className="pt-4 border-t border-gray-200">
                  <label className="flex items-center gap-3 cursor-pointer group">
                    <input
                      type="checkbox"
                      checked={hideUnselected}
                      onChange={() => setHideUnselected(!hideUnselected)}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-600 group-hover:text-gray-900">
                      Hide Unselected Values
                    </span>
                  </label>
                </div>
              )}
            </div>

            {/* Action Button */}
            <button
              onClick={handleSubmit}
              className="btn-primary btn-xl  items-center space-x-1  lg:hidden "
            >
              <span>{step === 5 ? "Back to Dashboard" : "Save & Continue"}</span>
              <CheckIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const Values = () => {
  const nav = useNav();
  const valuesArr = useArray(valuesExerciseValues);
  const [valueCount, setValueCount] = useState(0);
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [caution, setCaution] = useState("");
  const [success, setSuccess] = useState("");
  const [hideUnselected, setHideUnselected] = useState(false);
  const accessToken = useSelector((state) => state.user.accessToken);

  const postValues = async () => {
    const selectedValues = valuesArr.array
      .filter((value) => value.active);

    try {
      const response = await patchReq(API.USERS, "patch_user_values", accessToken, {
        values: selectedValues,
      });
      return response.status;
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClick = (index, newState) => {
    valuesArr.update(index, {
      ...valuesArr.array[index],
      active: newState,
    });
    setValueCount(prevCount => newState ? prevCount + 1 : prevCount - 1);
  };

  const handleSubmit = async () => {
    setError("");
    setCaution("");
    setSuccess("");

    if (step === 0) {
      setStep(1);
      return;
    }

    if (valueCount < 5) {
      setCaution("Please select at least 5 values.");
      return;
    }
    if (valueCount > 15) {
      if (step === 2) {
        setCaution("Please reduce your selection to 15 values before proceeding to the next phase");
      } else {
        setStep(2);
        setSuccess("Great progress. Your next task is to identify your top 15 values from your current selection.");
      }
    } else if (valueCount > 10) {
      if (step === 3) {
        setCaution("Further refinement is needed - please select your top 10 values to continue");
      } else {
        setStep(3);
        setSuccess("Well done on the refinement. Now focus on determining your top 10 most impactful values.");
      }
    } else if (valueCount > 5) {
      if (step === 4) {
        setCaution("Final refinement needed - please identify your 5 core values to complete the exercise");
      } else {
        setStep(4);
        setSuccess("Excellent progress. For the final step, determine the 5 values that most strongly guide your decisions.");
      }
    } else if (valueCount === 5) {
      await postValues();
      setStep(5);
      setSuccess("Congratulations on completing the values exercise. These core values will help inform your financial strategy.");
    }

    if (step === 5) {
      nav("/dashboard");
    }
  };

  return (
    <div>
      <SideNavBar>
        {/* Header Panel */}

        {/* Values Grid */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <HeaderPanel
            step={step}
            valueCount={valueCount}
            hideUnselected={hideUnselected}
            handleSubmit={handleSubmit}
            alerts={{
              error:
              {
                text: error,
                setError: setError,
              },
              caution:
              {
                text: caution,
                setCaution: setCaution,
              },
              success:
              {
                text: success,
                setSuccess: setSuccess,
              },
            }}
            setHideUnselected={setHideUnselected}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 my-12">
            {step > 0 && valuesArr.array.map((value, index) => (
              hideUnselected && !value.active ? null : (
                <ValueCard
                  key={index}
                  value={value}
                  isSelected={value.active}
                  onClick={() => handleClick(index, !value.active)}
                />
              )
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <p className="text-sm text-gray-600 text-center">
            Exercise and values can be originally found on{" "}
            <a
              href="https://www.think2perform.com/our-approach/values/new"
              target="_blank"
              className="text-blue-600 hover:text-blue-800 underline"
              rel="noopener noreferrer"
            >
              think2perform.com
            </a>
          </p>
        </div>
      </SideNavBar>
    </div>
  );
};

export default Values;