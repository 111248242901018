import React, { useMemo } from "react";
import MarkdownNotes from "../../../../../components/content/MarkdownNotes";
import ItemMenu from "../../../../../components/menus/ItemMenu";
import { dateFormatterGmailTime } from "../../../../../helpers/formatters";
import { patchReq } from "../../../../../apis/internalApi";
import { API } from "../../../../../constants/index";
import { useSelector } from "react-redux";
import { ArchiveIcon, PencilAltIcon } from "@heroicons/react/outline";
import EditNote from "./EditNote";
import { useState } from "react";
import NoteEdits from "./NoteEdits";
import RichTextDisplay from "../../../../../components/textEditor/RichTextDisplay";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../api/apiConstants";
import ApiRequest from "../../../../../api/ApiRequest";

const Note = ({ note, listQueryKey ,advisors}) => {
  const [showEditNote, setShowEditNote] = useState(false);
  const [openEdits, setOpenEdits] = useState(false);
  const notesApi = new ApiRequest(
    API_ROUTES.HOUSEHOLD_NOTES,
    API_AUTH_TYPES.ADVISOR
  );
  const queryClient = useQueryClient();
  const { mutate: mutateHandleArchive } = useMutation(
    () =>
      notesApi.patch({
        endpoint: "archive_note",
        body: { id: note.household_note_id },
      }),
    { onSuccess: () => queryClient.invalidateQueries(listQueryKey) }
  );
  const lastEditedBy = useMemo(() => {
    if (!note.last_edited_by) return "Parabot"
    const advisor = advisors.find((advisor) => advisor.user_id === note.last_edited_by);
    if (!advisor) return "Parabot";

    return `${advisor?.first_name} ${advisor?.last_name}`;
  }, [advisors, note.last_edited_by]);
  // const { data } = useQuery({
  //   queryFn: () => notesApi.findUnique([note.household_note_id]),
  //   queryKey: [listQueryKey, note.household_note_id],
  // });
  const handleEditNote = async () => {
    setShowEditNote(true);
  };

  const menuOptions = [
    {
      title: "Edit",
      action: handleEditNote,
      icon: PencilAltIcon,
    },
    {
      title: "Archive",
      action: mutateHandleArchive,
      icon: ArchiveIcon,
    },
  ];

  const menuOptionsNoEdit = [
    {
      title: "Archive",
      action: mutateHandleArchive,
      icon: ArchiveIcon,
    },
  ];
  return (
    <>
      {openEdits && (
        <NoteEdits open={openEdits} setOpen={setOpenEdits} edits={note.edit_history} />
      )}

      <li key={note.household_note_id} className="py-4 w-full px-5">
        {!showEditNote ? (
          <div className=" w-full">
            <div>
              <div className="flex space-x-3">
                <div className="flex-1 space-y-1">
                  <div className="flex justify-between">
                    <div className="flex flex-col w-full flex-wrap">
                      {" "}
                      <div className="text-sm text-gray-900 w-full ">
                        {note.content && (
                          <div>
                            <RichTextDisplay
                              content={note.content}
                              thisClassName="notes"
                            />
                          </div>
                        )}
                        {note.body?.delta && (
                          <div>
                            <RichTextDisplay
                              content={{ ops: note.body?.delta }}
                              thisClassName="notes"
                            />
                          </div>
                        )}

                        {note.body?.blocks && (
                          <MarkdownNotes noteRaw={note.body} />
                        )}
                      </div>
                      {note.edit_history && (
                        <div className="w-full text-blue-500  text-xs ">
                          <button
                            onClick={() => {
                              setOpenEdits(true);
                            }}
                            className=""
                          >
                            {note.edit_history.length > 1
                              ? `${note.edit_history.length} edits`
                              : "1 edit"}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className=" flex flex-col space-y-1 items-end sm:pl-10">
                      {" "}
                      <ItemMenu
                        options={note.content ? menuOptions : menuOptionsNoEdit}
                        id={note.household_note_id}
                      />
                      <p className="text-sm text-gray-600 w-max">
                        {dateFormatterGmailTime(
                          new Date(note.created_at || note.body?.time)
                        )}
                      </p>
                      <p className="w-max text-sm text-gray-600">                      {lastEditedBy}
</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EditNote
            documentId={note.household_note_id}
            listQueryKey={listQueryKey}
            setOpen={setShowEditNote}
            open={showEditNote}
          />
        )}{" "}
      </li>
    </>
  );
};

export default Note;
