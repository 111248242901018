import { Combobox } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ToolTipHoverContainer, ToolTipLeft } from "../../../../../components/ToolTips";
import { CheckIcon, PlusIcon } from "@heroicons/react/outline";
import { classNames } from "../../../../../helpers/classNames";


function SelectBox({ options, handleChange = () => { }, isEmptyState = true }) {
    const [query, setQuery] = useState('')

    const [selectedOption, setSelectedOption] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) => {
                return option.toLowerCase().includes(query.toLowerCase())
            })
    useEffect(() => {
        if (!isInitialized) return setIsInitialized(true)
        if (!selectedOption) return
        setQuery('')

        handleChange(selectedOption)

    }, [selectedOption])


    return (
        <Combobox as="div" className={''} value={selectedOption} onChange={setSelectedOption}>

                <Combobox.Button >
                    {
                        isEmptyState ?
                            <div className='flex  space-x-1 items-center btn-gray-outline btn-lg'>
                                <p className="text-gray-500">Add a planning tag</p>
                                {<PlusIcon className="h-5 w-5   text-gray-500" />}
                            </div>
                            :
                            <div className="absolute top-0  right-0 z-50">
                                <ToolTipHoverContainer>

                                    <PlusIcon className="h-5 w-5   text-gray-500" />
                                    <ToolTipLeft >
                                        <p className="text-sm ">Add a new tag</p>
                                    </ToolTipLeft>
                                </ToolTipHoverContainer>
                            </div>
                    }

                </Combobox.Button>

                {true && (
                    <Combobox.Options className="absolute top-full  z-40 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Input
                            placeholder="Search for an option..."
                            className="w-full border-0 bg-white py-1.5 pl-3 pr-10  text-gray-900 ring-0  text-sm sm:leading-6"
                            onChange={(event) => setQuery(event.target.value)}
                            value={query}
                            displayValue={(option) => option}
                        />
                        {filteredOptions.length > 0 && filteredOptions.map((option) => (
                            <Combobox.Option
                                key={option}
                                value={option}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{option}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-blue-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
        </Combobox>
    )
}

export default SelectBox;
