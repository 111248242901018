import React from "react";
import ClickOutSideClose from "./ClickOutSideClose";
import FadeIn from "./transitions/FadeIn";
import { classNames } from "./misc/styling";

const ButtonDropdown = ({ open, setOpen, children ,position = 'top-10'}) => {
  return (
    <div className="relative">
      <div className={classNames(position,"absolute z-40")}>
        {" "}
        <ClickOutSideClose setOpen={setOpen}>
          <FadeIn isShowing={open}>
                <div className={classNames( "bg-white border-2 border-gray-300 p-3 rounded-lg shadow-lg")}>
                  {children}
                </div>
          </FadeIn>
        </ClickOutSideClose>
      </div>
    </div>
  );
};

export default ButtonDropdown;
