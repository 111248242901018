import React, { useState, useEffect, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { authenticateAdvisorAdmin } from './../../helpers/jwtAuth';
import ErrorFallback from './../errorHandling/error-fallback-page';

export default function AdminRoute({ component: Component, ...rest }) {
  //state
  const [isAdmin, setIsAdmin] = useState(false);
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(true);
  const accessToken = useSelector((state) => state.user.accessToken);
  //functions
  const authenticate = useCallback(async () => {

    try {
     const auth = await authenticateAdvisorAdmin(accessToken)
      setIsAdmin(auth.is_admin);
      setLoading(false);
    } catch (error) {
      setIsAdmin(false);
      setLoading(false);
    }
  }, [accessToken]);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) authenticate();
    return () => {
      isCancelled = true;
    };
  }, [authenticate]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} >
      <Route
        {...rest}
        render={(props) => {
          return !loading ? (
            <>
              {currentUser && isAdmin ? (
                <Component {...props} />
              ) : (
                <Redirect to="/login" />
              )}
            </>
          ) : null;
        }}
      ></Route>
    </ErrorBoundary>
  );
}
