/**
 * Compares two arrays of objects to determine if they are identical.
 * 
 * @param {Array<Object>} arr1 - The first array to compare.
 * @param {Array<Object>} arr2 - The second array to compare.
 * @returns {boolean} True if the arrays are identical, false otherwise.
 */
function areArraysIdentical(arr1, arr2) {
    // Check if the lengths are different - if they are, the arrays can't be identical
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate through the arrays and compare each object using JSON.stringify
    for (let i = 0; i < arr1.length; i++) {
        // JSON.stringify provides a way to compare the structure and values within objects
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
            return false;
        }
    }

    // If loop completes without finding any differences, arrays are identical
    return true;
}

export default areArraysIdentical;