import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};
export const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    r__loading: (state) => {
      state.loading = !state.loading;
    },
    r_loadingTrue: (state) => {
      state.loading = true;
    },
    r_loadingFalse: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { r_loading, r_loadingTrue, r_loadingFalse } =
  utilitiesSlice.actions;

export default utilitiesSlice.reducer;
