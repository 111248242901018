// src/hooks/useHouseholdAccess.js
import { useQuery } from 'react-query';
import { API_ROUTES, API_AUTH_TYPES, ApiRequest } from '../../../../api';
import useNav from '../../../../hooks/useNav';

/**
 * Hook to check and handle household access control
 * @param {string} householdId - The household ID to check access for
 * @param {Object} options - Additional options
 * @param {string} options.redirectPath - Path to redirect to if access is denied (default: '/crm/dashboard')
 * @returns {Object} Query result object
 */
export const useHouseholdAccess = (householdId, options = {}) => {
    const nav = useNav()
    const { redirectPath = '/crm/dashboard' } = options;

    const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);

    return useQuery({
        queryFn: householdsApi.getFn({
            endpoint: "has_access",
            params: [householdId]
        }),
        queryKey: `household-access-${householdId}`,
        onSuccess: (data) => {
            if (data?.user_has_access === false) {
                nav(redirectPath);
            }
        },
        onError: () => {
            nav(redirectPath);
        }
    });
};
