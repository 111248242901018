import { useEffect, useRef } from 'react';

const useScrollToBottom = (monitoredArray, tolerance = 75) => {
    const scrollRef = useRef(null); // Ref for the scrollable container
    const observerRef = useRef(null); // Ref for the MutationObserver

    // Check if the user is at the bottom of the scrollable container
    const isUserAtBottom = () => {
        if (!scrollRef.current) {
            return false;
        }

        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const bottomOffset = scrollHeight - scrollTop - clientHeight;
        return bottomOffset <= tolerance;
    };

    // Scroll to the bottom of the container, optionally with a delay
    const scrollToBottom = (manualScrollToBottom = false) => {
        const delay = manualScrollToBottom ? 0 : 0.5 * 1000; // Delay for automatic scrolling
        setTimeout(() => {
            if ((isUserAtBottom() || manualScrollToBottom) && scrollRef.current) {
                // Scroll to the bottom if the user is at the bottom or it's a manual scroll
                scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
            }
        }, delay);
    };

    // Observe changes to the last child element (used for auto-scrolling)
    const observeLastMessage = () => {
        if (scrollRef.current && scrollRef.current.lastChild) {
            observerRef.current = new MutationObserver(scrollToBottom);
            observerRef.current.observe(scrollRef.current.lastChild, { childList: true });
        }
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to the bottom on component mount
        observeLastMessage(); // Observe changes to the last child element
    }, [monitoredArray]); // Re-trigger when 'monitoredArray' changes

    // Returns the scrollable container ref and a function to hard-scroll to the bottom
    return { scrollRef, hardScrollToBottom: () => scrollToBottom(true) };
};

export default useScrollToBottom;
