import React, { useMemo } from "react";

import { GrDocumentPdf } from "react-icons/gr";
import {  PhotographIcon } from "@heroicons/react/outline";
import DOMPurify from "dompurify";
import Highlighter from "react-highlight-words";

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  let dataBase64Rep = b64Data.replace(/-/g, "+").replace(/_/g, "/");

  let byteCharacters = window.atob(dataBase64Rep);
  let byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  let urlBlob = URL.createObjectURL(blob);
  return urlBlob;
}

const Email = ({ email, query }) => {
  const sanitizedHtml = DOMPurify.sanitize(email.body);
  const searchWords = query && query.trim() !== '' ? (Array.isArray(query) ? query : [query]) : [];

  const renderContent = useMemo(() => {
    if (query && query.trim() !== "") {
      const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedQuery, 'gi');
      const highlightedHtml = sanitizedHtml.replace(regex, match => `<span class="highlighted">${match}</span>`);
      return { __html: highlightedHtml };
    }
    return { __html: sanitizedHtml };
  }, [sanitizedHtml, query]); 
  return (
    
                <li key={email.id} className="flex flex-col w-full">
                  <div className="flex justify-between">
                  <div
                    className="flex flex-col mb-2 text-gray-500 text-xs"
                  >

                    <p>
                      to: {email.to.split("<")[1]?.split(">")[0] || email.to}
                    </p>

                    <p>
                      from:{" "}
                      {email.from.split("<")[1]?.split(">")[0] || email.from}
                    </p>
                    </div>
                 
                  </div>
                  {" "}
              
                  {email.body ? (
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={renderContent}
                    />
                  ) : (
          <Highlighter
            highlightClassName="highlighted"
            searchWords={searchWords}
            autoEscape={true}
            textToHighlight={email.snippet}
          />
                  )}{" "}
                  <div className=" mt-5  pt-2">
                    {email.attachments && <p>Attachments</p>}
                    <ul className="flex space-x-3 w-full  overflow-x-auto ">
                      {email.attachments &&
                        email.attachments.map((attachment) => (
                          <li className="w-full" key={attachment.attachmentId}>
                            {" "}
                            <a
                              download
                              className="rounded-full p-2  my-2 flex w-max items-center space-x-2"
                              href={b64toBlob(
                                attachment.data.data.data,
                                attachment.mimeType,
                                attachment.size
                              )}
                            >
                              {attachment.mimeType === "application/pdf" && (
                                <GrDocumentPdf className="text-red-500" />
                              )}
                              {attachment.mimeType.includes("image") && (
                                <PhotographIcon className="w-5" />
                              )}
                              <p>{attachment.filename.substring(0, 20)}...</p>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </li>
             
  );
};

export default Email;
