import { PlusIcon } from "@heroicons/react/solid";
import DropTarget from "../../../../../../components/drag-drop/DropTarget";
import { DRAG_N_DROP_TYPES, STATUS_OPTIONS } from "../../../../../../constants";
import TaskSuggestions from "../StorySuggestions/TaskSuggestions";
import Task from "../task-components/Task";
import Toggle from "../../../../../../components/toggle";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../../../../api";
import SmallLoadingSpinner from "../../../../../../components/loading/SmallLoadingSpinner";
import isVisibleNowFunction from "../task-components/isVisibleNowFunction";

/**
 * The TaskList is responsible for rendering and managing tasks related to a specific chapter of a client's financial story.
 * It allows for creating new tasks, viewing actionable tasks, and suggesting tasks based on templates. Additionally,
 * completed tasks can be shown or hidden based on user preference.
 *
 * @param {Object} props The component properties.
 * @param {Array} props.tasks The list of tasks associated with the chapter.
 * @param {Object} props.chapter The current chapter data.
 * @param {String} props.tasksListQueryKey The query key used for task list queries.
 * @param {String} props.chaptersListQueryKey The query key used for chapter list queries.
 * @param {Boolean} props.isMeetingMode Flag indicating if the component is used in meeting mode.
 * @param {Array} props.queryKeys Array of query keys used in the component.
 * @param {String} props.householdId The ID of the current household.
 * @param {Function} props.setTemplateType Function to set the type of template for task creation.
 * @returns {JSX.Element} The rendered TaskList.
 */
export default function TaskList({ tasks, chapter, tasksListQueryKey, chaptersListQueryKey, isMeetingMode, queryKeys, householdId, setTemplateType }) {
    // API setup for tasks management
    const tasksApi = new ApiRequest(
        API_ROUTES.CLIENT_TASKS,
        API_AUTH_TYPES.ADVISOR
    );

    const queryClient = useQueryClient();

    // State for managing UI elements
    const [showCompletedTasks, setShowCompletedTasks] = useState(chapter.status === STATUS_OPTIONS.TASKS.COMPLETED);
    const [showHiddenTasks, setShowHiddenTasks] = useState(!isMeetingMode);
    // Mutation for creating a new task
    const { mutate: createTask, isLoading: isCreatingTask } = useMutation(
        () => tasksApi.post({ endpoint: "post_task", body: { chapter_id: chapter.chapter_id, household_id: householdId } }),
        {
            onSuccess: () => {
                // Invalidate the tasks and chapters queries to reflect the new task
                setTimeout(() => {
                    queryClient.invalidateQueries(tasksListQueryKey);
                    queryClient.invalidateQueries(chaptersListQueryKey);
                }, 1000);
            },
        }
    );

    // Mutation for reordering tasks within a chapter
    const { mutate: handleReorderToEndOfChapter } = useMutation(
        (item) => {
            tasksApi.patch({
                endpoint: "reorder_to_end_of_chapter", body: {
                    item_list_id: chapter.chapter_id, item_id: item.content.props.task?.task_id, item_type: DRAG_N_DROP_TYPES.TASK, household_id: householdId,
                    dropped_over_list_id: chapter.chapter_id
                }
            });
        },
        {
            onSuccess: () => {
                // Invalidate the tasks and chapters queries to reflect the reordered tasks
                setTimeout(() => {
                    queryClient.invalidateQueries(tasksListQueryKey);
                    queryClient.invalidateQueries(chaptersListQueryKey);
                }, 250);
            },
        }
    );

    return (
        <>
            {/* Toggle for showing completed tasks */}
            <div className="flex justify-end mt-5 space-x-3">
                <div className="flex items-center space-x-2">
                    <p className="text-gray-800 text-sm">Show completed tasks</p>
                    <Toggle enabled={showCompletedTasks} setEnabled={setShowCompletedTasks} />
                </div>
                <div className="flex items-center space-x-2">
                    <p className="text-gray-800 text-sm">Show hidden tasks</p>
                    <Toggle enabled={showHiddenTasks} setEnabled={setShowHiddenTasks} />
                </div>
            </div>

            {/* Header section with actionable tasks title and task creation buttons */}
            <div className="flex justify-between items-center mb-5 mt-4 border-b pb-3">
                <h5 className="text-lg sm:text-2xl">Actionable Tasks</h5>
                <div className="flex space-x-5 items-center">
                    {/* Button to create a new task */}
                    <button className="flex items-center btn-md btn-gray-outline-rounded" onClick={createTask}>
                        {isCreatingTask ? <SmallLoadingSpinner /> : <PlusIcon className="  h-5 w-5 text-blue-500" />} <p className=" text-lg ml-3">Create New</p>

                    </button>
                    {/* Button to create a task from a template (if not in meeting mode) */}
                    {!isMeetingMode && (
                        <button className="flex items-center btn-md btn-gray-outline-rounded" onClick={() => { setTemplateType('task'); }}>
                            <PlusIcon className="h-5 w-5 mr-3 text-blue-500" /><p className="text-lg">Create From Template</p>
                        </button>
                    )}
                </div>
            </div>

            {/* List of actionable tasks */}
            <ul>
                {tasks && tasks.map((task) => (
                    (task.chapter_id === chapter.chapter_id && task.status !== STATUS_OPTIONS.TASKS.COMPLETED && (
                        showHiddenTasks || isVisibleNowFunction(task)
                   )) && (
                        <li key={task.task_id}>
                            <Task
                                task={task}
                                tasksListQueryKey={tasksListQueryKey}
                                chaptersListQueryKey={chaptersListQueryKey}
                                isMeetingMode={isMeetingMode}
                                queryKeys={queryKeys}
                            />
                        </li>
                    )
                ))}
            </ul>

            {/* Drop target for task reordering */}
            <DropTarget accept={'task'} onDrop={(item) => { handleReorderToEndOfChapter(item) }} isActiveStyling={'py-10 drop-target'}>
                <div className="h-10"></div>
            </DropTarget>

            {/* Task suggestions (if not in meeting mode) */}
            {!isMeetingMode && <TaskSuggestions chapterId={chapter.chapter_id} queryKeys={queryKeys} />}

            {/* List of completed tasks (if shown) */}
            {showCompletedTasks && (
                <ul>
                    {tasks && tasks.map((task) => (
                        task.chapter_id === chapter.chapter_id && task.status === STATUS_OPTIONS.TASKS.COMPLETED && (
                            <li key={task.task_id}>
                                <Task
                                    task={task}
                                    tasksListQueryKey={tasksListQueryKey}
                                    chaptersListQueryKey={chaptersListQueryKey}
                                    isMeetingMode={isMeetingMode}
                                    queryKeys={queryKeys}
                                />
                            </li>
                        )
                    ))}
                </ul>
            )}
        </>
    );
}
