import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ApiRequest from "../../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../api/apiConstants";
import { CheckCircleIcon, ChevronDownIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import FadeIn from "../../../../../../components/transitions/FadeIn";
import { classNames } from "../../../../../../components/misc/styling";
import Highlighter from "react-highlight-words";
import ClickOutSideClose from "../../../../../../components/ClickOutSideClose";

const chapterTemplatesApi = new ApiRequest(API_ROUTES.CHAPTER_TEMPLATES, API_AUTH_TYPES.ADVISOR);
export function Template({ template, searchQuery, queryKeys }) {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const toggleSuccessMessage = () => {
        setShowSuccess(true)
        setTimeout(() => {
            setShowSuccess(false)
        }, 2000);
    }
    const { household_id: householdId } = useParams();
    const queryClient = useQueryClient();
    const { mutate: createTemplate, isLoading } = useMutation(
        () => chapterTemplatesApi.post({
            endpoint: "post_chapter_from_template", body: {
                household_id: householdId,
                template
            }
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.chapters);
                toggleSuccessMessage()
            },
        }

    );




    const { mutate: archiveTemplate } = useMutation(
        () => chapterTemplatesApi.patch({ endpoint: "archive", params: [template.id] }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("chapter-templates");

            },
        }
    )

    const searchWords = Array.isArray(searchQuery)
        ? searchQuery
        : [searchQuery];
    return (
        <div className='flex px-2  justify-between w-full  items-start'>
            <div className="">
                <button onClick={createTemplate}>
                    <PlusCircleIcon className='h-8 w-8 text-blue-500 hover:text-blue-600 transition duration-100 ease-in' />
                </button>
                <div className="">
                    {showSuccess && <CheckCircleIcon className="h-8 w-8 text-green-500" />}
                    {(isLoading) && <AiOutlineLoading3Quarters className="h-8 w-8 animate-spin text-gray-500" />
                    }

                </div>
            </div>

            <button onClick={() => setShowDetails(!showDetails)} className="max-w-[800px] text-left w-full mr-auto ml-5">
                
                <ClickOutSideClose setOpen={setShowDetails}>
                <h3 className='text-left text-xl text-gray-800'>  <Highlighter
                    highlightClassName="highlighted" // Use your own highlight class
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={template.title} // Convert text to lowercase
                /></h3>
                {template.context && <RichTextDisplay content={template.context} thisClassName='markdown-notes' query={searchQuery} />}
                <FadeIn isShowing={showDetails}>
                    
                    <div className="">
                        <p className="text-xl font-semibold text-gray-800 mt-6 mb-2">Tasks:</p>
                        {template.tasks && <ul className="space-y-2">
                            {template.tasks.sort(
                                (a, b) => a.task_order - b.task_order
                            ).map((task, index) =>
                                <li className=" border border-gray-300 bg-white rounded-lg p-4" key={index}>
                                <RichTextDisplay
                                    content={task.content} thisClassName='markdown-notes' query={searchQuery} />
                            </li>)}
                        </ul>}
                        <p className="text-xl font-semibold text-gray-800 mt-6 mb-2">Ongoing Strategy:</p>

                        {
                            template.ongoing_advice && 
                            <ul className="space-y-2">
                                    {template.ongoing_advice.sort(
                                     (a, b) => a.goal_order - b.goal_order
                                    ).map((advice, index) => <li className="border border-gray-300 bg-white rounded-lg p-4" key={index}>
                                        <RichTextDisplay
                                            content={advice.content}
                                            thisClassName="notes"
                                            query={searchQuery}
                                        />
                                </li>)}
                            </ul>
                        }
                    </div>
                </FadeIn>
                <div className="flex justify-center mt-3">
                    <button
                        onClick={() => setShowDetails(!showDetails)}
                    ><ChevronDownIcon
                            className={classNames(showDetails && 'rotate-180', "text-gray-500 h-5 w-5 transition duration-200 ease-in")} /></button>
                </div>
               </ClickOutSideClose>
            </button>

            <button>
                <TrashIcon onClick={archiveTemplate} className='h-8 w-8 text-red-500 hover:text-red-600 transition duration-100 ease-in' />
            </button>
        </div>
    )
}
