import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { classNames } from '../../helpers/classNames';

const DropTarget = ({ accept, onDrop, onIsOver, children, isActiveStyling,className }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept,
        drop: (item) => {
            onDrop(item);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isActive = canDrop && isOver;

    // Hook to handle isOver changes
    useEffect(() => {
        if (!canDrop) return
        if (onIsOver && typeof onIsOver === 'function') {
            onIsOver(isOver);
        }
    }, [isOver]);

    return (
        <div
            ref={drop}
            style={{}}
            className={classNames(className,isActive && isActiveStyling, 'transition-all duration-200 ease-in-out')}
        >
            {children}
        </div>
    );
};

export default DropTarget;
