// AnnualChecklist.js

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useParams } from 'react-router-dom';
import DoubleClickEdit from '../../../../../components/DoubleClickEdit';
import FadeIn from '../../../../../components/transitions/FadeIn';
import { classNames } from '../../../../../components/misc/styling';
import DashboardPanel from '../../../../../components/panels/DashboardPanel';
import { PencilAltIcon } from '@heroicons/react/outline';
import ReactDatePicker from '../../../../../components/ReactDatePicker';

const AnnualChecklist = () => {
    const { household_id: householdId } = useParams();
    const householdsApi = new ApiRequest(
        API_ROUTES.HOUSEHOLDS,
        API_AUTH_TYPES.ADVISOR
    );
    const queryClient = useQueryClient();
    const [isEditing, setIsEditing] = useState(false);
    // Default items
    const defaultItems = [
        {
            name: 'Took RMD',
            default_reset_date: '12-31', // January 1st
        },
        {
            name: "Took Inherited RMD",
            default_reset_date: '12-31',
        },
        {
            name: 'FSA Spend',
            default_reset_date: '12-31'
        },
        {
            name: 'Recertified Income for Student Loans',
            default_reset_date: '01-01',
        },
        {
            name: 'Filed Taxes',
            default_reset_date: '04-15',
        },
        {
            name: 'Enrolled in Workplace Benefits',
            default_reset_date: '11-01',
        },
        {
            name: 'Checked Beneficiaries',
            default_reset_date: '01-01',
        },
        {
            name: 'Funded HSA',
            default_reset_date: '12-31',
        },
        {
            name: 'Funded 401(k)',
            default_reset_date: '12-31',
        },
        {
            name: 'Roth IRA Funded',
            default_reset_date: '04-15',
        },
        {
            name: 'Backdoor Roth IRA',
            default_reset_date: '04-15',
        },
        {
            name: 'Solo 401(k) Employee',
            default_reset_date: '12-31',
        },
        {
            name: 'Solo 401(k) Employer',
            default_reset_date: '04-15',
        },
        {
            name: 'Invest Vested RSUs',
            default_reset_date: '01-01',
        }
    ];

    // Function to format the reset date into text like 'Jan 1st'
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // 4th - 20th
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const formatResetDate = (dateStr) => {
        const [month, day] = dateStr.split('-').map(Number);
        const date = new Date(2020, month - 1, day); // Year doesn't matter here
        const monthName = date.toLocaleString('en-US', { month: 'short' });
        const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
        return `${monthName} ${dayWithSuffix}`;
    };

    // Function to compute the next upcoming reset date for sorting
    const computeNextResetDate = (item) => {
        const currentDate = new Date();
        const [month, day] = item.reset_date.split('-').map(Number);
        const resetDateThisYear = new Date(
            currentDate.getFullYear(),
            month - 1,
            day
        );

        let nextResetDate;
        if (resetDateThisYear >= currentDate) {
            nextResetDate = resetDateThisYear;
        } else {
            nextResetDate = new Date(currentDate.getFullYear() + 1, month - 1, day);
        }

        return nextResetDate;
    };

    // Function to compute the last reset date
    const getLastResetDate = (item) => {
        const currentDate = new Date();
        const [month, day] = item.reset_date.split('-').map(Number);
        let resetDate = new Date(currentDate.getFullYear(), month - 1, day);
        if (resetDate > currentDate) {
            // Reset date is in the future; last reset date was last year
            resetDate = new Date(currentDate.getFullYear() - 1, month - 1, day);
        }
        return resetDate;
    };

    // State to manage showing hidden items
    const [showHiddenItems, setShowHiddenItems] = useState(false);

    // Fetch data from the server
    const {
        data: serverData,
        isLoading,
        isError,
    } = useQuery(['annualChecklist', householdId], () =>
        householdsApi.get({
            endpoint: 'annual_checklist',
            params: [householdId],
        })
    );

    // Local state to manage the checklist
    const [checklist, setChecklist] = useState([]);

    // Effect to merge default items with server data and sort by upcoming reset date
    useEffect(() => {
        if (serverData && serverData) {
            const serverItems = serverData;

            const mergedItems = defaultItems.map((item) => {
                const serverItem = serverItems.find((si) => si.name === item.name);
                const mergedItem = {
                    ...item,
                    ...serverItem,
                    reset_date:
                        serverItem && serverItem.reset_date
                            ? serverItem.reset_date
                            : item.default_reset_date,
                    hidden: serverItem ? serverItem.hidden : false,
                    date_last_completed: serverItem
                        ? serverItem.date_last_completed
                        : null,
                };

                // Compute next upcoming reset date for sorting
                mergedItem.nextResetDate = computeNextResetDate(mergedItem);

                return mergedItem;
            });

            // Sort the items by upcoming reset date
            mergedItems.sort((a, b) => a.nextResetDate - b.nextResetDate);

            setChecklist(mergedItems);
        } else {
            // If data is null, initialize checklist with default items
            const initialItems = defaultItems.map((item) => {
                const mergedItem = {
                    ...item,
                    reset_date: item.default_reset_date,
                    hidden: false,
                    date_last_completed: null,
                };

                // Compute next upcoming reset date for sorting
                mergedItem.nextResetDate = computeNextResetDate(mergedItem);

                return mergedItem;
            });

            // Sort the items by upcoming reset date
            initialItems.sort((a, b) => a.nextResetDate - b.nextResetDate);

            setChecklist(initialItems);
        }
    }, [serverData]);

    // Updated function to determine if item is completed
    const isItemCompleted = (item) => {
        const lastResetDate = getLastResetDate(item);
        const lastCompletedDate = item.date_last_completed
            ? new Date(item.date_last_completed)
            : null;

        if (!lastCompletedDate) return false;

        // If last completed date is on or after the last reset date, item is completed
        return lastCompletedDate >= lastResetDate;
    };

    // Mutation to update the checklist with optimistic updates for checkbox
    const updateCheckboxMutation = useMutation(
        ({ updatedChecklist }) =>
            householdsApi.patch({
                endpoint: 'annual_checklist',
                params: [householdId],
                body: {
                    annual_checklist: updatedChecklist,
                },
            }),
        {
            onMutate: async ({ updatedChecklist }) => {
                // Cancel any outgoing refetches
                await queryClient.cancelQueries(['annualChecklist', householdId]);

                // Snapshot the previous value
                const previousChecklist = [...checklist];

                // Optimistically update local state
                setChecklist(updatedChecklist);

                // Return context with previous value
                return { previousChecklist };
            },
            onError: (err, variables, context) => {
                // Rollback to previous value
                setChecklist(context.previousChecklist);
            },
            onSettled: () => {
                // Optionally refetch after error or success
                queryClient.invalidateQueries(['annualChecklist', householdId]);
            },
        }
    );

    // Handler for checkbox change with optimistic updating
    const handleCheckboxChange = (itemName) => {
        const index = checklist.findIndex((item) => item.name === itemName);
        if (index === -1) return;

        const isCompleted = isItemCompleted(checklist[index]);
        const currentDate = new Date().toISOString().split('T')[0];

        // Prepare updated item
        const updatedItem = {
            ...checklist[index],
            date_last_completed: isCompleted ? null : currentDate,
        };

        // Create updated checklist
        const updatedChecklist = [...checklist];
        updatedChecklist[index] = updatedItem;

        updateCheckboxMutation.mutate({ updatedChecklist });
    };

    // Mutation to update other changes (reset date, hiding items)
    const updateMutation = useMutation(
        (updatedAnnualChecklist) =>
            householdsApi.patch({
                endpoint: 'annual_checklist',
                params: [householdId],
                body: {
                    annual_checklist: updatedAnnualChecklist,
                },
            }),
        {
            onSuccess: () => {
                // Optionally refetch or invalidate queries
                queryClient.invalidateQueries(['annualChecklist', householdId]);
            },
        }
    );

    // Handler for hiding an item
    const handleHideItem = (itemName) => {
        const index = checklist.findIndex((item) => item.name === itemName);
        if (index === -1) return;

        const updatedChecklist = [...checklist];
        updatedChecklist[index].hidden = !updatedChecklist[index].hidden;

        setChecklist(updatedChecklist);
        updateMutation.mutate(updatedChecklist);
    };

    // Handler for changing reset date
    const handleResetDateChange = (itemName, newDate) => {
        const index = checklist.findIndex((item) => item.name === itemName);
        if (index === -1) return;

        const updatedChecklist = [...checklist];
        updatedChecklist[index].reset_date = newDate;

        // Recompute the next reset date
        updatedChecklist[index].nextResetDate = computeNextResetDate(
            updatedChecklist[index]
        );

        // Re-sort the checklist after date change
        updatedChecklist.sort((a, b) => a.nextResetDate - b.nextResetDate);

        setChecklist(updatedChecklist);
        updateMutation.mutate(updatedChecklist);
    };

    // State to manage which item is being edited
    const [editingItemName, setEditingItemName] = useState(null);


    // Group checklist items by the year of their nextResetDate
    const groupedChecklist = checklist.reduce((groups, item) => {
        if (item.hidden) return groups; // Exclude hidden items from main list

        const year = item.nextResetDate.getFullYear();
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(item);
        return groups;
    }, {});
    if (isLoading) {
        return (
            <div className="text-center py-10">
                <span>Loading...</span>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="text-center py-10 text-red-500">
                <span>Error loading data.</span>
            </div>
        );
    }

    return (
        <DashboardPanel title='Annual Checklist'
            SecondaryTitleComponent={<button
                className='btn-md btn-gray-outline flex items-center space-x-2'
                onClick={() => setIsEditing(!isEditing)}
            >
                <p>
                 {isEditing? 'Stop Editing Checklist' : 'Edit Checklist'}
                </p> <PencilAltIcon className='h-5 w-5' />
        </button>}
        >
            <div className="">
                {Object.keys(groupedChecklist)
                    .sort((a, b) => a - b) // Sort years in ascending order
                    .map((year,index) => (
                        <div key={year}>
                            {/* Year Separator */}
                            <div className="flex items-center justify-between">
                                <h2 className="text-lg font-semibold">{year}</h2>
                                {index === 0 && (
                                    <p className="text-sm text-gray-800 font-semibold">
                                        Due by
                                    </p>
                                )}
                            </div>
                            <div className="space-y-2">
                                {groupedChecklist[year].map((item) => (
                                    <div
                                        key={item.name}
                                        className="flex items-center justify-between border-b pb-2"
                                    >
                                        <button
                                            onClick={() => handleCheckboxChange(item.name)}
                                            className={classNames(
                                                'btn-sm border rounded-md font-semibold',
                                                isItemCompleted(item) && 'bg-green-100'
                                            )}
                                        >
                                            {item.name}
                                        </button>

                                        {isEditing ? (
                                            <button
                                                className="text-sm text-gray-500 hover:text-gray-700 mt-1"
                                                onClick={() => handleHideItem(item.name)}
                                            >
                                                {item.hidden ? 'Unhide' : 'Hide'}
                                            </button>
                                        ) : (
                                            <DoubleClickEdit
                                                defaultComponent={
                                                    <div className="text-sm text-gray-500">
                                                        {formatResetDate(item.reset_date)}
                                                    </div>
                                                }
                                                    
                                                    editComponent={
                                                    <ReactDatePicker
                                                            selected={new Date(
                                                                new Date().getFullYear(),
                                                                parseInt(item.reset_date.split('-')[0], 10) - 1, // Months are zero-indexed
                                                                parseInt(item.reset_date.split('-')[1], 10)
                                                            )}                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                                                                    const day = String(date.getDate()).padStart(2, '0');
                                                                    const newDate = `${month}-${day}`; // 'MM-DD'
                                                                    handleResetDateChange(item.name, newDate); // Pass the Date object
                                                                }
                                                            }}

                                                            className="input-primary mr-auto"
                                                            dateFormat="MM-dd"
                                                      
                                                            placeholderText="Select date"
                                                     />
                                                  
                                                }
                                                handleSubmitEdit={() => {
                                                    // Optional: Any actions on submit
                                                    setEditingItemName(null);
                                                }}
                                                componentName={`resetDate-${item.name}`}
                                                showEdit={editingItemName === item.name}
                                                setShowEdit={(show) => {
                                                    if (show) {
                                                        setEditingItemName(item.name);
                                                    } else {
                                                        setEditingItemName(null);
                                                    }
                                                }}
                                                toolTipPosition="bottom"
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>

            {/* Hidden Items */}
            {checklist.some((item) => item.hidden) && (
                <div className="mt-6">
                    <button
                        className="text-sm text-gray-500 hover:text-gray-700 mb-2"
                        onClick={() => setShowHiddenItems(!showHiddenItems)}
                    >
                        {showHiddenItems ? 'Hide Hidden Items' : 'Show Hidden Items'}
                    </button>
                    <FadeIn isShowing={showHiddenItems}>
                        <div className="space-y-2">
                            {checklist
                                .filter((item) => item.hidden)
                                .map((item) => (
                                    <div
                                        key={item.name}
                                        className="flex items-center justify-between border-b pb-2"
                                    >
                                        <div className="text-sm font-semibold">{item.name}</div>
                                        <button
                                            className="text-sm text-gray-500 hover:text-gray-700"
                                            onClick={() => handleHideItem(item.name)}
                                        >
                                            Unhide
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </FadeIn>
                </div>
            )}
        </DashboardPanel>    );
};

export default AnnualChecklist;
