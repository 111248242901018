import React, {  useState } from "react";

import { useEffect } from "react";
import socketIoConnection from "../../../socketIoConnect";

import { SiChatbot } from "react-icons/si";
import useArray from "../../../hooks/useArray";
import { classNames } from "../../../helpers/classNames";
import AlertError from "../../alerts/AlertError";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { LightningBoltIcon, AcademicCapIcon } from "@heroicons/react/outline";
import useScrollToBottom from "../../../hooks/useScrollToBottom";

import ContextSelection from "./components/ContextSelection";
import Modal from "./components/Modal";

import Message from "./components/Message";
import Conversations from "./components/Conversations";
import { useQueryClient } from "react-query";
import TextBox from "./components/TextBox";
import ExpandedImage from "./components/ExpandedImage";
import Memory from "./components/Memory";

const quickActions = [
  {
    title: "Financial Story",
    query: `You are a paraplanner tasked with creating three chapters of a financial story for your financial planner's clients. Each chapter should include a title that summarizes the theme and 3-5 related tasks that the clients should complete. Do not use labels for the tasks. If your client has an existing financial story, build upon it. It is essential to avoid generalizations and provide as much detail as possible.
       Your writing should be client-focused and easy to read.
       Use your knowledge of finance to craft engaging and informative chapters that empower your clients to take control of their financial future. IMPORTANT: The financial story is meant to be read by the clients, not the financial planner.`,
  },
  {
    title: "Brainstorm Tasks",
    query: `As a paraplanner, your role is to support financial advisors in serving their clients. Your task is to brainstorm specific ways to help clients based on their Client Information, Notes, and Financial Story.
       Be concise and tailored in your suggestions, taking into account their unique circumstances and goals. Your brainstorming should focus on actionable steps that the financial advisor can take to support the client. 
       Use your expertise in finance and your understanding of the client's situation to provide valuable insights and recommendations. 
      Your goal is to help the financial advisor deliver exceptional service and support to their clients, leading to positive outcomes for everyone involved.`,
  },
  {
    title: "Action Items",
    query: `As a financial professional, you have a responsibility to follow up with your clients on action items and next steps. 
      Your task is to identify the specific action items that need to be addressed and the next steps that should be taken with each client.
       This may involve reviewing notes and financial records, contacting the client or other stakeholders, and coordinating with other professionals as needed. 
       Your focus should be on ensuring that all necessary tasks are completed in a timely and efficient manner, while also providing exceptional service to the client. 
       Use your expertise and attention to detail to identify any potential roadblocks or challenges that may arise, and develop effective strategies for overcoming them. 
      By following up on action items and next steps, you can help your clients achieve their financial goals and build lasting relationships based on trust and mutual success.`,
  },
  {
    title: "Email Draft",
    query: `As a paraplanner bot, you are currently having a conversation with the financial planner and have been tasked with setting up an email draft in a natural tone for the financial planner to send to their client(s). 
      Your email should be should be a draft for the financial planner to their clients that covers any action items, concerns, or questions that have been raised in your conversation. 
      Use a tone that reflects the financial planner's style and be sure to capture the essence of their communication. 
      Your email should be easy to understand and provide clear next steps for the client to take. Use correct grammar and spelling to ensure that the email is professional and polished. 
      Your goal is to help the financial planner communicate effectively with their clients and provide them with a clear and concise summary of the current conversation. Do not mention the conversation.
       Don't use markdown. Don't include a subject line. The closing should just be my first name.`,
  },
  {
    title: "Checkin Email",
    query: `As a paraplanner, you are responsible for setting up a check-in email draft  in a natural tone for the financial planner to send to their client(s). 
      Your goal is to provide a friendly and approachable message that summarizes any action items that need follow-up and invites clients to ask questions. 
      Use specific details to ensure that the email is informative and actionable. 
      Your email should also include clear next steps and a call to action that encourages clients to reach out if they have any questions or concerns.
       Use correct grammar and spelling to ensure that the email is professional and polished.
       Your goal is to help the financial planner stay connected with their clients and provide them with a high level of service and support. Don't use markdown. Don't include a subject line. The closing should just be my first name.`,
  },
  {
    title: "HH Summary",
    query: `
As a paraplanner bot, you have been tasked with creating a quick action that summarizes a household's financial situation. 
Your goal is to provide a concise and informative summary that includes key details such as income, expenses, assets, and liabilities. 
Use a clear and organized format to make the summary easy to understand and reference. 
Be sure to tailor the summary to the client's specific situation and provide actionable insights that can help the financial planner make informed recommendations.
Your goal is to provide a quick and useful resource that helps the financial planner stay informed and provide exceptional service to their clients. Only include information that you know.`,
  },
  {
    title: "More specific",
    query: "Reword what you just said, but be more specific",
  },
  {
    title: "More Concise",
    query: "Reword what you just said, but be more concise",
  },
];
const models = [

  { id: 'quicker', name: "Quicker", icon: LightningBoltIcon }, { id: 'smarter', name: "Smarter", icon: AcademicCapIcon },

]

const ParabotInterface = ({ location }) => {
  const params = useParams();
  const [householdId, setHouseholdId] = useState(params.household_id || '');
  const [files, setFiles] = useState([
  ]);
  const [query, setQuery] = useState("");
  const [runType, setRunType] = useState(models[0].id)
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const conversation = useArray([
  ]);
  const [socket, setSocket] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  let currentMessage = "";
  const queryClient = useQueryClient();
  const { scrollRef, hardScrollToBottom } = useScrollToBottom(conversation.array, 35);
  const [enableContext, setEnableContext] = useState(true)
  const [expandedImageUrl, setExpandedImageUrl] = useState(null)

  //activeiate socket
  useEffect(() => {
    const socket = socketIoConnection("parabot");
    setSocket(socket);
    socket.on("completion-done", () => {

      setTimeout(() => {
        setLoading(false);
        queryClient.invalidateQueries("parabot_conversation_ids");
        currentMessage = "";
      }, 100);
    });
    //get current conversation

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return
    if (!conversationId) {

      conversation.clear()
      return
    }
    if (socket) {
      socket.emit("fetch-active-conversation", { id: conversationId });
    }
  }, [socket, conversationId]);



  useEffect(() => {
    hardScrollToBottom();
  }, [isOpen])

  //update message with incoming data stream
  useEffect(() => {
    if (!socket) return;
    const handler = (newData) => {
      currentMessage = currentMessage + newData;
      const otherContent = conversation.array[conversation.array.length - 1].content.filter((item) => item.type !== 'text');
      if (conversation.array[conversation.array.length - 1].role === "assistant") {
        conversation.update(conversation.array.length - 1, {
          role: "assistant",
          content: [{ type: 'text', text: currentMessage }, ...otherContent],
        });
      } else {
        conversation.push({
          role: "assistant",
          content: [{ type: 'text', text: currentMessage },...otherContent],
        })
      }
     
    };
    socket.on("completion_data", handler);
    return () => socket.off("completion_data");
  }, [socket, conversation.array.length]);

  useEffect(() => {
    if (!socket) return;
    const handler = (fileDownloadContent) => {
      conversation.push( {
        role: "assistant",
        content: fileDownloadContent,
      });
    };
    socket.on("file_download", handler);
    return () => socket.off("file_download");
  }, [socket, conversation.array.length]);

  useEffect(() => {
    if (!socket) return;
    const handler = ({ id }) => {
      queryClient.invalidateQueries('parabot_conversation_ids');
      setConversationId(id);
    };
    socket.on("new_name", handler);
    return () => socket.off("new_name");
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    const handler = (newData) => {
      setError(
        "Sorry, something went wrong. Please refresh the page and try again."
      );
    };
    socket.on("error", handler);
    return () => socket.off("error");
  }, [socket]);

  //set the active conversation
  useEffect(() => {
    if (!socket) return;

    const handler = (data) => {
      conversation.set(data.messages)
      setLoading(false)
    };
    socket.on("return-active-conversation", handler);
    return () => socket.off("return-active-conversation");
  }, [socket, conversation.array.length]);

  const handleSubmit = (message) => {
    setLoading(true);
    let newConversation = conversation.array;
    let resources = householdId === 'mental_models' ? [{ type: 'mental_models' }] : [{ type: 'household', household_id: householdId }];
    if (!enableContext) resources = []
    let content = []
    if (message) content.push({ type: "text", text: message.trim() });
    if (files.length > 0) files.filter(f => f.type === 'image').forEach((image) => content.push({ type: "image_url", image_url: { url: image.url } }));
    newConversation.push({ role: "user", content });
    socket.emit("continue-conversation", {
      message: content,
      location: 'household',
      resources,
      run_type: runType,
      conversation_id: conversationId,
      context_enabled: enableContext,
      local_time: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()} - ${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      files: files.filter(f => f.type === 'spreadsheet' || f.type === 'document' || f.type === 'powerpoint')
    });
    conversation.clear();
    setFiles([]);
    conversation.set([...newConversation, { role: "assistant", content: [{ type: 'text', text: '' }] }]);
    setQuery('');
    hardScrollToBottom()
  };

  const handleQuickAction = (quickActionQuery) => {
    handleSubmit(quickActionQuery);
  };

  const textAreaRef = useRef(null);




  const openExpandedImage = (imageUrl) => {
    setExpandedImageUrl(imageUrl);
  };


  const handleStopGenerating = async () => {
    socket.emit('stop-streaming')
  }

  const [household, setHousehold] = useState()

  useEffect(() => {
    if (window.location.pathname.includes('/crm/dashboard')) {
      setHouseholdId('mental_models');
      setHousehold({
        name: 'All Households',
        household_id:'mental_models'
      })
    } else if (household) {
      setHouseholdId(household.household_id);
    }
  }, [household]);


  return (
    <div className={classNames("fixed right-6 bottom-6 z-50")}>
      <Modal open={isOpen} setOpen={setIsOpen}>
        <div>
          <div className="  bg-gradient-to-r rounded-t-xl from-blue-700 via-blue-500 to-blue-900">

            <div className="flex justify-between px-8 py-3  items-center"> <h5 className="text-white font-medium   text-2xl  rounded-t-xl">
              Parabot
            </h5>

              <div className="flex space-x-2 items-center">
                <Memory />
                <ContextSelection enableContext={enableContext} setEnableContext={setEnableContext} household={household} setHousehold={setHousehold} />
                {/* <ModelSelection models={models} selectedModel={runType} setSelectedModel={setRunType} /> */}
              </div>

            </div>
          </div>
          <div className="flex">
            <Conversations conversationId={conversationId} setConversationId={setConversationId} handleClearConversation={() => conversation.clear()} />
            <div className="bg-white  w-full rounded-br-xl ">
              <div className="w-full" ref={scrollRef}>
                <div className="relative">
                  <ExpandedImage imgUrl={expandedImageUrl} setImgUrl={setExpandedImageUrl} />

                  <div className=" h-[75vh]   overflow-y-auto scroll-smooth custom-scrollbar-thick  custom-scrollbar-gray relative">
                  <ul className="py-5  space-y-6   mx-auto max-w-4xl px-10  mb-16 ">
                    {conversation.array.map((item, index) => {
                      return (
                        <Message index={index} item={item} openExpandedImage={openExpandedImage} />
                      )
                    })}
                    </ul>
                  </div>
                  <div className=" w-full  max-w-4xl mx-auto  flex  bg-white rounded-t-2xl">
                    <TextBox openExpandedImage={openExpandedImage} query={query} setQuery={setQuery} handleSubmit={handleSubmit} textAreaRef={textAreaRef} files={files} setFiles={setFiles} loading={loading} handleStopGenerating={handleStopGenerating} />
                  </div>
                </div>
                {error && (
                  <div className="p-5">
                    <AlertError>{error}</AlertError>
                  </div>
                )}

              </div>
              
            </div>
          </div>
        </div>
      </Modal>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-400 shadow-2xl rounded-full sm:w-16 sm:h-16 w-14 h-14 absolute right-0 bottom-0 hover:brightness-110 "
      >
        <SiChatbot className="w-7 h-7 sm:h-8 sm:w-8  text-white my-auto mx-auto" />
      </button>{" "}
    </div>
  );
};

export default ParabotInterface;
