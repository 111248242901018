import React, { useEffect } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import QuillTextEditor from "../../../../../../components/textEditor/Quill-Text-Editor";
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import { PencilIcon } from "@heroicons/react/outline";

const ChapterContext = ({ chapter, chaptersListQueryKey }) => {
    const [showEdit, setShowEdit] = useState(false);
    const handleSave = () => {
        queryClient.invalidateQueries(chaptersListQueryKey);
        setShowEdit(false);
    };
    const options = {
        is_draft: false,
        route: "chapter_context",
        search_params: { document_id: chapter.chapter_id },
    };

    useEffect(() => {
        if (showEdit) return
        setTimeout(() => {
            queryClient.invalidateQueries(chaptersListQueryKey);
        }, 500)
    }, [showEdit])

    const queryKey = `${chapter.chapter_id}`;
    const queryClient = useQueryClient();
    const [showFullContext, setShowFullContext] = useState(false)
    return (
        <>
            {chapter.content || showEdit ?
                <DoubleClickEdit
                    showEdit={showEdit}
                    setShowEdit={setShowEdit}
                    defaultComponent={<div className="flex items-start justify-between text-left w-full">
                        <div className="">
                            {showFullContext ?
                                <RichTextDisplay content={chapter.content} thisClassName='markdown-notes' />
                                :
                                <p>{chapter.content?.ops[0].insert.trim().split(' ').slice(0, 100).join(" ")}</p>
                            }
                            <button className="text-blue-600 hover:underline mt-2" onClick={() => setShowFullContext(!showFullContext)}>
                                {showFullContext ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                        <button
                            className=" ml-10"
                            onClick={() => { setShowEdit(true) }}
                        >
                            <PencilIcon className="text-gray-500 h-6 w-6" />
                        </button>
                    </div>}
                    editComponent={
                        <div className="w-full">
                            <QuillTextEditor
                                placeholder="Create New Note"
                                options={options}
                                queryKey={queryKey}
                                listQueryKey={chaptersListQueryKey}
                            />
                            <button
                        onClick={() => setShowEdit(false)}
                                className="btn-md btn-primary">Stop Editing</button>
                        </div>
                    }
                    handleSubmitEdit={handleSave}
                    componentName="description"
                /> :
                <button
                    className="btn-md btn-gray-outline-rounded"
                    onClick={() => setShowEdit(true)}

                > Add Context</button>
            }
        </>
    );
};

export default ChapterContext;
