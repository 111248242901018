import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { patchReq, postReq } from "../../../../../apis/internalApi";
import { API } from "./../../../../../constants/index";

const ClientStatus = ({ householdMeta }) => {
  const accessToken = useSelector((state) => state.user.accessToken);
  const [householdType, setHouseholdType] = useState(
    householdMeta.household_type
  );
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  let status = "";
  if (householdType === "client") {
    status = "Client";
  }
  if (householdType === "prospect") {
    status = "Prospect";
  }
  if (householdType === "former_client") {
    status = "Former Client";
  }
  if (householdType === "internal") {
    status = "Internal";
  }


  const handleManageSubscription = async () => {
    if (!householdMeta.stripe) return setShowChangeStatus(true);

    try {
      const response = await postReq(
        API.STRIPE,
        "create_customer_portal_session_auth_advisor",
        accessToken,
        { household_id: householdMeta.household_id }
      );
      if (response.status === 200) {
        window.location = response.data.redirect;
      }
    
    } catch (error) {}
  };

  const handleManualClientStatusChange = async (e) => {
       const newStatus = e.target.value         
    try {
      const response = await patchReq(
        API.HOUSEHOLDS,
        "manual_household_status_change",
        accessToken,
        { household_id: householdMeta.household_id, new_status: newStatus }
      );
      if (response.status === 200) {

        setHouseholdType(newStatus);
        setShowChangeStatus(false);
      }
    } catch (error) {}
  };
  return (
    <div className="">
      {" "}
      <div className="flex justify-between space-x-2">       <p >Status</p>
        <p className="font-semibold">{status}</p></div>
      {!showChangeStatus ? (
        <div className="flex justify-end">
          {" "}
       
          <button
            className="btn-danger btn-md mt-5 "
            onClick={handleManageSubscription}
          >
            Manage subscription
          </button>
        </div>
      ) : (
        <>
          {" "}
          <select
            className="input-primary"
            value={householdType}
            onChange={handleManualClientStatusChange}
          >
            <option value="prospect">Prospect</option>{" "}
            <option value="client">Client</option>{" "}
              <option value="former_client">Former Client</option>
            <option value="internal">Internal</option>
          </select>
          <button
            className="underline mt-2"
            onClick={() => setShowChangeStatus(false)}
          >
            Cancel{" "}
          </button>
        </>
      )}
    </div>
  );
};

export default ClientStatus;
