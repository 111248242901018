import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  reload: false,
  manual: false,
  info: {
    // info about the account
    id: "", //*
    custodian: "", //*
    accountType: "", //*
    accountName: "", //*
    portfolio: "", //*
    portfolioDisplay: "", //*
    useType: null, //*
    submissionId: ""
  },

  it: {
    rebalance: {
      excludedTickers: [], //*
      assetAllocation: "", //*
      portfolio: "", //*
      holdingsToSell: [],
    },
    //it is data specifically for an investment tool run
    balance: 0, //account value not including excluded holdings
    cash: 0,
    cashCarveout: 0,
    accountValue: 0, //total account value
    assetAllocation: "",
    assetAllocationDisplay: "",
    currentHoldings: [],
    mutualFunds: [],
    //data needed for IT slides
    resultEnd: "",
    purchaseArr: [],

    //summary data
    //sell
  },
  fees: {
    //fees header
    portfolioEr: 0,
    savings1Yr: 0,
    savings10Yr: 0,
    savings30Yr: 0,

    industryAUM: 0,
    industryFees: 0,
    ourFees: 0,
    timesLower: 0,
    //fees body
    yearsIndex: 0,
    yearsArr: [1, 5, 10, 20, 30, 40],
    tierArr: [],
    leftOverArr: [],
    savingsXyrArr: [0, 1, 2, 3, 4, 5],
    imageArr: [],
    itemTitleArr: [],
    activeButton: [
      "w-24 py-2 rounded lg:butn butn-primary-outline",
      "w-24 py-2 rounded lg:butn butn-primary-outline",
      "w-24 py-2 rounded lg:butn butn-primary",
      "w-24 py-2 rounded lg:butn butn-primary-outline",
      "w-24 py-2 rounded lg:butn butn-primary-outline",
      "w-24 py-2 rounded lg:butn butn-primary-outline",
    ],
  },
  acctPicked: false,
  r_fromAccountsPage: false, //*
  successMessage: "",
};

export const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers: {
    r_fromAccountsPage: (state) => {
      state.r_fromAccountsPage = !state.r_fromAccountsPage;
    },
    r_setAccountId: (state, action) => {
      state.info.id = action.payload;
    },
    r_changeAssetAllocation: (state, action) => {
      state.it.assetAllocation = action.payload.assetAllocation;
      state.it.assetAllocationDisplay = action.payload.assetAllocationDisplay;
    },
    r_changePortfolio: (state, action) => {
      state.info.portfolio = action.payload.portfolio;
      state.info.portfolioDisplay = action.payload.portfolioDisplay;
    },
    r_currentHoldings: (state, action) => {
      state.it.currentHoldings = action.payload.holdings;
      state.it.mutualFunds = action.payload.mutualFunds;
    },
    r_investments: (state, action) => {
      state.it.investments = action.payload.investments;
      state.it.resultEnd = action.payload.resultEnd + 1;
    },
    r_balance: (state, action) => {
      state.it.balance = action.payload;
      state.it.cashRemaining = action.payload;
      state.it.netBalance = action.payload
    },
    r_cashCarveout: (state, action) => {
      state.it.cashCarveout = action.payload
    },
    r_useType: (state, action) => {
      state.info.useType = action.payload;
    },
    r_clearInvestmentTool: () => initialState,
    r_rebalanceMetaData: (state, action) => {
      state.it.rebalance.portfolio = action.payload.portfolio;
      state.it.rebalance.assetAllocation = action.payload.assetAllocation;
      state.it.rebalance.cash = action.payload.cash;
    },
    r_excludedTickers: (state, action) => {
      state.it.rebalance.excludedTickers = action.payload;
    },
    r_holdingsToSell: (state, action) => {
      state.it.rebalance.holdingsToSell = action.payload;
    },
    r_submissionId: (state, action) => {
      state.info.submissionId = action.payload
    },
    // Above Confirmed
    r_accountInfo: (state, action) => {
      state.info.custodian = action.payload.custodian;
      state.info.accountType = action.payload.accountType;
      state.info.accountName = action.payload.accountName;
      state.info.portfolio = action.payload.portfolio;
      state.info.portfolioDisplay = action.payload.portfolioDisplay;
    },
    r_reload: (state) => {
      state.reload = !state.reload;
    },
    r_purchaseArr: (state, action) => {
      state.it.purchaseArr = action.payload;
    },
    r_accountValue: (state, action) => {
      state.it.accountValue = action.payload;
    },
    r_cash: (state, action) => {
      state.it.cash = action.payload;
    },

    //investment tool --------------------------------------

    //new actions above ------------------------------

    // TODO: Delete
    // togglePicked: (state) => {
    //   state.acctPicked = !state.acctPicked;
    // },
    // notPicked: (state) => {
    //   state.acctPicked = false;
    // },
    setPortfolio: (state, action) => {
      state.it.portfolio = action.payload.portfolio;
      state.it.portfolioDisplay = action.payload.display;
    },

    setSuccessMessage: (state, action) => {
      state.it.successMessage = action.payload;
    },
    //Fees -------------------------------------------
    setFeesHeader: (state, action) => {
      state.fees.portfolioEr = action.payload.portfolioEr;
      state.fees.savings1Yr = action.payload.savings1Yr;
      state.fees.savings10Yr = action.payload.savings10Yr;
      state.fees.savings30Yr = action.payload.savings30Yr;
      state.fees.industryAUM = action.payload.industryAUM;
      state.fees.industryFees = action.payload.industryFees;
      state.fees.ourFees = action.payload.ourFees;
      state.fees.timesLower = action.payload.timesLower;
    },
    setFeesBody: (state, action) => {
      state.fees.leftOverArr = action.payload.leftOverArr;
      state.fees.savingsXYrArr = action.payload.savingsXYrArr;
      state.fees.imageArr = action.payload.imageArr;
      state.fees.itemTitleArr = action.payload.itemTitleArr;
      state.fees.tierArr = action.payload.tierArr;
      // state.fees.leftOver = action.payload.leftOver;
      // state.fees.savingsXYr = action.payload.savingsXYr;
      // state.fees.image = action.payload.image;
      // state.fees.itemTitle = action.payload.itemTitle
    },
    setYears: (state, action) => {
      state.fees.yearsIndex = action.payload.index;
      state.fees.activeButton = [
        "w-24 py-2 rounded lg:butn butn-primary-outline",
        "w-24 py-2 rounded lg:butn butn-primary-outline",
        "w-24 py-2 rounded lg:butn butn-primary-outline",
        "w-24 py-2 rounded lg:butn butn-primary-outline",
        "w-24 py-2 rounded lg:butn butn-primary-outline",
        "w-24 py-2 rounded lg:butn butn-primary-outline",
      ];
      state.fees.activeButton[action.payload.index] =
        " w-24 py-2 rounded lg:butn butn-primary";
    },

    r_manualInvest: (state) => {
      state.manual = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  r_fromAccountsPage,
  r_cashCarveout,
  r_clearInvestmentTool,
  r_setAccountId,
  r_changeAssetAllocation,
  r_currentHoldings,
  r_investments,
  r_balance,
  r_useType,
  r_rebalanceMetaData,
  r_holdingsToSell,
  r_purchaseArr,
  r_accountValue,
  r_cash,  r_changePortfolio,

  // AboveConfirmed
  r_reload,
  r_accountInfo,
  r_excludedTickers,
  // togglePicked,
  // notPicked,
  setPortfolio,
  setSuccessMessage,
  setFeesHeader,
  setFeesBody,
  setYears,
  r_manualInvest,
  r_submissionId
} = investSlice.actions;

export const selectIT = (state) => state.invest.IT;

export default investSlice.reducer;
