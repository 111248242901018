import { useState } from "react";
import { useMutation, useQueryClient } from 'react-query';
import {
  countries,
  usStates,
} from "../../../../../../../components/option-lists/geography";
import { longUid } from "../../../../../../../helpers/id-generators";
import { dateFormatterYYYYMMDD } from '../../../../../../../helpers/formatters';
import Toggle from "../../../../../../../components/toggle";
import AlertError from "../../../../../../../components/alerts/AlertError";
import ApiRequest from "../../../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../../api/apiConstants";
import SmallLoadingSpinner from "../../../../../../../components/loading/SmallLoadingSpinner";

// Create API instance
const usersApi = new ApiRequest(API_ROUTES.USERS,API_AUTH_TYPES.ADVISOR);

const EditUser = ({ person, type, householdId,closeSlider }) => {
  const queryClient = useQueryClient();

  // Form state
  const [formData, setFormData] = useState({
    first_name: person.first_name || "",
    last_name: person.last_name || "",
    email: person.email || "",
    secondary_email: person.secondary_email || "",
    marital_status: person.marital_status || "",
    tax_status: person.tax_status || "",
    job_title: person.job_title || "",
    company: person.company || "",
    phone_number: person.phone_number || "",
    street_address: person.street_address || "",
    street_address_2: person.stress_address_2 || "",
    city: person.city || "",
    state: person.state || "",
    zip: person.zip || "",
    country: person.country || "",
    gender: person.gender || "",
    dob: person.dob ? dateFormatterYYYYMMDD(new Date(person.dob)) : null,
    is_deceased: person.is_deceased || false,
    household_id: householdId,
    preferred_first_name: person.preferred_first_name || (person.first_name || ""),
    preferred_last_name: person.preferred_last_name || (person.last_name || ""),
  });

  // Mutations
  const updateUserMutation = useMutation(
    async (data) => {
      return await usersApi.patch({
        endpoint: 'users_update',
        params: [person.user_id],
        body: data
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users', householdId]);
        closeSlider();
      }
    }
  );

  const createUserMutation = useMutation(
    async (data) => {
      return await usersApi.post({
        endpoint: 'users_post',
        body: { ...data,  }
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users', householdId]);
        closeSlider();
      }
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (type === "edit user") {
        await updateUserMutation.mutateAsync(formData);
      } else {
        await createUserMutation.mutateAsync(formData);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4 sm:p-6">
      {(updateUserMutation.isError || createUserMutation.isError) && (
        <AlertError variant="destructive" className="mb-6">
          There was an error saving the user. Please try again.
        </AlertError>
      )}

      <form className="space-y-8" onSubmit={handleSubmit}>
        {/* Personal Information Section */}
        <div className="space-y-6">
          <div className="border-b border-gray-200 pb-4">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">
              Personal Information
            </h3>
            <p className="mt-1 text-sm sm:text-base text-gray-600">
              Basic user details and contact information
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {/* Name Fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
               Legal First Name*
              </label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required={type !== "edit user"}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
               Legal Last Name*
              </label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required={type !== "edit user"}

                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          


            {/* Contact Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date of Birth*
              </label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
                
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            {/* Email Fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Primary Email*
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required={type !== "edit user"}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Secondary Email
              </label>
              <input
                type="email"
                name="secondary_email"
                value={formData.secondary_email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            {/* Status Fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Marital Status
              </label>
              <select
                name="marital_status"
                value={formData.marital_status}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="" hidden>Please Select</option>
                <option>Single</option>
                <option>Married</option>
                <option>Widowed</option>
                <option>Divorced</option>
                <option>Separated</option>
                <option>Domestic Partnership</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Tax Status
              </label>
              <select
                name="tax_status"
                value={formData.tax_status}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="" hidden>Please Select</option>
                <option>Single</option>
                <option>Married filing jointly</option>
                <option>Married filing separately</option>
                <option>Head of household</option>
                <option>Qualifying widow(er) with dependent child</option>
              </select>
            </div>

            {/* Pronouns */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Pronouns
              </label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="" hidden>Please Select</option>
                <option>He/Him</option>
                <option>She/Her</option>
                <option>They/Them</option>
                <option>Prefer not to respond</option>
              </select>
            </div>
            <div></div>

            {/* Employment Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Job Title
              </label>
              <input
                type="text"
                name="job_title"
                value={formData.job_title}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div >
              <label className="block text-sm font-medium text-gray-700 ">
                Company
              </label>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {/* Preferred Name Inputs */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Preferred First Name
              </label>
              <input
                type="text"
                name="preferred_first_name"
                placeholder='Same as Legal First name'
                value={formData.preferred_first_name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Preferred Last Name
              </label>
              <input
                type="text"
                name="preferred_last_name"
                placeholder='Same as Legal Last name'
                value={formData.preferred_last_name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {/* Deceased Status */}
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Deceased Status
              </label>
              <Toggle
                enabled={formData.is_deceased}
                setEnabled={(value) => setFormData(prev => ({ ...prev, is_deceased: value }))}
                color="red"
              />
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="space-y-6">
          <div className="border-b border-gray-200 pb-4">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900">
              Address Information
            </h3>
            <p className="mt-1 text-sm sm:text-base text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Country
              </label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              >
                {countries}
              </select>
            </div>

            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Street Address
              </label>
              <input
                type="text"
                name="street_address"
                value={formData.street_address}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Street Address 2
              </label>
              <input
                type="text"
                name="street_address_2"
                value={formData.street_address_2}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                City*
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                State / Province*
              </label>
              <select
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              >
                {usStates}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                ZIP / Postal Code
              </label>
              <input
                type="text"
                name="zip"
                value={formData.zip}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end pt-6 border-t border-gray-200">
          <button
            type="submit"
            disabled={updateUserMutation.isLoading || createUserMutation.isLoading}
            className="btn-xl btn-primary flex items-center gap-2"
          >
            {updateUserMutation.isLoading || createUserMutation.isLoading ? (
              <>
               <SmallLoadingSpinner textColor="text-white"/>
                <span>Saving Changes...</span>
              </>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;