import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {  XIcon } from '@heroicons/react/outline';

import SelectBox from './SelectBox';
import chunkArray from './chunkArray';


const householdsApi = new ApiRequest(API_ROUTES.HOUSEHOLDS, API_AUTH_TYPES.ADVISOR);
const tagOptions = [
  { value: "RMD", color: "bg-blue-200", order: 1 },
  { value: "Backdoor", color: "bg-orange-200", order: 2 },
  { value: "I-bonds", color: "bg-purple-200", order: 3 },
  { value: "Student Loans", color: "bg-red-200", order: 4 },
  { value: "Roth Conversion", color: "bg-green-200", order: 5 },
  { value: "Drawdown", color: "bg-yellow-200", order: 6 },
  { value: "Unwind", color: "bg-cyan-200", order: 7 },
  { value: "Lump Sum Roth", color: "bg-pink-200", order: 8 },
  { value: "SEP", color: "bg-teal-200", order: 9 },
  { value: '529', color: 'bg-blue-200', order: 10 },
  { value: 'PSLF', color: 'bg-orange-200', order: 11 },
  { value: "RMD Done", color: "bg-red-200", order: 13 },
  { value: "Downturn Contribution", color: "bg-green-200", order: 14 },
  { value: 'Tax Loss Harvesting', color: 'bg-blue-200', order: 15 },
  { value: "Market Sensitive", color: "bg-orange-200", order: 16 },
  { value: "Tax Refund", color: "bg-purple-200", order: 17 },
  { value: "Self-Employed", color: "bg-red-200", order: 18 },
  { value: 'Travel Hacker', color: "bg-green-200", order: 19 },
  { value: 'Uses CPA', color: 'bg-blue-200', order: 20 }
]


const PlanningTags = () => {
  const { household_id: householdId } = useParams();
  const queryClient = useQueryClient();
  const planningTags = useQuery({
    queryFn: householdsApi.getFn({ endpoint: "planning_tags", params: [householdId] }),
    queryKey: `planning-tags-${householdId}`,
  });
  const removeTag = useMutation((tag) => householdsApi.patch({ endpoint: "remove_planning_tag", params: [householdId], body: { tag } }),
    {
      onMutate: async (tagToRemove) => {
        await queryClient.cancelQueries(`planning-tags-${householdId}`);
        const previousTags = queryClient.getQueryData(`planning-tags-${householdId}`);
        queryClient.setQueryData(`planning-tags-${householdId}`, old => ({
          ...old,
          planning_tags: old.planning_tags.filter(tag => tag !== tagToRemove)
        }));
        return { previousTags };
      },
      onError: (err, tagToRemove, context) => {
        queryClient.setQueryData(`planning-tags-${householdId}`, context.previousTags);
      },
      onSettled: () => {
        queryClient.invalidateQueries(`planning-tags-${householdId}`);
      },
    }
  );

  const addTag = useMutation((tag) => householdsApi.patch({ endpoint: "add_planning_tag", params: [householdId], body: { tag } }),
    {
      onMutate: async (newTag) => {
        await queryClient.cancelQueries(`planning-tags-${householdId}`);
        const previousTags = queryClient.getQueryData(`planning-tags-${householdId}`);
        queryClient.setQueryData(`planning-tags-${householdId}`, old => ({
          ...old,
          planning_tags: [...old.planning_tags, newTag]
        }));
        return { previousTags };
      },
      onError: (err, newTag, context) => {
        queryClient.setQueryData(`planning-tags-${householdId}`, context.previousTags);
      },
      onSettled: () => {
        queryClient.invalidateQueries(`planning-tags-${householdId}`);
      },
    }
  );

  //tag options that are not in the planning tags
  const tagOptionsNotInPlanningTags = planningTags.data?.planning_tags ? tagOptions.filter(tag => !planningTags.data?.planning_tags.includes(tag.value)) : tagOptions

  if (planningTags.isLoading) return <div>Loading...</div>
  return (
    <div className='relative'>
      {
        (planningTags.data.planning_tags?.length > 0) ?
          <div>
            <ul className='flex flex-col gap-2 -mb-6'>
              {planningTags.data.planning_tags && (() => {
                const tagChunks = chunkArray(planningTags.data.planning_tags, 3);
                return tagChunks.map((tagGroup, index) => (
                  <div key={index} className="flex justify-start flex-wrap">
                    {tagGroup.map((tag) => (
                      <li key={tag} className="bg-gray-200 flex items-center text-gray-700 font-normal rounded-full px-2 py-0.5 text-xs mr-2">
                        <p>{tag}</p>
                        <button onClick={() => { removeTag.mutate(tag) }}>
                          <XIcon className="h-4 w-4" />
                        </button>
                      </li>
                    ))}
                  </div>
                ));
              })()}
            </ul>
              <SelectBox isEmptyState={false} options={tagOptionsNotInPlanningTags.flatMap(item => item.value)} handleChange={(value) => addTag.mutate(value)} />
          </div>
          : (

              <SelectBox isEmptyState={true} options={tagOptionsNotInPlanningTags.flatMap(item => item.value)} handleChange={(value) => addTag.mutate(value)} />


          )
      }





    </div>
  )
}

export default PlanningTags




