import { XIcon } from '@heroicons/react/solid'
import React from 'react'

const ExpandedImage = ({ imgUrl, setImgUrl }) => {

    const closeExpandedView = () => {
        setImgUrl(null);
    };


  return (
      <>{imgUrl &&
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center   z-50" onClick={closeExpandedView}>
          <div className="flex items-center ">

              <img
                  src={imgUrl}
                  alt="Expanded view"
                  className="max-w-[500px] object-cover"
              />
              <button
                  onClick={closeExpandedView}
                  className="absolute top-4 right-4 text-gray-700 bg-white rounded-lg h-8 w-8 flex items-center justify-center"
              >
                  <XIcon className="h-5 w-5" />
              </button>
          </div>
          </div>
      }</>)
}

export default ExpandedImage