import React from "react";
import { useState } from "react";
import DoubleClickEdit from "../../../../../../components/DoubleClickEdit";
import { BsArrowClockwise } from "react-icons/bs";
import { STATUS_OPTIONS } from "../../../../../../constants";
import { XCircleIcon } from "@heroicons/react/solid";

const RepeatFrequency = ({ handleSubmitEdit, isClientPortal, task }) => {
  const [value, setValue] = useState(task.repeat_frequency);
  const [showEdit, setShowEdit] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value || null;
    setValue(newValue === "Never" ? null : newValue);
    handleSubmitEdit(newValue);
    setShowEdit(false);
  };

  const handleClearValue = () => {
    setValue(null);
    handleSubmitEdit(null);
    setShowEdit(false);
  }

  const repeatOptions = [
    { value: null, label: "Never" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "bi-yearly", label: "Bi-Yearly" },
    { value: "yearly", label: "Yearly" },
  ]
  return (
    <>


      {isClientPortal ?

        <div className="mt-2 flex items-center  text-gray-500 sm:mt-0 justify-end text-right ">

          {task?.repeat_frequency ? (
            <div className="flex space-x-2">
              <BsArrowClockwise
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <p>Repeats {value}</p>

            </div>
          ) : (
            <div>
            </div>
          )}

        </div> :
        <DoubleClickEdit
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          defaultComponent={
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <BsArrowClockwise
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {value ? (
                <div className="group flex items-center space-x-2">
                  <p>Repeats {value}</p>
                  <button onClick={handleClearValue} className="invisible w-5 group-hover:visible">
                    <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
              ) : (
                <p className="min-w-max">Does not repeat</p>
              )}
            </div>
          }
          editComponent={
            <select
              name="repeat-input"
              id="repeat-input"
              className="input-primary"
              onChange={handleChange}
            >
              <option hidden value=""></option>
              {repeatOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          }
          handleSubmitEdit={() => { }}
          componentName="repeat_frequency"
        />}
    </>
  );
};

export default RepeatFrequency;
